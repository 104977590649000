import { createSlice } from '@reduxjs/toolkit';

import { TDCalculationResponseModel } from '../models/td-calculation.model';
import { getDepositSummary } from '../thunks/deposit.thunk';

const initialState: TDCalculationResponseModel = {
  TDCalculationResponse: undefined,
  TransactionStatus: undefined,
  error: null,
  loading: false,
};

const tdSlice = createSlice({
  name: 'tdcalculator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepositSummary.fulfilled, (state, { payload }) => {
      ({
        TDCalculationResponse: state.TDCalculationResponse,
        TransactionStatus: state.TransactionStatus,
      } = payload.data);
    });
    builder.addCase(
      getDepositSummary.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = payload;
        state.loading = false;
      }
    );
  },
});

export default tdSlice.reducer;
