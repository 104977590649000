import { createSlice } from '@reduxjs/toolkit';

import {
  CardDetailsList,
  CardProcessingDetail,
  DebitCardSummaryResponse,
} from '../../models/debit-card.model';
import { getDebitCardList } from '../thunks/debit-card.thunk';

const initialState: DebitCardSummaryResponse = {
  status: undefined,
  data: undefined,
  error: undefined,
  successfulResponse: undefined,
  loading: true,
  cardProcessingDetail: {} as CardProcessingDetail,
  updateDebitCardList: {} as CardDetailsList[],
};
const getDebitCardSlice = createSlice({
  name: 'getDebitCardList',
  initialState,
  reducers: {
    cardProcessingDetail(state, { payload }) {
      state.cardProcessingDetail = payload;
    },
    updateDebitCardList(state, { payload }) {
      state.updateDebitCardList = payload;
    },
    clearDebitCardData(state) {
      state.status = undefined;
      state.error = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDebitCardList.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.successfulResponse = payload.successful_response;
      state.data = payload.data;
      state.error = payload.error;
      state.loading = false;
    });
    builder.addCase(getDebitCardList.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getDebitCardList.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export const { cardProcessingDetail, updateDebitCardList, clearDebitCardData } =
  getDebitCardSlice.actions;
export default getDebitCardSlice.reducer;
