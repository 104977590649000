import { useEffect } from 'react';

import { useThrottle } from '@core-hooks';

const InactivityDetector = ({
  timeout,
  onInactive,
}: {
  timeout: number;
  onInactive: () => void;
}) => {
  let inactivityTimer;

  const resetTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      document.removeEventListener('mousemove', resetOnActivity);
      document.removeEventListener('keydown', resetOnActivity);
      document.removeEventListener('touchstart', resetOnActivity);
      onInactive();
    }, timeout);
  };

  const debounceResetTimer = useThrottle(resetTimer, 500);

  const resetOnActivity = () => {
    debounceResetTimer();
  };

  useEffect(() => {
    debounceResetTimer();
    document.addEventListener('mousemove', resetOnActivity);
    document.addEventListener('keydown', resetOnActivity);
    document.removeEventListener('touchstart', resetOnActivity);

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener('mousemove', resetOnActivity);
      document.removeEventListener('keydown', resetOnActivity);
      document.removeEventListener('touchstart', resetOnActivity);
    };
  }, []);

  return <></>;
};

export default InactivityDetector;
