import { ACCOUNT_STATUS_CODES } from '@core-constants';
import { nominees } from '@src/modules/deposit/constants/deposit.constant';

export const noOftransactions = '10';
export const currentAccProductCode = '202';
export const savingsAccProductCode = '201';

export const AccountStatusOrder = {
  Active: 1,
  Inactive: 2,
  Dormant: 3,
  Blocked: 4,
};

export const mockGraphData = [
  {
    logMonth: 'Jan',
    avgBalance: '276',
    averageAmountType: 'Monthly',
  },
  {
    logMonth: 'Feb',
    avgBalance: '124',
    averageAmountType: 'Monthly',
  },
  {
    logMonth: 'Mar',
    avgBalance: '4000',
    averageAmountType: 'Monthly',
  },
  {
    avgBalance: '225',
    logMonth: 'Jan',
    averageAmountType: 'Quaterly',
  },
  { logMonth: 'Oct', avgBalance: '435', averageAmountType: 'Quaterly' },
  {
    avgBalance: '165',
    logMonth: 'July',
    averageAmountType: 'Quaterly',
  },
];

export const monthlyTitle = 'Monthly';
export const quarterlyTitle = 'Quaterly';

export const ACCOUNT_STATUS_ORDER_MAP = new Map([
  [AccountStatusOrder.Active, ACCOUNT_STATUS_CODES.active],
  [AccountStatusOrder.Inactive, ACCOUNT_STATUS_CODES.inactive],
  [AccountStatusOrder.Dormant, ACCOUNT_STATUS_CODES.dormant],
  [AccountStatusOrder.Blocked, ACCOUNT_STATUS_CODES.blocked],
]);

export const CustomerAccountTypes = {
  savingAccount: 'SavingsAccount',
  currentAccount: 'CurrentAccount',
  cashCredit: 'CashCredit',
  overDraft: 'Overdraft',
  current: 'current',
  savings: 'savings',
};

export const MockProfileData = {
  status: 'success',
  data: {
    userId: '36433a57-15f8-4c8d-8e0b-ce750d9b46da',
    name: 'Radhey Shyam Dang',
    username: 'testdebit12',
    emailAddress: 'testcif4+dcpb@gmail.com',
    mobileNumber: '7766776601',
    cifNumber: '22398099',
    userType: 'MERCHANT',
    preferredLanguage: 'EN',
    platform: 'WEB',
    activeMerchantId: '6f5ca077-6159-4758-a62e-03afa4a37a0c-1702623418934',
    merchants: {
      '6f5ca077-6159-4758-a62e-03afa4a37a0c-1702623418934': {
        entityCif: '22398099',
        userCif: '22398099',
        default: true,
      },
    },
    roles: [
      {
        id: 'b245818e-5cf6-402f-85e7-7df826e6156c',
        name: 'onboarding service role',
      },
    ],
    permissions: [
      'onboarding.qr.access',
      'merchant.domain.vpa',
      'deposit.request.update',
      'deposit.request.view',
      'deposit.request.delete',
      'deposit.request.create',
      'account.aubank.view',
      'account.otherbank.view',
      'account.loan.create',
      'account.loan.view',
      'account.statement.view',
      'account.monthlybalance.view',
      'account.payee.view',
      'account.payee.create',
      'account.payee.delete',
      'account.payee.update',
      'contentexporter.accountstatement.download',
      'contentexporter.interestcertificate.download',
      'contentexporter.amortization.download',
      'contentexporter.loanstatement.download',
      'csr.email.update',
      'csr.15ghform.create',
      'csr.cheque.update',
      'csr.cheque.create',
      'csr.srstatus.view',
      'positive.pay.create',
      'csr.address.update',
      'csr.sr.list',
      'csr.crmlead.create',
      'positive.pay.view',
      'csr.cheque.view',
      'crosssell.payload.view',
      'crosssell.cache.update',
      'crosssell.equitypayload.view',
      'videobanking.payload.view',
      'videobanking.cache.update',
      'debitcard.detail.view',
      'debitcard.limit.update',
      'debitcard.cvv.view',
      'debitcard.upgrade.update',
      'debitcard.pin.update',
      'debitcard.pin.view',
      'debitcard.reward.view',
      'master.ifsc.view',
      'master.banklist.view',
      'master.sidemenu.view',
      'master.apiheaders.view',
      'csr.email.update',
      'csr.15ghform.create',
      'csr.cheque.update',
      'csr.cheque.create',
      'csr.srstatus.view',
      'positive.pay.create',
      'csr.address.update',
      'csr.sr.list',
      'csr.crmlead.create',
      'positive.pay.view',
      'crosssell.payload.view',
      'crosssell.cache.update',
      'crosssell.equitypayload.view',
      'videobanking.payload.view',
      'videobanking.cache.update',
      'csr.customerdetails.update',
      'login.sso.view',
      'csr.cbs.accountblock.update',
      'login.sso.view',
      'inventory.stockmovement.update',
      'debitcard.detail.update',
      'inventory.stockmovement.view',
      'payment.ift.view',
      'payment.neft.view',
      'payment.neft.create',
      'payment.rtgs.view',
      'payment.rtgs.create',
      'payment.imps.create',
      'payment.imps.view',
      'payment.transfer.create',
      'payment.transaction.view',
      'payment.transaction.delete',
      'payment.linkpayee.create',
      'payment.linkpayee.delete',
      'payment.linkpayee.update',
      'payment.linkpayee.view',
      'deposit.request.update',
      'deposit.request.view',
      'deposit.request.delete',
      'deposit.request.create',
      'account.aubank.view',
      'account.otherbank.view',
      'account.loan.create',
      'account.loan.view',
      'account.statement.view',
      'account.monthlybalance.view',
      'account.payee.view',
      'account.payee.create',
      'account.payee.delete',
      'account.payee.update',
      'contentexporter.accountstatement.download',
      'contentexporter.interestcertificate.download',
      'contentexporter.amortization.download',
      'contentexporter.loanstatement.download',
      'csr.email.update',
      'csr.15ghform.create',
      'csr.cheque.update',
      'csr.cheque.create',
      'csr.srstatus.view',
      'positive.pay.create',
      'csr.address.update',
      'csr.sr.list',
      'csr.crmlead.create',
      'positive.pay.view',
      'csr.cheque.view',
      'crosssell.payload.view',
      'crosssell.cache.update',
      'crosssell.equitypayload.view',
      'videobanking.payload.view',
      'videobanking.cache.update',
      'csr.customerdetails.update',
      'login.sso.view',
      'csr.frauddispute.create',
      'csr.cbs.accountblock.update',
      'debitcard.detail.view',
      'debitcard.limit.update',
      'debitcard.cvv.view',
      'debitcard.upgrade.update',
      'debitcard.pin.update',
      'debitcard.pin.view',
      'debitcard.reward.view',
      'debitcard.detail.update',
      'master.ifsc.view',
      'master.banklist.view',
      'master.sidemenu.view',
      'master.apiheaders.view',
      'payment.ift.view',
      'payment.neft.view',
      'payment.neft.create',
      'payment.rtgs.view',
      'payment.rtgs.create',
      'payment.imps.create',
      'payment.imps.view',
      'payment.transfer.create',
      'payment.transaction.view',
      'payment.transaction.delete',
      'payment.linkpayee.create',
      'payment.linkpayee.delete',
      'payment.linkpayee.update',
      'payment.linkpayee.view',
      'payment.auth.callback',
      'Persona1',
      'deposit.request.mapped.view',
      'debitcard.module.view',
      'payment.module.view',
      'payee.module.view',
      'casa.module.view',
      'onboarding.module.view',
      'deposit.module.view',
      'account.module.view',
      'contentexporter.module.view',
      'csr.module.view',
      'inventory.module.view',
      'cpc.module.view',
      'loan.module.view',
      'investment.module.view',
      'insurance.module.view',
      'applynow.module.view',
      'gstpayment.module.view',
      'deposit.auth.callback',
      'allowance.module.view',
    ],
    userGroups: [],
    browserInfo: {
      name: 'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Mobile Safari/537.36',
    },
    lastLoggedIn: '2024-01-12 06:57:00',
    userNotification: {
      mobileOtpActive: false,
      emailOtpActive: true,
      pushNotificationActive: true,
      mobileEmailOtpActive: true,
    },
    selfAuth: false,
  },
  error: null,
  successfulResponse: true,
};

export const CIFData = {
  status: '',
  loading: false,
  error: null,
  successfulResponse: true,
  data: {
    Overdraft: [],
    Loans: [],
    Trade: [],
    Deposit: [],
    CurrentAccount: [],
    SavingsAccount: [
      {
        ModuleCode: 'C',
        ProductName: 'AU Savings Account-Maximum',
        AccountId: '1711219510616344',
        CASAAccountName: 'HANIF BABU SAYYED',
        CurrencyCode: '1',
        CurrencyShortName: 'INR',
        CustomerRelationship: 'SOW',
        BalanceBook: '12887.64',
        UnclearFunds: '0.00',
        Classification: 'NORMAL',
        Reason: 'UNBLOCKED ',
        BillAmount: '0.00',
        OriginalBalance: '0.00',
        CurrentStatus: '17',
        CurrentStatusDescription: 'ACCOUNT OPEN - CREDIT WITH OVE',
        AcyAmount: '12887.64',
        AvailableBalance: '12887.64',
        LcyAmount: '12887.64',
        TotalAcyAmount: '18186.64',
        TotalLcyAmount: '18186.64',
        BranchName: 'Khetri',
        FutureDatedAmount: '0.00',
        SafeDepositBoxId: '0',
        Iban: '0',
        DateAccountOpen: '2017-08-17',
        DateRelation: '2017-05-06',
        MonthsSinceActive: '69',
        BalUncollectedPrinc: '0.00',
        BalUncollectedInt: '0.00',
        TotalBalUncollecPrinc: '0.00',
        TotalBalUncollecInt: '0.00',
        TotalBalBook: '18186.64',
        DateValue: '2017-05-06',
        BalPrincipal: '0',
        IntRate: '0',
        LienAmount: '0',
        InstallmentAmount: '0',
        OtherArrear: '0',
        BalCombinedAcy: '12887.64',
        BalCombinedLcy: '12887.64',
        BranchCode: '2195',
        IsTDLinkage: 'N  ',
        HoldAmount: '0',
        NomineeName: 'sunil',
        ODLimitSactioned: '0',
        ODLimitUtilized: '0',
        OperationMode: 'SINGLYnull',
        SmallClearingAccountNo: '000061',
        CASARelationshipDetails: [
          {
            CustomerId: '22157603',
            Emailid: 'aishwarygupta22@gmail.com',
            JointHolderName: 'HANIF BABU SAYYED',
            MobileNo: '8879048733',
            Relationship: 'SOW',
          },
        ],
        AmtGoal: '0',
        ProductCode: '20115',
        Tenure: '0 Months 0 Days 0 Years ',
        AccountBalance: '12887.64',
        AccountStatus: 'Active',
        UtilizedLimit: '12887.64',
        SanctionedLimit: '0.00',
        AvailableLimit: '-12887.64',
        NickName: '',
        HoldingType: 'Sole Owner',
        ProductDisplayName: 'Savings Account',
      },
      {
        ModuleCode: 'C',
        ProductName: 'AU Savings Account-Maximum',
        AccountId: '1911219522202492',
        CASAAccountName: 'HANIF BABU SAYYED',
        CurrencyCode: '1',
        CurrencyShortName: 'INR',
        CustomerRelationship: 'JOF',
        BalanceBook: '5299.00',
        UnclearFunds: '0.00',
        Classification: 'NORMAL',
        Reason: 'UNBLOCKED ',
        BillAmount: '0.00',
        OriginalBalance: '0.00',
        CurrentStatus: '8',
        CurrentStatusDescription: 'ACCOUNT OPEN REGULAR',
        AcyAmount: '5299.00',
        AvailableBalance: '5299.00',
        LcyAmount: '5299.00',
        TotalAcyAmount: '18186.64',
        TotalLcyAmount: '18186.64',
        BranchName: 'Khetri',
        FutureDatedAmount: '0.00',
        SafeDepositBoxId: '0',
        DateAccountOpen: '2019-02-27',
        DateRelation: '2019-02-27',
        MonthsSinceActive: '51',
        BalUncollectedPrinc: '0.00',
        BalUncollectedInt: '0.00',
        TotalBalUncollecPrinc: '0.00',
        TotalBalUncollecInt: '0.00',
        TotalBalBook: '18186.64',
        DateValue: '2019-02-27',
        BalPrincipal: '0',
        IntRate: '0',
        LienAmount: '0',
        InstallmentAmount: '0',
        OtherArrear: '0',
        BalCombinedAcy: '5299',
        BalCombinedLcy: '5299',
        BranchCode: '2195',
        IsTDLinkage: 'N  ',
        HoldAmount: '0',
        NomineeName: 'Ravi',
        ODLimitSactioned: '0',
        ODLimitUtilized: '0',
        OperationMode: 'EITHER OR SURVIVORnull',
        SmallClearingAccountNo: '004270',
        CASARelationshipDetails: [
          {
            CustomerId: '23797979',
            Emailid: '23797979@axc.com',
            JointHolderName: 'PUJA DEVI',
            MobileNo: '1023797979',
            Relationship: 'JOO',
          },
        ],
        AmtGoal: '0',
        ProductCode: '20115',
        Tenure: '0 Months 0 Days 0 Years ',
        AccountBalance: '5299.00',
        AccountStatus: 'Active',
        UtilizedLimit: '5299.00',
        SanctionedLimit: '0.00',
        AvailableLimit: '-5299.00',
        NickName: 'bla',
        HoldingType: 'Joint Or First',
        ProductDisplayName: 'Savings Account',
      },
    ],
    Locker: [],
    Insurance: [],
    CashCredit: [],
    KisanCreditCard: [],
    InternalProduct: [],
    FixedDeposit: [],
    RecurringDeposit: [],
    TaxSavingDeposit: [],
    CustomerAccount: [],
  },
};

export const productTypes = {
  Savings: 'sa',
  Current: 'ca',
  Odcc: 'odcc',
};

export const mockNominee = {
  status: 'success',
  loading: false,
  error: null,
  successfulResponse: true,
  NomineeDetails: nominees,
  acNo: '22388393939',
  TransactionBranch: '2011',
  addNomineeData: nominees,
  TransactionStatus: {
    ResponseCode: '',
    ResponseMessage: '',
  },
};

export const accountConfig = {
  CONFIG_TYPE: 'FE_ACCOUNT_CONFIG',
  MODULE: 'feAccountConfig',
};
