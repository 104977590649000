import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { UpiRequest } from '../models/upi.model';

export const upiValidate = createAsyncThunk(
  '/api/integration-service/verify-vpa',
  async (upiValidateDetail: UpiRequest, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.UPIVALIDATE,
        url: '',
        obj: upiValidateDetail,
      });

      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(handleError(error));
    }
  }
);
