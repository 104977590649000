import React, { FC, useMemo } from 'react';

import { XB_Input } from '@core-components/atoms';
import { RE_DIGIT } from '@core-constants';

import './XB_InputOtp.style.scss';

export interface InputOtpProps {
  id: string;
  length: number;
  value?: string;
  label?: string;
  additionalText?: string | React.ReactNode;
  textType?: 'error' | 'success' | 'info';
  separator?: number;
  size?: 'x' | '2x';
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onValueChange: (string) => void;
}

export const XB_InputOtp: FC<InputOtpProps> = ({
  id,
  length,
  label,
  value = '',
  additionalText,
  placeholder = '_',
  separator,
  textType,
  disabled = false,
  size = 'x',
  autoFocus,
  onValueChange,
}: InputOtpProps) => {
  const classList = ['input-otp', `input-otp--${size}`];
  textType && classList.push(`input-otp--${textType}`);
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items: string[] = [];

    for (let i = 0; i < length; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [length, value]);

  const focusToSiblingInput = (elm: HTMLElement) => {
    if (elm) {
      setTimeout(() => {
        elm.focus();
      }, 0);
    }
  };

  const getSibling = (elmId: string, dir: 'prev' | 'next') => {
    const lastIndex = elmId.lastIndexOf('_') + 1;
    const elmIdx = Number(elmId.substring(lastIndex));
    const siblingIdx = dir === 'next' ? elmIdx + 1 : elmIdx - 1;
    return document.getElementById(
      `${elmId.substring(0, lastIndex)}${siblingIdx.toString()}`
    ) as HTMLInputElement | null;
  };

  const setFieldType = (target: HTMLInputElement) => {
    setTimeout(function () {
      target.type = 'password';
    }, 300);
  };

  const onOtpChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    const targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    let nextInputEl = getSibling(target.id, 'next');
    target.type = 'text';
    setFieldType(target);
    if (
      !isTargetValueDigit &&
      (targetValue !== '' || (nextInputEl && nextInputEl.value !== ''))
    ) {
      return;
    }

    if (targetValue.length === 0) {
      nextInputEl = getSibling(target.id, 'prev');
    }
    const newValue =
      value.substring(0, idx) + targetValue + value.substring(idx + 1);
    onValueChange(newValue.substring(0, 6));
    focusToSiblingInput(nextInputEl as HTMLInputElement);
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToSiblingInput(
        getSibling(target.id, 'next') as HTMLInputElement
      );
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToSiblingInput(
        getSibling(target.id, 'prev') as HTMLInputElement
      );
    }

    const targetValue = target.value;
    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);
    if (targetValue === key) {
      e.preventDefault();
      target.type = 'text';
      setFieldType(target);
      const nextInputEl = getSibling(target.id, 'next');
      focusToSiblingInput(nextInputEl as HTMLInputElement);
      return;
    }

    if (key !== 'Backspace' || targetValue !== '') {
      return;
    }
    focusToSiblingInput(getSibling(target.id, 'prev') as HTMLInputElement);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    const prevInputEl = getSibling(target.id, 'prev');

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div
      className={`${classList.join(' ')}`}
      role="group"
      aria-labelledby={`otp-label-${id}`}
      aria-describedby={`otp-text-${id}`}
    >
      {label && (
        <div id={`otp-label-${id}`} className="otp-input__label text-sm mb-2">
          {label}
        </div>
      )}
      <div className="input-otp__fields flex gap-x-2 items-center">
        {valueItems.map((digit, idx: number) => {
          const isSeparatorInput =
            !!separator &&
            idx !== length - 1 &&
            (idx + 1 + separator) % separator === 0;
          return (
            <React.Fragment key={`${id}_otp_${idx}`}>
              <XB_Input
                id={`${id}_otp_${idx}`}
                classes="inline"
                type="password"
                placeholder={placeholder}
                inputMode="numeric"
                pattern="\d{1}"
                value={digit}
                onChange={(e) => onOtpChange(e, idx)}
                onKeyDown={inputOnKeyDown}
                onFocus={inputOnFocus}
                disabled={disabled}
                autoFocus={autoFocus && idx === 0}
              />
              {isSeparatorInput && (
                <span className="input-otp__separator w-4 text-center">-</span>
              )}
            </React.Fragment>
          );
        })}
      </div>
      {additionalText && (
        <p id={`otp-text-${id}`} className="input-otp__text text-sm mt-2">
          {additionalText}
        </p>
      )}
    </div>
  );
};
