import { combineReducers } from 'redux';

import accountDetailsSlice from '@modules/account-services/stores/slices/account-details.slice';
import casaSummarySlice from '@modules/account-services/stores/slices/casa-summary.slice';
import debitCardDetailSlice from '@modules/account-services/stores/slices/debit-card-detail.slice';
import statementSlice from '@modules/account-statement/stores/slices/account-statement.slice';
import mappedAccountSlice from '@modules/admin-portal/reducers/slices/merchant-creation.slice';
import auBankOffersSlice from '@modules/au-dashboard/stores/slices/au-bank-offers.slice';
import auDashboardSlice from '@modules/au-dashboard/stores/slices/au-dashboard.slice';
import auOffersSlice from '@modules/au-dashboard/stores/slices/au-offers.slice';
import getPayeeSlice from '@modules/beneficiary/stores/slices/get-payee.slice';
import creditCardSlice from '@modules/credit-card/stores/slices/credit-card.slice';
import getDebitCardSlice from '@modules/debit-card/stores/slices/debit-card.slice';
import depositSummarySlice from '@modules/deposit/stores/slices/deposit-summary.slice';
import fdOpenFormDataSlice from '@modules/deposit/stores/slices/fd-open-form-data.slice';
import tdDepositSlice from '@modules/deposit/stores/slices/td-deposit.slice';
import tdEnquirySlice from '@modules/deposit/stores/slices/td-enquiry.slice';
import inventoryManagementSlice from '@modules/inventory-management/stores/slices/inventory-management.slice';
import linkPayeeSetAllowanceSlice from '@modules/link-payee/stores/slices/link-payee-set-allowance.slice';
import linkPayeeSlice from '@modules/link-payee/stores/slices/link-payee.slice';
import loanSummarySlice from '@modules/loan/stores/slices/loan-summary.slice';
import changeNomineeSlice from '@modules/nominee/stores/slices/change-nominee.slice';
import coolingPeriodSlice from '@modules/payments/stores/slices/cooling-period.slice';
import freqPayeeSlice from '@modules/payments/stores/slices/freq-payee.slice';
import paymentsSlice from '@modules/payments/stores/slices/payments.slice';
import recentTransactionsListSlice from '@modules/payments/stores/slices/recent-transaction-list.slice';
import scheduledTransactionsListSlice from '@modules/payments/stores/slices/scheduled-transaction-list.slice';
import transactionSlice from '@modules/payments/stores/slices/transaction.slice';
import notificationsSlice from '@modules/profile-info/stores/slices/notifications.slice';
import validateResumeJourneySlice from '@modules/qr-collection/stores/slices/validate-resume-journey.slice';
import getSrListSlice from '@modules/service-request/stores/slices/request-list.slice';
import serviceRequestSlice from '@modules/service-request/stores/slices/service-request.slice';
import inventorySlice from '@modules/so-inventory/stores/slices/so-inventory.slice';
import getAppIconsSlice from '@src/core-stores/slices/au-dashboard-icons.slice';
import merchantManagementSlice from '@src/modules/admin-portal/stores/slices/merchant-management.slice';
import auOverviewSlice from '@src/modules/au-dashboard/stores/slices/au-overview.slice';
import auDedupeLiteSlice from '@src/modules/mr-login/stores/slices/au-dedupe-lite.slice';
import onboardingDebitCardSlice from '@src/modules/mr-registration/stores/slices/onboarding-debit-card.slice';
import fraudAndDisputeSlice from '@src/modules/profile-info/stores/slices/fraud-and-dispute.slice';
import profileSettingSlice from '@src/modules/profile-info/stores/slices/profile-setting.slice';

import qrOnboardingSlice from '../../modules/qr-collection/stores/slices/qr-onboarding.slice';
import internalAccountVerify from '../slices/internal-account-verification.slice';
import accTypeDetailsSlice from './account-type-details.slice';
import accountVerificationSlice from './account-verification.slice';
import AUAlertContainerSlice from './au-alert-container.slice';
import auDashboardDataSlice from './au-dashboard-data.slice';
import authReducer from './auth.slice';
import bankListSlice from './bank-list.slice';
import configServiceSlice from './config-service.slice';
import getConcentDataSlice from './consent-acceptance.slice';
import countryCodeSlice from './country-code.slice';
import leadGenMasterSlice from './crm-lead-gen-master.slice';
import crmLeadGenSlice from './crm-lead-gen.slice';
import customerAccountsSlice from './customer-accounts.slice';
import getCustomerDetailsSlice from './get-customer-details.slice';
import getIncomeTurnoverListSlice from './get-income-turnover-list.slice';
import getMerchantDetail from './get-merchant-details.slice';
import getPincodeDetailSlice from './get-pincode-detail.slice';
import ifscSearchBank from './ifsc-search.slice';
import initSlice from './init.slice';
import kitTypeListSlice from './kit-type.slice';
import profileDetailsSlice from './login.slice';
import dashboardTypeToggle from './merchant-type.slice';
import nomineeDetailSlice from './nominee-detail.slice';
import payeeAccountConfirmation from './payee-account-confirm.slice';
import payeeAccountList from './payee-casa-accounts.slice';
import paymentTransferSlice from './payment-transfer.slice';
import productCodeSlice from './product-code.slice';
import saveBackModalSlice from './save-back-modal.slice';
import tdCalculatorSlice from './td-calculator.slice';
import updatePurposeSlice from './update-purpose.slice';
import upiValidateSlice from './upi-validation.slice';
import userProfileImageSlice from './user-profile-image.slice';

export { authReducer };

const rootReducer = (state: any, action: { type: string; payload?: any }) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  auth: authReducer,
  userProfileImage: userProfileImageSlice,
  init: initSlice,
  td: tdCalculatorSlice,
  productCode: productCodeSlice,
  nomineeDetail: nomineeDetailSlice,
  tdDepositDetail: tdDepositSlice,
  tdEnquiry: tdEnquirySlice,
  getIncomeList: getIncomeTurnoverListSlice,
  debitCard: debitCardDetailSlice,
  casaSummary: casaSummarySlice,
  updatePurpose: updatePurposeSlice,
  bankListDetail: bankListSlice,
  payeeCasaAccounts: payeeAccountList,
  fdRdformData: fdOpenFormDataSlice,
  upiValidateData: upiValidateSlice,
  kitTypeList: kitTypeListSlice,
  countryCodeList: countryCodeSlice,
  getPincode: getPincodeDetailSlice,
  depositSummaryData: depositSummarySlice,
  getCustomerDetails: getCustomerDetailsSlice,
  payeeAccountConfirm: payeeAccountConfirmation,
  getPayee: getPayeeSlice,
  ifscSearchList: ifscSearchBank,
  changeNominee: changeNomineeSlice,
  paymentsData: paymentsSlice,
  internalAccountVerification: internalAccountVerify,
  freqPayee: freqPayeeSlice,
  accountVerification: accountVerificationSlice,
  paymentTransfer: paymentTransferSlice,
  accType: accTypeDetailsSlice,
  coolingPeriod: coolingPeriodSlice,
  getDebitCard: getDebitCardSlice,
  auAlertContainer: AUAlertContainerSlice,
  customerAccounts: customerAccountsSlice,
  casaDetails: accountDetailsSlice,
  saveBackModal: saveBackModalSlice,
  statement: statementSlice,
  creditCard: creditCardSlice,
  loanSummaryData: loanSummarySlice,
  serviceRequestData: serviceRequestSlice,
  requestList: getSrListSlice,
  soInventory: inventorySlice,
  leadGenMasterSlice,
  crmLeadGenSlice,
  linkPayeeSetAllowanceSlice,
  linkPayee: linkPayeeSlice,
  inventoryManagementSlice,
  profileSettingSlice,
  auDashboardData: auDashboardSlice,
  dashboardTypeToggle,
  getAppIcons: getAppIconsSlice,
  auDashboard: auDashboardDataSlice,
  getMerchantDetail,
  profile: profileDetailsSlice,
  resumeJourneySlice: validateResumeJourneySlice,
  fraudAndDispute: fraudAndDisputeSlice,
  onboardingDebitCardList: onboardingDebitCardSlice,
  upiValidateSlice,
  config: configServiceSlice,
  auDashboardOffers: auOffersSlice,
  transaction: transactionSlice,
  recentTransactionsList: recentTransactionsListSlice,
  scheduledTransactionsList: scheduledTransactionsListSlice,
  concentData: getConcentDataSlice,
  auOverviewList: auOverviewSlice,
  auDedupeLite: auDedupeLiteSlice,
  auBankOffers: auBankOffersSlice,
  qrOnboarding: qrOnboardingSlice,
  getNotifications: notificationsSlice,
  mrMerchantDetails: merchantManagementSlice,
  mapperAccount: mappedAccountSlice,
});

export default rootReducer;
