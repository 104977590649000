import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetCustomerDetailsReq } from '../models/get-customer-details.model';

export const getCustomerDetails = createAsyncThunk(
  '/api/deposit-service/get-customer-details',
  async (customerDetailsData: GetCustomerDetailsReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.GET_CUSTOMER_DETAIL,
        url: '',
        obj: customerDetailsData,
      });
      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
