import { createSlice } from '@reduxjs/toolkit';

import { NomineeDetailState } from '../models/nominee-detail.model';
import { nomineeDetails } from '../thunks/deposit.thunk';

const initialState: NomineeDetailState = {
  status: undefined,
  error: undefined,
  successfulResponse: undefined,
  NomineeDetails: [],
  TransactionStatus: undefined,
  loading: false,
  acNo: undefined,
  TransactionBranch: undefined,
  addNomineeData: undefined,
};

const nomineeDetailSlice = createSlice({
  name: 'nomineedetail',
  initialState,
  reducers: {
    addNomineeData(state, { payload }) {
      state.addNomineeData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      nomineeDetails.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.loading = false;
        state.status = payload.data?.status;
        state.error = payload.data?.error;
        state.successfulResponse = payload.successful_response;
        state.NomineeDetails = payload.data?.NomineeDetails;
        state.acNo = payload.data?.acNo;
        state.TransactionBranch = payload.data?.transactionBranch;
      }
    );
    builder.addCase(
      nomineeDetails.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.loading = false;
        state.successfulResponse = false;
        state.NomineeDetails = undefined;
      }
    );
    builder.addCase(nomineeDetails.pending, (state) => {
      state.status = undefined;
      state.error = '';
      state.loading = true;
      state.successfulResponse = false;
      state.NomineeDetails = undefined;
    });
  },
});
export const { addNomineeData } = nomineeDetailSlice.actions;
export default nomineeDetailSlice.reducer;
