import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { initializeApp } from 'firebase/app';

import { ErrorBoundary } from '@core-components/atoms';
import { appConstants } from '@core-constants';
import { store } from '@core-stores';
import i18n from '@i18n';

import App from './App';
import firebaseConf from './config/firebaseConfig';
import reportWebVitals from './reportWebVitals';

import './styles/index.scss';
import 'react-tooltip/dist/react-tooltip.css';

const container = document.getElementById('root');
const root = createRoot(container!);
const { REACT_APP_TYPE, REACT_APP_BROWSER_NAVIGATION } = process.env;

// initalize firebase
initializeApp(firebaseConf);

function importBuildTarget() {
  if (REACT_APP_TYPE === appConstants.APP_TYPE.MR) {
    return import('./core-navigations/mr-routes.navigation');
  } else if (REACT_APP_TYPE === appConstants.APP_TYPE.SO) {
    return import('./core-navigations/so-routes.navigation');
  } else if (REACT_APP_TYPE === appConstants.APP_TYPE.ADMIN) {
    return import('./core-navigations/admin-routes.navigation');
  } else {
    return Promise.reject(new Error(`No such build target: ${REACT_APP_TYPE}`));
  }
}

importBuildTarget().then(({ default: routes, redirectRoutes }) => {
  root.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ErrorBoundary>
          <App
            routes={routes}
            redirectRoutes={redirectRoutes}
            browserNavigation={REACT_APP_BROWSER_NAVIGATION !== 'false'}
          />
        </ErrorBoundary>

        <div id="toastContainer"></div>
      </Provider>
    </I18nextProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
