import { useEffect, useState } from 'react';

import './AU_RangeSlider.style.scss';

interface RangeSliderProps {
  min: number;
  max: number;
  initValue: number;
  showMinMax: boolean;
  minValToShow?: any;
  maxValToShow?: string;
  idappend?: number;
  onChange?: (val) => void;
}

export const AU_RangeSlider = ({
  min,
  max,
  showMinMax,
  minValToShow,
  maxValToShow,
  onChange,
  initValue,
  idappend = 1,
}: RangeSliderProps) => {
  const [currentRange, setCurrentRange] = useState<number>(initValue);
  const myID = new Date().getTime() + idappend;

  useEffect(() => {
    setCurrentRange(initValue);
  }, [initValue]);

  useEffect(() => {
    updateCss(window.document.getElementById(`${myID}-psider`));
  }, [currentRange]);

  const updateCss = (e) => {
    e.style.setProperty('--value', e.value);
    e.style.setProperty('--min', e.min === '' ? '0' : e.min);
    e.style.setProperty('--max', e.max === '' ? '100' : e.max);
  };

  return (
    <div className="flex  flex-col">
      <div className="range-slider">
        <input
          type="range"
          className="slider-progress"
          min={min}
          max={max}
          id={`${myID}-psider`}
          value={currentRange}
          autoFocus
          onChange={(e) => {
            setCurrentRange(Number(e.target.value));
            onChange?.(e.target.value);
            updateCss(e.target);
          }}
        />
      </div>
      {showMinMax && (
        <div className="flex justify-between mt-2">
          <p className="m-text-md-regular text-gray-500">{minValToShow}</p>
          <p className="m-text-md-regular text-gray-500">{maxValToShow}</p>
        </div>
      )}
    </div>
  );
};
