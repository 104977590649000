import { createSlice } from '@reduxjs/toolkit';

import { ChangeNomineeRes } from '../../models/change-nominee.model';
import { changeNominee } from '../thunks/nominee.thunk';

const initialState: ChangeNomineeRes = {
  error: undefined,
  data: undefined,
  status: undefined,
  successfulResponse: undefined,
  loading: undefined,
};
const changeNomineeSlice = createSlice({
  name: 'changeNomineeDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeNominee.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.successfulResponse = payload.successful_response;
      state.data = payload.data;
      state.error = payload.error;
      state.loading = false;
    });
    builder.addCase(changeNominee.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = undefined;
    });
    builder.addCase(
      changeNominee.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = undefined;
      }
    );
  },
});

export default changeNomineeSlice.reducer;
