import { EndPoints, Service } from '@core-services';

export const initVideoKyc = async (dataObject: any) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.initVideoKyc,
    method: 'POST',
    url: '',
    obj: dataObject,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const videoKycStatus = async (dataObject: any) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.videoKycStatus,
    method: 'POST',
    url: '',
    obj: dataObject,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const videoKycPoll = async (dataObject: any) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.videoKycPoll,
    method: 'POST',
    url: '',
    obj: dataObject,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
