import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function useReloadNav(nav: string) {
  const navigate = useNavigate();

  useEffect(() => {
    const onUnload = () => {
      sessionStorage.setItem('page-nav', 'reload');
      return '';
    };
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('page-nav') === 'reload') {
      sessionStorage.removeItem('page-nav');
      navigate(nav);
    }
  }, [nav, navigate]);
}
