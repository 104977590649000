import { useLayoutEffect, useRef, useState } from 'react';

import { getINRFormatWithDecimal } from '@core-utils';

import './AU_HorizontalBargraph.style.scss';

interface HorizontalBargraphProps {
  label: string;
  value: string;
  percentage: number;
  backgroundColor: 'blue' | 'rose';
}

export const AU_HorizontalBargraph = ({
  label,
  value = '0',
  percentage = 5,
  backgroundColor = 'blue',
}: HorizontalBargraphProps) => {
  const barRef = useRef<HTMLDivElement>(null);
  const h3Ref = useRef<HTMLDivElement>(null);
  const h2Ref = useRef<HTMLDivElement>(null);

  const [labelPlacementIn, setLabelPlacementIn] = useState(true);
  const [valuePlacementIn, setValuePlacementIn] = useState(true);

  useLayoutEffect(() => {
    const labelWidth = h3Ref.current?.clientWidth;
    const amountWidth = h2Ref.current?.clientWidth;
    const barWidth = barRef.current?.clientWidth;
    if (barWidth && labelWidth && amountWidth) {
      if (barWidth < labelWidth + amountWidth + 10) {
        if (barWidth > labelWidth + 20) {
          setLabelPlacementIn(true);
          setValuePlacementIn(false);
        } else {
          setLabelPlacementIn(false);
          setValuePlacementIn(false);
        }
      }
    }
  }, [label]);

  return (
    <div className="main-graph-container">
      <div
        className={`graph ${
          backgroundColor === 'blue' ? 'blue-graph' : 'rose-graph'
        }`}
        style={{ width: String(percentage) + '%' }}
        ref={barRef}
      >
        {labelPlacementIn && (
          <>
            <h3 className="m-text-md-regular" ref={h3Ref}>
              {label}
            </h3>
          </>
        )}
        {valuePlacementIn && (
          <>
            <h2 className="m-text-md-medium" ref={h2Ref}>
              {getINRFormatWithDecimal(Number(value))}
            </h2>
          </>
        )}
      </div>

      {!labelPlacementIn && (
        <>
          <h3 className="m-text-md-regular external-font mr-2" ref={h3Ref}>
            {label}
          </h3>
        </>
      )}
      {!valuePlacementIn && (
        <>
          <h2 className="external-font m-text-md-medium" ref={h2Ref}>
            {getINRFormatWithDecimal(Number(value))}
          </h2>
        </>
      )}
    </div>
  );
};
