import { createSlice } from '@reduxjs/toolkit';

import { ResponseCasaSummaryDetails } from '../../models/response-account-services.model';
import { casaSummaryDetails } from '../thunks/casa-summary.thunk';

const initialState: ResponseCasaSummaryDetails = {
  loading: false,
  data: undefined,
  error: undefined,
};

const casaSummarySlice = createSlice({
  name: 'casaSummary',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      casaSummaryDetails.pending,
      (state: ResponseCasaSummaryDetails) => {
        state.loading = true;
      }
    );

    builder.addCase(
      casaSummaryDetails.fulfilled,
      (state: ResponseCasaSummaryDetails, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.data = payload.data;
      }
    );

    builder.addCase(
      casaSummaryDetails.rejected,
      (state: ResponseCasaSummaryDetails, { payload }) => {
        state.loading = false;
        state.error = payload as undefined;
        state.data = undefined;
      }
    );
  },
  reducers: {
    updateNickName: (
      state,
      { payload }: { payload: { id: string; nickname: string } }
    ) => {
      state.data = {
        ...state.data,
        CasaAccounts:
          state.data?.CasaAccounts.map((i) => {
            if (i.accountNumber === payload.id) {
              return { ...i, nickName: payload.nickname };
            }
            return i;
          }) ?? [],
      };
    },
  },
});

export const { updateNickName } = casaSummarySlice.actions;

export default casaSummarySlice.reducer;
