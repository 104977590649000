import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, Service } from '@src/core-services';
import { ReqAccountVerification } from '@src/core-stores/models/account-verification.model';
import { handleError } from '@src/core-utils';

export const AccountVerify = createAsyncThunk(
  'AccountVerification',
  async (accountVerification: ReqAccountVerification, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentsService.paymentService.ACCOUNT_VERIFICATION,
        obj: accountVerification,
      });

      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getBucketMessage());
    }
  }
);
