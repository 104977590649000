import React, { useEffect, useState } from 'react';

import { Icon, XB_Button } from '@core-components/atoms';

import './XB_AlertDialog.style.scss';

interface AlertDialogProps {
  id?: string;
  title?: string;
  classes?: string;
  onCloseClick?: (val: any) => void;
  onPrimaryBtnClick?: (val: any) => void;
  onScondaryBtnClick?: (val: any) => void;
  iconName?: string;
  description?: string;
  type?: 'info' | 'error' | 'warning' | 'success';
  btnPrimary?: string;
  btnSecondary?: string;
  autoCloseInterval?: number;
  autoclose?: boolean;
}

export const XB_AlertDialog = ({
  id,
  title,
  type = 'success',
  description,
  classes = '',
  onCloseClick,
  iconName,
  btnPrimary,
  btnSecondary,
  onScondaryBtnClick,
  onPrimaryBtnClick,
  autoCloseInterval = 3000,
  autoclose = false,
}: AlertDialogProps) => {
  const [showElement, setShowElement] = useState(true);
  useEffect(() => {
    const timer = setTimeout(function () {
      if (autoclose) {
        setShowElement(false);
        onCloseClick?.(false);
      }
    }, autoCloseInterval);

    return () => {
      clearTimeout(timer);
    };
  }, [autoCloseInterval, autoclose]);

  const classList = ['alert', `alert--${type}`];
  classes && classList.push(classes);
  return (
    <>
      {showElement && (
        <div id={id} className={`${classList.join(' ')}`} role="alert">
          <div
            className={`container ${
              description ? 'container__icon--position' : ''
            } `}
          >
            {iconName && <Icon icon={iconName} width={24} height={24} />}

            {description ? (
              <>
                <div className="container__box">
                  <h4 className="m-text-md-medium">{title}</h4>
                  <p className="m-text-sm-regular mt-1">{description}</p>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="flex flex-row mt-0.5">
                    <p className="font-style">{title}</p>
                  </div>
                  <span className="icon__box">
                    <XB_Button
                      classes="btn--style"
                      dataTestId="action-btn"
                      size="md"
                      btnType="primary-underline"
                    >
                      {btnPrimary}
                    </XB_Button>
                  </span>
                </div>
              </>
            )}
          </div>
          <span
            className="close--icon text-xs cursor-pointer"
            onClick={onCloseClick}
          >
            <Icon icon="close" width={16} height={16} />
          </span>

          {description && (
            <div className="action__btn mt-2">
              {btnSecondary && (
                <XB_Button
                  dataTestId="action-btn"
                  size="md"
                  btnType="gray-underline"
                  onClick={onScondaryBtnClick}
                >
                  {btnSecondary}
                </XB_Button>
              )}
              {btnPrimary && (
                <XB_Button
                  dataTestId="action-btn"
                  size="md"
                  btnType="primary-underline"
                  onClick={onPrimaryBtnClick}
                >
                  {btnPrimary}
                </XB_Button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
