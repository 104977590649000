interface ErrorInfo {
  /**
   * Captures which component contained the exception, and its ancestors.
   */
  componentStack?: string | null;
  digest?: string | null;
}

export const logError = (...error: Array<ErrorInfo | string | Error | any>) => {
  return error;
};
