import './AU_Banner.style.scss';

interface BannerProps {
  title: string;
  titleClasses?: string;
  description?: string;
  descClasses?: string;
  actions?: React.JSX.Element;
}

export const AU_Banner = ({
  title,
  titleClasses,
  description,
  actions,
  descClasses,
}: BannerProps) => {
  return (
    <div className="banner-container">
      <div className="banner-info-container py-6 gap-x-1 w-6/12">
        <div className="banner-heading flex">
          <h3 className={titleClasses ?? 'm-display-lg-bold text-gray-800'}>
            {title}
          </h3>
        </div>
        {description !== undefined && (
          <div className="banner-desc flex-col mt-2">
            <p className={descClasses ?? 'm-text-lg-medium text-gray-600'}>
              {description}
            </p>
          </div>
        )}
      </div>
      <div className="button-container">{actions !== undefined && actions}</div>
    </div>
  );
};
