import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getOffersListThunk = createAsyncThunk(
  'getOffersListThunk',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: AUEndPoints.strapiService.getOffers,
        url: '',
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(handleError(error));
    }
  }
);
