import { createSlice } from '@reduxjs/toolkit';

import { ResGetNotifications } from '../../models/profile-info.model';
import { getNotifications } from '../thunks/profile-setting.thunk';

const initialState: ResGetNotifications = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: [],
};
const getNotificationsSlice = createSlice({
  name: 'getNotificationsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
        loading: state.loading,
      } = payload);
    });
    builder.addCase(getNotifications.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = [];
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getNotifications.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = [];
        state.loading = false;
      }
    );
  },
});

export default getNotificationsSlice.reducer;
