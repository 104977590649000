import { createSlice } from '@reduxjs/toolkit';

import { IConfigRes } from '../models/config-service.modal';
import { configRequest } from '../thunks/config-service.thunk';

const initialState: IConfigRes = {
  status: undefined,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
  loading: false,
};
const getConfigData = createSlice({
  name: 'configDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(configRequest.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
      } = payload);
      state.loading = false;
    });
    builder.addCase(configRequest.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      configRequest.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default getConfigData.reducer;
