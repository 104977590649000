import { createSlice } from '@reduxjs/toolkit';

import {
  CustomerAccountDetails,
  CustomerAccountsData,
  CustomerAccountsResponseState,
} from '../models/customer-accounts.model';
import { customerAccounts } from '../thunks/customer-accounts.thunk';

const initialState: CustomerAccountsResponseState = {
  status: undefined,
  error: null,
  data: undefined,
  loading: false,
  successfulResponse: undefined,
};

const customerAccountsSlice = createSlice({
  name: 'customerAccounts',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(customerAccounts.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
        loading: state.loading,
      } = payload);
    });
    builder.addCase(customerAccounts.pending, (state, { payload }) => {
      state.status = undefined;
      state.error = payload;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.loading = true;
    });

    builder.addCase(
      customerAccounts.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
  reducers: {
    updateNickNameCustomer: (
      state,
      { payload }: { payload: { id: string; nickname: string } }
    ) => {
      let stateType = '';
      let initVal = 0;
      const stateList: string[] = [
        'CurrentAccount',
        'SavingsAccount',
        'CashCredit',
        'Overdraft',
      ];

      while (initVal < stateList.length && stateType === '') {
        if (
          state.data?.[stateList[initVal]]?.findIndex((item) => {
            return item.AccountId === payload.id;
          }) > -1
        ) {
          stateType = stateList[initVal];
        }
        initVal++;
      }
      const value: CustomerAccountDetails[] = state.data?.[stateType].map(
        (i: CustomerAccountDetails) => {
          if (i.AccountId === payload.id) {
            i.NickName = payload.nickname;
          }
          return i;
        }
      );
      state.data = {
        ...state.data,
        [stateType]: value,
      } as CustomerAccountsData;
    },
    clearCustomerData(state) {
      state.status = undefined;
      state.error = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.loading = false;
    },
  },
});

export const { updateNickNameCustomer, clearCustomerData } =
  customerAccountsSlice.actions;

export default customerAccountsSlice.reducer;
