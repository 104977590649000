import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  mappedAccountList: [] as any,
  mappedRoleList: [] as any,
  remainingAccountList: [] as any,
  isAutoLink: false,
  isToggle: false,
};

const merchantCreationSlice = createSlice({
  name: 'Merchant Creation',
  initialState,
  reducers: {
    mappedAccount(state, { payload }) {
      console.log(payload);
      state.mappedAccountList = [...payload];
    },

    mappedRole(state, { payload }) {
      state.mappedRoleList = [...payload];
    },

    remainingAccount(state, { payload }) {
      state.remainingAccountList = [...payload];
    },
  },
});

export const { mappedAccount, mappedRole, remainingAccount } =
  merchantCreationSlice.actions;

export default merchantCreationSlice.reducer;
