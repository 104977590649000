import { EndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { PersonaAccountRequestData } from '../models/persona-req.model';

export const fetchPersonaAccountDetails = createAsyncThunk(
  'user/fetchPersonaAccountDetails',
  async (data: PersonaAccountRequestData, { rejectWithValue }) => {
    const persona = data?.persona ?? 'PERSONA01';
    const personaURL = EndPoints.strapiService.getPersona(persona);
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: personaURL,
        url: ``,
      });

      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
