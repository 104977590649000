import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetPincodeReq } from '../models/get-pincode-detail.model';

export const getPincodeDetail = createAsyncThunk(
  'api/master-service/masters/get-pincode-detail',
  async (getPincodeData: GetPincodeReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.GET_PINCODE,
        url: '',
        obj: getPincodeData,
      });
      if (response.data.status === 'error') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
