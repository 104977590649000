import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DASHBOARD_TYPE, MERCHANT_DASHBOARD } from '@core-constants';
import { setDashboardType } from '@core-stores';
import { setNavThemeType } from '@src/themes/utils.theme';

import { setNavThemeType as setNavThemeTypeRdx } from '../../../core-stores/slices/init.slice';

import './AU_ToggleOptions.style.scss';

interface AUToggleOptionsProps {
  setToggle: (val) => void;
  storeToggle: boolean;
}

export const AU_ToggleOptions: React.FC<AUToggleOptionsProps> = ({
  setToggle,
  storeToggle = true,
}: AUToggleOptionsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      setDashboardType(storeToggle ? DASHBOARD_TYPE.STORE : DASHBOARD_TYPE.BANK)
    );
    setNavThemeType(storeToggle ? DASHBOARD_TYPE.STORE : DASHBOARD_TYPE.BANK);
    setTimeout(() => {
      dispatch(
        setNavThemeTypeRdx(
          storeToggle ? DASHBOARD_TYPE.STORE : DASHBOARD_TYPE.BANK
        )
      );
    }, 50);
  }, [storeToggle]);

  return (
    <div className="toggle_options_container">
      <div
        onClick={() => {
          setToggle(true);
          navigate(MERCHANT_DASHBOARD);
        }}
        className={storeToggle ? 'store_toggle_active' : 'toggle_inactive'}
      >
        <p className="m-text-md-medium">{t('business')}</p>
      </div>
      <div
        onClick={() => {
          setToggle(false);
          navigate(MERCHANT_DASHBOARD);
        }}
        className={storeToggle ? 'toggle_inactive' : 'bank_toggle_active'}
      >
        <p className="m-text-md-medium">{t('bank')}</p>
      </div>
    </div>
  );
};
