import { createSlice } from '@reduxjs/toolkit';

export interface IcifStaffStatus {
  cifStaffStatusFlag: string;
}

const initialState: IcifStaffStatus = {
  cifStaffStatusFlag: '',
};

const qrOnboardingSlice = createSlice({
  name: 'qrOnboardingSlice',
  initialState,
  reducers: {
    setCifStaffStatusData(state, { payload }: { payload: IcifStaffStatus }) {
      state.cifStaffStatusFlag = payload.cifStaffStatusFlag;
    },
  },
});

export const { setCifStaffStatusData } = qrOnboardingSlice.actions;
export default qrOnboardingSlice.reducer;
