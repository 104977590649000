import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, XB_Button, XB_Divider, XB_Modal } from '@core-components/atoms';
import { AU_StatementPdfDownload } from '@core-components/molecules/statement-pdf-download/AU_StatementPdfDownload.component';
import { appConstants } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  convertAppDateFormat,
  getFormattedDate,
  getINRFormatWithDecimal,
  isEmpty,
} from '@core-utils';
import EmptyTransactions from '@src/assets/images/no-transactions.svg';

import { AU_DataTable, TabColumns } from '../data-table/AU_DataTable.component';

import './AU_CasaRecentTransactions.style.scss';

interface CasaRecentTransactionsProps {
  transactionData: any;
  // loading?: boolean;
}

export const AU_CasaRecentTransactions = ({
  transactionData,
}: // loading,
CasaRecentTransactionsProps) => {
  const auAlert = useAlertBox();
  const { t } = useTranslation();
  const [pressedItem, setPressedItem] = useState<any>();
  const [showCardModal, setShowCardModal] = useState(false);

  const handleTransactionModalClick = (val: boolean) => {
    setShowCardModal(val);
  };

  const copyToClipBoard = (val) => {
    navigator.clipboard.writeText(val);
    auAlert.success(t('copiedSuccessfully').toString());
  };

  const handleLabelforRefOrChequeNo = (
    cheque: string,
    reference: string
  ): boolean => {
    return !isEmpty(cheque) && !isEmpty(reference);
  };

  const makeData = (data) => {
    const dateDiv = (date: string) => {
      return convertAppDateFormat(
        date,
        appConstants.YYYYMMDD_WH,
        appConstants.dateFormatWithCommaSpace2
      );
    };
    data = data.map((item) => {
      let chequeFlg;
      if (item.chequeNo && item.depositNumber) {
        chequeFlg = true;
      } else if (!item.chequeNo && item.depositNumber) {
        chequeFlg = false;
      }
      return {
        ...item,
        txnDateAndTime: dateDiv(item.txnDate),
        txnValDate: dateDiv(item.valueDate),
        txnDesc: item.description,
        txnAmtField: (
          <div
            className={`flex gap-2 ${item.flgDrCr === 'C' ? 'green' : 'red'}`}
          >
            <p>{item.flgDrCr === 'C' ? <span>+</span> : <span>-</span>}</p>
            <p>{getINRFormatWithDecimal(Number(item.amount))}</p>
          </div>
        ),
        txnAvlBalance: getINRFormatWithDecimal(Number(item.runningTotal)),
        txnChequeNumber: item.chequeNo,
        chequeFlg: chequeFlg ? item.chequeNo : item.depositNumber,
        transactionId: item.depositNumber,
      };
    });
    return data;
  };

  const getTransactionTabData = (selectedVal) => {
    const data: any[] = [];

    data.push({
      key: t('transactionDate'),
      value: getFormattedDate(
        selectedVal.txnDateAndTime ?? '',
        appConstants.dateFormatWithCommaSpace2,
        appConstants.DDMMYYYY_WH
      ),
    });

    data.push({
      key: t('valueDate'),
      value: getFormattedDate(
        selectedVal.txnValDate ?? '',
        appConstants.dateFormatWithCommaSpace2,
        appConstants.DDMMYYYY_WH
      ),
    });
    data.push({
      key: t('transactionType'),
      value: selectedVal.flgDrCr === 'C' ? t('credit') : t('debit'),
    });
    data.push({
      key: t('transactionAmount'),
      value: selectedVal.amount,
    });
    data.push({ key: t('narration'), value: selectedVal.txnDesc });
    return data;
  };

  const renderModal = (presseditem: any) => {
    const bgImgUrl = () => {
      return presseditem.txnType === 'C'
        ? '/assets/money-receive.svg'
        : '/assets/money-send.svg';
    };
    return (
      <XB_Modal
        headPos="text-left"
        open={showCardModal}
        setOpen={(open) => setShowCardModal(open)}
        title={t('transactionDetails')}
        maxWidth="25rem"
        modalBackgroundStyle={{
          backgroundImage: `url(${bgImgUrl()})`,
          backgroundPosition: 'right bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 203,
        }}
        body={
          <div className="modal-container flex flex-col">
            <div className="popup-amount flex gap-2">
              {presseditem.flgDrCr === 'C' ? (
                <>
                  <Icon icon="transactionplus" width={20} height={20} />
                  <span className="creditAmount">
                    <p>{getINRFormatWithDecimal(Number(presseditem.amount))}</p>
                  </span>
                </>
              ) : (
                <>
                  <Icon icon="transactionminus" width={20} height={20} />
                  <span className="debitAmount">
                    <p>{getINRFormatWithDecimal(Number(presseditem.amount))}</p>
                  </span>
                </>
              )}
            </div>

            <div className="flex gap-12">
              <div>
                <div className="pop-up-field ">
                  <p className="m-text-sm-regular text-gray-500">
                    {t('transactionDate')}
                  </p>
                </div>
                <div className="pop-up-value">
                  <p className="m-text-lg-medium text-gray-900">
                    {presseditem.txnDateAndTime}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="pop-up-field">
                <p className="m-text-sm-regular text-gray-500">
                  {t('valueDate')}
                </p>
              </div>
              <div className="pop-up-value">
                <p className="m-text-lg-medium text-gray-900">
                  {presseditem?.txnValDate}
                </p>
              </div>
            </div>

            {presseditem?.chequeFlg &&
              presseditem.chequeFlg !== '0' &&
              presseditem.chequeFlg.trim().length !== 0 && (
                <div>
                  <div className="pop-up-field">
                    {handleLabelforRefOrChequeNo(
                      presseditem?.txnChequeNumber as string,
                      presseditem?.transactionId as string
                    )
                      ? t('chequeNumber')
                      : t('referenceNo')}
                  </div>
                  <div className="pop-up-value flex justify-between">
                    <p className="break-all m-text-lg-medium">
                      {presseditem?.chequeFlg}
                    </p>
                    <XB_Button
                      iconName="copyorange"
                      onClick={() => copyToClipBoard(presseditem.chequeFlg)}
                      dataTestId="COPY"
                      classes="mt-[-1rem] ml-3"
                      btnType="tertiary"
                    />
                  </div>
                </div>
              )}

            <div>
              <XB_Divider size="thin"></XB_Divider>
            </div>
            <div>
              <div className="pop-up-value">
                <p className="m-text-lg-medium break-all">
                  {presseditem.txnDesc}
                </p>
              </div>
            </div>
          </div>
        }
        actions={
          <AU_StatementPdfDownload
            closeModal={() => {
              handleTransactionModalClick(false);
            }}
            data={getTransactionTabData(presseditem)}
          />
        }
      />
    );
  };

  const casaRecentTabColumns: TabColumns[] = [
    { name: t('transactionDate'), key: 'txnDateAndTime', width: '8.725rem' },
    { name: t('description'), key: 'txnDesc', width: '15.875rem' },
    { name: t('amount'), key: 'txnAmtField', width: '9.25rem' },
    { name: t('balance'), key: 'txnAvlBalance', width: '9.15rem' },
  ];

  return (
    <div>
      {transactionData && transactionData.length > 0 ? (
        <div className="recent-transactions overflow-x-auto">
          <div className="w-full pb-3 cursor-pointer">
            <AU_DataTable
              columns={casaRecentTabColumns}
              classes={'casa-summary-table'}
              isShowToolBar={false}
              data={makeData(transactionData)}
              onRowPress={(pressedval) => {
                setPressedItem(pressedval);
                setShowCardModal(true);
              }}
            />
          </div>
          {showCardModal && renderModal(pressedItem)}
        </div>
      ) : null}
      {!transactionData ? (
        <div className="no-trans flex flex-col justify-center items-center py-20">
          <img src={EmptyTransactions} width={204} height={140} />
          <h2 className="no-trans-title m-text-lg-bold">
            {t('noTransactions')}
          </h2>
        </div>
      ) : null}
    </div>
  );
};
