import { createSlice } from '@reduxjs/toolkit';

import { AUBankOffersRdxData, getBankOffersListThunk } from '../../index';

const initialState: AUBankOffersRdxData = {
  data: undefined,
  error: undefined,
  loading: true,
};

const auBankOffersSlice = createSlice({
  name: 'au-bank-offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBankOffersListThunk.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.error = payload.error;
      state.loading = payload.loading;
    });
    builder.addCase(getBankOffersListThunk.pending, (state) => {
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getBankOffersListThunk.rejected,
      (state, { payload }: { payload: any }) => {
        state.data = undefined;
        state.error = payload;
        state.loading = true;
      }
    );
  },
});

export default auBankOffersSlice.reducer;
