import { Icon, XB_Button, XB_Modal } from '@core-components/atoms';
import { RootState, useAppDispatch, useAppSelector } from '@core-stores';
import {
  setOnCancelClick,
  setOnConfirmClick,
} from '@src/core-stores/slices/save-back-modal.slice';

export const AU_ChangesSaveModal = () => {
  const dispatch = useAppDispatch();
  const { config } = useAppSelector((state: RootState) => state.saveBackModal);

  return (
    <div>
      <XB_Modal
        open={config?.show}
        setOpen={() => {
          dispatch(setOnCancelClick(true));
        }}
        img={
          <Icon
            icon={config?.icon ?? 'warningmodal'}
            color="none"
            height={48}
            width={48}
          />
        }
        modalBackgroundStyle={{ width: '400px' }}
        maxWidth="400px"
        body={
          <>
            <div>
              <h1 className="m-display-sm-medium text-gray-900">
                {config?.title}
              </h1>
              <p className="m-text-md-regular text-gray-500">
                {config?.subTitile}
              </p>
            </div>
          </>
        }
        actions={
          <div className="flex gap-x-3 justify-center">
            {config?.isShowCancelBtn && (
              <XB_Button
                classes="w-2/4"
                dataTestId="CANC-BTN"
                btnType="secondary-gray"
                onClick={() => {
                  dispatch(setOnCancelClick(true));
                }}
              >
                {config?.cancelBtnLbl}
              </XB_Button>
            )}

            <XB_Button
              classes="w-2/4"
              dataTestId="CONF-BTN"
              onClick={() => {
                dispatch(setOnConfirmClick(true));
              }}
            >
              {config?.confirmBtnLbl}
            </XB_Button>
          </div>
        }
      />
    </div>
  );
};
