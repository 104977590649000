import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, XB_Button, XB_Divider } from '@core-components/atoms';
import { AU_BackButton } from '@core-components/molecules';

import './AU_TransactionStatusScreen.style.scss';

interface TransactionStatusScreenProps {
  pageTitle?: string;
  backNavigation?: string;
  statusDetailsBody: React.JSX.Element | null;
  statusDetailsHeader: React.JSX.Element;
  bottomBackBtnText?: string;
  bottomBackBtnId?: string;
  bottomBackBtnNav?: string;
  statusType: 'success' | 'failed' | 'pending' | string;
  statusText: string;
  actionBtnList?: React.JSX.Element;
  onClickBackAction?: () => void;
  actionBtnClass?: string;
}

export const AU_TransactionStatusScreen = ({
  pageTitle,
  statusText,
  backNavigation,
  statusDetailsBody,
  statusDetailsHeader,
  bottomBackBtnText,
  statusType,
  bottomBackBtnId,
  actionBtnList,
  bottomBackBtnNav,
  onClickBackAction,
  actionBtnClass = 'actionBtnClass',
}: TransactionStatusScreenProps) => {
  const navigate = useNavigate();

  const getIconName = () => {
    if (statusType === 'success') {
      return 'greenSuccess';
    }
    if (statusType === 'failed') {
      return 'failedIcon';
    } else {
      return 'pendingIcon';
    }
  };

  const textColor = () => {
    if (statusType === 'failed') {
      return 'text-red-600';
    }
    if (statusType === 'success') {
      return 'text-green-600';
    } else {
      return 'text-orange-700';
    }
  };

  const bottomBtn = bottomBackBtnId && bottomBackBtnNav && bottomBackBtnText;

  const onHandleBack = () => {
    if (backNavigation) {
      navigate(backNavigation);
    } else {
      onClickBackAction?.();
    }
  };

  return (
    <section>
      <div className="payment-status-container">
        <div className="flex gap-2">
          <AU_BackButton onClickAction={onHandleBack} />
          {pageTitle && (
            <div className="mt-1">
              <h2 className="m-display-xl-medium text-gray-800">{pageTitle}</h2>
            </div>
          )}
        </div>

        <div className="payment-status-details-container">
          {actionBtnList && (
            <div className={actionBtnClass}>{actionBtnList}</div>
          )}
          <div className="status-type">
            <div className="transaction-status">
              <Icon
                icon={getIconName()}
                height={101}
                width={200}
                color="none"
              />
              <p className={`m-text-lg-medium ${textColor()} mt-2 status-text`}>
                {statusText}
              </p>
            </div>

            {statusDetailsHeader}
          </div>

          {statusDetailsBody && (
            <div>
              <XB_Divider classes="mt-6" />

              <div className="mb-4">{statusDetailsBody}</div>
            </div>
          )}

          {bottomBtn && (
            <XB_Button
              dataTestId={bottomBackBtnId}
              btnType="tertiary"
              iconName="left-arrow"
              classes="mt-6 w-max self-center"
              onClick={() => {
                navigate(bottomBackBtnNav);
                onClickBackAction?.();
              }}
            >
              {bottomBackBtnText}
            </XB_Button>
          )}
        </div>
      </div>
    </section>
  );
};
