import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestNomineeDetails } from '../models/nominee-detail.model';
import { ReqGetProductCode } from '../models/product-code.model';
import { TDCalculationRequestModel } from '../models/td-calculation.model';

export const nomineeDetails = createAsyncThunk(
  'api/deposit-service/get-nominee-details',
  async (nomineeDetailsData: RequestNomineeDetails, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.GET_NOMINEE_DETAILS,
        url: '',
        obj: nomineeDetailsData,
      });
      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      response.data.acNo = nomineeDetailsData.AccountNo;
      response.data.transactionBranch = nomineeDetailsData.TransactionBranch;
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getDepositSummary = createAsyncThunk(
  'deposit/fd/getDepositSummary',
  async (TdCalculation: TDCalculationRequestModel, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.TD_CALCULATOR,
        url: '',
        obj: TdCalculation,
      });
      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getProductCode = createAsyncThunk(
  'api/deposit-service/product-codes-ind',
  async (productCode: ReqGetProductCode, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.GET_PRODUCT_CODE,
        url: '',
        obj: productCode,
      });
      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getDepositLabels = createAsyncThunk(
  'deposit/fd/getDepositLabels',
  async (_DepositLabels, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: AUEndPoints.fdService.GET_FD_LABELS,
        url: '',
      });
      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
