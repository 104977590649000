import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, Service } from '@src/core-services';
import { handleError } from '@src/core-utils';

import { IBankListReq } from '../models/bank-list.modal';

export const getBankList = createAsyncThunk(
  'getBankList',
  async (payload: IBankListReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.depositService.SHOW_BANK_LIST,
        obj: payload,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);
