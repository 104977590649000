import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  QRCollectionListResquestType,
  QRSettlementListResquestType,
  QRSettlementTransactionResquestType,
} from '../../models/qr-collection.model';

export const qrTransctionsListData = createAsyncThunk(
  'getQRTransctionListDetail',
  async (reqData: QRCollectionListResquestType, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.settlementService.collectionList({
          page: 0,
          size: 1000,
          sort: ['transactionTime,desc'].map((i) => `&sort=${i}`).join(''),
        }),
        obj: reqData,
      });

      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const qrSettlementListData = createAsyncThunk(
  'getQRSettlementListDetail',
  async (reqData: QRSettlementListResquestType, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.settlementService.settlementList({
          page: 0,
          size: 1000,
          sort: ['settlementTime,desc'].map((i) => `&sort=${i}`).join(''),
        }),
        obj: reqData,
      });

      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const qrSettlementTransctionsData = createAsyncThunk(
  'getSettlementTransctionDetail',
  async (reqData: QRSettlementTransactionResquestType, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.settlementService.SettlementDetail({
          page: 0,
          size: 1000,
          sort: ['transactionTime,desc'].map((i) => `&sort=${i}`).join(''),
        }),
        obj: reqData,
      });

      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(handleError(error));
    }
  }
);
