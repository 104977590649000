import { createSlice } from '@reduxjs/toolkit';
import { OpenFdFormData } from '@src/modules/deposit/models/request-deposit.model';

const initialState = {
  openFDFormData: {} as OpenFdFormData,
  fdConfigData: {},
  partialTdCal: {},
  fdClosureResponse: {},
};

const fdRdOpenFormDataSlice = createSlice({
  name: 'fdRdformData',
  initialState,
  reducers: {
    setOpenFdFormdata(state, { payload }) {
      state.openFDFormData = payload;
    },
    setFDConfigData(state, { payload }) {
      state.fdConfigData = payload;
    },
    setPartialTdCal(state, { payload }) {
      state.partialTdCal = payload;
    },
    setFdClosureResposne(state, { payload }) {
      state.fdClosureResponse = payload;
    },
  },
});

export const {
  setOpenFdFormdata,
  setFDConfigData,
  setPartialTdCal,
  setFdClosureResposne,
} = fdRdOpenFormDataSlice.actions;
export default fdRdOpenFormDataSlice.reducer;
