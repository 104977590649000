import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { AU_Badge, XB_Button, XB_Divider } from '@core-components/atoms';
import { appConstants, PERMISSIONS } from '@core-constants';
import { useAppDispatch, useAppSelector } from '@core-stores';
import {
  capitalizeFirstChar,
  checkPermission,
  convertAppDateFormat,
  getConcatenatedString,
  getINRFormatWithDecimal,
} from '@core-utils';
import noPayeeImg from '@src/assets/images/no-payees.svg';
import { paymentStatus } from '@src/modules/payments/constants/payments.constant';

import { transactionsStatus } from '../constants/au-dashboard.constant';
import { setDashboardData } from '../stores/slices/au-dashboard.slice';

import '../styles/transactions.style.scss';

export interface ITransactionsData {
  date: string;
  amt: string;
  txnType: string;
  balance?: string;
  toPayeeTitle?: string;
  toPayeeSubtitle?: string;
  status?: string;
}

interface ITransactions {
  recentTransactionsTitle: string;
  recentTransactionsData: ITransactionsData[];
  showRecentRightBtn: boolean;
  recentRightBtn?: string;
  scheduledTransactionsTitle: string;
  scheduledTransactionsData: ITransactionsData[];
  showScheduledRightBtn: boolean;
  scheduledRightBtn?: string;
  tableFooter?: React.JSX.Element;
  onRecentTransactionClick: (index: number) => void;
}

export const Transactions = ({
  recentTransactionsTitle,
  recentTransactionsData,
  showRecentRightBtn,
  recentRightBtn,
  scheduledTransactionsTitle,
  scheduledTransactionsData,
  showScheduledRightBtn,
  scheduledRightBtn,
  onRecentTransactionClick,
}: ITransactions) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToDownload = () => {
    navigate('/accountstatement/download', {
      state: {
        financialYearsData: [],
        currentModuleState: 'account',
      },
    });
  };

  const noTransactionCard = (
    <div className="p-10">
      <div className="flex justify-center items-center">
        <img src={noPayeeImg} alt="no-payee-bg" />
      </div>
      <div className="text-center mt-1">
        <span className="m-text-lg-regular no-payee">
          {t('lblNoTransaction')}
        </span>
      </div>
    </div>
  );
  const permissions = useAppSelector(
    (state) => state.profile.data?.permissions
  );
  const handleViewAllClick = () => {
    dispatch(setDashboardData({ moneyTransferTabCount: 0 }));
    navigate('/payments/moneytransfer');
  };
  const renderIcon = (status): string => {
    let value = '';
    if (
      status?.toLocaleLowerCase() === paymentStatus?.success ||
      status?.toLocaleLowerCase() === paymentStatus?.transferred
    ) {
      value = 'arrow-right-up';
    } else if (
      status?.toLocaleLowerCase() === paymentStatus?.failed ||
      status?.toLocaleLowerCase() === paymentStatus?.failure
    ) {
      value = 'redalerttriangle';
    } else if (status?.toLocaleLowerCase() === paymentStatus?.pending) {
      value = 'tenure';
    }
    return value;
  };

  return (
    <div className="transactions_container">
      <div className="transactions_container_section">
        <div className="transactions_title_section">
          <h1 className="m-display-sm-bold text-gray-800">
            {t(recentTransactionsTitle)}
          </h1>
          {showRecentRightBtn && recentRightBtn && (
            <XB_Button
              dataTestId="RCNT-TXNS"
              btnType="primary-underline"
              suffixIcon="chevron-down"
            >
              {t(recentRightBtn)}
            </XB_Button>
          )}
        </div>
        <div className="transactions_table">
          <div className="transactions_table_header">
            <div className="table_header_section min-w-[30%]">
              <p className="m-text-sm-bold text-gray-800">
                {t('transactionDate')}
              </p>
            </div>
            <div className="table_header_section min-w-[40%]">
              <p className="m-text-sm-bold text-gray-800">{t('amount')} </p>
            </div>
            <div className="table_header_section min-w-[30%]">
              <p className="m-text-sm-bold text-gray-800">{t('status')} </p>
            </div>
          </div>
          <div className="transactions_table_rows">
            {recentTransactionsData.length > 0
              ? recentTransactionsData.map((item, index) => {
                  return (
                    <Fragment key={getConcatenatedString([index, item.date])}>
                      <div
                        onClick={() => onRecentTransactionClick(index)}
                        className="table_row"
                      >
                        <div className="row_section min-w-[30%]">
                          <p className="m-text-md-regular text-gray-600">
                            {convertAppDateFormat(
                              item.date,
                              appConstants.SERVER_DATE_WITH_Z,
                              appConstants.dateFormatWithCommaSpace
                            )}
                          </p>
                        </div>
                        <div className="row_section min-w-[40%]">
                          <p
                            className={`${
                              item.status?.toLocaleLowerCase() ===
                                transactionsStatus.SUCCESS.toLocaleLowerCase() ||
                              item.status?.toLocaleLowerCase() ===
                                transactionsStatus.TRANSFERRED.toLocaleLowerCase()
                                ? 'text-red-700'
                                : 'text-gray-600'
                            } m-text-md-regular`}
                          >
                            {`${
                              item.status?.toLocaleLowerCase() ===
                                transactionsStatus.SUCCESS.toLocaleLowerCase() ||
                              item.status?.toLocaleLowerCase() ===
                                transactionsStatus.TRANSFERRED.toLocaleLowerCase()
                                ? '-'
                                : ''
                            } ${getINRFormatWithDecimal(Number(item.amt))}`}
                          </p>
                        </div>
                        <div className="row_section min-w-[30%]">
                          <AU_Badge
                            className={`${
                              item?.status?.toLocaleLowerCase() as string
                            }-bg-color pr-2 w-fit`}
                            iconColor="none"
                            iconName={renderIcon(item?.status)}
                            contentClass={`m-text-sm-medium ${
                              item?.status?.toLocaleLowerCase() as string
                            }-color`}
                          >
                            {capitalizeFirstChar(item?.status as string)}
                          </AU_Badge>
                        </div>
                      </div>

                      <XB_Divider />
                    </Fragment>
                  );
                })
              : noTransactionCard}
          </div>
        </div>
        {recentTransactionsData.length > 0 && (
          <div className="dashboard_transactions_footer">
            <XB_Button
              btnType="primary-underline"
              dataTestId="VIEW-ALL"
              onClick={() =>
                checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW) &&
                handleViewAllClick()
              }
              disabled={
                !checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW)
              }
            >
              {t('viewAll')}
            </XB_Button>
            <XB_Button
              btnType="primary-underline"
              dataTestId="DWND-STMT"
              onClick={() =>
                checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW) &&
                navigateToDownload()
              }
              disabled={
                !checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW)
              }
            >
              {t('downloadStatement')}
            </XB_Button>
          </div>
        )}
      </div>
      <div className="transactions_container_section">
        <div className="transactions_title_section">
          <h1 className="m-display-sm-bold text-gray-800">
            {t(scheduledTransactionsTitle)}
          </h1>
          {showScheduledRightBtn && scheduledRightBtn && (
            <XB_Button
              dataTestId="RCNT-TXNS"
              btnType="primary-underline"
              suffixIcon="chevron-down"
            >
              {t(scheduledRightBtn)}
            </XB_Button>
          )}
        </div>
        <div className="transactions_table">
          <div className="transactions_table_header">
            <div className="table_header_section min-w-[30%]">
              <p className="m-text-sm-bold text-gray-800">
                {t('scheduleDate')}
              </p>
            </div>
            <div className="table_header_section min-w-[40%]">
              <p className="m-text-sm-bold text-gray-800">{t('toPayee')} </p>
            </div>
            <div className="table_header_section min-w-[30%]">
              <p className="m-text-sm-bold text-gray-800">{t('amount')} </p>
            </div>
          </div>
          <div className="transactions_table_rows">
            {scheduledTransactionsData.length > 0
              ? scheduledTransactionsData.map((item, index) => {
                  return (
                    <Fragment key={getConcatenatedString([index, item.date])}>
                      <div className="table_row">
                        <div className="row_section min-w-[30%]">
                          <p className="m-text-md-regular text-gray-600">
                            {convertAppDateFormat(
                              item.date,
                              appConstants.SERVER_DATE_WITH_Z,
                              appConstants.dateFormatWithCommaSpace
                            )}
                          </p>
                        </div>
                        <div className="row-section_description min-w-[40%]">
                          <p className="m-text-md-medium text-gray-900">
                            {item.toPayeeTitle}
                          </p>
                          <p className="m-text-md-regular text-gray-600">
                            {`xx${item.toPayeeSubtitle?.trim().slice(-4)}`}
                          </p>
                        </div>
                        <div className="row_section min-w-[30%]">
                          <p className="m-text-md-regular text-gray-600">
                            {getINRFormatWithDecimal(Number(item.amt))}
                          </p>
                        </div>
                      </div>

                      <XB_Divider />
                    </Fragment>
                  );
                })
              : noTransactionCard}
          </div>
        </div>
        {scheduledTransactionsData.length > 0 && (
          <div className="dashboard_transactions_footer">
            <XB_Button
              btnType="primary-underline"
              dataTestId="VIEW-ALL"
              onClick={() =>
                checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW) &&
                handleViewAllClick()
              }
              disabled={
                !checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW)
              }
            >
              {t('viewAll')}
            </XB_Button>
            <XB_Button
              btnType="primary-underline"
              dataTestId="DWND-STMT"
              onClick={() =>
                checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW) &&
                navigateToDownload()
              }
              disabled={
                !checkPermission(permissions, PERMISSIONS.RBAC_STATEMENT.VIEW)
              }
            >
              {t('downloadStatement')}
            </XB_Button>
          </div>
        )}
      </div>
    </div>
  );
};
