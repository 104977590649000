import React from 'react';

import './AU_Linearprogressbar.style.scss';

interface LinearprogressbarProps {
  type: 'default' | 'label-outside';
  completionPercent?: string;
  classProgress?: string;
  classRangeBar?: string;
  labelInside?: string;
  labelInsideClass?: string;
  labelStart?: string;
  labelEnd?: string;
  labelStartClass?: string;
  labelEndClass?: string;
  amountStart?: string;
  amountEnd?: string;
}

export const AU_Linearprogressbar: React.FC<LinearprogressbarProps> = ({
  type = 'default',
  completionPercent,
  labelInside = '',
  labelStart,
  labelEnd,
  labelStartClass,
  labelEndClass,
  classProgress,
  classRangeBar,
  labelInsideClass,
  amountStart,
  amountEnd,
}: LinearprogressbarProps) => {
  return (
    <div className="au-linear-progress">
      <div className={classRangeBar}>
        <div
          data-testid="RNGE"
          className={classProgress}
          style={{ width: `${completionPercent}%` }}
        >
          {labelInside && <div className={labelInsideClass}>{labelInside}</div>}
        </div>
      </div>
      {type === 'label-outside' && (
        <div className="flex justify-between mb-1">
          <div className="label-container">
            <span className={`${labelStartClass} mr-1`}>{amountStart}</span>
            <span className={labelStartClass}>{labelStart}</span>
          </div>
          <div className="label-container">
            <span className={`${labelEndClass} mr-1`}>{amountEnd}</span>
            <span className={labelEndClass}>{labelEnd}</span>
          </div>
        </div>
      )}
    </div>
  );
};
