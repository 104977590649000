import { isArray } from 'lodash';

import { BLANK, NULL } from '@core-constants';
import { IConfigDataType } from '@core-stores';

export const checkPermission = (
  permissionArr: string[] = [],
  elem = ''
): boolean =>
  isArray(permissionArr) ? permissionArr?.includes(elem ?? '') : false;

export const filterByPermission = (
  allPermissions: string[] = [],
  items: any[] = [],
  elem = ''
): any[] =>
  items.filter(
    (item) =>
      item[elem] === BLANK ||
      item[elem] === NULL ||
      allPermissions?.includes(item[elem] ?? BLANK)
  );

export const getFEConfig = (
  stateItems: IConfigDataType[],
  configType: string,
  module: string
): any =>
  stateItems?.filter((e) => e?.configType === configType)?.[0]?.[module] ??
  false;

export const userAllowPermissionsList = (
  allPermissionList: string[],
  userPermissionList: string[]
): string[] => {
  const allPermissionSet = new Set(allPermissionList);
  return userPermissionList.filter((el) => allPermissionSet.has(el));
};
