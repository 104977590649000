import React from 'react';

import { XB_SectionInfo } from '@core-components/atoms';

import './AU_CollapsibleCard.style.scss';

interface CollapsibleCardProps {
  dropDownId: string;
  leftIcon?: any;
  title: string;
  subTitle: string;
  isDropdown?: boolean | false;
  isExpended: boolean | true;
  children: string | React.JSX.Element | React.JSX.Element[];
  onDropDownPress: (dropDownId: string) => void;
}

export const AU_CollapsibleCard = ({
  dropDownId,
  leftIcon,
  title,
  subTitle,
  isDropdown,
  isExpended,
  children,
  onDropDownPress,
}: CollapsibleCardProps) => {
  const pressDropDown = () => {
    if (isDropdown) {
      onDropDownPress(dropDownId);
    }
  };
  return (
    <div className="expandablemenu">
      <div className="internal-padding">
        <div
          className="flex justify-between flex-row upper-section-mobile-left upper-section-mobile-left-two"
          onClick={() => {
            pressDropDown();
          }}
        >
          {isDropdown ? (
            <XB_SectionInfo
              title={title}
              description={subTitle}
              prefixIconName={leftIcon}
              surfixIconName={'dropdown-icon'}
              isExpended={isExpended}
              iconClass="text-orange-600"
            />
          ) : (
            <XB_SectionInfo
              title={title}
              description={subTitle}
              prefixIconName={leftIcon}
              iconClass="text-orange-600"
            />
          )}
        </div>
      </div>
      <div
        className={
          'justify-content-start ' +
          (isExpended
            ? 'expandablemenudiv fade-in grow'
            : ' expandablemenudiv fade-out shrink')
        }
      >
        <div className="internal-padding">{children}</div>
      </div>
    </div>
  );
};
