import { EndPoints, Service } from '@core-services';

export const dedupeLite = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.dedupeLiteV1,
    method: 'POST',
    url: '',
    obj: {
      ...data,
      channel: 'WEB',
    },
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const fetchUserGroup = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.fetchUserGroup,
    method: 'POST',
    url: '',
    obj: {
      ...data,
      channel: 'WEB',
    },
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const searchUser = async (header, config) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.searchUser,
    method: 'POST',
    url: '',
    obj: {
      channel: 'WEB',
    },
  };
  const response = await Service.send(reqObj, header, config);
  return response.data;
};

export const fetchEmployee = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.fetchEmployee,
    method: 'POST',
    url: '',
    obj: {
      ...data,
      channel: 'WEB',
    },
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const fetchUserGroups = async (page: number, size: number) => {
  const url = EndPoints.userMgmtService.fetchUserGroupList(page, size);
  const reqObj = {
    baseurl: url,
    method: 'POST',
    url: '',
    obj: {
      appId: 'EMPLOYEE_APP',
      isReferralGroup: true,
    },
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const getUserGroupService = async (obj) => {
  const reqObj = {
    baseurl: `${EndPoints.admin.userGroup}`,
    method: 'POST',
    url: '',
    obj,
  };

  const resp = await Service.send(reqObj);
  return resp.data;
};
