import { useEffect, useState } from 'react';

import { AU_Ttficon, XB_Dropdown } from '@core-components/atoms';
import { useAppSelector } from '@core-stores';

import './XB_CountryDropdown.style.scss';

interface CountryDropdownProps {
  id: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  global?: boolean;
}

export const XB_CountryDropdown = ({
  id,
  onChange,
  disabled = true,
  global = false,
}: CountryDropdownProps) => {
  const countryCodeList = useAppSelector((state) => state.countryCodeList);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('+91');

  useEffect(() => {
    const tmpCountryCodes: any[] = [];
    if (global) {
      countryCodeList?.data &&
        countryCodeList?.data?.forEach((countryCodeData) => {
          tmpCountryCodes.push({
            countryInitials: countryCodeData.CountryCode,
            countryName: countryCodeData.Country,
            countryFlag: 'in-flag',
            countryValue: countryCodeData.InternationalDialing,
          });
        });
    } else {
      tmpCountryCodes.push({
        countryInitials: 'IN',
        countryName: 'India',
        countryFlag: 'in-flag',
        countryValue: '+91',
      });
    }
    setCountryList(tmpCountryCodes);
  }, [countryCodeList]);
  const dropdownItems = countryList.map((c) => {
    return {
      text: c.countryInitials,
      value: c.countryValue,
      icon: <AU_Ttficon icon={c.countryInitials} type="country" size={20} />,
    };
  });
  return (
    <XB_Dropdown
      id={id}
      classes="country-dropdown"
      items={dropdownItems}
      onChange={(val) => {
        setSelectedCountryCode(val);
        onChange?.(val);
      }}
      disabled={disabled}
      selectedValue={selectedCountryCode}
    />
  );
};
