import { useTranslation } from 'react-i18next';

import { AU_RoundBox, Icon, XB_Button, XB_Modal } from '@core-components/atoms';
import { ModalCall } from '@modules/common/models/model';

import { XB_InlineMessage } from '../inline-message/XB_InlineMessage.component';

interface ErrorProps extends ModalCall {
  isOpen: boolean;
  iconName: string;
  iconColor?: string;
  iconBgClass: string;
  title: string;
  setOpen: (args) => void;
  inLineMessage?: boolean;
  inlineMessageProps?: {
    inlineTitle?: string;
    inlineMessage?: string;
    classes?: string;
  };
  startingText?: string;
  middleText?: string;
  endingText?: string;
  startEndTextClass?: string;
  middleTextClass?: string;
  buttonLoading?: boolean;
}

export const AU_GenericErrorModal = ({
  isOpen,
  iconName,
  iconColor = 'none',
  iconBgClass,
  hideIcon,
  title,
  setOpen,
  inLineMessage,
  inlineMessageProps,
  action,
  secondaryAction,
  actionEvent,
  secondaryActionEvent,
  startingText,
  middleText,
  endingText,
  startEndTextClass,
  middleTextClass,
  buttonLoading,
}: ErrorProps) => {
  const { t } = useTranslation();

  const SplitTitleUI = () => {
    return (
      <div>
        <h2 className="mb-2 text-lg font-medium text-gray-900">{t(title)}</h2>
        <span className={startEndTextClass}>
          {t(startingText ?? '')}
          <span className={`${middleTextClass} ml-0.5 mr-0.5`}>
            {t(middleText ?? '')}
          </span>
          {t(endingText ?? '')}
        </span>
      </div>
    );
  };

  return (
    <XB_Modal
      open={isOpen}
      setOpen={(open) => setOpen(open)}
      maxWidth="400px"
      img={
        hideIcon ? (
          <></>
        ) : (
          <AU_RoundBox boxClass={iconBgClass}>
            <Icon
              width={48}
              height={48}
              icon={iconName}
              className={iconColor}
            />
          </AU_RoundBox>
        )
      }
      body={
        <>
          {SplitTitleUI()}
          {inLineMessage && (
            <XB_InlineMessage
              size="sm"
              type="info"
              icon="info-circle"
              title={t(inlineMessageProps?.inlineTitle ?? '')}
              message={t(inlineMessageProps?.inlineMessage ?? '')}
              classes={`${inlineMessageProps?.classes} mt-6`}
            />
          )}
        </>
      }
      actions={
        <div className="flex flex-row gap-3">
          {secondaryAction && (
            <XB_Button
              dataTestId="MDL-ONBRD-MR"
              classes="w-full flex-1"
              btnType="secondary"
              onClick={secondaryActionEvent}
            >
              {t(secondaryAction)}
            </XB_Button>
          )}
          <XB_Button
            dataTestId="MDL-ONBRD-MR"
            classes="w-full flex-1"
            onClick={actionEvent}
            showLoader={buttonLoading}
          >
            {t(action)}
          </XB_Button>
        </div>
      }
    />
  );
};
