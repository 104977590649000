import { createSlice } from '@reduxjs/toolkit';

import { ResGetMerchantDetails } from '../models/merchant-details.modal';
import { merchantDetails } from '../thunks/merchant-details.thunk';

const initialState: ResGetMerchantDetails = {
  status: undefined,
  successfulResponse: undefined,
  loading: false,
  error: undefined,
  data: undefined,
};
const getMerchantDetail = createSlice({
  name: 'merchantDetail',
  initialState,
  reducers: {
    resetMerchantDetails: (state) => {
      state.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(merchantDetails.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.successfulResponse = payload.successful_response;
      state.data = payload.data;
      state.error = payload.error;
      state.loading = false;
    });
    builder.addCase(merchantDetails.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      merchantDetails.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});
export const { resetMerchantDetails } = getMerchantDetail.actions;
export default getMerchantDetail.reducer;
