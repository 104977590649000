import { createSlice } from '@reduxjs/toolkit';

import { CountryCodeResponse } from '../models/country-code.model';
import { countryCodeListCall } from './../thunks/country-code.thunk';

const initialState: CountryCodeResponse = {
  status: undefined,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};
const countryCodeSlice = createSlice({
  name: 'countrycode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(countryCodeListCall.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
      } = payload);
    });
    builder.addCase(countryCodeListCall.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      countryCodeListCall.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
      }
    );
  },
});

export default countryCodeSlice.reducer;
