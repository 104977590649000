export const Platform = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = '';

  if (macosPlatforms.includes(platform)) {
    os = 'WEB-IOS';
  } else if (iosPlatforms.includes(platform)) {
    os = 'MOB-IOS';
  } else if (windowsPlatforms.includes(platform)) {
    os = 'WEB-WIN';
    //   } else if (/Android/.test(userAgent)) {
  } else if (userAgent.includes('Android')) {
    os = 'MOB-AND';
    //   } else if (/Linux/.test(platform)) {
  } else if (platform.includes('Linux')) {
    os = 'WEB_LIN';
  }

  return os;
};
