import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonProps,
  Icon,
  XB_Button,
  XB_Divider,
} from '@core-components/atoms';
import { BackButtonProps } from '@core-stores';

import { AU_ScreenLayout } from '../screen-layout/AU_ScreenLayout.component';

interface StatusScreenProps {
  pageTitle?: string;
  statusType: 'success' | 'failed' | 'pending' | string;
  statusTypeDesc: string;
  statusTypeBody?: React.JSX.Element;
  statusDescBody?: React.JSX.Element;
  actionBtns?: React.JSX.Element;
  backBtnProps?: BackButtonProps;
  bottomBtnProps: ButtonProps;
}

export const AU_StatusScreen = ({
  pageTitle,
  statusType,
  statusTypeDesc,
  statusDescBody,
  statusTypeBody,
  actionBtns,
  backBtnProps,
  bottomBtnProps,
}: StatusScreenProps) => {
  const { t } = useTranslation();
  const getIconName = () => {
    if (statusType === 'success') {
      return 'greenSuccess';
    }
    if (statusType === 'failed') {
      return 'failedIcon';
    } else {
      return 'pendingIcon';
    }
  };

  const textColor = () => {
    if (statusType === 'failed') {
      return 'text-red-600';
    }
    if (statusType === 'success') {
      return 'text-green-600';
    } else {
      return 'text-orange-700';
    }
  };

  const renderLeftContainer = () => {
    return (
      <div className="status-main-container w-full">
        <div className="status-details-container">
          {actionBtns && <div className="flex justify-end">{actionBtns}</div>}
          <div className="status-type-container">
            <div className="status-type">
              <Icon
                icon={getIconName()}
                height={101}
                width={200}
                color="none"
                className="mx-auto"
              />
              <p className={`m-text-lg-medium ${textColor()} mt-2 text-center`}>
                {statusTypeDesc}
              </p>
            </div>
            {statusTypeBody && <div className="w-full">{statusTypeBody}</div>}
          </div>

          <XB_Divider classes="my-4" />

          {statusDescBody && <div className="w-full">{statusDescBody}</div>}

          {bottomBtnProps && (
            <XB_Button
              btnType="tertiary"
              iconName="left-arrow"
              classes="mt-6 mx-auto"
              {...bottomBtnProps}
            >
              {bottomBtnProps.children ?? t('backHome')}
            </XB_Button>
          )}
        </div>
      </div>
    );
  };
  return (
    <section className="status-container">
      <AU_ScreenLayout
        isShowBackBtn={true}
        leftContainer={renderLeftContainer()}
        leftContainerClasses="bg-white p-4"
        backBtnProps={{ ...backBtnProps }}
        pageHeading={pageTitle}
      />
    </section>
  );
};
