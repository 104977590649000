import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, Service } from '@src/core-services';
import { handleError } from '@src/core-utils';

import { ReqAccountTypeDetails } from '../models/account-type-details.model';

export const accountType = createAsyncThunk(
  'accountType',
  async (AccountTypeObj: ReqAccountTypeDetails, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.depositService.ACC_TYPE,
        obj: AccountTypeObj,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
