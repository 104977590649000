import { createSlice } from '@reduxjs/toolkit';

import { LoanSummaryRdxData } from '../../models/loan.model';

const initialState: LoanSummaryRdxData = {
  selectedLoanType: undefined,
  overdueAmount: undefined,
  paidOverdueDetails: undefined,
  appliedLoanType: undefined,
};

const loanSummarySlice = createSlice({
  name: 'statement',
  initialState,
  reducers: {
    selectedLoanAccount(state, { payload }) {
      state.selectedLoanType = payload;
    },
    overdueAmount(state, { payload }) {
      state.overdueAmount = payload;
    },
    paidOverdueDetails(state, { payload }) {
      state.paidOverdueDetails = payload;
    },
    clearSelectedLoanAccount(state) {
      state.selectedLoanType = undefined;
    },
    setApplyForLoan(state, { payload }) {
      state.appliedLoanType = payload;
    },
  },
});

export const {
  selectedLoanAccount,
  clearSelectedLoanAccount,
  overdueAmount,
  paidOverdueDetails,
  setApplyForLoan,
} = loanSummarySlice.actions;
export default loanSummarySlice.reducer;
