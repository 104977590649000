import { EndPoints, Service } from '@core-services';

export const doAadhaarService = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.doAadhaarService,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const fetchAddressDetails = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.fetchAddressDetails,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const saveAddress = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.saveAddress,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const deleteAddress = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.deleteAddress,
    method: 'POST',
    url: '',
    obj: { id: data },
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const fetchPincodeCoordinates = async (address) => {
  const reqObj = {
    baseurl:
      EndPoints.onboardingService.getCoordinates(address).pincodeCoordinates,
    method: 'POST',
    url: '',
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const saveCoordinates = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.saveLocation,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const saveEditAddress = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.saveEditAddress,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const saveVpaAddress = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.saveVpaAddress,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const preferredAddress = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.savePreferredAddress,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const saveEditVpaAddress = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.saveEditVpaAddressUrl,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const displayNameApi = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.displayNameUrl,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const deleteRegistration = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.deleteRegistration,
    method: 'POST',
    url: '',
  };
  const response = await Service.send(reqObj, { data });
  return response.data;
};
export const getDocuments = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.getDocuments,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const checkUser = async (mobileNumber: string) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.fetchUserDetails,
    method: 'POST',
    url: '',
    obj: {
      userAttribute: mobileNumber,
    },
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};

export const saveDraftData = async (data) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.saveDraftData,
    method: 'POST',
    url: '',
    obj: data,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
