import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { AU_Badge, AU_RoundBox, XB_Button } from '@core-components/atoms';
import { getConcatenatedString } from '@core-utils';

import { CardsCarousel } from './cards-carousel.component';

import './../styles/payment-services.style.scss';

export interface IPaymentCardProps {
  title: string;
  description?: string;
  icon: string;
  badgeText?: string | null;
  isURL?: boolean;
  isNew?: boolean;
  onCardClick?: () => void;
  isDisabled: boolean;
  isVisible: boolean;
}

interface IPaymentServicesProps {
  sectionTitle?: string;
  rightBtnName?: string;
  onRightBtnClick?: () => void;
  isCarousel?: boolean;
  cardData: IPaymentCardProps[];
  showRightBtn?: boolean;
}

export const PaymentServices = ({
  sectionTitle = 'paymentServices',
  rightBtnName = 'paymentDashboard',
  showRightBtn = true,
  cardData,
  isCarousel = false,
  onRightBtnClick,
}: IPaymentServicesProps) => {
  const { t } = useTranslation();

  const ItemComponent = () => {
    return (
      <div className="ntb_payment_cards">
        {cardData.map((item, index) => {
          return (
            <Fragment key={getConcatenatedString([index, item.title])}>
              {item.isVisible && (
                <div
                  className={`payment_service_card ${
                    item.isDisabled ? 'make-disable' : ''
                  } `}
                  onClick={item.onCardClick}
                >
                  {item.isNew && (
                    <AU_Badge className="payment_card_new_badge bg-warning_50 text-warning_700">
                      {t('stockBadgeNew')}
                    </AU_Badge>
                  )}
                  <div className="flex flex-col">
                    <AU_RoundBox
                      width="3.5rem"
                      height="3.5rem"
                      boxClass="payment_service_card_roundbox"
                    >
                      <img src={item.icon} width={32} height={32} />
                    </AU_RoundBox>
                  </div>

                  <h2 className="m-text-md-medium text-neutral-900 text-center">
                    {t(item.title)}
                  </h2>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const CarouselComponent = () => {
    return (
      <CardsCarousel
        showArrows={false}
        showDots={false}
        isFocusOnSelect={true}
        isimageCarausel={false}
        isResponsive={true}
        data={cardData}
      />
    );
  };

  return (
    <div className="payment_services_container">
      <div className="payment_services_title_section">
        <h1 className="m-display-sm-bold text-gray-800">{t(sectionTitle)}</h1>
        {showRightBtn && (
          <XB_Button
            dataTestId="PYMT-DSBD"
            btnType="primary-underline"
            suffixIcon="pay-icon"
            suffixIconClick={onRightBtnClick}
            onClick={onRightBtnClick}
          >
            {t(rightBtnName)}
          </XB_Button>
        )}
      </div>
      <div className={`${isCarousel ? '!block' : ''} payment_service_cards`}>
        {isCarousel ? CarouselComponent() : ItemComponent()}
      </div>
    </div>
  );
};
