import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestCustomerAccounts } from '../models/customer-accounts.model';

export const customerAccounts = createAsyncThunk(
  'api/account-service/mapped-customer-accounts-details',
  async (
    customerAccountsParams: RequestCustomerAccounts,
    { rejectWithValue }
  ) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.customerAccountsService.CUSTOMER_ACCOUNTS_DETAILS,
        url: '',
        obj: customerAccountsParams,
      });
      if (response.data.status === 'error') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
