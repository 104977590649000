import { useEffect, useState } from 'react';

export function useCountdown(
  value: number
): [number, React.Dispatch<React.SetStateAction<number>>] {
  const [countdown, setCountdown] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countdown > 0) {
        setCountdown((prevCount): number => prevCount - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [countdown]);
  return [countdown, setCountdown];
}
