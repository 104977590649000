import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import { Camera } from '@mediapipe/camera_utils';
import { NormalizedRect, Results } from '@mediapipe/face_detection';

export const useFaceDetection = (props) => {
  const {
    mirrored,
    handleOnResults,
    faceDetectionOptions: options,
    faceDetection: faceDetectionInitializer,
    camera: cameraInitializer,
  } = props;

  /** Bounding Box for element to use, e.g. can create a bounding box with these values using a div  */
  const [boundingBox, setBoundingBox] = useState<NormalizedRect[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  /** Refs */
  const webcamRef = useRef<Webcam>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const camera = useRef(cameraInitializer).current;
  const faceDetection = useRef(faceDetectionInitializer).current;
  const faceDetectionOptions = useRef(options);
  const [cameraManager, setCameraManager] = useState<Camera>();

  const onResults = useCallback(
    (results: Results) => {
      /** Callback to return detection results */
      if (handleOnResults) handleOnResults(results);

      const { detections } = results;

      /** Set bounding box data */
      const boundingBoxes = detections.map((detection) => {
        const xCenter =
          detection.boundingBox.xCenter - detection.boundingBox.width / 2;
        return {
          ...detection.boundingBox,
          yCenter:
            detection.boundingBox.yCenter - detection.boundingBox.height / 2,
          xCenter: mirrored ? 1 - xCenter : xCenter,
        };
      });

      setBoundingBox(boundingBoxes);
    },
    [handleOnResults, mirrored]
  );

  const handleFaceDetection = useCallback(
    async (mediaSrc: HTMLVideoElement | HTMLImageElement) => {
      /** Configure faceDetection usage/options */
      faceDetection.setOptions({ ...faceDetectionOptions.current });
      faceDetection.onResults(onResults);

      /** Handle webcam detection */
      if (cameraManager) {
        cameraManager.start();
      }

      /** Handle image face detection */
      if (mediaSrc instanceof HTMLImageElement && cameraManager) {
        await faceDetection.send({ image: mediaSrc });
        if (isLoading) setIsLoading(false);
      }
    },
    [cameraManager, faceDetection, isLoading, onResults, camera]
  );

  useEffect(() => {
    if (!webcamRef?.current || !webcamRef.current.video || !camera) return;

    const mediaSrc = webcamRef.current.video;
    setCameraManager(() =>
      camera({
        mediaSrc,
        width: mediaSrc.videoWidth,
        height: mediaSrc.videoHeight,
        onFrame: async () => {
          await faceDetection.send({
            image: mediaSrc,
          });
        },
      })
    );
  }, []);

  useEffect(() => {
    if (!webcamRef?.current || !webcamRef.current.video || !camera) return;

    const mediaSrc = webcamRef.current.video;
    handleFaceDetection(mediaSrc);

    // stops camera detection when component unmounts
    return () => {
      cameraManager?.stop();
    };
  }, [cameraManager]);

  return {
    boundingBox,
    isLoading,
    detected: boundingBox.length > 0,
    facesDetected: boundingBox.length,
    webcamRef,
    imgRef,
  };
};
