import { createSlice } from '@reduxjs/toolkit';

import { ShowBankListRes } from '../models/add-bank-account.model';
import { getBankList } from '../thunks/add-bank-account.thunk';

const initialState: ShowBankListRes = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};
const showBankList = createSlice({
  name: 'bankListDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBankList.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        bankListData: state.data,
        data: state.data,
        loading: state.loading,
      } = payload);
    });
    builder.addCase(getBankList.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getBankList.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default showBankList.reducer;
