import { Icon, XB_Dropdown } from '@core-components/atoms';

import './AU_Amountdropdown.style.scss';

interface AmountdropdownProps {
  id: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  label?: string;
  classes?: string;
}

export const AU_Amountdropdown = ({
  id,
  onChange,
  disabled = false,
  label,
  classes,
}: AmountdropdownProps) => {
  const amountList = [
    {
      countryInitials: 'IN',
      countryName: 'IN',
      currencySymbol: 'rupees',
      countryValue: '91',
    },
  ];
  const dropdownItems = amountList.map((c) => {
    return {
      text: c.countryName,
      value: c.countryValue,
      icon: <Icon icon={c.currencySymbol} height={20} width={20} />,
    };
  });
  return (
    <XB_Dropdown
      id={id}
      classes={`amount-dropdown ${classes}`}
      items={dropdownItems}
      onChange={onChange}
      disabled={disabled}
      selectedValue="91"
      label={label}
    />
  );
};
