import { Service } from '.';
import { AUEndPoints } from './au-endpoints.service';

export const s3DownlaodImg = async (header) => {
  try {
    const reqObj = {
      baseurl: AUEndPoints.userManagementService.DOWNLOAD_FILE,
      method: 'POST',
      url: '',
    };

    const response = await Service.send(reqObj, header);

    return response.data;
  } catch (error: any) {
    return error;
  }
};
