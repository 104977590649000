import { XB_Modal } from '../../atoms/modal/XB_Modal.component';
import { XB_Spinner } from '../../atoms/spinner/XB_Spinner.component';

interface IconProps {
  iconName?: string;
  className?: string;
  open: boolean;
  setOpen: (boolean) => void;
  title: string;
  subTitle: string;
  maxWidth?: string;
  body?: React.ReactNode;
}
export const XB_SpinnerModal = ({
  iconName,
  open = false,
  setOpen,
  maxWidth = 'auto',
  title,
  subTitle,
  body,
}: IconProps) => {
  return (
    <>
      <XB_Modal
        backdropClose={false}
        open={open}
        setOpen={setOpen}
        title={title}
        subTitle={subTitle}
        maxWidth={maxWidth}
        body={body}
        img={<XB_Spinner iconName={iconName} />}
      />
    </>
  );
};
