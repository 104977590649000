import { Service } from '@core-services';

import { AUEndPoints } from './au-endpoints.service';
import { ReqMpinAuthenticate } from './models/mpin-validate.model';

export const MpinAuthenticationApi = async (payload: ReqMpinAuthenticate) => {
  const reqObj = {
    baseurl: AUEndPoints.userManagementService.MPIN_AUTHENTICATE,
    method: 'POST',
    url: '',
    obj: payload,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
