import { startOfDay, startOfMonth } from 'date-fns';
import moment from 'moment';

import { ButtonListProps } from '@core-components/molecules';
import { appConstants, Items } from '@core-constants';

import { FinancialYearResponse } from '../models/account-statement.model';

export interface NoRecordModalProps {
  showFilterModalFunc: any;
}

export const downloadFormatOptions = {
  PDF: 'PDF',
  CSV: 'CSV',
  XLS: 'EXCEL',
};
export const formatButtons = ['PDF', 'CSV', 'XLS'];
export const reverseFormatButtons = ['XLS', 'CSV', 'PDF'];
export const loanFormatButtons = ['PDF'];
export const formatButtonIcons = ['pdfIcon', 'csvIcon', 'xlsIcon'];

export const errorCodesStatement = {
  AS0810: 'AS0810',
  AS0809: 'AS0809',
  88: '88',
};
export const maxRecordsToShow = 999;

export const loanString = 'Loans';
export const loadKey = {
  account: ['CurrentAccount', 'SavingsAccount'],
  loan: ['Loans'],
};
export const statusToGet = ['active'];

export const DEFAULT_MODULE = 'account';
export const dateFormats = {
  dateFormatToSendInApi: 'DDMMMYYYY',
  dateFormatToSendInApiWithDash: 'DD-MMM-YYYY',
  dateFormatToSendInApiWithoutDash: 'YYYYDDMM',
  dateFormatToSendInLoanApi: 'YYYYMMDD',
  timeFormat: 'hh:mm A',
};
export const buttonList: ButtonListProps[] = [
  {
    buttonText: 'Download',
    activeIcon: 'downloadicon',
    inactiveIcon: 'downloadicon',
  },
  {
    buttonText: 'Email',
    activeIcon: 'mailOrange',
    inactiveIcon: 'mailGray',
  },
];

export const today = moment().format(appConstants.DDMMMYYYY);

export const quickDownloadSections = [
  {
    title: 'currentFinancialYear',
    timerangetoshow: `April ${moment().format('YYYY')} - Present Month`,
  },
  {
    title: 'previousFinancialYear',
    timerangetoshow: `April ${moment().format('YYYY')} - Present Month`,
  },
];

export const filterPricePlaceholderValues = {
  fromAmount: 0,
  toAmount: 9999999,
};

export const inputValidation = {
  maxLengthPrice: 7,
  maxAmountToFilter: 9999999,
};
export const intialFilterDateRange = {
  isInitialfilterByCalender: false,
  startDate: startOfMonth(new Date()),
  endDate: startOfDay(new Date()),
};

export const intialPriceFilter = {
  transactionType: 'all',
  isIntialPriceFilter: false,
  fromAmount: '',
  toAmount: '',
};

export interface IFilterData {
  accountNumber: string;
  filterDateRange: {
    isInitialfilterByCalender: boolean;
    startDate: Date;
    endDate: Date;
  };
  priceRangeFilter: {
    transactionType: string;
    isIntialPriceFilter: boolean;
    fromAmount: string;
    toAmount: string;
  };
}

export const filterDataInitial = {
  accountNumber: '',
  filterDateRange: intialFilterDateRange,
  priceRangeFilter: intialPriceFilter,
};

export const screenTypes = {
  loadingScreen: 'loadingScreen',
  dataScreen: 'dataScreen',
  noResultScreen: 'noResultScreen',
  noResultTryAnotherSearch: 'noResultTryAnotherSearch',
  tooManyTxnsOnSingleDay: 'tooManyTxnsOnSingleDay',
  tooManyTxnsMultipleDays: 'tooManyTxnsMultipleDays',
};

export const booleanValues = { true: true, false: false };
export const filterModalTypes = {
  emailInProgress: 'emailInProgress',
  calEnder: 'calender',
  filTer: 'filter',
  clickedModal: 'clickedmodal',
  downloadProgess: 'downloadprogess',
  downloadFailed: 'downloadfailed',
  downloadModal: 'downloadmodal',
  quickDownloadModal: 'quickdownloadmodal',
  quickEmailModal: 'quickemailmodal',
  emailModal: 'emailmodal',
  emailFailed: 'emailfailed',
  tooManyTxnsInfo: 'tooManyTxnsInfo',
  noRecordFound: 'noRecordFound',
};

export const accIds = {
  ID: 'ACCT',
  moduleState: 'account',
  heading: 'accountStatements',
  searchPlaceHolder: 'searchAccountStmt',
};

export const transactionTypes = [
  {
    text: 'All',
    value: 'all',
  },
  { text: 'Credit', value: 'C' },
  { text: 'Debit', value: 'D' },
];

export const transactionTypeObj = { all: 'All', C: 'Credit', D: 'Debit' };

export interface CalenderModalProps {
  currentModule?: string;
  applyFilter: (item: string, val: any) => void;
  showFilterModalFunc: (val: string) => void;
  filterValues?: IFilterData;
}

export interface DataTableProps {
  filterData: any;
  recordData: any;
  selectedRowFunc: any;
  moduleName: string;
  setHideRightBar: () => void;
  onDownloadPress: (format: string) => void;
}

export const statementData = {
  orignalData: [],
  filteredData: [],
  currentscreen: 'loadingScreen',
};

export interface DownloadProgressModalProp {
  showFilterModalFunc: any;
}
export interface DownloadFailedModalProp {
  showFilterModalFunc: any;
  errorType: string;
}

export interface MsgToShowInModalProps {
  msgToShow: string;
}
export interface TooManyTxnsAccountProp {
  emailApiCall?: () => void;
  resetFilters?: () => void;
}
export interface QuickDownloadProp {
  showFilterModalFunc: (type: string, item: any) => void;
  financialYears: FinancialYearResponse[];
  setModalDateString: (val: string) => void;
}
export interface EmailModalProp {
  showFilterModalFunc: (type: string) => void;
  onDownloadPress: (format: string, type: boolean, emailList: string[]) => void;
  financialModalDateString?: string;
}

export interface TooManyTxnsInfoProps {
  setOpen: (val: string) => void;
  errorMsg: string;
}

export const msgToShow = { msg: '' };

export const startOfMonthTmp = moment()
  .startOf('months')
  .format(appConstants.DDMMMYYYY);

export function getCurrentFinancialYear() {
  const todayDate = new Date();
  const currentMonth = todayDate.getMonth() + 1;
  const currentYear = todayDate.getFullYear();

  if (currentMonth < 4) {
    return `${currentYear - 1} - ${currentYear}`;
  } else {
    return `${currentYear} - ${currentYear + 1}`;
  }
}

export function getPreviousFinancialYear() {
  const currentFinancialYear = getCurrentFinancialYear();
  const years = currentFinancialYear.split('-');
  const startYear = parseInt(years[0], 10);
  return `${startYear - 1} - ${startYear}`;
}

export const financialYearList = [
  {
    startDate: moment(
      `${getCurrentFinancialYear()?.split('-')[0]}-04-01`,
      'YYYY-MM-DD'
    ).format(appConstants.DDMMMYYYY),
    endDate: moment().format(appConstants.DDMMMYYYY),
  },
  {
    startDate: moment(
      `${getPreviousFinancialYear()?.split('-')[0]}-04-01`,
      'YYYY-MM-DD'
    ).format(appConstants.DDMMMYYYY),
    endDate: moment(
      `${getPreviousFinancialYear()?.split('-')[1]}-03-31`,
      'YYYY-MM-DD'
    ).format(appConstants.DDMMMYYYY),
  },
];

export interface SelectedVal {
  selectedVal: any;
  showFilterModalFunc: any;
}

export interface FilterSectionProps {
  itemList: Items[];
  filterData: IFilterData;
  applyFilter: (item: string, val: any) => void;
  showFilterModalFunc: (val: string) => void;
  disableFilters: boolean;
}

export const statementConfig = {
  CONFIG_TYPE: 'FE_STATEMENT_CONFIG',
  MODULE: 'feStatementConfig',
};

export interface IStmtConfigData {
  maxEmailsForStatement: number;
}

export const pseudoCode = 'fe_pseudo_code_config';
