/**
 * @author Sunil Jangid - 323361
 * @description for check input is empty or not in case of string, object, array, number
 * @param input
 * @returns boolean
 */
export const isEmpty = (input: any) => {
  if (input === undefined || input === null) {
    return true;
  }
  // checking empty for string
  if (typeof input === 'string' && input === '') {
    return true;
  }
  // checking empty for object
  if (typeof input === 'object' && Object.keys(input).length === 0) {
    return true;
  }
  return false;
};
