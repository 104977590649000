import { createSlice } from '@reduxjs/toolkit';

import { AlertDialogConfig } from '../models/common.model';

interface AUAlertContainer {
  messeges: AlertDialogConfig[] | [];
}

const initialState: AUAlertContainer = {
  messeges: [] as AlertDialogConfig[],
};
const AUAlertContainerSlice = createSlice({
  name: 'alertContainer',
  initialState,
  reducers: {
    addMessage(state, { payload }) {
      state.messeges = [...state.messeges, payload];
    },
    removeMesaage(state, { payload }) {
      state.messeges = state.messeges.filter(
        (item) => item.messageId !== payload
      );
    },
    clearMessages(state) {
      state.messeges = [];
    },
  },
});

export const { addMessage, removeMesaage, clearMessages } =
  AUAlertContainerSlice.actions;
export default AUAlertContainerSlice.reducer;
