import { LeadGenMasterRes } from '@core-stores';
import { createSlice } from '@reduxjs/toolkit';

import { leadGenMaster } from '../thunks/crm-lead-gen-master.thunk';

export const initialState: LeadGenMasterRes = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};
const leadGenMasterData = createSlice({
  name: 'leadGenMaster',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(leadGenMaster.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
        loading: state.loading,
      } = payload);
    });
    builder.addCase(leadGenMaster.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      leadGenMaster.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default leadGenMasterData.reducer;
