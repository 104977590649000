import { createSlice } from '@reduxjs/toolkit';

import {
  consentAcceptance,
  consentAcceptedList,
} from '../thunks/consent-acceptance.thunk';

interface IConsentStateType {
  status: string | undefined;
  loading: boolean;
  error: any;
  data: any;
  acceptedList: any[];
  currentPage: number;
  userAcceptedList: any[];
  showConsentList: boolean[];
  isUserAcceptedata: boolean;
}

const initialState: IConsentStateType = {
  status: undefined,
  loading: false,
  error: undefined,
  data: undefined,
  acceptedList: [],
  currentPage: 1,
  userAcceptedList: [],
  showConsentList: [true, true, true],
  isUserAcceptedata: false,
};

const getConcentData = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    nextConsentPage(state) {
      state.currentPage = state.currentPage + 1;
    },
    updateshowConsent(state, action) {
      state.showConsentList[action.payload] = false;
    },
    updateAcceptedList(state, action) {
      state.acceptedList.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(consentAcceptance.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        data: state.data,
      } = payload);
      state.loading = false;
    });
    builder.addCase(consentAcceptance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(consentAcceptance.rejected, (state, { payload }) => {
      state.error = payload;
      state.data = null;
    });

    builder.addCase(consentAcceptedList.fulfilled, (state, { payload }) => {
      state.userAcceptedList = payload.data;
      state.loading = false;
      state.error = payload.error;
      state.isUserAcceptedata = true;
    });
    builder.addCase(consentAcceptedList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(consentAcceptedList.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export default getConcentData.reducer;
export const { nextConsentPage, updateshowConsent, updateAcceptedList } =
  getConcentData.actions;
