const {
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_VAPID_KEY,
} = process.env;

const firebaseConfig = {
  apiKey: 'AIzaSyAxuppCOiyhbfv0J_osn17sxVOLwnV1g2g',
  authDomain: 'au-bank-925e7.firebaseapp.com',
  projectId: 'au-bank-925e7',
  storageBucket: 'au-bank-925e7.appspot.com',
  messagingSenderId: '40596484072',
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
  vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
};

export default firebaseConfig;
