import { useState } from 'react';
import { useLocation } from 'react-router';

import {
  AU_TopNavbar,
  XB_SideNavbar,
  XB_SideNavbarMob,
} from '@core-components/organisms';
import { excludedSidebarList } from '@core-constants';

interface NavigationMenuProps {
  // add props here....
  children: string | React.JSX.Element | React.JSX.Element[];
}

export const XB_NavigationMenu = ({ children }: NavigationMenuProps) => {
  const [expandedNavbar, setExpandedNavbar] = useState<boolean>(false);
  const location = useLocation();
  const excludedUrl = !excludedSidebarList.includes(location.pathname);

  return (
    <div className="grid h-screen relative grid-rows-[auto 1fr] overflow-auto bg-neutral-100">
      <AU_TopNavbar
        handleNavbarOpen={() => setExpandedNavbar((prev) => !prev)}
      />

      <div className="flex overflow-hidden bg-neutral-100">
        {excludedUrl && (
          <XB_SideNavbar
            isOpenSideNavbar={expandedNavbar}
            setOpenNavbar={setExpandedNavbar}
          />
        )}
        {expandedNavbar && excludedUrl && (
          <XB_SideNavbarMob
            isOpenSideNavbar={expandedNavbar}
            setOpenNavbar={setExpandedNavbar}
          />
        )}
        <div className="overflow-y-scroll w-screen" id="root_id">
          {children}
        </div>
      </div>
    </div>
  );
};
