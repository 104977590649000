import { t } from 'i18next';

import { AU_Ttficon, Icon, XB_Button } from '@core-components/atoms';
import { RegEx } from '@core-constants';
import { replaceOccuranceWithDelimeter } from '@core-utils';

import './AU_AccountHolderCard.style.scss';

interface AccountHolderCardProps {
  title: string;
  subTitle: string;
  bankName: string;
  ifscCode?: string;
  verifyStatus?: boolean;
  onDeletePress: () => void;
}

export const AU_AccountHolderCard = ({
  title,
  subTitle,
  bankName,
  ifscCode,
  verifyStatus,
  onDeletePress,
}: AccountHolderCardProps) => {
  return (
    <div className="p-3 mt-6 account-card-container">
      <div className="flex justify-between flex-row">
        <div className="basis-4/5 justify-content-start">
          <span className="text-gray-800 m-text-md-bold flex flex-row items-center">
            {title}
            {verifyStatus === true && (
              <div className="ml-1">
                <Icon icon={'verified'} height={10} width={10} />
              </div>
            )}
          </span>
          <div className="flex justify-content-start flex-row">
            <span className="text-gray-800 m-text-md-medium">
              {`${t('acNo')} : ${subTitle}`}
            </span>
          </div>
        </div>
        <div className="basis-1/5 flex items-start justify-end">
          <XB_Button
            dataTestId="REMV-ACC"
            backgroundColor="white"
            size="sm"
            onClick={() => {
              onDeletePress();
            }}
          >
            <Icon icon={'delete-orange-icon'} height={16} width={16} />
          </XB_Button>
        </div>
      </div>
      <div className="flex justify-start items-center flex-row mt-2">
        <AU_Ttficon
          icon={replaceOccuranceWithDelimeter(
            bankName ?? '',
            RegEx.spaceRegex,
            '_'
          )}
          style={{
            height: '1.8rem',
            width: '1.8rem',
            minHeight: '1.8rem',
            minWidth: '1.8rem',
          }}
          type="bank"
        />
        <div className="justify-content-start ml-1">
          <h4 className="text-gray-600 m-text-md-medium">{bankName}</h4>
          {ifscCode && (
            <p className="text-gray-600 m-text-sm-medium">
              {`${t('ifscCode')}: ${ifscCode}`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
