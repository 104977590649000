import { createSlice } from '@reduxjs/toolkit';

import { IProfileSettingRdxData } from '../../models/profile-info.model';

const initialState: IProfileSettingRdxData = {
  storeDetails: undefined,
};

const profileSettigSlice = createSlice({
  name: 'profileSetting',
  initialState,
  reducers: {
    setStoreDetails(state, { payload }) {
      state.storeDetails = payload;
    },
  },
});

export const { setStoreDetails } = profileSettigSlice.actions;
export default profileSettigSlice.reducer;
