import { AUEndPoints, Service } from '@core-services';
import { CrmLeadGenReq } from '@core-stores';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const crmLeadGen = createAsyncThunk(
  'accountType',
  async (leadGenObj: CrmLeadGenReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        url: '',
        baseurl: AUEndPoints.serviceRequest.CRM_LEAD_GEN,
        obj: leadGenObj,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: error.getErrorMessage(),
        requestId: error.getRequestId(),
      });
    }
  }
);
