import { EndPoints, Service } from '@core-services';

export const vpaStatus = async (data) => {
  const reqObj = {
    baseurl: EndPoints.inventoryService.vpaStatus,
    method: 'POST',
    url: '',
    obj: {
      ...data,
    },
  };
  const response = await Service.send(reqObj);
  return response.data;
};
