import { createSlice } from '@reduxjs/toolkit';

import { ResAccountVerification } from '../models/account-verification.model';
import { AccountVerify } from '../thunks/account-verification.thunk';

const initialState: ResAccountVerification = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};
const AccountVerifyRes = createSlice({
  name: 'accountVerification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AccountVerify.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
        loading: state.loading,
      } = payload);
    });
    builder.addCase(AccountVerify.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      AccountVerify.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default AccountVerifyRes.reducer;
