import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from '../models/auth-res.model';
import { getUserProfile, login, logout } from '../thunks/login.thunk';

const initialState: AuthState = {
  loggedIn: false,
  error: null,
  username: '',
  emailAddress: '',
  employeeId: '',
  mobileNumber: '',
  name: '',
  userId: '',
  persona: '',
  userGroups: [],
  cifNumber: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loggedIn = payload.loggedIn;
      state.username = payload.username;
    });
    builder.addCase(login.rejected, (state, { payload }: { payload: any }) => {
      state.loggedIn = false;
      state.error = payload;
    });
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.userId = data.userId;
      state.emailAddress = data.emailAddress;
      state.employeeId = data.employeeId;
      state.mobileNumber = data.mobileNumber;
      state.name = data.name;
      state.username = data.username;
      state.userGroups = data.userGroups;
      state.cifNumber = data.cifNumber;
      state.persona = data?.persona;
    });
    builder.addCase(
      getUserProfile.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = payload;
      }
    );
    builder.addCase(logout.fulfilled, (_state, _) => initialState);
    builder.addCase(logout.rejected, (_state, _) => initialState);
  },
});

export default authSlice.reducer;
