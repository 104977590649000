import { getAnalytics, logEvent } from 'firebase/analytics';
import i18next, { t } from 'i18next';

import { logError } from '@core-utils';
import { errorCodes } from '@src/core-constants';

interface Message {
  bucketCode: string;
  bucketMessage: string;
  displayMessage: string;
  serverMessage?: string;
  errCode?: string;
}
export interface ClientError {
  status: number;
}
export interface ServerError {
  error: {
    errorCode?: string;
    error_code?: string;
  };
}
const { REACT_APP_LOG_ANALYTICS, REACT_APP_FIREBASE_IDENTIFIER } = process.env;
const logInAnalytics = REACT_APP_LOG_ANALYTICS;

const logEventToFirebase = (error: Message) => {
  try {
    const analytics = getAnalytics();
    const errorObject = {
      X_Error_Bucket_Code: error?.bucketCode,
      X_Error_Bucket_Message: error?.bucketMessage,
      X_Error_Display_Message: error?.displayMessage,
    };
    const eventName = REACT_APP_FIREBASE_IDENTIFIER
      ? `${REACT_APP_FIREBASE_IDENTIFIER}_APP_ERRORS`
      : `APP_ERRORS`;
    logInAnalytics && logEvent(analytics, eventName, errorObject);
  } catch (errors: any) {
    logError('CUSTOM EVENT WITH PARAM ERROR', errors.toString());
  }
};

export const handleClientError = (
  bucketCode: string,
  errorType = 'client',
  errorCode?: string
): Message => {
  const bucketCodeTranslatedMsg = t(`${bucketCode}`);

  return {
    bucketCode,
    bucketMessage: `${errorCode} : ${bucketCodeTranslatedMsg}`,
    displayMessage: errorType === 'server' ? t(`${errorCode}`) : t(`AUWEB012`),
    errCode: errorCode,
  };
};

export const getClientErrorCode = (
  status?: string,
  errorCode?: string
): Message => {
  switch (status) {
    case '000':
      return handleClientError(errorCodes.client[0], 'server', errorCode);
    case '400':
      return handleClientError(errorCodes.client[1], 'client', status);
    case '401':
      return handleClientError(errorCodes.client[2], 'client', status);
    case '404':
      return handleClientError(errorCodes.client[3], 'client', status);
    case '405':
      return handleClientError(errorCodes.client[4], 'client', status);
    case '408':
      return handleClientError(errorCodes.client[5], 'client', status);
    case '415':
      return handleClientError(errorCodes.client[6], 'client', status);
    case '500':
      return handleClientError(errorCodes.client[7], 'client', status);
    case '502':
      return handleClientError(errorCodes.client[8], 'client', status);
    case '503':
      return handleClientError(errorCodes.client[9], 'client', status);
    case '504':
      return handleClientError(errorCodes.client[10], 'client', status);
    case '505':
      return handleClientError(errorCodes.client[11], 'client', status);
    default:
      return handleClientError(
        errorCodes.client[12],
        'client',
        status ?? '000'
      );
  }
};

export const handleError = (error: any): Message => {
  let errorObj;
  const errorCode = error.error?.errorCode as string;
  const status = error?.status;
  if (errorCode && i18next.exists(`${errorCode}`)) {
    errorObj = getClientErrorCode('000', error.error.errorCode);
    errorObj.serverMessage = error.error?.message as string;
    errorObj.consumedAmount = error.error?.consumedAmount;
  } else if (status) {
    errorObj = getClientErrorCode(status.toString());
  } else {
    errorObj = getClientErrorCode();
  }
  if (error.error?.requestId) {
    errorObj.requestId = error.error?.requestId;
  }
  if (error.error?.availableLimit) {
    errorObj.availableLimit = error.error?.availableLimit;
  }
  if (error.error?.errorDetails) {
    errorObj.errorDetails = error.error?.errorDetails;
  }

  logEventToFirebase(errorObj);
  return errorObj;
};
