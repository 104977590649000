import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, Service } from '@src/core-services';
import { InternalAccountVerificationReq } from '@src/core-stores/models/internal-account-verification.model';
import { handleError } from '@src/core-utils';

export const internalAccountVerify = createAsyncThunk(
  'internalAccountVerification',
  async (
    internalAccountVerification: InternalAccountVerificationReq,
    { rejectWithValue }
  ) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentsService.paymentService
          .INTERNAL_ACCOUNT_VERIFICATION,
        obj: internalAccountVerification,
      });

      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
