import { createSlice } from '@reduxjs/toolkit';

import { ResGetPayee } from '../../models/response-beneficiary.model';
import { getPayeeDetails } from '../thunks/get-payee.thunk';

const initialState: ResGetPayee = {
  status: undefined,
  data: undefined,
  error: undefined,
  successfulResponse: undefined,
  loading: false,
};
const getPayeeSlice = createSlice({
  name: 'getPayeeDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPayeeDetails.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.successfulResponse = payload.successful_response;
      state.data = payload.data;
      state.error = payload.error;
      state.loading = false;
    });
    builder.addCase(getPayeeDetails.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getPayeeDetails.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default getPayeeSlice.reducer;
