import React from 'react';

interface BGProviderProps {
  children: React.ReactNode;
  style?: {
    [key: string]: string;
  };
  bgColor?: string;
}
export function BGProvider({
  children,
  style,
  bgColor = '#FFFAEB',
}: BGProviderProps) {
  return (
    <div
      style={{
        ...style,
        backgroundColor: bgColor,
        padding: 8,
        borderRadius: 4,
      }}
    >
      {children}
    </div>
  );
}
