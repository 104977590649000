import { useEffect, useRef, useState } from 'react';

import { Icon, XB_Button } from '@src/core-components/atoms';

import './AU_PopupMenu.style.scss';

interface MenuItem {
  text: string;
  value: string;
  icon?: string;
  color?: string;
}
interface PopupMenuProps {
  textSize: 'md' | 'lg';
  iconSize?: number;
  items: MenuItem[];
  onItemClick?: (val: any) => void;
}

export const AU_PopupMenu = ({
  textSize,
  iconSize,
  items,
  onItemClick,
}: PopupMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleItemClick = (val: any) => {
    onItemClick?.(val);
    setIsOpen(false);
  };

  return (
    <>
      <div className="popup-container">
        <XB_Button
          btnType="tertiary-gray"
          dataTestId="POP-UP"
          iconName="threedoticon"
          size="sm"
          onClick={() => setIsOpen(!isOpen)}
        />
        {isOpen && (
          <div className="popup-menu" ref={popupRef}>
            <div className="menu-items flex flex-col gap-y-3">
              {items.map((item, index) => (
                <div
                  key={index}
                  className="menu-item flex gap-x-2 p-1 cursor-pointer"
                  onClick={() => handleItemClick(item.value)}
                >
                  {item.icon && (
                    <span className="menu-icon">
                      <Icon
                        icon={item.icon}
                        height={iconSize}
                        width={iconSize}
                        color={item.color}
                      />
                    </span>
                  )}
                  <span className={`m-text-${textSize}-regular text-gray-900`}>
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
