export enum countryTypeOption {
  IN = 'IN',
  OTH = 'OTH',
}

export enum errorTypeOption {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  VERIFIED = 'verified',
  BLANK = '',
}

export const minMaxValLength = {
  addressMinLength: 5,
  addressMaxLength: 35,
  payeeMinLength: 3,
  payeeMaxLength: 32,
  cityMinLength: 5,
  cityMaxLength: 35,
  billingMaxLength: 100,
  zipMinLength: 3,
  zipMaxLength: 10,
  upiMaxLength: 120,
  emailMaxLength: 120,
  panMaxLength: 10,
  gstMaxNumber: 15,
};

export const TXN_BRANCH = '2011';
export const IFSC_FLAG = {
  YES: 'Y',
  NO: 'N',
};

export const FE_PAYEE_CONFIG = {
  CONFIG_TYPE: 'FE_PAYEE_CONFIG',
  MODULE: 'fePayeeConfig',
};

export const payeeColumns = [
  {
    name: 'payeeName',
    key: 'PayeeName',
  },
  {
    name: 'bankAccount',
    key: 'bankAccount',
  },
  {
    name: 'contactDetails',
    key: 'contactDetails',
  },
  {
    name: 'action',
    key: 'action',
  },
];

export const BANK_CATEGORY = {
  IFSC: '1', // AXIS BANK
  WITHOUT_IFSC: '0', // KARNATAKA BANK
  AU: '2',
};

export const CSV = 'csv';
