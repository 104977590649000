import { useTranslation } from 'react-i18next';

import './AU_Widgetcard.style.scss';

interface AUWidgetcardProps {
  title?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export const AU_Widgetcard = ({
  title,
  onClick,
  children,
}: AUWidgetcardProps) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-md">
      <div className="widget_header bg-gray-200">
        <h2 className="widget_head m-text-lg-bold text-black">{title}</h2>
        <div className="wd">
          <span
            className="flex space-x-2 text-orange-600 m-text-md-medium items-start cursor-pointer"
            onClick={() => {
              onClick?.();
            }}
          >
            {t('viewAll')}
          </span>
        </div>
      </div>
      <div className="widget_body bg-white">{children}</div>
    </div>
  );
};
