export function sentenceFormatter(strLabel: string): string {
  if (!strLabel) {
    return '';
  }
  return (
    strLabel.toLowerCase().charAt(0).toUpperCase() +
    strLabel.toLowerCase().slice(1)
  );
}

export const maskCreditCardNumber = (num: string) => {
  const first: string = num.substring(0, 4);
  const last: string = num.substring(num.length - 4);
  return first + ' xxxx xxxx ' + last;
};
