import { createSlice } from '@reduxjs/toolkit';

import { TransactionsRespo } from '../../models/payments.model';
import { getScheduledTransactionsList } from '../thunks/payments.thunk';

const initialState: TransactionsRespo = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: {
    paymentTxnCount: undefined,
    paymentTxnData: undefined,
  },
};

const recentSheduledTransactionsListSlice = createSlice({
  name: 'get-scheduled-transactions-list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScheduledTransactionsList.pending, (state) => {
      state.status = undefined;
      state.error = undefined;
      state.loading = true;
      state.successfulResponse = undefined;
      state.data = { paymentTxnCount: undefined, paymentTxnData: undefined };
    });
    builder.addCase(
      getScheduledTransactionsList.fulfilled,
      (state, { payload }: { payload: any }) => {
        ({
          status: state.status,
          error: state.error,
          successfulResponse: state.successfulResponse,
          data: state.data,
        } = payload);
        state.loading = false;
      }
    );
    builder.addCase(
      getScheduledTransactionsList.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = {
          paymentTxnCount: undefined,
          paymentTxnData: undefined,
        };
        state.loading = false;
      }
    );
  },
});

export default recentSheduledTransactionsListSlice.reducer;
