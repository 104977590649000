import { PaymentTransferData } from '@core-stores';
import { createSlice } from '@reduxjs/toolkit';

interface ITxnSlice {
  repeatTransaction: PaymentTransferData | undefined;
  selectTransaction: PaymentTransferData | undefined;
}
const initialState: ITxnSlice = {
  repeatTransaction: undefined,
  selectTransaction: undefined,
};

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState,
  reducers: {
    setRepeatTransaction(state, { payload }) {
      state.repeatTransaction = payload;
    },
    setTransactionData(state, { payload }) {
      state.selectTransaction = payload;
    },
  },
});

export const { setRepeatTransaction, setTransactionData } =
  transactionSlice.actions;

export default transactionSlice.reducer;
