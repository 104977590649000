import { ButtonProps, Icon, XB_Button } from '@core-components/atoms';

import './XB_InlineMessage.style.scss';

interface InlineMessageProps {
  title?: string;
  message?: string | React.ReactNode;
  icon?: 'info-circle' | 'warning' | 'tick-circle' | string;
  type: 'success' | 'error' | 'warning' | 'info' | 'secondary';
  size?: 'sm' | 'lg';
  classes?: string;
  rightButtons?: ButtonProps[];
}

export const XB_InlineMessage = ({
  title,
  message,
  icon,
  type,
  classes,
  size = 'sm',
  rightButtons = [],
}: InlineMessageProps) => {
  const classList = [
    'inline-message',
    `inline-message--${size}`,
    `${title ? '' : 'items-center'}`,
  ];
  classes && classList.push(classes);

  const showDescriptionContentFunc = () => {
    if (typeof message === 'object') return message;
    return <p className="message">{message}</p>;
  };

  const rightSideButtonFunc = () => {
    return (
      <div className="mt-2 flex justify-end items-center">
        {rightButtons.map((item, index) => (
          <XB_Button {...item} key={index} />
        ))}
      </div>
    );
  };
  return (
    <div className={`${classList.join(' ')}`} data-type={type}>
      <div className="inline-message__icon">
        {icon && <Icon icon={icon} width={24} height={24} className="icon" />}
      </div>
      <div className="inline-message__content w-full">
        {title && <h4 className="title">{title}</h4>}
        {showDescriptionContentFunc()}
        {rightButtons.length > 0 && rightSideButtonFunc()}
      </div>
    </div>
  );
};
