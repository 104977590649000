import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestCasaSummaryDetails } from '../../models/request-account-services.model';

export const casaSummaryDetails = createAsyncThunk(
  'api/deposit-service/casa-summary',
  async (casaSummaryData: RequestCasaSummaryDetails, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.CASA_SUMMARY_DETAIL,
        url: '',
        obj: casaSummaryData,
      });
      if (response.data.status === 'error') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
