import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, Service } from '@src/core-services';
import { handleError } from '@src/core-utils';

import { ReqIFetchMerchant } from '../../models/merchant-manegement.model';

export const apifetchMerchantDetails = createAsyncThunk(
  '/api/merchant-management-service/merchant/fetch',
  async (reqFetchMerchant: ReqIFetchMerchant, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.merchantService.GET_MERCHANT_DETAILS,
        url: '',
        obj: reqFetchMerchant,
      });
      if (
        response?.data === null ||
        response?.data?.status === 'error' ||
        response?.data?.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.getErrorMessage());
    }
  }
);
