import { XOR_hex } from './basic.util';

export const getCardType = (cardNumber: string): string => {
  const alphaChar = cardNumber[0];
  switch (alphaChar) {
    case '4':
      return 'visa';
    case '5':
      return 'mastercard';
    case '6':
      return 'rupay';
    default:
      return 'Invalid Card';
  }
};

export const renderCardTypeIcon = (cardNumber: string) => {
  const cardType = getCardType(cardNumber);
  switch (cardType) {
    case 'mastercard':
      return 'mastercard';
    case 'visa':
      return 'visacard';
    case 'rupay':
      return 'rupaycard';
    default:
      return 'debitcard';
  }
};

export const generatePinBlock = (pin: string, cardNumber: string) => {
  const block1 =
    '0' + pin.length.toString() + pin + 'F'.repeat(14 - pin.length);
  const block2 = '0000' + cardNumber.slice(0, -1).slice(-12);
  return XOR_hex(block1, block2);
};

export const getNCardNumber = (cardNum: string, n = 4): string => {
  return cardNum?.slice(-n) ?? '';
};
