import { createSlice } from '@reduxjs/toolkit';

import { ResAppIcons } from '../index';
import { getAppIcons } from '../thunks/au-dashboard-icons.thunk';

const initialState: ResAppIcons = {
  data: undefined,
  error: undefined,
  status: undefined,
  loading: true,
};

const getAppIconsSlice = createSlice({
  name: 'getAppIcons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppIcons.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.error = payload.error;
      state.status = payload.status;
      state.loading = false;
    });
    builder.addCase(getAppIcons.pending, (state) => {
      state.data = undefined;
      state.error = undefined;
      state.status = undefined;
      state.loading = true;
    });
    builder.addCase(
      getAppIcons.rejected,
      (state, { payload }: { payload: any }) => {
        state.data = undefined;
        state.error = payload;
        state.status = undefined;
        state.loading = false;
      }
    );
  },
});

export default getAppIconsSlice.reducer;
