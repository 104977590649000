import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, Service } from '@src/core-services';
import { handleError } from '@src/core-utils';

import { IfscSearchReq } from '../models/ifsc-search.model';

export const ifscSearch = createAsyncThunk(
  'ifscSearch',
  async (IfscSearch: IfscSearchReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.merchantService.GET_BANK_DETAIL_BY_IFSC,
        obj: IfscSearch,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
