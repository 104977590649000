import { useState } from 'react';

import { t } from 'i18next';

import {
  AU_SideDrawer,
  HeadingBar,
  XB_SectionInfo,
} from '@core-components/atoms';
import { screenNumber } from '@core-constants';
import ShowAllBanks from '@modules/beneficiary/screens/addbank-sidebar.screen';
import VerifyBank from '@modules/beneficiary/screens/verify-bank-details';

import './AU_AddBankDrawer.style.scss';

interface AddBankDrawerProps {
  isOpen: boolean;
  onCloseDrawer: (e) => void;
  onAccountDataSend: (...args) => void;
  onMobileDataSend: (...args) => void;
  onWithIfscDataSend: (...args) => void;
  arrLength?: number;
  screenChange?: () => void;
}

export const AU_AddBankDrawer: React.FC<AddBankDrawerProps> = ({
  isOpen,
  onCloseDrawer,
  onAccountDataSend,
  onMobileDataSend,
  onWithIfscDataSend,
  arrLength,
}: AddBankDrawerProps) => {
  const [screen, setScreen] = useState(screenNumber.FIRST_SCREEN);
  const [state, setState] = useState<any>({});
  const [searchVal, setSearchVal] = useState<string>('');

  const handleChooseBank = (data, search, setSearch) => {
    setState({
      ...state,
      selectedBank: data,
    });
    setScreen(screenNumber.SECOND_SCREEN);
    if (search) {
      setSearch('');
    }
  };

  const onVerifyBank = () => {
    setScreen(screenNumber.FIRST_SCREEN);
  };

  const handleAccountBankDetails = (accountdata) => {
    onAccountDataSend(accountdata, setScreen);
  };

  const handleMobileBankDetails = (mobileData, bankData) => {
    onMobileDataSend(mobileData, bankData, setScreen);
  };

  const handleDatawithIfsc = (ifscData) => {
    onWithIfscDataSend(ifscData, setScreen);
  };

  const renderDrawerHeader = () => {
    if (
      screen === screenNumber.SECOND_SCREEN ||
      screen === screenNumber.THIRD_SCREEN
    ) {
      return (
        <HeadingBar
          onClose={() => {
            onCloseDrawer(false);
            setScreen(screenNumber.FIRST_SCREEN);
          }}
        >
          <XB_SectionInfo
            title={t('addBankDetail')}
            description={t('addBankDescription')}
          />
        </HeadingBar>
      );
    } else {
      return (
        <HeadingBar
          onClose={() => {
            onCloseDrawer(false);
            setScreen(screenNumber.FIRST_SCREEN);
            setSearchVal('');
          }}
        >
          <XB_SectionInfo
            title={t('selectBank')}
            description={t('selectBankNamePayeeAccount')}
          />
        </HeadingBar>
      );
    }
  };
  const renderDrawerBody = () => {
    if (
      screen === screenNumber.SECOND_SCREEN ||
      screen === screenNumber.THIRD_SCREEN
    ) {
      return (
        <VerifyBank
          bankData={{
            BankNameAPI: state?.selectedBank?.BankNameAPI,
            BankCategory: state?.selectedBank?.BankCategory,
            IFSC: state?.selectedBank?.IFSC,
            BankDisplayName: state?.selectedBank?.BankDisplayName,
            showIfsc: state?.selectedBank?.BankCategory?.toString(),
          }}
          arrlen={arrLength}
          onClick={onVerifyBank}
          onSendAccountBankDetails={handleAccountBankDetails}
          onSendMobileBankDetails={handleMobileBankDetails}
          onSendAccountDetailWithIfsc={handleDatawithIfsc}
        />
      );
    } else {
      return (
        <ShowAllBanks
          onclick={handleChooseBank}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
        />
      );
    }
  };

  return (
    <div>
      <AU_SideDrawer open={isOpen} header={renderDrawerHeader()}>
        {renderDrawerBody()}
      </AU_SideDrawer>
    </div>
  );
};
