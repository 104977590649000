import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AU_Loader, XB_Button, XB_Input } from '@core-components/atoms';
import { XB_CountryDropdown } from '@core-components/molecules';
import { AU_RefErrorModal, AU_ScreenLayout } from '@core-components/templates';
import { inputLength, PERMISSIONS, RegEx } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  crmLeadGen,
  CustomerAccountsResponseState,
  leadGenMaster,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import {
  checkPermission,
  isEmpty,
  maskStringInBetween,
  validateEmail,
  validateMobileNumber,
} from '@core-utils';
import {
  customerType,
  leadSource,
} from '@modules/loan/constants/loan-details.constants';
import { apply_now_urls } from '@src/core-navigations';
import { ResProfileData } from '@src/core-stores/models/profile-details.model';

import { minMaxValLength } from '../../../beneficiary/constant/constant';
import { productId } from '../../constants/apply-now.constant';

const BusinessBanking: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auAlert = useAlertBox();
  const [countryCode, setCountryCode] = useState('+91');
  const [name, setName] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [emailId, setEmailId] = useState<string>('');
  const [errorMsgs, setErrorMsgs] = useState<string>('');
  const [msgType, setMsgType] = useState<
    'info' | 'success' | 'error' | 'loading' | 'verified' | ''
  >('');
  const [disableButtonEmail, setDisableButtonEmail] = useState<boolean>(false);
  const [disableButtonMobNumber, setDisableButtonMobNumber] =
    useState<boolean>(false);
  const [mobErrorMsg, setMobErrorMsg] = useState<string>('');
  const [mobMsgType, setMobMsgType] = useState<
    'info' | 'success' | 'error' | 'loading' | 'verified' | ''
  >('');
  const [leaddata, setLeadData] = useState<any>();
  const [originalMobileNumber, setOriginalMobileNumber] = useState<string>('');
  const [mobileNumberForAPI, setMobileNumberForAPI] = useState<string>('');

  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<{
    buttonLoading: boolean;
    pageLoading: boolean;
  }>({
    buttonLoading: false,
    pageLoading: false,
  });
  const profileData = useAppSelector((state: RootState) => state?.profile) as
    | ResProfileData
    | undefined;

  const { isNTB } = useAppSelector((state: RootState) => state?.auDashboard);

  const permissions = useAppSelector(
    (state) => state?.profile?.data?.permissions
  );

  const customerAccounts = useAppSelector(
    (state: RootState) => state?.customerAccounts
  ) as CustomerAccountsResponseState | undefined;
  const [showRefErrModal, setShowRefErrModal] = useState<{
    show?: boolean;
    refNo?: string;
  }>({
    show: false,
    refNo: '',
  });

  const data =
    customerAccounts?.data?.CustomerAccount?.[0]?.CustomerBasicInquiry;

  const useBackspaceClear = () => {
    const [backspacePressed, setBackspacePressed] = useState<boolean>(false);

    const handleKeyDown = (e, setState) => {
      if (e?.key === 'Backspace' && !backspacePressed) {
        setState('');
        setBackspacePressed(true);
      }
    };
    return { handleKeyDown };
  };

  const leadGenMasterList = async () => {
    setLoading({
      ...loading,
      pageLoading: true,
    });
    try {
      const masterData = await dispatch(leadGenMaster({ refreshCache: true }));
      if (masterData?.payload?.successfulResponse) {
        const loanType = masterData?.payload?.data?.find(
          (item) => item?.productId === productId?.businessBanking
        );
        setLeadData(loanType);
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
        auAlert.error(masterData?.payload as string);
      }
    } catch (err: any) {
      auAlert.error(err?.displayMessage);
      setButtonDisable(false);
    } finally {
      setLoading({
        ...loading,
        pageLoading: false,
      });
    }
  };

  useEffect(() => {
    if (checkPermission(permissions, PERMISSIONS?.RBAC_CSR?.CRM_LEAD_GEN)) {
      leadGenMasterList();
    }
  }, []);

  const handleCrmLeadApiCall = async () => {
    setLoading({
      ...loading,
      buttonLoading: true,
    });

    const checkMobileUpdate = () => {
      return originalMobileNumber === mobileNumberForAPI ? data?.PAN : '';
    };

    const reqObj = {
      LayoutId: leaddata?.productLayoutId,
      name,
      DateOfBirth: '',
      PanNumber: checkMobileUpdate(),
      dob: '',
      ProductCategoryID: leaddata?.productCategoryId,
      ProductID: leaddata?.productId,
      MobilePhone: mobileNumberForAPI ?? '',
      StampCustName: name,
      country: '',
      cif: '',
      TypeofCustomerSA: customerType,
      CurrentState: '',
      ShortName: data?.CustomerName?.ShortName,
      CurrentCity: '',
      TerritoryID: '',
      CurrentAddressLine1: '',
      CurrentAddressLine2: '',
      CurrentAddressLine3: '',
      Email: emailId,
      LeadSource: leadSource,
    };
    try {
      const crmLeadData = await dispatch(crmLeadGen(reqObj));
      if (crmLeadData?.payload?.successfulResponse) {
        navigate(apply_now_urls?.business_banking_status, {
          state: {
            name: name?.trim(),
            email: emailId,
            phoneNo: mobileNumber,
            account: '',
            accountName: '',
            refNo: crmLeadData?.payload?.data?.LeadID,
          },
        });
      } else {
        setShowRefErrModal({
          ...showRefErrModal,
          show: true,
          refNo: crmLeadData?.payload?.requestId,
        });
      }
    } catch (error: any) {
      setShowRefErrModal({
        ...showRefErrModal,
        show: true,
        refNo: error?.requestId,
      });
    } finally {
      setLoading({
        ...loading,
        buttonLoading: false,
      });
    }
  };

  useEffect(() => {
    if (isNTB) {
      if (!isEmpty(profileData?.data?.name)) {
        setName(profileData?.data?.name ?? '');
      }
      if (!isEmpty(profileData?.data?.mobileNumber)) {
        setMobileNumber(
          maskStringInBetween(
            profileData?.data?.mobileNumber as string,
            2,
            5,
            'x'
          )
        );
        setOriginalMobileNumber(profileData?.data?.mobileNumber ?? '');
        setMobileNumberForAPI(profileData?.data?.mobileNumber ?? '');
      }
      if (!isEmpty(profileData?.data?.emailAddress)) {
        setEmailId(
          maskStringInBetween(
            profileData?.data?.emailAddress as string,
            2,
            (profileData?.data?.emailAddress?.indexOf('@') as number) - 1,
            'x'
          )
        );
      }
    } else {
      if (!isEmpty(data?.CustomerFullName)) {
        setName(data?.CustomerFullName as string);
      }
      if (!isEmpty(data?.MobileNumber)) {
        setMobileNumber(
          maskStringInBetween(data?.MobileNumber as string, 2, 5, 'x')
        );
        setOriginalMobileNumber(data?.MobileNumber ?? '');
        setMobileNumberForAPI(data?.MobileNumber ?? '');
      }
      if (!isEmpty(data?.EmailAddress)) {
        setEmailId(
          maskStringInBetween(
            data?.EmailAddress as string,
            2,
            (data?.EmailAddress?.indexOf('@') as number) - 1,
            'x'
          )
        );
      }
    }
  }, [isNTB]);

  const useRenderLeftContainer = () => {
    const { handleKeyDown: handleKeyDownName } = useBackspaceClear();
    const { handleKeyDown: handleKeyDownMobNumber } = useBackspaceClear();
    const { handleKeyDown: handleKeyDownEmail } = useBackspaceClear();

    const handleEmail = (val) => {
      const { error } = validateEmail(val);
      if (error) {
        setErrorMsgs(t(`${error}`));
        setMsgType('error');
        setDisableButtonEmail(true);
      } else {
        setMsgType('');
        setErrorMsgs('');
        setDisableButtonEmail(false);
      }
      setEmailId(val);
    };

    const handleMobileNumber = (val) => {
      const { error } = validateMobileNumber(val);
      if (error) {
        setMobErrorMsg(t(`${error}`));
        setMobMsgType('error');
        setDisableButtonMobNumber(true);
      } else {
        setMobErrorMsg('');
        setMobMsgType('');
        setDisableButtonMobNumber(false);
      }
      setMobileNumber(val);
      setMobileNumberForAPI(val);
    };

    const handleInputChangeName = (val) => {
      setName(val);
    };
    const tapoutPayeeName = () => {
      setName(name?.trim());
    };
    const isButtonDisabled = () => {
      if (checkPermission(permissions, PERMISSIONS?.RBAC_CSR?.CRM_LEAD_GEN)) {
        let isEmptyOrNot = false;
        if (!mobileNumber || !emailId || !name) {
          isEmptyOrNot = true;
        }

        return (
          isEmptyOrNot ||
          disableButtonEmail ||
          disableButtonMobNumber ||
          buttonDisable
        );
      } else {
        return true;
      }
    };

    return (
      <div className="business-banking-card">
        <div className="business-bank-card-heading m-text-lg-bold text-gray-900">
          {t('fillUpTheBelowDetails')}
        </div>
        <XB_Input
          id="NAME"
          label={t('name')}
          classes="max-w-[20rem] w-full mt-6"
          onInputChange={(val) => {
            handleInputChangeName(val?.replace('  ', ''));
          }}
          value={name}
          regex={RegEx?.onlyAlpha}
          onKeyDown={(e) => handleKeyDownName(e, name)}
          onBlur={() => tapoutPayeeName()}
        />
        <XB_Input
          id="MBLE-NMBR"
          label={t('mobileNumber')}
          classes="max-w-[20rem] w-full mt-6"
          value={mobileNumber}
          onKeyDown={(e) => handleKeyDownMobNumber(e, setMobileNumber)}
          prefixChild={
            <div className="flex">
              <XB_CountryDropdown
                id="MRCT-CNTY-CODE"
                onChange={(val) => {
                  setCountryCode(val);
                }}
              />
              <span className="m-text-lg-regular code">{countryCode}</span>
            </div>
          }
          regex={RegEx?.onlyDigit}
          autoComplete="off"
          maxLength={
            countryCode === '+91'
              ? inputLength?.MOBILE_NUMBER_IND
              : inputLength?.MOBILE_NUMBER_OUTSIDE
          }
          onInputChange={(val) => handleMobileNumber(val)}
          msgType={mobMsgType}
          infoMsgText={mobErrorMsg}
        />
        <XB_Input
          type="text"
          id="ADD-NEW-EMAL"
          regex={RegEx?.email_valid_input}
          label={t('email')}
          classes="add-new-email max-w-[20rem] mt-4"
          placeholder={t('enterNewEmailId')}
          value={emailId}
          onKeyDown={(e) => handleKeyDownEmail(e, setEmailId)}
          maxLength={minMaxValLength?.emailMaxLength}
          msgType={msgType}
          infoMsgText={errorMsgs}
          onInputChange={(val) => handleEmail(val)}
        />
        <XB_Button
          btnType="primary"
          dataTestId="PRCD"
          classes="mt-8 max-w-[12rem] w-full"
          disabled={isButtonDisabled()}
          onClick={() => {
            if (
              checkPermission(permissions, PERMISSIONS?.RBAC_CSR?.CRM_LEAD_GEN)
            ) {
              handleCrmLeadApiCall();
            }
          }}
          showLoader={loading?.buttonLoading}
        >
          {t('proceed')}
        </XB_Button>
      </div>
    );
  };
  return (
    <>
      {loading?.pageLoading && <AU_Loader />}
      {showRefErrModal?.show && (
        <AU_RefErrorModal
          isOpen={showRefErrModal?.show}
          setOpen={(val) =>
            setShowRefErrModal({ ...showRefErrModal, show: val })
          }
          refNo={showRefErrModal?.refNo}
          rightBtnProps={{
            dataTestId: 'TRY-AGIN',
            children: 'tryAgain',
            onClick: () => {
              setShowRefErrModal({ ...showRefErrModal, show: false });
              handleCrmLeadApiCall();
            },
          }}
          leftBtnProps={{
            dataTestId: 'CNCL',
            children: 'cancel',
            onClick: () => {
              setShowRefErrModal({ ...showRefErrModal, show: false });
            },
          }}
        />
      )}
      <AU_ScreenLayout
        isShowBackBtn={true}
        leftContainer={useRenderLeftContainer()}
        pageHeading={t('applyForBusinessBanking')}
        leftContainerClasses="bg-white p-4"
      />
    </>
  );
};

export default BusinessBanking;
