import { createSlice } from '@reduxjs/toolkit';

import { validateResumeJourney } from '../thunks/validate-resume-journey.thunk';

const initialState = {
  status: undefined,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};

const validateResumeJourneySlice = createSlice({
  name: 'validate-resume-journey',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validateResumeJourney.pending, (state) => {
      state.status = undefined;
      state.error = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
    });
    builder.addCase(
      validateResumeJourney.fulfilled,
      (state, { payload }: { payload: any }) => {
        ({
          status: state.status,
          error: state.error,
          successfulResponse: state.successfulResponse,
          data: state.data,
        } = payload);
      }
    );
    builder.addCase(
      validateResumeJourney.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
      }
    );
  },
});
export default validateResumeJourneySlice.reducer;
