import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EMIDetail } from '../../models/credit-card.model';

const initialState: { emiList: EMIDetail[]; selectedEMI: EMIDetail | null } = {
  emiList: [],
  selectedEMI: null,
};

const creditCardSlice = createSlice({
  name: 'creditCardSlice',
  initialState,
  reducers: {
    setEmiList: (state, payload: PayloadAction<EMIDetail[]>) => {
      state.emiList = payload.payload;
    },
    setSelectedEmi: (state, payload: PayloadAction<EMIDetail>) => {
      state.selectedEMI = payload.payload;
    },
  },
});

export const { setEmiList, setSelectedEmi } = creditCardSlice.actions;
export default creditCardSlice.reducer;
