import { EndPoints, Service } from '@core-services';
import { getStoredLanguage } from '@core-utils';

const { REACT_APP_FIREBASE_IDENTIFIER } = process.env;

export const getPincodeDetails = async (data) => {
  const reqObj = {
    baseurl: EndPoints.masterService.getPincodeDetails,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj);
  return response?.data;
};

export const getPremisesTypes = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.premisesTypes,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getSalutationTypes = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.salutationsTypes,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getEducationTypes = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.educationTypes,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getOccupationTypes = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.occupationTypes,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const checkJourney = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.checkJourney,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};
export const getMaritalTypes = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.maritalTypes,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getCityNames = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.cityNames,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const businessFilterDtl = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.masterService.businessFilter,
    method: 'POST',
    url: '',
    obj,
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getNegativeWords = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.negativeWords,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getDisposalEmails = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.disposalDomain,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getDocumentTypes = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.documentTypes,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const ifscDetails = async (data) => {
  const reqObj = {
    baseurl: EndPoints.masterService.ifscCodes,
    method: 'POST',
    url: '',
    obj: data,
  };
  const headers = {
    appIdentifier: `WEB_${REACT_APP_FIREBASE_IDENTIFIER}`,
  };
  const response = await Service.send(reqObj, headers);
  return response?.data;
};

export const fetchSettlementCycle = async () => {
  const reqObj = {
    baseurl: EndPoints.masterService.settlementCycle,
    method: 'POST',
    url: '',
    obj: {
      refreshCache: true,
    },
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const getConsentList = async () => {
  const lang = getStoredLanguage() === 'undefined' ? 'en' : getStoredLanguage();

  const reqObj = {
    baseurl: EndPoints.strapiService.filterLang(`${lang}`).getConsentList,
    method: 'GET',
    url: '',
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};
export const getConsentByVersion = async (versionId: string) => {
  const lang = getStoredLanguage();
  const reqObj = {
    baseurl: EndPoints.strapiService.filterLang(`${lang}`, `${versionId}`)
      .getConsentByVersion,
    method: 'GET',
    url: '',
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};
export const getSavedUserConsents = async (data) => {
  const reqObj = {
    baseurl: EndPoints.masterService.savedUserConsents,
    method: 'POST',
    url: '',
    obj: data,
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};
export const saveConsent = async (data) => {
  const reqObj = {
    baseurl: EndPoints.masterService.captureConsentsList,
    method: 'POST',
    url: '',
    obj: data,
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};

export const fetchEmployeeDetail = async (data) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.fetchEmployeeDetail,
    method: 'POST',
    url: '',
    obj: data,
  };
  const resp = await Service.send(reqObj);
  return resp?.data;
};
