export const breadcrumb = [
  { url: '/generate-vpa', name: 'Home', id: 1, parents: [] },
  {
    url: '/send-for-printing',
    name: 'Send for printing',
    parents: [1],
    id: 2,
  },
  {
    url: '/printing-status',
    name: 'Printing status',
    id: 3,
    parents: [2, 1],
  },
  { url: '/vertical-allocation', name: 'Verticals', id: 4, parents: [2, 1] },
  { url: '/', name: 'Home', id: 5, parents: [] },
  {
    url: '/inventory',
    name: 'inventory',
    parents: [5],
    id: 6,
  },
  {
    url: '/allocate',
    name: 'Allocate',
    parents: [1, 4],
    id: 7,
  },
  {
    url: '/allocation-history',
    name: 'Allocation History',
    parents: [1, 4],
    id: 8,
  },
  {
    url: '/allocation-report',
    name: 'Atish Market',
    parents: [1, 4],
    id: 9,
  },
  {
    url: '/customer-request',
    name: 'Customer Request',
    parents: [1],
    id: 10,
  },
];
