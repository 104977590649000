import { Fragment } from 'react';

import { getConcatenatedString } from '@core-utils';

import './AU_CardItemDetail.style.scss';

interface CardItemDetailsProps {
  content: React.ReactElement[];
  mainClass?: string;
  containerClass?: string;
  onClick?: () => void;
}

export const AU_CardItemDetails = ({
  content,
  mainClass = '',
  containerClass = '',
  onClick,
}: CardItemDetailsProps) => {
  return (
    <div
      className={`au-card-item-detail rounded-xl bg-white mb-4 ${mainClass}`}
      onClick={() => {
        onClick?.();
      }}
    >
      <div className={`au-card-details items-center ${containerClass}`}>
        {content.map((item: React.ReactElement, index: number) => {
          return (
            <Fragment key={getConcatenatedString([index, item.key])}>
              {item}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
