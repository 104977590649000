import { v4 } from 'uuid';

import { appConstants } from '@core-constants';
import { AlertDialogConfig, useAppDispatch } from '@core-stores';
import {
  addMessage,
  clearMessages,
} from '@src/core-stores/slices/au-alert-container.slice';

interface AlertType {
  info: (message: string, config?: AlertDialogConfig) => void;
  error: (message: string, config?: AlertDialogConfig) => void;
  success: (message: string, config?: AlertDialogConfig) => void;
  warning: (message: string, config?: AlertDialogConfig) => void;
}

export function useAlertBox(): AlertType {
  const dispatch = useAppDispatch();

  const commonConfig: AlertDialogConfig = {
    position: appConstants.AU_ALERT_POSITION.top_center,
    autoCloseInterval: 5000,
    autoclose: true,
  };

  const info = (messege: string, config?: AlertDialogConfig) => {
    const uuid = v4();

    const alertConfig: AlertDialogConfig = {
      messageId: uuid,
      description: messege,
      type: 'info',
      iconName: 'info-circle',
      ...commonConfig,
    };
    if (config) {
      Object.assign(alertConfig, config);
    }
    dispatch(clearMessages());
    dispatch(addMessage(alertConfig));
  };
  const error = (messege: string, config?: AlertDialogConfig) => {
    const uuid = v4();
    const alertConfig: AlertDialogConfig = {
      messageId: uuid,
      description: messege,
      type: 'error',
      iconName: 'warning',
      ...commonConfig,
    };
    if (config) {
      Object.assign(alertConfig, config);
    }
    dispatch(clearMessages());
    dispatch(addMessage(alertConfig));
  };
  const warning = (messege: string, config?: AlertDialogConfig) => {
    const uuid = v4();
    const alertConfig: AlertDialogConfig = {
      messageId: uuid,
      description: messege,
      type: 'warning',
      iconName: 'warning',
      ...commonConfig,
    };
    if (config) {
      Object.assign(alertConfig, config);
    }
    dispatch(clearMessages());
    dispatch(addMessage(alertConfig));
  };
  const success = (messege: string, config?: AlertDialogConfig) => {
    const uuid = v4();
    const alertConfig: AlertDialogConfig = {
      messageId: uuid,
      description: messege,
      type: 'success',
      iconName: 'tick-circle',
      ...commonConfig,
    };
    if (config) {
      Object.assign(alertConfig, config);
    }
    dispatch(clearMessages());
    dispatch(addMessage(alertConfig));
  };
  const aualert: AlertType = {
    info,
    error,
    success,
    warning,
  };

  return aualert;
}
