import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CountryCodeRequest } from '../models/country-code.model';

export const countryCodeListCall = createAsyncThunk(
  '/api/master-service/masters/fetch/country-code-list',
  async (countryCodeRequestDetail: CountryCodeRequest, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.masterService.COUNTRY_CODE_LIST,
        url: '',
        obj: countryCodeRequestDetail,
      });

      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
