import { useTranslation } from 'react-i18next';

import './XB_AuthStep.style.scss';

interface AuthStepProps {
  title: string;
  description: string;
  headerContent?: React.ReactNode;
  mainContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  showPolicy?: boolean;
}

export const XB_AuthStep = ({
  title,
  description,
  headerContent,
  mainContent,
  footerContent,
  showPolicy = false,
}: AuthStepProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      {headerContent}
      <div className="auth-step flex flex-col">
        <div className="mb-8 lg:px-3 md:px-0">
          <h2 className="auth-step__title m-display-xl-medium">{t(title)}</h2>
          <p className="auth-step__sub-title m-text-lg-medium">
            {t(description)}
          </p>
        </div>
        <div className="grow mb-8 lg:px-3 md:px-0 py-1.5">{mainContent}</div>

        <div className="lg:px-3 md:px-0">
          {showPolicy && (
            <p className="mb-6 text-sm font-medium text-darkgray">
              {t('byCreatingThisAccountYouAgreeToOur')}{' '}
              <a
                href={t('ppRedirection')}
                target="blank"
                className="text-orange-600"
              >
                {t('privacyPolicy')}
              </a>{' '}
              {t('and')}{' '}
              <a
                href={t('tncRedirection')}
                target="blank"
                className="text-orange-600"
              >
                {t('termsOfUse')}
              </a>
            </p>
          )}
          {footerContent}
        </div>
      </div>
    </div>
  );
};
