import { AUEndPoints, Service } from '@src/core-services';
import { buildUrl } from '@src/core-utils';

import {
  ICollectionListExtractionReq,
  IConfirmDocumentReq,
  IinvalidTransactionReq,
  IReqBlockReleaseTxn,
  IReqSettlementParameter,
  IReqSettlementReport,
  ISettlementPayouts,
} from '../models/settlement-management.model';

export const settlementDocumentDownload = async (header) => {
  try {
    const reqObj = {
      baseurl: AUEndPoints.settlementService.DownloadDocument,
      method: 'POST',
      url: '',
    };

    const response = await Service.send(reqObj, header);

    return response.data;
  } catch (error: any) {
    return error;
  }
};

export const ConfirmDocumentApi = async (
  reqConfirmDocument: IConfirmDocumentReq
) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.CONFIRM_DOCUMENT,
    method: 'POST',
    url: '',
    obj: reqConfirmDocument,
  };

  const response = await Service.send(reqObj);
  return response.data;
};

export const InvalidTransactionListFile = async (
  reqInvalidTransaction: IinvalidTransactionReq
) => {
  const queryParams = {
    page: 0,
    size: 10,
    sort: '',
  };
  const reqObj = {
    baseurl: AUEndPoints.settlementService.InvalidTransactionList(queryParams),
    method: 'POST',
    url: '',
    obj: reqInvalidTransaction,
  };

  const response = await Service.send(reqObj);

  return response.data;
};

export const uploadStatusFile = async (
  file: any,
  metadata: {
    batchId: string;
    fileName: string;
  },
  fileHeader: {
    fileType: string;
  }
) => {
  const queryParams = {
    fileName: file.name,
    batchId: metadata.batchId,
  };
  const url = buildUrl(
    AUEndPoints.settlementService.UploadDocument,
    queryParams
  );
  const data = new FormData();
  data.append('document', file);
  const reqObj = {
    baseurl: url,
    method: 'POST',
    url: '',
    obj: data,
  };
  const headers = {
    ...fileHeader,
    'Content-Type': 'multipart/form-data',
  };

  const response = await Service.send(reqObj, headers);
  return response.data;
};

export const CollectionListExtraction = async (
  reqCollectionListExtraction: ICollectionListExtractionReq
) => {
  const queryParams = {
    page: 0,
    size: 1000,
    sort: 'transactionTime,desc',
  };
  const reqObj = {
    baseurl:
      AUEndPoints.settlementService.collectionListExtraction(queryParams),
    method: 'POST',
    url: '',
    obj: reqCollectionListExtraction,
  };

  const response = await Service.send(reqObj);

  return response.data;
};

export const getSettleMentParameter = async (req: IReqSettlementParameter) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.SETTLEMENT_PARAMETER,
    method: 'POST',
    url: '',
    obj: req,
  };

  const response = await Service.send(reqObj);
  return response.data;
};

export const blockReleaseTxns = async (req: IReqBlockReleaseTxn) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.BLOCK_RELEASE_TXNS,
    method: 'POST',
    url: '',
    obj: req,
  };

  const response = await Service.send(reqObj);
  return response.data;
};

export const settlementReportDownload = async (req: IReqSettlementReport) => {
  const reqObj = {
    baseurl: AUEndPoints.entitleRequest.SETTLEMENT_REPORT,
    method: 'POST',
    url: '',
    obj: req,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const getSettleMentPayouts = async (
  paymentMode: string,
  req: ISettlementPayouts
) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.SETTLEMENT_PAYOUTS(paymentMode),
    method: 'POST',
    url: '',
    obj: req,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const exportSettlementReport = async () => {
  const reqObj = {
    baseurl: AUEndPoints.entitleRequest.EXPORT_REPORT,
    method: 'POST',
    url: '',
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const s3DownlaodReportFile = async (header) => {
  try {
    const reqObj = {
      baseurl: AUEndPoints.settlementService.COLLECTION_DOWNLOAD_FILE,
      method: 'POST',
      url: '',
    };

    const response = await Service.send(reqObj, header);

    return response.data;
  } catch (error: any) {
    return error;
  }
};
