import { Storage } from '@core-utils';

export const CIF_RD_NRI = '22157603';
export const CIF_FD = '22157603';
export const CIF_RD = '22157603';
export const CIF_SOT = '22157603';

export const CIF_DEPOSIT_SUMMARY = '22157603';
export const CIF = () => {
  return Storage.getItemAsync('cif');
};

export const OTP = () => {
  return Storage.getItemAsync('enableOtp');
};

export const depositFDLabel = {
  amountMinMax: [
    {
      key: 'deposits.fd.amount-min-max.type.minimum-amount',
      value: '1000',
    },
    {
      key: 'deposits.fd.amount-min-max.type.maximum-amount',
      value: '19999999',
    },
  ],
  amountSuggestions: [
    {
      key: 'deposits.fd.amount-suggestions.type.ten-thousand',
      value: '10000',
    },
    {
      key: 'deposits.fd.amount-suggestions.type.five-thousand',
      value: '5000',
    },
    {
      key: 'deposits.fd.amount-suggestions.type.one-thousand',
      value: '1000',
    },
  ],
  payoutTypes: [
    {
      key: 'deposits.fd.payout.type.on-maturity',
      value: 'On-Maturity',
    },
    {
      key: 'deposits.fd.payout.type.monthly',
      value: 'Monthly',
    },
    {
      key: 'deposits.fd.payout.type.quarterly',
      value: 'Quarterly',
    },
  ],
  maturityActions: [
    {
      key: 'deposits.fd.maturity-actions.type.renew',
      value: 'Renew',
    },
    {
      key: 'deposits.fd.maturity-actions.type.close-maturity',
      value: 'Close Maturity',
    },
  ],
  tenureAction: {
    onMaturityAction: [
      {
        key: 'deposits.fd.tenure-action.on-maturity-action.type.min-tenure',
        value: '7',
        valueType: 'day',
      },
      {
        key: 'deposits.fd.tenure-action.on-maturity-action.type.max-tenure',
        value: '10',
        valueType: 'year',
      },
    ],
    onMonthlyAction: [
      {
        key: 'deposits.fd.tenure-action.on-monthly-action.type.min-tenure',
        value: '3',
        valueType: 'months',
      },
      {
        key: 'deposits.fd.tenure-action.on-monthly-action.type.min-tenure',
        value: '1',
        valueType: 'days',
      },
      {
        key: 'deposits.fd.tenure-action.on-monthly-action.type.max-tenure',
        value: '10',
        valueType: 'year',
      },
    ],
    onQuarterlyAction: [
      {
        key: 'deposits.fd.tenure-action.on-quarterly-action.type.min-tenure',
        value: '3',
        valueType: 'months',
      },
      {
        key: 'deposits.fd.tenure-action.on-monthly-action.type.min-tenure',
        value: '1',
        valueType: 'days',
      },
      {
        key: 'deposits.fd.tenure-action.on-quarterly-action.type.max-tenure',
        value: '10',
        valueType: 'year',
      },
    ],
  },
};

export const depositRDLabel = {
  amountMinMax: [
    {
      key: 'deposits.rd.amount-min-max.type.minimum-amount',
      value: '100',
    },
    {
      key: 'deposits.rd.amount-min-max.type.maximum-amount',
      value: '10000000',
    },
  ],
  amountSuggestions: [
    {
      key: 'deposits.rd.amount-suggestions.type.ten-thousand',
      value: '1000',
    },
    {
      key: 'deposits.rd.amount-suggestions.type.five-thousand',
      value: '500',
    },
    {
      key: 'deposits.rd.amount-suggestions.type.one-thousand',
      value: '100',
    },
  ],
  dateOfDeposit: [
    {
      key: 'deposits.rd.date-of-deposit.type.five',
      value: '5th of every month',
    },
    {
      key: 'deposits.rd.date-of-deposit.type.ten',
      value: '10th of every month',
    },
    {
      key: 'deposits.rd.date-of-deposit.type.fifteen',
      value: '15th of every month',
    },
    {
      key: 'deposits.rd.date-of-deposit.type.twenty',
      value: '20th of every month',
    },
    {
      key: 'deposits.rd.date-of-deposit.type.twenty-five',
      value: '25th of every month',
    },
  ],
  tenureAction: [
    {
      key: 'deposits.rd.tenure-action.type.min-tenure',
      value: '3',
      valueType: 'months',
    },
    {
      key: 'deposits.rd.tenure-action.type.max-tenure',
      value: '10',
      valueType: 'year',
    },
  ],
};

export const depositSOTLabel = {
  amountMinMax: [
    {
      key: 'deposits.sot.amount-min-max.type.minimum-amount',
      value: '1000',
    },
    {
      key: 'deposits.sot.amount-min-max.type.maximum-amount',
      value: '150000',
    },
  ],
  amountSuggestions: [
    {
      key: 'deposits.sot.amount-suggestions.type.ten-thousand',
      value: '10000',
    },
    {
      key: 'deposits.sot.amount-suggestions.type.five-thousand',
      value: '5000',
    },
    {
      key: 'deposits.sot.amount-suggestions.type.one-thousand',
      value: '1000',
    },
  ],
  tenureAction: [
    {
      key: 'deposits.sot.tenure-action.type.min-tenure',
      value: '5',
      valueType: 'year',
    },
    {
      key: 'deposits.sot.tenure-action.type.max-tenure',
      value: '5',
      valueType: 'year',
    },
  ],
};
