import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { XB_Button } from '@core-components/atoms';
import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import AuLogo from '@src/assets/images/AuLogo.png';

interface RowData {
  key: string;
  value: string;
}

interface DataSet {
  data: RowData[] | undefined;
}

interface Header {
  title: string;
}

interface PdfDataType extends DataSet, Header {
  greeting: string;
  greetingNotes?: string;
  tableTitle?: string;
  systmeGenMsg?: string;
}

const mTextMdregular = { fontSize: '0.37cm' };
const mTextLgBold = { fontSize: '5.23mm', color: '#222636' };
const mTextMdMedium = { fontSize: '3.70mm' };

const PdfHeader: React.FC<Header> = ({ title }: Header) => {
  const styles = StyleSheet.create({
    headerData: {
      color: '#ffffff',
      alignSelf: 'center',
      fontSize: 13.49,
      marginLeft: 11.99,
      fontWeight: 100,
    },
    containerHeader: {
      display: 'flex',
      backgroundColor: '#6d3078',
      width: '100%',
      flexDirection: 'row',
      height: 39.59,
      paddingLeft: 10,
      justifyContent: 'space-between',
    },
    headerImage: {
      position: 'relative',
      height: 23.03,
      width: 52.67,
      alignSelf: 'center',
      marginRight: 10,
    },
  });
  return (
    <View style={styles.containerHeader}>
      <Text style={styles.headerData}>{title}</Text>
      <Image style={styles.headerImage} src={AuLogo} />
    </View>
  );
};

const PdfTable: React.FC<DataSet> = ({ data }: DataSet) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      position: 'relative',
      marginTop: '6.34mm',
      marginLeft: '15mm',
      marginRight: '15mm',
      marginBottom: '15mm',
    },
    tableRow: {
      flexDirection: 'row',
      borderLeftColor: 'black',
      borderRightColor: 'black',
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderTopWidth: 1,
      borderTopColor: 'black',
      alignItems: 'center',
      fontStyle: 'bold',
    },
    leftText: {
      color: '#10111A',
      ...mTextMdMedium,
      width: '40%',
      textAlign: 'left',
      paddingLeft: 4,
      paddingTop: 4,
    },
    rightText: {
      color: '#10111A',
      ...mTextMdMedium,
      width: '40%',
      textAlign: 'left',
      paddingLeft: 4,
      borderLeftColor: 'black',
      borderLeftWidth: 1,
      paddingTop: 4,
    },
  });

  const getlastBorder = (len: number, index: number) => {
    if (index === len - 1) {
      return { borderBottomColor: 'black', borderBottomWidth: 1 };
    }
  };
  return (
    <View style={styles.container}>
      {data?.map((item, index) => {
        return (
          <View
            style={{ ...styles.tableRow, ...getlastBorder(data.length, index) }}
            key={item.key}
          >
            <Text style={styles.leftText}>{item.key}</Text>
            <Text style={styles.rightText}>{item.value}</Text>
          </View>
        );
      })}
    </View>
  );
};

export const PdfFile: React.FC<PdfDataType> = ({
  data,
  title,
  greeting,
  tableTitle,
  greetingNotes,
  systmeGenMsg,
}: PdfDataType) => {
  const styles = StyleSheet.create({
    body: {
      display: 'flex',
      width: '22.27cm',
    },
    content: {
      marginLeft: '0.42cm',
      marginRight: '0.42cm',
      marginTop: '0.42cm',
    },
    greeting: {
      ...mTextLgBold,
      marginBottom: '0.5cm',
    },
    greetingNote: {
      color: '#10111A',
      ...mTextMdregular,
      marginBottom: '6.34mm',
    },
    tableTitle: {
      ...mTextLgBold,
      marginBottom: '0.5cm',
      fontWeight: 'bold',
    },
    greetingSytemMsg: {
      color: '#10111A',
      ...mTextMdregular,
      textAlign: 'center',
    },
  });
  return (
    <Document>
      <Page style={styles.body} size="A4">
        <View>
          <PdfHeader title={title} />
          <View style={styles.content}>
            <Text style={styles.greeting}>{greeting}</Text>
            <Text style={styles.greetingNote}>{greetingNotes}</Text>
            <Text style={styles.tableTitle}>{tableTitle}</Text>
            <PdfTable data={data} />
            <Text style={styles.greetingSytemMsg}>{systmeGenMsg}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
interface AUStatementPdfDownloadProps {
  title?: string;
  greeting?: string;
  greetingNotes?: string;
  tableTitle?: string;
  fileName?: string;
  data: RowData[] | undefined;
  closeModal: () => void;
}

export const AU_StatementPdfDownload = ({
  title,
  greeting,
  greetingNotes,
  tableTitle,
  data,
  closeModal,
  fileName = `TxnRpt${moment().format('_DD_MM_YYYY_hh_mm')}`,
}: AUStatementPdfDownloadProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end gap-x-3">
      <div className="basis-2/4">
        <XB_Button
          dataTestId="CNCL"
          btnType="secondary-gray"
          color="none"
          onClick={() => {
            closeModal();
          }}
          classes="w-full"
        >
          {t('cancel')}
        </XB_Button>
      </div>
      <div className="basis-2/4">
        <PDFDownloadLink
          document={
            <PdfFile
              data={data}
              title={title ?? t('transactionReceipt')}
              greeting={greeting ?? t('dearCustomer')}
              greetingNotes={greetingNotes ?? t('transactionSuccessMsgForPdf')}
              tableTitle={tableTitle ?? t('transactionDetailsPdf')}
              systmeGenMsg={t('systemGeneratedMsg')}
            />
          }
          fileName={`${fileName}.pdf`}
          className="w-full"
        >
          <XB_Button
            classes="w-full"
            dataTestId="DNLD"
            iconPos="left"
            iconName="download"
          >
            {t('download')}
          </XB_Button>
        </PDFDownloadLink>
      </div>
    </div>
  );
};
