import { Icon } from '@core-components/atoms';

import { SubStep, XB_SubStep } from './XB_SubStep.component';

export interface Step {
  stepId: string;
  stepLabel: string;
  stepIcon: string;
  subStep: SubStep[];
}

interface StepProps {
  step: Step;
  onStepChanged?: (stepId: string, subStepId: string) => void;
  status: string;
  completedSteps: number;
  expanded: boolean;
  onToggle: (stepId: string) => void;
}

export function XB_Step({
  step,
  onStepChanged,
  status,
  completedSteps,
  expanded,
  onToggle,
}: StepProps) {
  return (
    <div
      className={`step step--${status} flex flex-col ${
        expanded ? 'm-active' : 'm-inactive'
      }`}
    >
      <div
        className={`step__title`}
        onClick={() => {
          onToggle(step.stepId);
        }}
      >
        <div className="label">
          <span className={`label__icon`}>
            <Icon icon={step.stepIcon} width={16} height={16} />
          </span>
          {
            <span className={`label__title sm:inline ml-1 sm:ml-4`}>
              {step.stepLabel}
            </span>
          }
        </div>
        <span className="step__status">
          {completedSteps}/{step.subStep.length}
        </span>
      </div>
      <div className={`step__sub-steps ${expanded ? 'show' : 'hide'}`}>
        {step.subStep.map((subStep) => {
          return (
            <XB_SubStep
              key={subStep.stepId}
              stepId={subStep.stepId}
              stepLabel={subStep.stepLabel}
              stepStatus={subStep.stepStatus}
              onStepChanged={(subStepId) => {
                onStepChanged?.(step.stepId, subStepId);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
