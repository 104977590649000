const { REACT_APP_API_BASE_URL, REACT_APP_STRAPI_URL } = process.env;

export const AUEndPoints = {
  depositService: {
    GET_DEPOSIT_LABELS: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-deposit-label`,
    GET_NOMINEE_DETAILS: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-nominee`,
    GET_PRODUCT_CODE: `${REACT_APP_API_BASE_URL}/api/deposit-service/product-codes-ind`,
    TD_CALCULATOR: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-td-calculation`,
    RD_CALCULATOR: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-rd-calculation`,
    DEPOSIT_CREATION: `${REACT_APP_API_BASE_URL}/api/deposit-service/deposit-creation`,
    RD_ENQUIRY: `${REACT_APP_API_BASE_URL}/api/deposit-service/rd-enquiry`,
    TD_ENQUIRY: `${REACT_APP_API_BASE_URL}/api/deposit-service/td-enquiry`,
    RD_CLOSURE: `${REACT_APP_API_BASE_URL}/api/deposit-service/rd-closure`,
    TD_CLOSURE: `${REACT_APP_API_BASE_URL}/api/deposit-service/td-closure`,
    ADD_NOMINEE: `${REACT_APP_API_BASE_URL}/api/deposit-service/add-nominee`,
    CASA_SUMMARY_DETAIL: `${REACT_APP_API_BASE_URL}/api/deposit-service/casa-summary`,
    DEPOSIT_SUMMARY: `${REACT_APP_API_BASE_URL}/api/deposit-service/deposit-summary`,
    GET_TD_DEPOSIT: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-td-deposit`,
    CHANGE_NOMINEE: `${REACT_APP_API_BASE_URL}/api/deposit-service/update-nominee`,
    UPDATE_PURPOSE: `${REACT_APP_API_BASE_URL}/api/deposit-service/set-purpose`,
    ACCOUNT_PERIODIC_STATEMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/account-periodic-statement`,
    SHOW_BANK_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/banks-list`,
    UPIVALIDATE: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/verify-vpa`,
    GET_PINCODE: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-pincode-detail`,
    GET_AU_ACC_PAYEES: `${REACT_APP_API_BASE_URL}/api/account-service/get-au-acc-payees`,
    GET_CUSTOMER_DETAIL: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-customer-details`,
    GET_BANK_DETAILS: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-bank-details`,
    ACC_TYPE: `${REACT_APP_API_BASE_URL}/api/master-service/masters/payee-account-type-details`,
    INTEREST_CERTIFICATE: `${REACT_APP_API_BASE_URL}/api/deposit-service/interest-certificate-download`,
    GET_ALL_FINANCIAL_YEARS: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-all-financial-years`,
    CUSTOMER_ACCOUNT_RELATIONSHIP: `${REACT_APP_API_BASE_URL}/api/deposit-service/customer-account-relationship`,
  },
  paymentService: {
    PAYMENT_FREQUENCY: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-schedule-payment-frequency`,
    COOLING_PERIOD: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-cooling-period`,
  },

  logService: {
    LOGS: `${REACT_APP_API_BASE_URL}/api/audit-service/audits/log-web-request`,
  },

  settlementService: {
    collectionList: (params: { page: number; size: number; sort: string }) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/collection/list?page=${params.page}&size=${params.size}${params.sort}`,
    collectionListExtraction: (params: {
      page: number;
      size: number;
      sort: string;
    }) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/collection/list/extraction?page=${params.page}&size=${params.size}&sort=${params.sort}`,
    settlementList: (params: { page: number; size: number; sort: string }) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/settlement/list?page=${params.page}&size=${params.size}${params.sort}`,
    SettleVpa: (vpaId: string, merchantId: string) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/settlement/${merchantId}/settle/${vpaId}`,
    SettleAll: (merchantId: string) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/settlement/${merchantId}/settle`,
    SettlementDetail: (params: { page: number; size: number; sort: string }) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/settlement/list/transaction?page=${params.page}&size=${params.size}${params.sort}`,
    FileList: (params: { page: number; size: number; sort: string }) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/file/list?page=${params.page}&size=${params.size}${params.sort}`,

    InvalidTransactionList: (params: {
      page: number;
      size: number;
      sort: string;
    }) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/file/status/list/invalid-transaction?page=${params.page}&size=${params.size}${params.sort}`,
    DownloadDocument: `${REACT_APP_API_BASE_URL}/api/settlement-service/document/download`,
    DownloadCollection: `${REACT_APP_API_BASE_URL}/api/settlement-service/collection/download`,
    UploadDocument: `${REACT_APP_API_BASE_URL}/api/settlement-service/document/upload-file`,
    CONFIRM_DOCUMENT: `${REACT_APP_API_BASE_URL}/api/settlement-service/file/process/confirm`,
    SETTLEMENT_PARAMETER: `${REACT_APP_API_BASE_URL}/api/master-service/masters/settlement-parameter`,
    BLOCK_RELEASE_TXNS: `${REACT_APP_API_BASE_URL}/api/settlement-service/settlement/block-release/transactions`,
    COLLECTION_DOWNLOAD_FILE: `${REACT_APP_API_BASE_URL}/api/settlement-service/aws/s3-bucket/file/download`,
    SETTLEMENT_PAYOUTS: (paymentMode: string) =>
      `${REACT_APP_API_BASE_URL}/api/settlement-service/settlement/${paymentMode}/payouts`,
    GET_DYNAMIC_QR_CODE: `${REACT_APP_API_BASE_URL}/api/settlement-service/get-dynamic-qr-code`,
  },

  contentExporterService: {
    DOWNLOAD_ACCOUNT_STATEMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/download-account-statement`,
    DOWNLOAD_LOAN_STATEMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/loan-statement-download`,
    LOAN_AMORTIZATION_PDF: `${REACT_APP_API_BASE_URL}/api/payments-service/loan-amortization-pdf`,
  },
  masterService: {
    COUNTRY_CODE_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/fetch/country-code-list`,
    DEBIT_CARD_PRODUCT_DETAIL: `${REACT_APP_API_BASE_URL}/api/master-service/masters/debit-card-product-detail`,
    GET_SERVICE_REQUEST_TYPES: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-service-request-types`,
  },
  fdService: {
    GET_FD_LABELS: `${REACT_APP_API_BASE_URL}/api/v1/data/getFDLabels`,
  },
  debitCardService: {
    GET_DEBIT_CARD_LIST: `${REACT_APP_API_BASE_URL}/api/debit-card-service/debit-card-details`,
    DEBIT_CARD_LIMIT_LIST: `${REACT_APP_API_BASE_URL}/api/debit-card-service/debit-card-limit`,
    INTERNATIONAL_DOMESTIC_USAGE: `${REACT_APP_API_BASE_URL}/api/debit-card-service/international-domestic-usage`,
    GET_REWARDS_BALANCE_POINT: `${REACT_APP_API_BASE_URL}/api/debit-card-service/get-rewards-balance-points`,
    SSO_REDIRECTION: `${REACT_APP_API_BASE_URL}/api/debit-card-service/sso-redirection`,
    BLOCK_DEBIT_CARD: `${REACT_APP_API_BASE_URL}/api/debit-card-service/update-debit-card-details`,
    GET_DEBIT_CARD_CVV: `${REACT_APP_API_BASE_URL}/api/debit-card-service/get-debit-card-cvv`,
    SET_PIN_DEBIT_CARD: `${REACT_APP_API_BASE_URL}/api/debit-card-service/set-debit-card-pin`,
    PERMANENT_BLOCK_DEBIT_CARD: `${REACT_APP_API_BASE_URL}/api/debit-card-service/update-debit-card`,
    VALIDATE_DEBIT_CARD_PIN: `${REACT_APP_API_BASE_URL}/api/user-management-service/registration/validate-debit-card`,
  },

  loansService: {
    GET_LOANS_REDIRECTION_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-loans-redirection-details`,
    LOAN_CROSS_SELL_FETCH: `${REACT_APP_API_BASE_URL}/api/csr-service/cross-sell/submit`,
    EQUITY_CROSS_SELL_FETCH: `${REACT_APP_API_BASE_URL}/api/csr-service/cross-sell/equity`,
  },

  configService: {
    CONFIG_URL: `${REACT_APP_API_BASE_URL}/api/config-service/config/feGet`,
  },
  customerAccountsService: {
    CUSTOMER_ACCOUNTS_DETAILS: `${REACT_APP_API_BASE_URL}/api/deposit-service/mapped-customer-accounts-details`,
  },
  inventoryService: {
    GET_VPA_PREFIX_LIST: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-vpa-prefix-list`,
    GET_VPA_HANDLER: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-vpa-handlers`,
    GENERATE_VPA: `${REACT_APP_API_BASE_URL}/api/inventory-service/generate-vpa`,
    CHECK_VPA: `${REACT_APP_API_BASE_URL}/api/inventory-service/check-vpa`,
    CHECK_GENERATED_VPA: `${REACT_APP_API_BASE_URL}/api/inventory-service/check-generated-vpa-status`,
    VPA_PRINTABLE_quantity: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-vpa-printable-quantity`,
    GET_VENDORS: `${REACT_APP_API_BASE_URL}/api/inventory-service/masters/get-vendors`,
    GET_VERTICALS: `${REACT_APP_API_BASE_URL}/api/inventory-service/masters/get-verticals`,
    GET_KIT_TYPE: `${REACT_APP_API_BASE_URL}/api/inventory-service/masters/get-kit-type`,
    STOCK_SUMMARY: `${REACT_APP_API_BASE_URL}/api/inventory-service/stock-summary`,
    GET_ALLOCATED_STOCKS_STATUS: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-allocated-stocks-status`,
    STOCKS_REPORT: `${REACT_APP_API_BASE_URL}/api/inventory-service/stock-report`,
    ALLOCATION_REPORT_SO: `${REACT_APP_API_BASE_URL}/api/inventory-service/allocation-report-so`,
    GET_ACTIVE_STOCK_COUNT: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-active-stock-count`,
    STOCK_STATUS_UPDATE: `${REACT_APP_API_BASE_URL}/api/inventory-service/stock-status-update`,
    SEND_FOR_PRINTING: `${REACT_APP_API_BASE_URL}/api/inventory-service/send-for-printing`,
    PRINTING_LIST: `${REACT_APP_API_BASE_URL}/api/inventory-service/printing-list`,
    ALLOCATION_HISTORY_LIST: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-allocation-history`,
    PRINTING_VPA_LIST: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-printable-vpa-prefix-list`,
    UPDATE_PRINTING_STATUS: `${REACT_APP_API_BASE_URL}/api/inventory-service/update-printing-status`,
    PRODUCT_MASTER: `${REACT_APP_API_BASE_URL}/api/inventory-service/masters/get-product-masters`,
    STOCK_QUANTITY: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-stock-quantity`,
    STOCK_ALLOCATION: `${REACT_APP_API_BASE_URL}/api/inventory-service/stock-allocation`,
    ALLOCATED_STOCK: `${REACT_APP_API_BASE_URL}/api/inventory-service/past-allocated-stock`,
    ALLOCATED_STOCK_STATUS: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-allocated-stocks-status`,
    USER_LIST: `${REACT_APP_API_BASE_URL}/api/inventory-service/masters/stockable/user/list`,
    ALLOCATED_ITEMS_UPC_FILTER: `${REACT_APP_API_BASE_URL}/api/inventory-service/allocated-items-upc-filter`,
    GET_CUST_DETAIL: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/get-customer-details/`,
    RAISE_REQUEST: `${REACT_APP_API_BASE_URL}/api/inventory-service/inventory-request`,
    REGENERATE_VPA: `${REACT_APP_API_BASE_URL}/api/inventory-service/regenerate-vpa`,
    STOCK_REQUEST_HISTORY: `${REACT_APP_API_BASE_URL}/api/inventory-service/stock-request-history`,
    CUSTOMER_REQUEST_HISTORY: `${REACT_APP_API_BASE_URL}/api/inventory-service/custom-request-list`,
    REQUEST_STATUS_UPDATE: `${REACT_APP_API_BASE_URL}/api/inventory-service/inventory-request-status-update`,
    UPLOAD_POD_FILE: `${REACT_APP_API_BASE_URL}/api/inventory-service/upload`,
    GET_ALLOCATION_FILE: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-allocation-file`,
    ADMIN_DOWNLOAD_FILE: `${REACT_APP_API_BASE_URL}/api/inventory-service/download-files`,
    VPA_PREFIX_LIST_BY_MR: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/get-vpa-list/`,
    GENERATE_CUSTOM_INVENTORY: `${REACT_APP_API_BASE_URL}/api/inventory-service/generate-custom-inventory`,
    CUSTOM_LIST: `${REACT_APP_API_BASE_URL}/api/inventory-service/custom-request-list`,
    PRINT_CUSTOM_INVENTORY: `${REACT_APP_API_BASE_URL}/api/inventory-service/print-custom-inventory`,
    STOCK_VPA_LIST: `${REACT_APP_API_BASE_URL}/api/inventory-service/get-stock-vpa-prefix-list`,
  },
  entitleRequest: {
    ROLE_LIST_ENDPOINT: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/list`,
    SETTLEMENT_REPORT: `${REACT_APP_API_BASE_URL}/api/settlement-service/collection/list/extraction-download`,
    EXPORT_REPORT: `${REACT_APP_API_BASE_URL}/api/settlement-service/transaction/export-collect-txn`,
    MODULE_LIST_ENDPOINT: `${REACT_APP_API_BASE_URL}/api/user-management-service/module/list`,
    ROLE_DETAIL_ENDPOINT: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/details`,
    USERS_LIST: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/employee/list`,
    USER_DETAILS: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/employee/details`,
    USER_GROUP_DETAILS: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/details`,
    GROUP_DETAILS: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/details`,
    USER_BLOCK_UNBLOCK: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/block-unblock`,
    ROLE_BLOCK_UNBLOCK: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/block-unblock`,
    CREATE_ROLE_ENDPOINT: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/add`,
    DELETE_ROLE: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/delete`,
    USER_DISABLE: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/disable`,
    REMOVE_USER_ROLE: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/unassign`,
    ASSIGN_USER_ROLES: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/assign`,
    ASSIGN_USER_GROUPS: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/assign`,
    UNASSIGN_USER_GROUPS: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/unassign`,
    UPDATE_CREATE_ROLE: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/manage`,
    MANAGE_AUTH_GROUP: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/manage`,
    MANAGE_USER_GROUP: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/manage`,
    GROUP_LIST_ENDPOINT: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/list`,
    ROLE_LIST: (page = 0, size = 10, isSystemRole = true): string => {
      return `${REACT_APP_API_BASE_URL}/api/user-management-service/role/list?page=${page}&size=${size}&isSystemRole=${isSystemRole.toString()}`;
    },
    EMPLOYEE_ONBOARD: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/employee/onboard`,
    GET_USER_NAME: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/get-user-name`,
    AUTH_GROUP_LIST: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/list`,
    DISABLE_AUTH_GRP: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/remove`,
    DISABLE_USER_GRP: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/remove`,
    CREAT_USER_GRP: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/add`,
    CREAT_AUTH_GRP: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/add`,
    BLOCK_UNBLOCK_USER_GRP: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/block-unblock`,
    BLOCK_UNBLOCK_Auth_GRP: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/block-unblock`,
    FETCH_ALL_MR: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/fetch-all`,
    GET_MR_USER_LIST: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/get-user-list`,
    MR_BLOCK_UNBLOCK: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/block-unblock`,
    MR_ACCOUNT_EDIT: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/account/update`,
    MR_ASSIGN_AUTH_GROUP: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/assign`,
    MR_UNASSIGN_AUTH_GROUP: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/unassign`,
    USER_GROUP_AVAILABILITY: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/group/availability`,
  },
  serviceRequest: {
    CHEQUE_STATUS: `${REACT_APP_API_BASE_URL}/api/csr-service/fetch-cheque-details`,
    STOP_CHEQUE: `${REACT_APP_API_BASE_URL}/api/csr-service/request-stop-cheque`,
    FORM_15GH: `${REACT_APP_API_BASE_URL}/api/csr-service/sr-form-15gh`,
    GET_BRANCH_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-branch-list`,
    REQUEST_CHEQUE_BOOK: `${REACT_APP_API_BASE_URL}/api/csr-service/request-cheque-book`,
    EMAIL_UPDATE: `${REACT_APP_API_BASE_URL}/api/csr-service/update-customer-email`,
    SR_STATUS: `${REACT_APP_API_BASE_URL}/api/csr-service/sr-status`,
    POSITIVE_PAY: `${REACT_APP_API_BASE_URL}/api/csr-service/positive-pay`,
    GET_SR_LIST: `${REACT_APP_API_BASE_URL}/api/csr-service/get-sr-list`,
    POSITIVE_PAY_LIST: `${REACT_APP_API_BASE_URL}/api/csr-service/list-positive-pay`,
    POSITIVE_PAY_STATUS: `${REACT_APP_API_BASE_URL}/api/csr-service/positive-pay-status`,
    LEAD_GEN_MASTER: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-lead-creation-product-details`,
    CRM_LEAD_GEN: `${REACT_APP_API_BASE_URL}/api/csr-service/crm-lead-generation`,
    UPDATE_COMMUNICATION_ADDRESS: `${REACT_APP_API_BASE_URL}/api/csr-service/update-communication-address`,
    GET_CONSTITUTION: `${REACT_APP_API_BASE_URL}/api/master-service/masters/constitutions`,
    VIDEO_BANKING_REDIRECT: `${REACT_APP_API_BASE_URL}/api/csr-service/video-banking/submit`,
    LOGIN_SSO_IPO_REDIRECT: `${REACT_APP_API_BASE_URL}/api/csr-service/loginsso-ipo-redirection`,
  },
  applyNow: {
    STATE_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-au-bank-state-list`,
    LOCKER_STATE_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-locker-state-list`,
    CITY_BY_STATE_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-au-bank-city-by-state`,
    LOCKER_CITY_BY_STATE_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-locker-city-by-state`,
    GET_BRANCH_LIST: `${REACT_APP_API_BASE_URL}/api/master-service/masters/get-branch-list`,
  },
  strapiService: {
    getTheme: (userTpe = 'userGroup1') =>
      `${REACT_APP_STRAPI_URL}/api/getUserTheme/${userTpe}`,
    GET_FAQ_CATEGORIES: `${REACT_APP_STRAPI_URL}/api/getFaqCategories`,
    getFaqQuestions: (categaryType) =>
      `${REACT_APP_STRAPI_URL}/api/getFaqQuestions/${categaryType as string}`,
    getDashboardPersona: (personaKey: string) =>
      `${REACT_APP_STRAPI_URL}/api/getPersona?name=${personaKey}`,
    getOffers: `${REACT_APP_STRAPI_URL}/api/getOfferList`,
    getBankOffers: (
      customerCategory: string,
      merchantId: string,
      customerId: string
    ) =>
      `${REACT_APP_STRAPI_URL}/api/getBankOffers?channel=AUMB&customerCategory=${customerCategory}&mode=Web&active=true` +
      `${'&customerCategory=Both'}${
        merchantId ? `&merchantId=${merchantId}` : ''
      }${customerId ? `&customerId=${customerId}` : ''}`,
    getAppIcons: `${REACT_APP_STRAPI_URL}/api/getAppIcons`,
  },
  merchantService: {
    GET_MERCHANT_DETAILS: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/fetch`,
    NOTIFICATION_SETTINGS: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/notification/enable-disable`,
    LOGOUT: `${REACT_APP_API_BASE_URL}/api/authentication-service/logout`,
    PROFILE_UPDATE: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/upload`,
    deleteBankAccount: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/account/delete-account`,
    addBankAccount: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/account/add`,
    updateVpa: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/update-vpa`,
    GET_BANK_DETAIL_LIST: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/get-bank-detail-list`,
    GET_BANK_DETAIL_BY_IFSC: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/get-bank-detail-by-ifsc`,
  },

  fraudDisputeService: {
    FRAUD_ACCOUNT_BLOCK: `${REACT_APP_API_BASE_URL}/api/csr-service/fraud-account-block`,
    FRAUD_UPI_BLOCK: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/upi/block-account`,
    FRAUD_SR: `${REACT_APP_API_BASE_URL}/api/csr-service/fraud-and-dispute`,
  },

  fileDownload: {
    s3Dowmload: `${REACT_APP_API_BASE_URL}/api/onboarding-service/onboarding/download`,
  },
  merchantManagementService: {
    UPDATE_MERCHANT_PROFILE: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/update-merchant-profile`,
    UPDATE_PROFILE_PHOTO: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/document/update-image-url`,
    ADD_DOCUMENT: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/document/add-document`,
    CKYC: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/ckyc-detail`,
    MERCHANT_ENABLE_DISABLE_NOTIFICATION: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/notification/enable-disable`,
    UPDATE_MERCHANT_MODULES: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/update-merchant-modules`,
    BLOCK_UNBLOCK_VPA: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/vpa/block-unblock`,
    RECEIVE_CARD_PAYMENTS: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/toggle-receiving-card-payments`,
    CARD_PAYMENTS_ENQUIRY: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/card-payments-acceptance-enquiry`,
    BLOCK_AMOUNT_VPA: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/update-vpa`,
    BLOCK_UNBLOCK_MERCHANT: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/block-unblock`,
  },
  userManagementService: {
    ONBOARDING_DEBIT_CARD_LIST: `${REACT_APP_API_BASE_URL}/api/user-management-service/debit-card-details`,
    FETCH_CUSTOMER_ADDRESS: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/fetch-customer-address`,
    RELATIONSHIP_MANAGER_DETAILS: `${REACT_APP_API_BASE_URL}/api/user-management-service/relationship-manager-details`,
    LANG_UPDATE: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/update`,
    UPDATE_USERNAME: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/username/update`,
    UPDATE_BUSINESSNAME: `${REACT_APP_API_BASE_URL}/api/merchant-management-service/merchant/update-merchant`,
    UPDATE_USER_DATA: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/update`,
    SESSION_DETAILS: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/session-details`,
    MERCHANT_DETAILS: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant/session-details`,
    USER_MERCHANT_DETAILS: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant/user/user-merchant-details`,
    ENABLE_DISABLE: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/device/block-unblock-disable`,
    MPIN_AUTHENTICATE: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/authenticate`,
    DEACTIVATE: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/device/disable`,
    NTB_TO_ETB: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/update/ntb-etb`,
    EMP_UPDATE: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/employee/manage`,
    DOWNLOAD_FILE: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/download-file`,
    UPDATE_USER_IMG: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/update-user-image`,
    DEFAULT_MERCHANT: (merchantId: string) =>
      `${REACT_APP_API_BASE_URL}/api/user-management-service/user/set-default-merchant?merchantId=${merchantId}`,
    MR_USER_ACCOUNT_LIST: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant-user-account/list`,
    USER_ROLE_PERMISSIONS: `${REACT_APP_API_BASE_URL}/api/user-management-service/role/permission/user`,
    SOLE_PROP_PRE_REGISTRATION: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/v1/pre-registration`,
  },
  registerServices: {
    FACE_MATCH: `${REACT_APP_API_BASE_URL}/api/user-management-service/registration/validate-face-match`,
  },
  consentService: {
    CONSENT_FEEDBACK: `${REACT_APP_API_BASE_URL}/api/user-management-service/save-faq-feedback`,
  },

  loanStatementService: {
    ACCOUNT_PERIODIC_STATEMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/loan-account-statement`,
  },

  paymentsService: {
    loansService: {
      LOAN_OVERDUE_PAYMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/loan-overdue-payment`,
      LOAN_ACCOUNT_INQUIRY: `${REACT_APP_API_BASE_URL}/api/payments-service/loan-account-inquiry`,
      LOAN_ACCOUNT_STATEMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/loan-account-statement`,
    },

    linkPayee: {
      WITHDRAW_ALLOWANCE: `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/withdraw-allowance`,
      PAYMENT_SET_ALLOWANCE: `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/set-allowance`,
      GET_ALLOWENCE: `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/get-allowances`,
      GET_ALLOWANCE_DETAIL_LIST: `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/get-allowance-detail-list`,
      CANCEL_ALLOWANCE_OR_TXN: `${REACT_APP_API_BASE_URL}/api/payments-service/link-payee/cancel-allowance-or-txn`,
      BENEFICIARY_DOWNLOAD: `${REACT_APP_API_BASE_URL}/api/payments-service/get-payee-csv`,
    },
    payeeService: {
      GET_PAYEE: `${REACT_APP_API_BASE_URL}/api/payments-service/get-payee`,
      VERIFYPAYEENAME: `${REACT_APP_API_BASE_URL}/api/payments-service/verify-payee-name`,
      UPDATEPAYEE: `${REACT_APP_API_BASE_URL}/api/payments-service/update-payee-details`,
      PAYEE_ACCOUNT_CONFIRM: `${REACT_APP_API_BASE_URL}/api/payments-service/payee-account-validation`,
      DEACTIVAEPAYEE: `${REACT_APP_API_BASE_URL}/api/payments-service/deactivate-payee`,
      ADDPAYEE: `${REACT_APP_API_BASE_URL}/api/payments-service/add-payee`,
    },
    casaService: {
      MONTHLY_ACCOUNT_BALANCE: `${REACT_APP_API_BASE_URL}/api/payments-service/monthly-account-balance`,
    },
    stateMentService: {
      ACCOUNT_PERIODIC_STATEMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/account-periodic-statement`,
      ACCOUNT_MINI_STATEMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/account-mini-statement`,
    },
    paymentService: {
      MAKE_PAYMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/make-payment`,
      GET_PAYMENTS_IN_SERIES: `${REACT_APP_API_BASE_URL}/api/payments-service/get-payments-in-series`,
      GET_TRANSACTION_LIST: `${REACT_APP_API_BASE_URL}/api/payments-service/get-transaction-list`,
      FREQ_PAYEE_LIST: `${REACT_APP_API_BASE_URL}/api/payments-service/freq-payee-list`,
      CANCEL_PAYMENT: `${REACT_APP_API_BASE_URL}/api/payments-service/cancel-payment`,
      INTERNAL_ACCOUNT_VERIFICATION: `${REACT_APP_API_BASE_URL}/api/payments-service/internal-account-verification`,
      ACCOUNT_VERIFICATION_SELF: `${REACT_APP_API_BASE_URL}/api/payments-service/account-verification-self`,
      ACCOUNT_VERIFICATION: `${REACT_APP_API_BASE_URL}/api/payments-service/account-verification`,
      PAYEE_CASA_ACCOUNTS: `${REACT_APP_API_BASE_URL}/api/payments-service/payee-casa-accounts`,
      FEATURE_LIMIT: `${REACT_APP_API_BASE_URL}/api/limit-service/limit/initiation-check`,
      ACCOUNT_LIMIT: (userId: string, merchantId: string) =>
        `${REACT_APP_API_BASE_URL}/api/limit-service/limit/user/${userId}/merchant/${merchantId}/account`,
    },
  },
  notificationService: {
    NOTIFICATIONS: `${REACT_APP_API_BASE_URL}/api/notification-service/notifications/get-all`,
    READ_NOTIFICATIONS: `${REACT_APP_API_BASE_URL}/api/notification-service/notifications/read`,
  },

  profileService: {
    GET_BUSINESS_DETAIL: `${REACT_APP_API_BASE_URL}/api/deposit-service/get-cif-details`,
  },
  authMatrixService: {
    AUTH_GROUP_GET: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/get`,
  },
  merchantManagementAdmin: {
    CIF_DETAILS_ADMIN: (isView: any) =>
      `${REACT_APP_API_BASE_URL}/api/deposit-service/cif-details-admin?isView=${
        isView as string
      }`,
    MERCHANT_PRE_REGISTRATION: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/merchant/pre-registration`,
    ADD_MERCHANT_ACCOUNT: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant-user-account/add`,
    GET_USER_LIST: `${REACT_APP_API_BASE_URL}/api/user-management-service/user/get-user-list`,
    MERCHANT_USER_CREATION: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant/user/add`,
    MERCHANT_USER_MAP: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant/map/user`,
    MERCHANT_USER_UNMAP: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant/unmap/user`,
    CHECK_AUTH_GROUP_AVAIL: `${REACT_APP_API_BASE_URL}/api/auth-matrix-service/auth-group/availability`,
    REMOVE_MERCHANT_USER_ACCOUNT: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant-user-account/remove`,
    MANAGE_MERCHANT_USER_ACCOUNT: `${REACT_APP_API_BASE_URL}/api/user-management-service/merchant-user-account/manage`,
  },
};
