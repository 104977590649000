import { Icon } from '../icon/XB_Icon.component';

import './XB_Spinner.style.scss';

interface IconProps {
  iconName?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}
export const XB_Spinner = ({ iconName, size = 'lg' }: IconProps) => {
  return (
    <div
      className={`spinner-container ${iconName ? 'icon' : ''}`}
      data-testid="SPNR"
    >
      <div className={`loader loader--${size}`}></div>
      {iconName && (
        <Icon
          width={24}
          height={24}
          className="spinner__icon"
          icon={iconName}
        />
      )}
    </div>
  );
};
