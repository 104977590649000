import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { appConstants } from '@core-constants';
import { ServerError } from '@core-services';
import {
  decryptWithIvSalt,
  encryptRSAWithPublicKey,
  encryptWithIvSalt,
  handleError,
  randomGenerateNumber,
  SessionStorage,
} from '@core-utils';

const { REACT_APP_IV } = process.env;

const defaultConfig = {
  headers: {
    Accept: 'application/json',
  },
};

const checkAvailUrl = (list: string[], str): boolean => {
  return list.some((item) => item === str.match(item?.trim())?.[0]);
};

const { REACT_APP_STRAPI_URL, REACT_APP_TYPE, REACT_APP_RESPONSE_TIMEOUT } =
  process.env;
export const instance = axios.create(defaultConfig);
export const unauthenticatedURLList = [
  'api/config-service/config/get',
  'api/getConsentByVersionId',
  'maps.googleapis.com/maps/api/geocode/json',
  'api/walkthroughs?populate=*&filters%5Blanguage%5D%5B$eq%5D=en',
];

export const staticEncryptedUrl = [
  `/api/config-service/global-configs/latest`,
  // `/api/config-service/global-configs/list`,
];

instance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const accessToken = SessionStorage.getItem('accessToken');
    const isAuthenticationRequired = unauthenticatedURLList.reduce(
      (required, url) => {
        if (config.url?.includes(url)) {
          return false;
        }
        return required;
      },
      true
    );
    if (!config.url?.startsWith(REACT_APP_STRAPI_URL as string)) {
      config.headers!['X-EVENT-TRACE'] =
        sessionStorage.getItem('X-EVENT-TRACE') ?? '';
    }

    if (accessToken && isAuthenticationRequired) {
      config.headers!.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      SessionStorage.getItem('accessToken')
    ) {
      SessionStorage.clear();
      localStorage.clear();
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  }
);

const applicationIdentifier = () => {
  let appIdentifier = '';
  if (REACT_APP_TYPE === appConstants.APP_TYPE.MR) {
    appIdentifier = `WEB_MERCHANT`;
  } else {
    appIdentifier = `WEB_${REACT_APP_TYPE}`;
  }
  return appIdentifier;
};

async function post(
  url: string,
  params: any,
  source: CancelTokenSource,
  headers?: object | any,
  config?: object,
  value?: string
) {
  const appIdentifier = applicationIdentifier();
  const deviceId: string = SessionStorage.getItem('browser_id') as string;
  const encryptedList = JSON.parse(
    SessionStorage.getItem('encryptedUrl') ?? '[]'
  );
  const timer = setTimeout(() => {
    source.cancel();
  }, (REACT_APP_RESPONSE_TIMEOUT as any) * 1000);
  const response = await instance.post(`${url}`, params, {
    cancelToken: source.token,
    headers: {
      ...headers,
      channel: 'WEB',
      appIdentifier,
      'X-DEVICE-ID': deviceId,
    },
    ...config,
  });
  clearTimeout(timer);

  const isEncryption =
    (encryptedList?.length > 0 && checkAvailUrl(encryptedList, url)) ||
    checkAvailUrl(staticEncryptedUrl, url);

  return isEncryption && response?.status === 200
    ? {
        ...response,
        data: JSON.parse(
          decryptWithIvSalt(value, REACT_APP_IV, response?.data?.data) ?? '{}'
        ),
      }
    : response;
}

async function get(
  url: string,
  source: CancelTokenSource,
  headers?: object | any
) {
  const timer = setTimeout(() => {
    source.cancel();
  }, (REACT_APP_RESPONSE_TIMEOUT as any) * 1000);

  const response = await instance.get(url, {
    cancelToken: source.token,
    headers: {
      ...headers,
    },
  });
  clearTimeout(timer);
  return response;
}

export async function send(
  params: {
    baseurl: string;
    method: string;
    url: string;
    obj?: FormData | object | string;
    reqToken?: CancelTokenSource;
  },
  headers?: object,
  config?: object
): Promise<any> {
  let Url;
  let Params;

  if (!params || typeof params !== 'object') {
    throw new Error('params is undefined or not an object');
  }
  try {
    const cancelToken = params.reqToken ?? axios.CancelToken.source();
    const encryptedList = JSON.parse(
      SessionStorage.getItem('encryptedUrl') ?? '[]'
    );
    const randomValue = randomGenerateNumber();
    if (params.method === 'POST') {
      Url = params.baseurl + params.url;
      const isMultipart: boolean =
        headers?.['Content-Type'] === 'multipart/form-data';
      const isEncryption =
        (encryptedList?.length > 0 && checkAvailUrl(encryptedList, Url)) ||
        checkAvailUrl(staticEncryptedUrl, Url);
      if (isEncryption || isMultipart)
        headers = { ...headers, auth: encryptRSAWithPublicKey(randomValue) };

      Params =
        isEncryption && params.obj && !isMultipart
          ? {
              data: encryptWithIvSalt(
                randomValue,
                REACT_APP_IV,
                JSON.stringify(params.obj)
              ),
            }
          : params.obj;

      return await post(Url, Params, cancelToken, headers, config, randomValue);
    } else {
      Url = params.baseurl + params.url;
      const strapiAuthKey = JSON.parse(
        SessionStorage.getItem('strapiAuth') ?? '{}'
      );

      if (Url?.includes(REACT_APP_STRAPI_URL) && strapiAuthKey) {
        headers = {
          ...headers,
          strapiauth: strapiAuthKey,
        };
      }

      return await get(Url, cancelToken, headers);
    }
  } catch (err: any) {
    let errData;
    if (err.code === 'ERR_CANCELED' || err.code === 'ERR_NETWORK') {
      errData = {
        error: {
          errorCode: err.code,
        },
      };
    } else {
      errData = err?.response?.data;
    }
    const error = handleError(errData);

    throw new ServerError(error);
  }
}
