export enum errorTypeOption {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  VERIFIED = 'verified',
  BLANK = '',
}

export const productId = {
  businessBanking: '12203',
  applyLocker: '20501',
};

export const CITY = 'CITY';
export const BANK_NAME = 'AU SMALL FINANCE BANK LIMITED';
