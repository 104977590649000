import { EndPoints, Service } from '@core-services';

const { REACT_APP_FIREBASE_IDENTIFIER } = process.env;

export const saveBankDetails = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.so.accountPreference,
    method: 'POST',
    url: '',
    obj,
  };
  const headers = {
    appIdentifier: `WEB_${REACT_APP_FIREBASE_IDENTIFIER}`,
  };
  const response = await Service.send(reqObj, headers);
  return response.data;
};

export const getLiabilityAccounts = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.getLiabilityAccount,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const saveLiabilityAccounts = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.saveLiabilityAccount,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
