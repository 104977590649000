import React from 'react';

import './AU_Circleprogressbar.style.scss';

interface CircleprogressbarProps {
  height: string;
  strokeBackgroundColor: string;
  strokeColor: string;
  strokeWidth: number;
  strokeLinecap: 'butt' | 'round' | 'square';
  percent: number;
  centerString: string;
  centerStringClass?: string;
}

export const AU_Circleprogressbar = ({
  height,
  strokeBackgroundColor,
  strokeColor,
  strokeWidth,
  strokeLinecap = 'butt',
  percent,
  centerString,
  centerStringClass = 'm-text-xs-medium text-gray-600',
}: CircleprogressbarProps) => {
  const radius = (parseInt(height) - strokeWidth) / 2;
  const svgHeight = parseInt(height) + 5;

  const calculateDashArray = () => {
    return 2 * Math.PI * radius;
  };
  const calculateDashOffset = (percentValue: number) => {
    const dashArray = calculateDashArray();
    const strokeDashoffset = dashArray - (dashArray * percentValue) / 100;
    return `${strokeDashoffset}px`;
  };

  return (
    <div className="au_circle-indicator">
      <div className="percent">
        <svg height={svgHeight} width={svgHeight}>
          <circle
            r={radius}
            strokeWidth={strokeWidth}
            stroke={strokeBackgroundColor}
            strokeLinecap={strokeLinecap}
          ></circle>
          <circle
            r={radius}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeDasharray={`${calculateDashArray().toString()}px`}
            strokeDashoffset={calculateDashOffset(percent)}
          ></circle>
        </svg>
        <div className="number">
          <span className={centerStringClass}>{centerString}</span>
        </div>
      </div>
    </div>
  );
};
