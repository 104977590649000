import { EndPoints, Service } from '@core-services';

export const getAadhaarByToken = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.getAadhaarByToken,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const getAadhaarToken = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.getAadhaarToken,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const deleteOnboardingUser = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.deleteOnboardingUser,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const ckycStatus = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.ckycStatus,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const ckycDetails = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.ckycDetails,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const fetchSupportingDocs = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.fetchSupportingDocuments,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const removeSupportingDocs = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.removeSupportingDocuments,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const createOrUpdateSupportDocs = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.createOrUpdateSupportingDocuments,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const verifyPoeDetails = async (obj) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.verifyPoeDetails,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
export const impsVerify = async (payload) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.verifyAccount,
    method: 'POST',
    url: '',
    obj: payload,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
