import {
  IAnnualTurnover,
  IIncomeRes,
} from '@modules/profile-info/models/profile-info.model';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  incomelist?: IIncomeRes[];
  turnoverlist?: IAnnualTurnover[];
} = {
  incomelist: undefined,
  turnoverlist: undefined,
};
const getIncomeTurnoverListSlice = createSlice({
  name: 'incomeTurnoverList',
  initialState,
  reducers: {
    setIncomeList(state, { payload }) {
      state.incomelist = payload;
    },
    setTurnoverList(state, { payload }) {
      state.turnoverlist = payload;
    },
  },
});
export const { setIncomeList, setTurnoverList } =
  getIncomeTurnoverListSlice.actions;
export default getIncomeTurnoverListSlice.reducer;
