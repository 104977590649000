import { createSlice } from '@reduxjs/toolkit';

import { GetPincodeRes } from '../models/get-pincode-detail.model';
import { getPincodeDetail } from '../thunks/get-pincode-detail.thunk';

const initialState: GetPincodeRes = {
  status: undefined,
  successfulResponse: undefined,
  loading: false,
  error: undefined,
  data: undefined,
};
const getPincodeSlice = createSlice({
  name: 'getPincode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPincodeDetail.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.successfulResponse = payload.successful_response;
      state.data = payload.data;
      state.error = payload.error;
      state.loading = false;
    });
    builder.addCase(getPincodeDetail.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getPincodeDetail.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default getPincodeSlice.reducer;
