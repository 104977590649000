import { useEffect, useRef, useState } from 'react';

export function useActiveElement(elems: string[], tag: string) {
  const [activeElem, setActiveElem] = useState('');
  const observer = useRef<IntersectionObserver | null>(null);
  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry?.isIntersecting) {
            setActiveElem(entry.target[tag]);
          }
        });
      },
      {
        threshold: 0.25,
        rootMargin: '0px 0px -40% 0px',
      }
    );

    const elem = document.querySelectorAll(elems.toString());
    elem.forEach((e) => observer.current?.observe(e));
    return () => observer.current?.disconnect();
  }, []);

  return { activeElem };
}
