export function tenureFormatter({
  years,
  months,
  days,
}: {
  years?: number;
  months?: number;
  days?: number;
}) {
  if (!years && !months && !days) return '-/-';
  if (!months && !days) {
    return getTenureFormatData(years ?? 0, 'years', 'year');
  }
  if (!years && !days) {
    return getTenureFormatData(months ?? 0, 'months', 'month');
  }
  if (!years && !months) {
    return getTenureFormatData(days ?? 0, 'days', 'day');
  }
  const y = years ? `${years}y ` : '';
  const m = months ? `${months}m ` : '';
  const d = days ? `${days}d` : '';
  return y + m + d;
}

function getTenureFormatData(
  tenureEntity: number,
  labelWithS: string,
  label: string
): string {
  return tenureEntity && tenureEntity > 1
    ? `${tenureEntity} ${labelWithS}`
    : `${tenureEntity} ${label}`;
}

export function getTenureData(str) {
  const pattern = /\d/g;
  const result = str.match(pattern);

  return tenureFormatter({
    years: Number(result[2]) ? Number(result[2]) : undefined,
    months: Number(result[0]) ? Number(result[0]) : undefined,
    days: Number(result[1]) ? Number(result[1]) : undefined,
  });
}
