export const FEATURE_ASSIGNED_LIST = [
  {
    sNo: 1,
    featureName: 'KYC Validation - PAN',
    permissionName:
      'Read, Delete, Approve, Reject, Block, Unblock, Link, Delink Resend & Review',
  },
  {
    sNo: 2,
    featureName: 'KYC Validation - Aadhaar',
    permissionName: 'Approve, Reject & Block',
  },
  {
    sNo: 3,
    featureName: 'KYC Validation - POE',
    permissionName: 'Read, Approve, Reject, Block, Unblock',
  },
  {
    sNo: 4,
    featureName: 'KYC Validation - Live photo',
    permissionName: 'Approve, Reject & Block',
  },
  {
    sNo: 5,
    featureName: 'KYC Validation - PAN',
    permissionName: 'Read, Approve, Reject, Block, Unblock',
  },
  {
    sNo: 6,
    featureName: 'KYC Validation - Aadhaar',
    permissionName:
      'Read, Delete, Approve, Reject, Block, Unblock, Link, Delink Resend & Review',
  },
];

export const authGroupMockData = [
  { sNo: '1', authGroupName: 'Auth Group 1' },
  { sNo: '2', authGroupName: 'Auth Group 2' },
  { sNo: '3', authGroupName: 'Auth Group 3' },
];

interface IUserPermission {
  onboardPermission: string;
  editPermission: string;
}

export const userPermission: IUserPermission = {
  onboardPermission: 'user.employee.onboard',
  editPermission: 'user.employee.manage',
};
