import { createSlice } from '@reduxjs/toolkit';

import { ICasaDetails } from '../../models/response-account-services.model';

const initialState: ICasaDetails = {
  accountNumber: undefined,
  productCode: undefined,
  accountType: undefined,
};

const accountDetailsSlice = createSlice({
  name: 'casa-details',
  initialState,
  reducers: {
    setCasaDetails(state, { payload }: { payload: any }) {
      state.accountType = payload?.accountType;
      state.accountNumber = payload?.accountNumber;
      state.productCode = payload?.productCode;
    },
  },
});

export const { setCasaDetails } = accountDetailsSlice.actions;
export default accountDetailsSlice.reducer;
