import { createSlice } from '@reduxjs/toolkit';

import { ResponseDebitCardDetails } from '../../models/response-account-services.model';
import { debitCardDetails } from '../thunks/debit-card.thunk';

const initialState: ResponseDebitCardDetails = {
  loading: false,
  data: undefined,
  error: undefined,
};

const debitCardDetailsSlice = createSlice({
  name: 'debitCard',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      debitCardDetails.pending,
      (state: ResponseDebitCardDetails) => {
        state.loading = true;
      }
    );

    builder.addCase(
      debitCardDetails.fulfilled,
      (state: ResponseDebitCardDetails, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.data = payload.data;
      }
    );

    builder.addCase(
      debitCardDetails.rejected,
      (state: ResponseDebitCardDetails, { payload }) => {
        state.loading = false;
        state.error = payload as undefined;
        state.data = undefined;
      }
    );
  },
});

export default debitCardDetailsSlice.reducer;
