import { Items } from '@core-constants';

import { TrayItemsProps } from '../models';

export enum activeScreenMapping {
  CASECHECKPOINTS = 'CASECHECKPOINTS',
  CASEREVIEW = 'CASEREVIEW',
  CASEDOCUMENTS = 'CASEDOCUMENTS',
  CASEDETAILS = 'CASEDETAILS',
}
export const caseHeaderMapping = {
  INPROGRESS: 'In-progress',
  userType: {
    MERCHANT: 'MERCHANT',
    SO: 'SO',
    EMPLOYEE: 'EMPLOYEE',
    DEFAULT: 'DEFAULT',
  },
};

export const buttonStatusMapping = {
  CLICKED: 'clicked',
  PENDING: 'pending',
  INITIAL: 'initial',
  DISABLE: 'disable',
};
const keyValueRestriction = {
  politicalExp: 'Political Exposure',
};
export const restrictedCheckPointsMapping = {
  [keyValueRestriction.politicalExp]: 'No',
};

export const acceptButtonProperties = {
  [buttonStatusMapping.CLICKED]: {
    color: 'var(--background)',
    backgroundColor: '#039855',
    border: '#039855',
  },
  [buttonStatusMapping.INITIAL]: {
    color: '#039855',
    backgroundColor: 'var(--background)',
    border: '#039855',
  },
  [buttonStatusMapping.DISABLE]: {
    color: '#6CE9A6',
    backgroundColor: 'var(--background)',
    border: '#6CE9A6',
  },
};
export const messageButtonProperties = {
  [buttonStatusMapping.CLICKED]: {
    color: 'var(--background)',
    backgroundColor: '#039855',
    border: '#039855',
  },
  [buttonStatusMapping.INITIAL]: {
    color: '#039855',
    backgroundColor: 'var(--background)',
    border: '#039855',
  },
  [buttonStatusMapping.DISABLE]: {
    color: '#6CE9A6',
    backgroundColor: 'var(--background)',
    border: '#6CE9A6',
  },
};
export const reworkButtonProperties = {
  [buttonStatusMapping.CLICKED]: {
    color: 'var(--neutrals-700)',
    backgroundColor: '#D1D2D5',
    border: '#D1D2D5',
  },
  [buttonStatusMapping.INITIAL]: {
    color: 'var(--neutrals-700)',
    backgroundColor: 'var(--background)',
    border: 'var(--neutrals-700)',
  },
  [buttonStatusMapping.DISABLE]: {
    color: 'var(--neutrals-200)',
    backgroundColor: 'var(--background)',
    border: 'var(--neutrals-200)',
  },
};
export const referButtonProperties = {
  [buttonStatusMapping.CLICKED]: {
    color: 'var(--background)',
    backgroundColor: 'var(--primary-600)',
    border: 'var(--primary-600)',
  },
  [buttonStatusMapping.INITIAL]: {
    color: 'var(--primary-600)',
    backgroundColor: 'var(--background)',
    border: 'var(--primary-600)',
  },
  [buttonStatusMapping.DISABLE]: {
    color: 'var(--primary-300)',
    backgroundColor: 'var(--background)',
    border: 'var(--primary-300)',
  },
};
export const resetButtonProperties = {
  [buttonStatusMapping.INITIAL]: {
    color: 'var(--neutrals-700)',
    backgroundColor: 'var(--background)',
    border: 'var(--neutrals-700)',
  },
  [buttonStatusMapping.DISABLE]: {
    color: 'var(--neutrals-200)',
    backgroundColor: 'var(--background)',
    border: 'var(--neutrals-200)',
  },
};
export const tickButtonProperties = {
  [buttonStatusMapping.INITIAL]: {
    color: 'var(--primary-600)',
    backgroundColor: 'var(--background)',
    border: 'var(--primary-600)',
  },
};
export const crossButtonProperties = {
  [buttonStatusMapping.INITIAL]: {
    color: 'var(--neutrals-700)',
    backgroundColor: 'var(--background)',
    border: 'var(--neutrals-700)',
  },
};
export const caseStatus = {
  inProgress: 'IN_PROGRESS',
  completed: 'COMPLETED',
  processed: 'PROCESSED',
  autoApproved: 'AUTO_APPROVED',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  sentForRework: 'SENT_FOR_REWORK',
  sentForRefer: 'SENT_FOR_REFER',
};
export const fieldStatus = {
  resolved: 'RESOLVED',
  approved: 'ACCEPTED',
  pending: 'PENDING',
  reset: 'RESET',
};
export const action = {
  rework: 'SENT_FOR_REWORK',
  resend: 'REWORK_REVERTED',
  refer: 'SENT_FOR_REFER',
  approve: 'APPROVED',
  referReverted: 'REFER_REVERTED',
  pending: 'PENDING',
  reject: 'REJECTED',
  fresh: 'FRESH',
  amlCheck: 'AML_CHECK',
  pepCheck: 'PEP_CHECK',
  vkycPending: 'VKYC_PENDING',
  vkycReject: 'VKYC_REJECTED',
  vkycSuccess: 'VKYC_COMPLETED',
  reviewPending: 'REVIEW_PENDING',
};
export const miscConstants = {
  other: 'Other',
  otherDocs: 'Other Documents',
  others: 'Others',
  text: 'TEXT',
  checkpoint: 'CHECKPOINT',
  highlight: 'HIGHLIGHT',
  case: 'CASE',
  ascending: 'ASC',
  descending: 'DESC',
  POE1: 'POE1',
  POE2: 'POE2',
  entity: '-Entity',
  ind: '-Individual',
  system: 'system',
  GRP: 'GROUP',
  USR: 'USER',
  chkpoint: 'Checkpoint',
  yes: 'Yes',
  no: 'No',
  todoReject: 'TODO :: groupId',
  adhaarAndLive: 'Aadhaar and Live Photo',
};
export const fieldContentType = {
  SCORE: 'SCORE',
  TEXT: 'TEXT',
  MERGE: 'MERGE',
  URL: 'URL',
  DOCUMENT: 'DOCUMENT',
};
export enum actions {
  APPROVE = 'approve',
  REWORK = 'rework',
  REFER = 'refer',
  VERIFIED = 'Verified',
}
export const showadditionalHeading = ['9', '10'];
export const assignedTray = {
  label: 'Assigned',
  value: 'ASSIGNED',
  key: 'assigned',
  badgeValue: '',
};
export const reworkTray = {
  label: 'Rework',
  value: 'REWORK',
  key: 'rework',
  badgeValue: '',
};
export const allTray = {
  label: 'All',
  value: 'ALL',
  key: 'all',
  badgeValue: '',
};
export const unassignedTray = {
  label: 'Unassigned',
  value: 'UNASSIGNED',
  key: 'unassigned',
  badgeValue: '',
};
export const referTray = {
  label: 'Refer',
  value: 'REFER',
  key: 'refer',
  badgeValue: '',
};
export const wipTray = {
  label: 'WIP',
  value: 'WIP',
  key: 'wip',
  badgeValue: '',
};
export const merchantTray = {
  label: 'Merchant Rework',
  value: 'MERCHANT',
  key: 'MERCHANT',
  badgeValue: '',
};

const commonTableItems: TrayItemsProps[] = [
  assignedTray,
  reworkTray,
  referTray,
  allTray,
];
export const tableItemsTeamLead: TrayItemsProps[] = [
  unassignedTray,
  ...commonTableItems,
];
export const tableItemsTeamMember: TrayItemsProps[] = [...commonTableItems];
export const tableItemsSO: TrayItemsProps[] = [
  wipTray,
  ...commonTableItems,
  merchantTray,
];
export const tableItemsSO_MANAGER: TrayItemsProps[] = [
  ...commonTableItems,
  merchantTray,
];
export const tableItemAML: TrayItemsProps[] = [assignedTray, allTray];
export const bankUserMapping = {
  CPC_TEAM_LEAD: 'CPC_TEAM_LEAD',
  CPC_USER: 'CPC_USER',
  SO: 'SO',
  SO_MANAGER: 'SO_MANAGER',
  AML_TEAM: 'AML_TEAM',
};
export const bankUserGrp = Object.values(bankUserMapping);
export const sortFieldMapping = {
  LEAD_ID: 'LEAD_ID',
  MERCHANT_NAME: 'MERCHANT_NAME',
  PAN: 'PAN',
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  VPA: 'VPA',
  ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
  STATUS: 'STATUS',
  CIF: 'CIF',
  QR_DISPLAY_NAME: 'QR_DISPLAY_NAME',
  DATE_OF_ONBOARDING: 'DATE_OF_ONBOARDING',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  SOURCE: 'SOURCE',
  ASSIGNED_TO: 'ASSIGNED_TO',
  LAST_ASSIGNED: 'LAST_ASSIGNED',
  DATE_OF_APPROVAL: 'DATE_OF_APPROVAL',
};
export const tableDropDown = [
  {
    text: 'Account Number',
    value: sortFieldMapping.ACCOUNT_NUMBER,
  },
  {
    text: 'Mobile Number',
    value: sortFieldMapping.MOBILE_NUMBER,
  },
  {
    text: 'Merchant Name',
    value: sortFieldMapping.MERCHANT_NAME,
  },
  {
    text: 'VPA',
    value: sortFieldMapping.VPA,
  },
  {
    text: 'PAN',
    value: sortFieldMapping.PAN,
  },
  {
    text: 'Lead ID',
    value: sortFieldMapping.LEAD_ID,
  },
  {
    text: 'QR Disaplay Name',
    value: sortFieldMapping.QR_DISPLAY_NAME,
  },
  {
    text: 'CIF',
    value: sortFieldMapping.CIF,
  },
];

export const cpcUserMappings = {
  CPC_TEAM_LEAD: 'CPC TL',
  CPC_USER: 'CPC',
  AML_TEAM: 'AML',
  SO: 'SO',
  DEFAULT: 'MERCHANT',
  PEP_CHECK: 'PEP',
  SO_MANAGER: 'SO MANAGER',
};
export const checkpointName = {
  proofOfEntity2: '10',
  proofOfEntity1: '9',
  entityDateOfIncorporation: '11',
  entityRegAddress: '12',
  commAddress: '13',
  bankAcc: '6',
  bankAccInd: '5',
  pep: '8',
};
export const highlightsName = {
  doiPOE: '115',
  regPOE: '114',
  profCatInd: '29',
  deptCatInd: '30',
  emplrCatInd: '31',
  emplntCatInd: '32',
  riskCatInd: '33',
  mcc: '34',
  amlMatch: '35',
  cbs: '36',
  pep: '20',
  adharNum: '3',
  email: '24',
  mobile: '1',
  name: '10',
};
export const actionType = {
  ACTIONABLE: 'Actionable',
  VIEWABLE: 'Viewable',
};
export const businessTypeMapping = {
  NTB: 'NTB',
  ETB: 'ETB',
};
export const customerTypeMapping = {
  SOLEPROP: 'SOLPROP',
  IND: 'IND',
};
export const initialFilterValues = {
  sources: [],
  journeyTypes: [],
  businessTypes: [],
  customerTypes: [],
  statuses: [],
  onBoardingStartDate: '',
  onBoardingEndDate: '',
  assignedTo: '',
};
export const disableMenuMapping = {
  [action.pepCheck]: {
    CASECHECKPOINTS: false,
    CASEDETAILS: false,
    CASEDOCUMENTS: false,
    CASEREVIEW: true,
  },
  [action.amlCheck]: {
    CASECHECKPOINTS: true,
    CASEDETAILS: false,
    CASEDOCUMENTS: true,
    CASEREVIEW: true,
  },
  [action.rework]: {
    CASECHECKPOINTS: false,
    CASEDETAILS: false,
    CASEDOCUMENTS: false,
    CASEREVIEW: false,
  },
  [caseStatus.inProgress]: {
    CASECHECKPOINTS: false,
    CASEDETAILS: false,
    CASEDOCUMENTS: false,
    CASEREVIEW: false,
  },
};
export const footerTypes = {
  AML_FOOTER: 'AML-FOOTER',
  PEP_FOOTER_CHECKPOINT: 'PEP_FOOTER_CHECKPOINT',
  PEP_FOOTER_DETAILS: 'PEP_FOOTER_DETAILS',
  COMMON: 'COMMON',
};
export const footerMapping = {
  [action.pepCheck]: {
    CASECHECKPOINTS: footerTypes.PEP_FOOTER_CHECKPOINT,
    CASEDETAILS: footerTypes.PEP_FOOTER_DETAILS,
    CASEDOCUMENTS: footerTypes.PEP_FOOTER_DETAILS,
  },

  [action.amlCheck]: {
    CASEDETAILS: footerTypes.AML_FOOTER,
  },
};
export const highlightedHeadingMapping = {
  [action.amlCheck]: [
    'Supporting Documents',
    'Business Details',
    'Additional Business Details',
  ],
  [action.pepCheck]: [
    'Supporting Documents',
    'Business Details',
    'Additional Business Details',
  ],
};
export const fieldValueColorMapping = {
  [action.amlCheck]: [
    highlightsName.amlMatch,
    highlightsName.adharNum,
    highlightsName.name,
    highlightsName.email,
    highlightsName.mobile,
  ],
  [action.pepCheck]: [
    highlightsName.pep,
    highlightsName.adharNum,
    highlightsName.name,
    highlightsName.email,
    highlightsName.mobile,
  ],
};
const { bankAccInd, proofOfEntity1, proofOfEntity2, bankAcc } = checkpointName;
export const placeContentBottom = [
  bankAccInd,
  proofOfEntity1,
  proofOfEntity2,
  bankAcc,
];
const { approved, autoApproved, rejected, sentForRework } = caseStatus;
export const disabledStatusList = [
  approved,
  autoApproved,
  rejected,
  sentForRework,
];

export const addressType: Items[] = [
  { value: 'Residential', text: 'Residential' },
  { value: 'Work', text: 'Work' },
  { value: 'Other Place', text: 'Other Place' },
];

export const handleSettlementCycle: Items[] = [
  { text: 'Daily', value: 'Daily' },
  { text: 'Monthly', value: 'Monthly' },
  { text: 'Yearly', value: 'Yearly' },
  { text: 'Hourly', value: 'Hourly' },
];

export const handleLinkedAccount: Items[] = [
  { text: '98273664647', value: '98273664647' },
  { text: '82736646947', value: '82736646947' },
  { text: '27366464789', value: '27366464789' },
  { text: '73669824647', value: '73669824647' },
];
