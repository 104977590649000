export const PERMISSIONS = {
  RBAC_DEPOSIT: {
    VIEW_MODULE_DEPOSIT: 'deposit.module.view',
    UPDATE: 'deposit.request.update',
    VIEW: 'deposit.request.view',
    DELETE: 'deposit.request.delete',
    CREATE: 'deposit.request.create',
    SET_PURPOSE: 'deposit.request.update',
    GET_NOMINEE: 'deposit.request.view',
    UPDATE_NOMINEE: 'deposit.request.update',
  },
  RBAC_ACCOUNT: {
    VIEW_STATEMENT: 'account.statement.view',
    VIEW_MODULE_CASA: 'casa.module.view',
    VIEW_AUBANK: 'account.aubank.view',
    CASA_MINI_STMT_VIEW: 'account.statement.view',
    MONTHLY_ACC_BALANCE_VIEW: 'account.monthlybalance.view',
    LOAN_OVERDUE_CREATE: 'account.loan.create',
  },
  RBAC_LOAN: {
    VIEW_MODULE_LOAN: 'loan.module.view',
    VIEW: 'account.loan.view',
    CREATE_PAY_OVERDUE: 'account.loan.create',
    VIEW_LOAN_ACC_STMT_DETAILS: 'account.loan.view',
    LOAN_INQUIRY: 'account.loan.view',
  },
  RBAC_PAYEE: {
    VIEW_MODULE_PAYEE: 'payee.module.view',
    VIEW: 'account.payee.view',
    VIEW_PAYEE_CASA_ACCTS: 'account.payee.view',
    CREATE_ADD_PAYEE: 'account.payee.create',
    VIEW_VERIFY_PAYEE_NAME: 'account.payee.view',
    VIEW_VERIFY_PAYEE_ACCT: 'account.payee.view',
    DEACTIVATE_PAYEE: 'account.payee.delete',
    UPDATE_PAYEE_DETAILS: 'account.payee.update',
    INTERNAL_ACC_VERIFICATION: 'account.aubank.view',
  },
  RBAC_STATEMENT: {
    VIEW: 'account.statement.view',
  },
  RBAC_DEBIT_CARD_SERVICE: {
    VIEW_MODULE_DEBIT: 'debitcard.module.view',
    DETAILS_VIEW: 'debitcard.detail.view',
    CARD_LIMIT_UPDATE: 'debitcard.limit.update',
    CVV_DETAILS_VIEW: 'debitcard.cvv.view',
    DETAILS_UPDATE: 'debitcard.detail.update',
    DETAILS_BLOCK_UPDATE: 'debitcard.detail.update',
    DETAILS_REPLACE_UPGRADE: 'debitcard.upgrade.update',
    PIN_UPDATE: 'debitcard.pin.update',
    INTERNATIONAL_DOMESTIC_USAGE: 'debitcard.limit.update',
    VALIDATE_NEW_PIN: 'debitcard.pin.view',
    REWARD_BALANCE_VIEW: 'debitcard.reward.view',
    SSO_REDIRECTION: 'debitcard.reward.view',
  },
  RBAC_CSR: {
    VIEW_MODULE_CSR: 'csr.module.view',
    FORM_15GH_CREATE: 'csr.15ghform.create',
    STOP_CHEQUE_UPDATE: 'csr.cheque.update',
    CHEQUE_STATUS: 'csr.cheque.view',
    CHEQUE_CREATE: 'csr.cheque.create',
    STATUS_VIEW: 'csr.srstatus.view',
    POSITIVE_PAY: 'positive.pay.create',
    POSITIVE_PAY_STATUS: 'positive.pay.view',
    UPDATE_EMAIL: 'csr.email.update',
    UPDATE_CUSTOMER_EMAIL: 'csr.email.update',
    GET_ALL_SR_LIST: 'csr.sr.list',
    UPDATE_NEW_CHEQUE_COMM_ADDRESS: 'csr.address.update',
    UPDATE_COMM_ADDRESS: 'csr.address.update',
    CRM_LEAD_GEN_CREATE: 'csr.crmlead.create',
    SET_ALLOWANCE_CREATE: 'payment.linkpayee.create',
    CRM_LEAD_GEN: 'csr.crmlead.create',
    VIDEOBANKING_MOBILE: 'videobanking.payload.view',
    CROSS_SELL_SUBMIT: 'crosssell.payload.view',
    CROSS_SELL_EQUITY: 'crosssell.equitypayload.view',
  },
  RBAC_PAYMENTS: {
    VIEW_MODULE_PAYMENT: 'payment.module.view',
    VIEW_MODULE_TAX: 'gstpayment.module.view',
    RECENT_TRANSACTION_VIEW: 'payment.transaction.view',
    SCHEDULED_TRANSACTION_VIEW: 'payment.transaction.view',
    CANCEL_PAYMENTS: 'payment.transaction.delete',
    SET_ALLOWANCE_CREATE: 'payment.linkpayee.create',
    IFT_CREATE: 'payment.ift.create',
    IMPS_CREATE: 'payment.imps.create',
    NEFT_CREATE: 'payment.neft.create',
    RTGS_CREATE: 'payment.rtgs.create',
    PAYMENT_TRANSFER_CREATE: 'payment.transfer.create',
  },
  RBAC_INVEST: {
    VIEW_MODULE_INVESTMENT: 'investment.module.view',
    VIEW_MODULE_INSURANCE: 'insurance.module.view',
    VIEW_MODULE_APPLYNOW: 'applynow.module.view',
  },
  RBAC_MASTERS: {
    GET_BANK_DETAILS: 'master.ifsc.details.view',
  },
  RBAC_CONTENT_EXPORTER: {
    DOWNLOAD_ACC_STMT: 'contentexporter.accountstatement.download',
    DOWNLOAD_LOAN_ACC_STMT: 'contentexporter.loanstatement.download',
  },
  RBAC_DEVICE: {
    MERCHANT_SESSION: 'merchant.session.view',
    USER_SESSION: 'User.session.view',
  },
  RBAC_ROLES: {
    WRITE_ACCESS: 'admin.role.update',
    CREATE_ROLE: 'admin.role.add',
    DOWNLOAD_REPORT: 'admin.role.view',
    VIEW: 'admin.role.view',
    CLONE: 'admin.role.add',
    BLOCK: 'admin.role.update',
    EDIT: 'admin.role.update',
    DISABLE: 'admin.role.remove',
    REMOVE: 'admin.role.remove',
    DELETE: 'admin.role.delete',
  },
  RBAC_ADMIN_USERS: {
    VIEW: 'user.employee.view',
    EDIT: 'user.employee.manage',
    BLOCK: 'user.employee.manage',
    DISABLE: 'user.employee.manage',
    CREATE: 'user.employee.onboard',
  },
  RBAC_ADMIN_USER_GROUP: {
    CREATE: 'user.group.add',
    VIEW: 'user.group.view',
    EDIT: 'user.group.update',
    DISABLE: 'user.group.delete',
    VIEW_DETAIL: 'user.group.details.view',
  },

  RBAC_ADMIN_MENU_GROUP: {
    INVENTORY: 'inventory.tab.view',
    CaseManagement: 'case.management.tab.view',
    ENTITLEMENT: 'entitlement.tab.view',
    LIMITMANAGEMENT: 'limit.management.tab.view',
    SETTLEMENT: 'settlement.tab.view',
  },
  RBAC_ADMIN_AUTH_GROUP: {
    CREATE: 'employee.authgroup.add',
    VIEW: 'employee.authgroup.listview',
    EDIT: 'employee.authgroup.update',
    DISABLE: 'employee.authgroup.remove',
  },
  RBAC_ADMIN_MERCHANT_ROLES: {
    VIEW: 'merchant.role.view',
  },
  RBAC_ADMIN_MERCHANT_AUTH_GROUP: {
    VIEW: 'merchant.authgroup.listview',
  },
  RBAC_ADMIN_MERCHANT_DETAILS: {
    VIEW: 'mms.merchant.list',
    EDIT: 'employee.authgroup.update',
    DISABLE: 'employee.authgroup.remove',
    VPA_BLOCK_UNBLOCK: 'mms.vpa.statusupdate',
    VPA_UPDATE: 'mms.vpa.update',
    VPA_BLOCK_AMOUNT: 'settlement.charges.update',
    MERCHANT_NOTIFICATION_EDIT: 'mms.merchant.notification.edit',
    MERCHANT_DETAILS_UPDATE: 'mms.merchant.details.update',
    MASTER_VIEW_PREMISE: 'master.premise.type.view',
    MMS_PROFILE_UPDATE: 'mms.merchant.profile.update',
    MMS_ACCOUNT_UPDATE: 'mms.account.update',
    MMS_BANK_IFSC_LIST: 'mms.bank.ifsc.list',
    MMS_OTHERBANK_VIEW: 'account.otherbank.view',
    MMS_CREATE_ACCOUNT: 'mms.account.create',
  },
  RBAC_CASE_MANAGEMENT: {
    ASSIGN: 'employee.assign.approve',
  },
};

export const BLANK = '';
export const NULL = null;
