import { useTranslation } from 'react-i18next';

import { XB_Button } from '@core-components/atoms';
import NoDataImg from '@src/assets/images/something-went-wrong.svg';

interface ErrorLayoutProps {
  errorImg?: string;
  NoDataImgClass?: string;
  onTryAgain?: () => void;
  onLeftBtnClick?: () => void;
  leftBtnText?: string;
  titleText?: string;
  subTitleText?: string;
  titleTextClass?: string;
  subTitleTextClass?: string;
  leftBtnClass?: string;
  rightBtnClass?: string;
  containerClass?: string;
  imgContainerClass?: string;
  btnContainerClass?: string;
  withBg?: boolean;
}

export const ErrorLayout = ({
  errorImg = NoDataImg,
  NoDataImgClass,
  onTryAgain,
  onLeftBtnClick,
  leftBtnText,
  titleText,
  subTitleText,
  titleTextClass,
  subTitleTextClass,
  leftBtnClass,
  rightBtnClass,
  containerClass,
  imgContainerClass,
  btnContainerClass,
  withBg = true,
}: ErrorLayoutProps) => {
  const { t } = useTranslation();
  const titleClassList = [
    'm-display-xl-medium',
    'text-gray-800 mt-12',
    titleTextClass,
  ];
  const subTitleClasslist = [
    'm-text-md-regular',
    'text-gray-800',
    subTitleTextClass,
  ];
  const leftBtnClassList = ['w-[16.5rem]', leftBtnClass];
  const rightBtnClassList = ['w-[16.5rem]', rightBtnClass];
  return (
    <div className="au-errorlayout-container">
      <div
        className={`flex flex-col items-center py-16 ${
          withBg ? 'bg-white rounded-xl' : ''
        } ${containerClass}`}
      >
        <div className={`flex flex-col items-center ${imgContainerClass} p-4`}>
          <img
            data-testid="NO-DATA-IMG"
            src={errorImg}
            width={228}
            height={180}
            className={NoDataImgClass}
          />
          <>
            {titleText && (
              <h2 className={`${titleClassList.join(' ')} text-center`}>
                {t(titleText)}
              </h2>
            )}
            {subTitleText && (
              <h3 className={`${subTitleClasslist.join(' ')} text-center`}>
                {t(subTitleText)}
              </h3>
            )}
          </>
          <div
            className={`flex gap-3 mt-12 ${btnContainerClass} flex-wrap justify-center px-4`}
          >
            {leftBtnText && (
              <XB_Button
                dataTestId="CNTC-SPRT"
                btnType="secondary"
                classes={`${leftBtnClassList.join(' ')}`}
                size="lg"
                onClick={() => {
                  if (onLeftBtnClick) onLeftBtnClick();
                }}
              >
                {t(leftBtnText)}
              </XB_Button>
            )}

            <XB_Button
              dataTestId="TRY-AGAN"
              btnType="primary"
              size="lg"
              classes={`${rightBtnClassList.join(' ')}`}
              onClick={() => {
                onTryAgain?.();
              }}
            >
              {t('tryAgain')}
            </XB_Button>
          </div>
        </div>
      </div>
    </div>
  );
};
