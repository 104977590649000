import { createSlice } from '@reduxjs/toolkit';

import { ILinkPayeeRdxData } from '../../index';

const initialState: ILinkPayeeRdxData = {
  statusData: undefined,
};

const linkPayeeSlice = createSlice({
  name: 'service_request',
  initialState,
  reducers: {
    setStatusData(state, { payload }) {
      state.statusData = payload;
    },
  },
});

export const { setStatusData } = linkPayeeSlice.actions;
export default linkPayeeSlice.reducer;
