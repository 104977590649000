import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, Service } from '@src/core-services';

import { ISetAllowance } from '../../models/link-payee.model';

export const setAllowanceLinkPayee = createAsyncThunk(
  '/api/payment-service/link-payee/set-allowance',
  async (setAllowanceReq: ISetAllowance, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.paymentsService.linkPayee.PAYMENT_SET_ALLOWANCE,
        url: '',
        obj: setAllowanceReq,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
