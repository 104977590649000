import { createSlice } from '@reduxjs/toolkit';

import { DepositSummaryData } from '../../models/response-deposit.model';

const initialState = {
  deposits: {} as DepositSummaryData,
  currentDeposit: {},
  depositAccountDetails: {} as {
    accountNumber?: string;
    accountType?: string;
  },
};

const depositSummarySlice = createSlice({
  name: 'depositSummary',
  initialState,
  reducers: {
    setDepositSummary(state, { payload }) {
      state.deposits = payload;
    },
    setCurrentDeposit(state, { payload }) {
      state.currentDeposit = payload;
    },
    removeCurrentDeposit(state) {
      state.currentDeposit = {};
    },
    depositAccountDetails(state, { payload }) {
      state.depositAccountDetails = payload;
    },

    updatePurpose: (
      state,
      {
        payload,
      }: {
        payload: {
          accountID: string;
          purposeValue: string;
          depositAccKey: string;
        };
      }
    ) => {
      state.deposits = {
        ...state.deposits,
        [payload.depositAccKey]:
          state.deposits?.[payload.depositAccKey].map((i) => {
            if (i.AccountID === payload.accountID) {
              return { ...i, Purpose: payload.purposeValue };
            }
            return i;
          }) ?? [],
      };
    },
  },
});
export const {
  setDepositSummary,
  updatePurpose,
  setCurrentDeposit,
  removeCurrentDeposit,
  depositAccountDetails,
} = depositSummarySlice.actions;
export default depositSummarySlice.reducer;
