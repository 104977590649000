import { createSlice } from '@reduxjs/toolkit';

const initialState: { tdDepositDetail?: any } = {
  tdDepositDetail: null,
};

const tdDepositSlice = createSlice({
  name: 'tdDepositDetail',
  initialState,
  reducers: {
    setData: (state, { payload }: { payload: any }) => {
      state.tdDepositDetail = payload;
    },
  },
});

export const { setData } = tdDepositSlice.actions;
export default tdDepositSlice.reducer;
