import { EndPoints, Service } from '@core-services';

export const nextAssignmentService = async (obj) => {
  const reqObj = {
    baseurl: `${EndPoints.admin.nextAssignment}`,
    method: 'POST',
    url: '',
    obj,
  };

  const resp = await Service.send(reqObj);
  return resp.data;
};

export const resetFieldsService = async (payload) => {
  const reqObj = {
    baseurl: `${EndPoints.admin.resetField}`,
    method: 'POST',
    url: '',
    obj: payload,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
