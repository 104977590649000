import { createSlice } from '@reduxjs/toolkit';

import { SelectedStatementType } from '../../models/account-statement.model';

const initialState: SelectedStatementType = {
  AccountId: undefined,
  LoanAccountId: undefined,
};
const statementSlice = createSlice({
  name: 'statement',
  initialState,
  reducers: {
    selectedStatementAccount(state, { payload }) {
      state.AccountId = payload;
    },
    clearSelectedStatementAccount(state) {
      state.AccountId = undefined;
    },
    selectedLoanStatementAccount(state, { payload }) {
      state.LoanAccountId = payload;
    },
    clearSelectedLoanStatementAccount(state) {
      state.LoanAccountId = undefined;
    },
  },
});

export const {
  selectedStatementAccount,
  clearSelectedStatementAccount,
  selectedLoanStatementAccount,
  clearSelectedLoanStatementAccount,
} = statementSlice.actions;
export default statementSlice.reducer;
