import { createSlice } from '@reduxjs/toolkit';

import { IfscSearchRes } from '../models/ifsc-search.model';
import { ifscSearch } from '../thunks/ifsc-search.thunk';

const initialState: IfscSearchRes = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};
const ifscSearchList = createSlice({
  name: 'ifscSearchBank',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ifscSearch.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
        loading: state.loading,
      } = payload);
    });
    builder.addCase(ifscSearch.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      ifscSearch.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default ifscSearchList.reducer;
