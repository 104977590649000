import { Icon } from '../icon/XB_Icon.component';

import './XB_Chip.style.scss';

interface ChipProps {
  classes?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  leftIcon?: string;
  rightIcon?: string;
  type?: 'orange' | 'gray';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  chipImage?: string;
}
export const XB_Chip: React.FC<ChipProps> = ({
  classes = '',
  children,
  onClick,
  type = 'orange',
  size = 'md',
  leftIcon,
  rightIcon,
  chipImage,
  ..._props
}: ChipProps) => {
  const sizeMap = {
    sm: {
      icon: 12,
      font: 'sm',
    },
    md: {
      icon: 16,
      font: 'md',
    },
    lg: {
      icon: 16,
      font: 'md',
    },
    xl: {
      icon: 20,
      font: 'lg',
    },
  };
  let iconOnly = '';
  if (!children) {
    iconOnly = `chip--icon-only`;
  }
  return (
    <>
      <div
        className={`chip chip--${size} ${iconOnly} ${classes}`}
        data-type={type}
        onClick={onClick}
      >
        {leftIcon && (
          <span className="chip__left-icon">
            <Icon
              icon={leftIcon}
              width={sizeMap[size].icon}
              height={sizeMap[size].icon}
            />
          </span>
        )}
        {chipImage && (
          <img
            src={chipImage}
            alt={chipImage}
            width={sizeMap[size].icon}
            height={sizeMap[size].icon}
          />
        )}
        {children && (
          <span className={`chip__label m-text-${sizeMap[size].font}-medium`}>
            {children}
          </span>
        )}
        {rightIcon && (
          <span className="chip__right-icon ml-1">
            <Icon
              icon={rightIcon}
              width={sizeMap[size].icon}
              height={sizeMap[size].icon}
            />
          </span>
        )}
      </div>
    </>
  );
};
