import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import QRCode from 'qrcode.react';

import {
  AU_Badge,
  AU_DateRangePicker,
  AU_Loader,
  AU_RoundBox,
  DateRangeChecker,
  Icon,
  XB_Button,
  XB_Chip,
  XB_Divider,
  XB_Dropdown,
  XB_Input,
  XB_Modal,
  XB_Radio,
} from '@core-components/atoms';
import {
  AU_Amountdropdown,
  AU_StatementPdfDownload,
  AU_Tab,
  XB_InlineMessage,
} from '@core-components/molecules';
import { AU_DataTable, TabColumns } from '@core-components/templates';
import {
  appCharLimit,
  appConstants,
  BLANK,
  downloadFormatOptions,
  Items,
  RegEx,
  settlementType,
} from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  IConfigDataType,
  IFeCollectionConfig,
  ISettlementConfig,
  ResGetMerchantDetails,
  ResProfileData,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import {
  amountTranslator,
  convertAppDateFormat,
  getConcatenatedString,
  getFEConfig,
  getFormattedDate,
  getINRFormat,
  getINRFormatWithDecimal,
  maskEmailId,
  maskMobileNumber,
} from '@core-utils';
import {
  booleanValues,
  CalenderModalProps,
  DEFAULT_MODULE,
  EmailModalProp,
  filterModalTypes,
  filterPricePlaceholderValues,
  inputValidation,
  reverseFormatButtons,
  transactionTypes,
} from '@modules/account-statement/constants/account-statement.constant';
import { SelectedVal } from '@modules/qr-settlement/constants/qr-settlement.constant';
import AuImg from '@src/assets/icons/au-logo.svg';
import noDataImg from '@src/assets/images/no-record-collection.svg';

import {
  bottomTab,
  collectionConfig,
  DateFormat,
  DYNAMIC_MAX_LIMIT_QR,
  DYNAMIC_QR_EXPIRATION_TIME,
  FE_SETTLEMENT_CONFIG_TYPE,
  intialFilterQrDateRange,
  MAX_LIMIT_QR,
  MAX_LIMIT_REMARK,
  MIN_LIMIT_QR,
  qrFilterDateRange,
  QrHeaderPropsType,
  RecentTransactionPropsType,
  SETTLED,
  SETTLEMENT_STATUS,
  ShowQrModalPropsType,
  STATUS_QR,
  TimeFormat,
  willBeSettled,
} from '../constants/qr-collection.constant';
import {
  IFilterSectionProps,
  QRCollectionListResponseType,
} from '../models/qr-collection.model';
import {
  getDynamicQrCode,
  settleAll,
  settleVpaWise,
} from '../services/qr-collection.service';
import {
  qrSettlementListData,
  qrTransctionsListData,
} from '../stores/thunks/qr-collection.thunk';
import defaultQR from '@src/assets/images/defaultQR.png';

export interface PartialProps {
  onDataSend: () => void;
}

export const CollectionHeader: React.FC<QrHeaderPropsType> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auAlert = useAlertBox();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState();
  const [totalQuantity, setTotalQuantity] = useState();
  const [creditAmountToday, setCreditAmountToday] = useState<number>(0);
  const [settledAmountToday, setSettledAmountToday] = useState<number>(0);
  const [vpaId, setVpaId] = useState<string | null>(null);
  const merchantDataRdx = useAppSelector(
    (state: RootState) =>
      state.getMerchantDetail as ResGetMerchantDetails | undefined
  );
  const profileRdx = useAppSelector((state: RootState) => state.profile) as
    | ResProfileData
    | undefined;
  const [loading, setLoading] = useState<boolean>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabHeaders: string[] = [t('collections'), t('settlements')];
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorContents, setErrorContents] = useState<{ title: string }>();
  const [filteredList, setFilteredList] =
    useState<QRCollectionListResponseType[]>();
  const [filteredSettlementList, setFilteredSettlementList] =
    useState<QRCollectionListResponseType[]>();

  const renderErrorModal = () => {
    return (
      <XB_Modal
        open={errorModal}
        setOpen={setErrorModal}
        title={errorContents?.title}
        img={<Icon width={48} height={48} icon="warning-modal-icon" />}
        actions={
          <div className="flex w-full gap-1 flex-around ">
            <XB_Button
              dataTestId="CNCL"
              btnType="secondary"
              classes="w-full"
              onClick={() => {
                setErrorModal(false);
              }}
            >
              {t('cancel')}
            </XB_Button>
            <XB_Button
              dataTestId="TRY-AGIN"
              btnType="primary"
              classes="w-full"
              onClick={() => {
                fetchCollectionList(SETTLEMENT_STATUS);
              }}
            >
              {t('tryAgain')}
            </XB_Button>
          </div>
        }
      />
    );
  };
  const fetchCollectionList = async (settlement_status) => {
    try {
      const response = await dispatch(
        qrTransctionsListData({
          merchantId: profileRdx?.data?.activeMerchantId,
          settlementStatus: settlement_status,
          searchKey: '',
          amountMin: MIN_LIMIT_QR,
          amountMax: MAX_LIMIT_QR,
          dateMin: moment().format(DateFormat.from),
          dateMax: moment().format(DateFormat.to),
          payeeVpaId: vpaId ?? null,
        })
      );

      if (response?.payload?.successfulResponse === true) {
        setFilteredList(
          response.payload?.data?.collectionListResponseList.slice(0, 5)
        );
        setTotalAmount(response?.payload?.data?.totalAmount);
        setTotalQuantity(response?.payload?.data?.totalCount);
        setCreditAmountToday(response?.payload?.data?.toBeCreditedToday);
        setSettledAmountToday(response?.payload?.data?.settledAmountToday);
      } else {
        setErrorModal(true);
        setErrorContents({
          title: response?.payload?.error?.message,
        });
      }
    } catch (error: any) {
      setErrorModal(true);
      setErrorContents({ title: error?.displayMessage });
    } finally {
      setLoading(false);
    }
  };
  const fetchSettlementList = async (settlement_status) => {
    try {
      const response = await dispatch(
        qrSettlementListData({
          merchantId: profileRdx?.data?.activeMerchantId,
          settlementStatus: settlement_status,
          fromDate: moment().format(DateFormat.from),
          toDate: moment().format(DateFormat.to),
        })
      );

      if (response?.payload?.successfulResponse) {
        setFilteredSettlementList(
          response.payload?.data?.settlementListResponses.slice(0, 5)
        );
      } else {
        setErrorModal(true);
        setErrorContents({
          title: response?.payload?.error?.message,
        });
      }
    } catch (error: any) {
      setErrorModal(true);
      setErrorContents({ title: error?.displayMessage });
    }
  };

  const settleVpaWiseApi = async () => {
    setLoading(true);
    try {
      const response = await settleVpaWise(
        vpaId,
        profileRdx?.data?.activeMerchantId
      );

      if (response?.successfulResponse) {
        auAlert.success(response?.data);
      } else {
        auAlert.error(response?.error?.message);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage);
    } finally {
      setLoading(false);
    }
  };

  const settleAllApi = async () => {
    setLoading(true);
    try {
      const response = await settleAll(profileRdx?.data?.activeMerchantId);

      if (response?.successfulResponse) {
        auAlert.success(response?.data);
      } else {
        auAlert.error(response?.error?.message);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage);
    } finally {
      setLoading(false);
    }
  };
  const headerFirstColumn = [
    {
      heading: t('receivedToday'),
      currentValue: totalAmount,
    },
    {
      heading: t('numberOfPayments'),
      currentValue: totalQuantity,
    },
  ];

  const firstRowHeader = () => {
    return headerFirstColumn.map((item, index: number) => (
      <div
        className="flex w-full flex-col"
        key={`header-first-row-column${index}`}
      >
        <span className="m-text-sm-regular text-white">{item.heading}</span>
        <span className="m-display-xl-bold text-white">
          {item.heading === t('receivedToday')
            ? getINRFormatWithDecimal(Number(item?.currentValue))
            : item?.currentValue}
        </span>
      </div>
    ));
  };

  const getAccountItems = (): Items[] => {
    const itemArrAccount: Items[] = [
      {
        value: '0',
        text: t('allVpa'),
      },
    ];
    merchantDataRdx?.data?.vpaDetails?.map((ele) => {
      return itemArrAccount.push({
        value: ele.vpaId,
        text: ele.vpaId,
      });
    });
    return itemArrAccount;
  };
  const dataTableFunc = () => {
    const tableColumns: TabColumns[] = [
      {
        name: t('transactionDate'),
        key: 'transactionDate',
        headerClasses: 'm-text-sm-bold text-gray-800',
        width: '11rem',
        onFormatter: (item) => {
          return (
            <div className="gap-0 flex flex-col">
              <span className="  flex-1 m-text-md-regular text-gray-900">
                {convertAppDateFormat(
                  item.transactionTime ?? '',
                  TimeFormat.Time,
                  TimeFormat.DateType
                )}
              </span>
              <span className="TableTime flex-1 m-text-md-regular text-gray-600">
                {getFormattedDate(
                  item.transactionTime,
                  appConstants.serverDateFormat,
                  appConstants.timeWithMedian
                )}
              </span>
            </div>
          );
        },
      },
      {
        name: t('customerName'),
        key: 'payerName',
        width: '13rem',
        headerClasses: 'm-text-sm-bold text-gray-800',
        onFormatter(item, _colProps, _key) {
          return (
            <div className="flex items-center gap-x-3 w-[15rem] min-w-full">
              <div className="flex items-center">
                <span className="m-text-md-regular text-gray-900">
                  {item.payerName}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        name: t('amount'),
        key: 'amount',
        width: '11rem',
        headerClasses: 'm-text-sm-bold text-gray-800',
        classes: 'm-text-md-regular text-gray-600',
        onFormatter(item, _colProps, _key) {
          return getINRFormatWithDecimal(Number(item.amount));
        },
      },
      {
        name: t('status'),
        key: 'status',
        width: '11rem',
        headerClasses: 'm-text-sm-bold text-gray-800',
        search: true,
        onFormatter(item, _colProps, _key) {
          return (
            <div className="w-[7rem] min-w-full">
              {item.settlementStatus === STATUS_QR.SETTLED ? (
                <AU_Badge
                  iconName={'check-circle'}
                  iconClass=""
                  className={`bg-blue-50 text-blue-700`}
                  contentClass={`m-text-sm-medium ${
                    item?.settlementStatus as string
                  }-color`}
                >
                  {t('settled')}
                </AU_Badge>
              ) : item.settlementStatus === STATUS_QR.onHold ? (
                <AU_Badge
                  iconName={'stop-circle'}
                  iconClass=""
                  iconColor="transparent"
                  className={` bg-warning_50 text-warning_700`}
                  contentClass={`m-text-sm-medium ${
                    item?.settlementStatus as string
                  }-color`}
                >
                  {t('onHold')}
                </AU_Badge>
              ) : (
                <AU_Badge
                  iconName={'arrow-down'}
                  iconClass="status-icon"
                  className={`success-bg-color`}
                  contentClass={`m-text-sm-medium success-color`}
                >
                  {STATUS_QR.INIT}
                </AU_Badge>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <AU_DataTable
        columns={tableColumns}
        data={filteredList ?? []}
        isShowToolBar={false}
        noData={
          <div className="p-10">
            <div className="flex justify-center items-center">
              <img src={noDataImg} alt="no-payee-bg" />
            </div>
            <div className="text-center mt-1 flex flex-col">
              <span className="m-text-md-regular text-gray-400">
                {t('oopsNothingMessage')}
              </span>
              <span className="m-text-md-medium text-gray-800 mt-1">
                {t('collectionsTableMessage')}
              </span>
            </div>
            <div className="items-center mt-4 flex flex-col">
              <XB_Button
                dataTestId="VIEW-PMNT"
                btnType="secondary-gray"
                iconName="chevron-right"
                iconColor="none"
                iconPos="right"
                classes="text-primary-600 border-primary-600"
                onClick={() => {
                  navigate('/qr-collection/qr-collection-detail');
                }}
              >
                {t('viewCollections')}
              </XB_Button>
            </div>
          </div>
        }
      />
    );
  };

  const dataTableSettlementFunc = () => {
    const tableColumns: TabColumns[] = [
      {
        name: t('transactionDate'),
        key: 'settlementTime',
        headerClasses: 'm-text-sm-bold text-gray-800',
        width: '11rem',
        onFormatter: (item) => {
          return (
            <div className="gap-0 flex flex-col">
              <span className="  flex-1 m-text-md-regular text-gray-900">
                {convertAppDateFormat(
                  item.settlementTime ?? '',
                  'YYYYMMDD',
                  'DD MMM, YYYY'
                )}
              </span>
              <span className="TableTime flex-1 m-text-md-regular text-gray-600">
                {getFormattedDate(
                  item.settlementTime,
                  appConstants.serverDateFormat,
                  appConstants.timeWithMedian
                )}
              </span>
            </div>
          );
        },
      },
      {
        name: t('noOfPayments'),
        key: 'payment',
        width: '13rem',
        headerClasses: 'm-text-sm-bold text-gray-800',
        onFormatter(item, _colProps, _key) {
          return (
            <div className="flex items-center gap-x-3 w-[15rem] min-w-full">
              <div className="flex items-center">
                <span className="m-text-md-regular text-gray-900">
                  {item.payment}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        name: t('amount'),
        key: 'totalAmount',
        width: '11rem',
        headerClasses: 'm-text-sm-bold text-gray-800',
        classes: 'm-text-md-regular text-gray-600',
        onFormatter(item, _colProps, _key) {
          return getINRFormatWithDecimal(Number(item.totalAmount));
        },
      },
      {
        name: t('status'),
        key: 'status',
        width: '11rem',
        headerClasses: 'm-text-sm-bold text-gray-800',
        search: true,
        onFormatter(item, _colProps, _key) {
          return (
            <div className="w-[7rem] min-w-full">
              <AU_Badge
                iconName={'check-circle'}
                iconClass="status-icon"
                className={`bg-blue-50 badge-class text-blue-700`}
                contentClass={`m-text-sm-medium ${
                  item?.status as string
                }-color`}
              >
                {t('settled')}
              </AU_Badge>
            </div>
          );
        },
      },
    ];

    return (
      <AU_DataTable
        columns={tableColumns}
        data={filteredSettlementList ?? []}
        isShowToolBar={false}
        noData={
          <div className="p-10">
            <div className="flex justify-center items-center">
              <img src={noDataImg} alt="no-payee-bg" />
            </div>
            <div className="text-center mt-1 flex flex-col">
              <span className="m-text-md-regular text-gray-400">
                {t('oopsNothingMessage')}
              </span>
              <span className="m-text-md-medium text-gray-800 mt-1">
                {t('collectionsTableMessage')}
              </span>
            </div>
            <div className="items-center mt-4 flex flex-col">
              <XB_Button
                dataTestId="VIEW-PMNT"
                btnType="secondary-gray"
                iconName="chevron-right"
                iconColor="none"
                iconPos="right"
                classes="text-primary-600 border-primary-600 w-auto items-center"
                onClick={() => {
                  navigate('/qr-settlement');
                }}
              >
                {t('viewSettlements')}
              </XB_Button>
            </div>
          </div>
        }
      />
    );
  };
  const handleOnChangeTab = (tabSelectedItem) => {
    if (tabSelectedItem === 0) {
      fetchCollectionList(SETTLEMENT_STATUS);
    } else {
      fetchSettlementList(SETTLED);
    }
  };
  useEffect(() => {
    fetchCollectionList(SETTLEMENT_STATUS);
  }, [vpaId]);

  const handleSettleNowBtn = () => {
    if (merchantDataRdx?.data?.settlementType === settlementType.BOTH) {
      return true;
    } else if (
      merchantDataRdx?.data?.settlementType === settlementType.ON_DEMAND
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {loading && <AU_Loader />}
      {renderErrorModal()}
      <div className="collection-header">
        <div className="flex">
          <div className="w-full">
            <XB_Dropdown
              id="VPA-LIST"
              items={getAccountItems()}
              onChange={(selectedvalue) => {
                selectedvalue === '0'
                  ? setVpaId(null)
                  : setVpaId(selectedvalue);
              }}
              selectedValue="0"
            />
          </div>
        </div>
        <div className="flex w-full max-md:flex-col mt-4">
          {firstRowHeader()}
        </div>
        <div className="flex w-full mt-4">
          <span className="text-white md-regular-md-bold">
            {t('toBeSettledToday', {
              amount: getINRFormatWithDecimal(settledAmountToday),
            })}
          </span>
        </div>
        <XB_Divider classes="my-4 qr-header-divider" size="thick" />
        <div className="flex justify-between items-center w-full mt-4">
          {creditAmountToday === 0 ? (
            <span className="text-white m-text-md-regular max-[560px]:w-[60%] flex gap-1">
              <Icon
                icon="no-payment-icon"
                color="none"
                width={18}
                height={18}
              />
              {t('noNewPayment')}
            </span>
          ) : (
            <span className="text-white md-regular-md-bold max-[560px]:w-[60%]">
              {t('toBeCreditedToday', {
                amount: getINRFormatWithDecimal(creditAmountToday),
              })}
            </span>
          )}
          {creditAmountToday !== 0 &&
            (handleSettleNowBtn()
              ? merchantDataRdx?.data?.vpaDetails[0]?.settlementCycles[0]
                  ?.settlementCycleId !== 0 && (
                  <XB_Button
                    dataTestId="SETL-NOW"
                    btnType="primary"
                    onClick={() => {
                      vpaId ? settleVpaWiseApi() : settleAllApi();
                    }}
                  >
                    {t('settleNow')}
                  </XB_Button>
                )
              : '')}
        </div>
      </div>
      <div>
        <div className="bg-white rounded-xl mt-6">
          <h1 className="text-base font-bold text-gray-800  p-4">
            {t('todayActivities')}
          </h1>
          <AU_Tab
            tablist={tabHeaders}
            tabChangeFunc={(item: number) => {
              setSelectedTab(item);
              handleOnChangeTab(item);
            }}
            tabContentList={[dataTableFunc(), dataTableSettlementFunc()]}
            tabSelected={selectedTab}
          />

          {selectedTab === 0 ? (
            filteredList?.length ? (
              <div className="flex justify-end pr-5 pb-5">
                <XB_Button
                  dataTestId="VIEW-PMNT"
                  btnType="secondary-gray"
                  iconName="chevron-right"
                  iconColor="none"
                  iconPos="right"
                  classes="text-primary-600 border-primary-600"
                  onClick={() => {
                    navigate('/qr-collection/qr-collection-detail');
                  }}
                >
                  {t('viewCollections')}
                </XB_Button>
              </div>
            ) : (
              ''
            )
          ) : filteredSettlementList?.length ? (
            <div className="flex justify-end pr-5 pb-5">
              <XB_Button
                dataTestId="VIEW-PMNT"
                btnType="secondary-gray"
                iconName="chevron-right"
                iconColor="none"
                iconPos="right"
                classes="text-primary-600 border-primary-600"
                onClick={() => {
                  navigate('/qr-settlement');
                }}
              >
                {t('viewSettlements')}
              </XB_Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export const BottomMenu: React.FC<RecentTransactionPropsType> = ({
  currentVpaDetail,
}: RecentTransactionPropsType) => {
  const { t } = useTranslation();

  const bottomSingleTab = (item, index: number) => {
    return (
      <div
        className="flex bottom-single-tab justify-center items-center flex-col bg-gray-25 rounded-lg border-2 py-3 px-2 w-full"
        key={`bottom-tab-${index}`}
      >
        <Icon icon={item.icon} className="qr-bottom-icon" />
        <span className="m-text-md-medium text-gray-700">{t(item.label)}</span>
      </div>
    );
  };

  return (
    <div className="w-full">
      <h1 className="m-display-xl-medium text-gray-800 mt-6 mb-3">
        {t('manageYourBusiness')}
      </h1>
      <div className="flex bg-white rounded-xl p-4 justify-around bottom-menu  gap-4">
        {bottomTab.map((item, index) => bottomSingleTab(item, index))}
      </div>
    </div>
  );
};
export const RightSideBar: React.FC<ShowQrModalPropsType> = ({
  currentVpaDetail,
  setCurrentVpaDetail,
  setShowGenerateQrModal,
  setSelectedAmount,
  setSelectedVpaDropdown,
}: ShowQrModalPropsType) => {
  const { t } = useTranslation();
  const auAlert = useAlertBox();
  const merchantDataRdx = useAppSelector(
    (state: RootState) =>
      state.getMerchantDetail as ResGetMerchantDetails | undefined
  );
  const [selectedVpa, setSelectedVpa] = useState(
    merchantDataRdx?.data?.vpaDetails[0]?.vpaId ?? ''
  );

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [errorPurpose, setErrorPurpose] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [remarksData, setRemarksData] = useState<string>('');
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const configRdx: IConfigDataType[] =
    useAppSelector((configState: RootState) => configState?.config?.data) ?? [];

  const CollectionDataConfig: IFeCollectionConfig = getFEConfig(
    configRdx,
    collectionConfig?.CONFIG_TYPE,
    collectionConfig?.MODULE
  );

  const settlementConfig: ISettlementConfig = getFEConfig(
    configRdx,
    FE_SETTLEMENT_CONFIG_TYPE?.FE_SETTLEMENT_CONFIG,
    FE_SETTLEMENT_CONFIG_TYPE?.MODULE
  );

  useEffect(() => {
    setSelectedVpa(merchantDataRdx?.data?.vpaDetails[0]?.vpaId ?? '');
  }, [merchantDataRdx]);

  const getAccountItems = (): Items[] => {
    const itemArrAccount: Items[] = [];
    merchantDataRdx?.data?.vpaDetails?.map((ele) => {
      return itemArrAccount.push({
        value: ele.vpaId,
        text: ele.vpaId,
      });
    });
    return itemArrAccount;
  };
  const handleQuantityInputChange = (val: any): void => {
    if (
      Number(val) <= Number(DYNAMIC_MAX_LIMIT_QR) &&
      Number(val) >= Number(MIN_LIMIT_QR)
    ) {
      setErrorPurpose('');
      setBtnDisabled(false);
    } else {
      if (val === BLANK) {
        setBtnDisabled(true);
        setErrorPurpose('');
      } else {
        setErrorPurpose(
          t('qrErrorMsg', {
            minAmount: getINRFormatWithDecimal(MIN_LIMIT_QR),
            maxAmount: getINRFormatWithDecimal(DYNAMIC_MAX_LIMIT_QR),
          })
        );
        setBtnDisabled(true);
      }
    }
  };

  const getDynamicQrCodeApi = async () => {
    try {
      setBtnLoading(true);
      const res = await getDynamicQrCode({
        amount,
        mccCode: merchantDataRdx?.data?.merchantProfileDetails?.mcc ?? '',
        payeeName: qrCodeValue?.qrDispalyName ?? '',
        remarks: remarksData,
        qrExpireTime:
          Number(settlementConfig?.dynamicQrExpireTime) ??
          Number(DYNAMIC_QR_EXPIRATION_TIME),
        source: appConstants.SOURCE_OR_CHANNEL,
        vpaName: selectedVpa,
      });
      if (res?.successfulResponse) {
        setShowGenerateQrModal(res?.data);
      } else {
        auAlert.error(res?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    } finally {
      setBtnLoading(false);
    }
  };

  const copyDownloadBtnFunc = () => {
    return (
      <>
        <div className="flex w-full">
          <XB_Button
            dataTestId="COPY-VPA"
            btnType="secondary-gray"
            classes="w-full text-primary-600 border-primary-600"
            iconName={'copyicon'}
            size="xs"
            iconColor="none"
            onClick={() => {
              navigator.clipboard.writeText(selectedVpa ?? '').then(() => {
                auAlert.success(t('copiedSuccessfully'));
              });
            }}
          >
            {t('copyVpa')}
          </XB_Button>
        </div>
        <div className="flex w-full">
          <XB_Button
            dataTestId="DWND"
            btnType="secondary-gray"
            classes="w-full text-primary-600 border-primary-600"
            iconName={'download'}
            size="xs"
            onClick={() => {
              downloadCode();
            }}
          >
            {t('download')}
          </XB_Button>
        </div>
      </>
    );
  };

  const downloadCode = () => {
    const canvas: any = document.getElementById('QR-CODE');
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `qr_code.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const dynamicQrInputContainer = () => {
    return (
      <div className="flex flex-col gap-1 mt-4">
        <h1 className="text-neutral-900 mb-4 m-text-lg-bold">
          {t('collectMoneyThrough')}
        </h1>
        <h2 className="text-black m-text-lg-bold">{t('dynamicQR')}</h2>
        <span className="text-gray-700 m-text-md-regular">
          {t('generateQRCodeDesc')}
        </span>

        <div className="flex mt-4">
          <XB_Input
            id="DYQR-AMNT"
            type="text"
            label={t('amount')}
            prefixIcon="rupees"
            autoComplete="off"
            value={amount}
            classes="w-full"
            placeholder={t('enterAmount')}
            onInputChange={(val) => {
              val.replaceAll(/[^\d]/g, '');
              setAmount(val);
              setSelectedAmount(val);
              handleQuantityInputChange(val);
            }}
            msgType={errorPurpose ? 'error' : 'info'}
            infoMsgText={
              errorPurpose ||
              t('qrErrorMsg', {
                minAmount: getINRFormatWithDecimal(
                  CollectionDataConfig?.dynamicQrLimit?.web?.min ?? 0
                ),
                maxAmount: getINRFormatWithDecimal(
                  CollectionDataConfig?.dynamicQrLimit?.web?.max ?? 0
                ),
              })
            }
            regex={RegEx.dynamicQrAmount}
          />
        </div>
        <div className="flex mt-4">
          <XB_Input
            id="DYQR-RMRK"
            type="text"
            label={t('optionalRemarks')}
            autoComplete="off"
            value={remarksData}
            regex={RegEx.remarkRegex}
            maxLength={MAX_LIMIT_REMARK}
            placeholder={t('enterRemark')}
            onInputChange={(val) => {
              setRemarksData(val);
            }}
            classes="w-full"
            onBlur={() => {
              setRemarksData(remarksData.trim());
            }}
          />
        </div>
        <div className="flex w-full mt-4">
          <XB_Button
            dataTestId="DYQR-GNRT"
            btnType="secondary"
            disabled={btnDisabled}
            showLoader={btnLoading}
            onClick={() => {
              getDynamicQrCodeApi();
            }}
            classes="w-full"
          >
            {t('generate')}
          </XB_Button>
        </div>
      </div>
    );
  };

  const qrCodeValue = merchantDataRdx?.data?.vpaDetails?.find(
    (ele) => ele.vpaId === selectedVpa
  );
  return (
    <div className="w-full bg-white right-side-container rounded-xl p-4 mt-3">
      <h1 className="m-text-lg-bold text-gray-900 mb-1  ">{t('yourQR')}</h1>
      <div className="relative flex justify-center mb-4">
        {qrCodeValue ? (
          <div className="relative">
            <QRCode
              value={
                qrCodeValue?.qrCode?.replace('&am=1.0&', '&am=&') ??
                `upi://pay?pn=${merchantDataRdx?.data?.businessName}&pa=${selectedVpa}`
              }
              includeMargin={true}
              id="QR-CODE"
              size={250}
              imageSettings={{
                src: AuImg,
                excavate: false,
                height: 28,
                width: 28,
              }}
            />
          </div>
        ) : (
          <img src={defaultQR} alt="no-QR" width={220} />
        )}
      </div>
      <div className="flex mb-4">
        <XB_Dropdown
          id="VPA-DROP"
          items={getAccountItems()}
          classes="w-full vpa-dropDown"
          onChange={(val) => {
            setSelectedVpa(val);
            setSelectedVpaDropdown(val);
          }}
          selectedValue={selectedVpa}
        />
      </div>
      <div className="flex mb-3 gap-1">{copyDownloadBtnFunc()}</div>
      <XB_Divider classes="bg-gray-200" />
      {dynamicQrInputContainer()}
    </div>
  );
};

export const QrFilterSectionFunc: React.FC<IFilterSectionProps> = ({
  currentModule,
  itemList,
  filterData,
  applyFilter,
  moduleName,
  onDataSend,
  disableFilters,
  showFilterModalFunc,
}: IFilterSectionProps) => {
  const { t } = useTranslation();

  const showCalendarLblFunc = () => {
    const rangeAvailable = DateRangeChecker(
      new Date(filterData.filterDateRange.startDate),
      new Date(filterData.filterDateRange.endDate),
      currentModule
    );
    return rangeAvailable
      ? rangeAvailable.label
      : `${moment(filterData.filterDateRange.startDate).format(
          appConstants.dateFormatWithCommaSpace
        )} - ${moment(filterData.filterDateRange.endDate).format(
          appConstants.dateFormatWithCommaSpace
        )}`;
  };
  const showTransTypeChip = (type: string) => {
    let afterCloseFilter = {
      transactionType: 'all',
      isIntialPriceFilter:
        filterData.priceRangeFilter.isIntialPriceFilter &&
        filterData.priceRangeFilter.toAmount !== '',
      fromAmount: MIN_LIMIT_QR,
      toAmount: MAX_LIMIT_QR,
    };

    let lblToShow = filterData.priceRangeFilter.transactionType;
    if (type === 'price') {
      afterCloseFilter = {
        transactionType: filterData.priceRangeFilter.transactionType,
        isIntialPriceFilter: booleanValues.false,
        fromAmount: MIN_LIMIT_QR,
        toAmount: MAX_LIMIT_QR,
      };

      lblToShow = `${getINRFormat(
        Number(filterData.priceRangeFilter.fromAmount),
        true
      )} - ${getINRFormat(Number(filterData.priceRangeFilter.toAmount), true)}`;
    }
    return (
      <XB_Chip
        key={`ABC${type}`}
        type="gray"
        classes={`ml-3 chip-button border rounded bg-gray-50 ${
          disableFilters ? '' : 'border-solid border-gray-500'
        }`}
        rightIcon="cross"
        onClick={() => {
          if (!disableFilters) {
            applyFilter('priceRangeFilter', afterCloseFilter);
          }
        }}
      >
        <span className="m-text-md-medium text-gray-700 py-[0.25rem]">
          {lblToShow}
        </span>
      </XB_Chip>
    );
  };
  const showFilterSection = () => {
    return (
      <div className="flex filter-chip-row justify-end mt-3">
        {filterData.priceRangeFilter.isIntialPriceFilter &&
          filterData.priceRangeFilter.toAmount !== '' &&
          showTransTypeChip('price')}
      </div>
    );
  };

  const handleChange = (val) => {
    onDataSend?.(val);
  };
  return (
    <div className="bg-white rounded-xl p-4">
      <div className="lg:flex lg:justify-between">
        <div className="lg:basis-2/4  max-lg:mb-3">
          <XB_Dropdown
            id={getConcatenatedString([moduleName, 'LIST'])}
            items={itemList}
            placeholderOption={t('selectAccount')}
            label={t('selectAccount')}
            disabled={disableFilters}
            search={true}
            selectedValue="0"
            classes="bg-transparent focus:outline-0 text-gray-900 max-w-[18rem] vpa-detail-dropdown"
            onChange={(val) => {
              handleChange(val);
            }}
          />
        </div>
        <div className="lg:basis-2/4 flex flex-col lg:items-end">
          <div className="flex flex-col justify-end">
            <div className="flex w-full justify-start mb-[0.375rem]">
              <label className="m-text-md-regular text-gray-700">
                {t('filters')}
              </label>
            </div>
            <div className="flex filters-actions">
              <XB_Button
                dataTestId={getConcatenatedString([moduleName, 'STMT', 'FLTR'])}
                btnType="secondary-gray"
                iconName="calendar"
                disabled={disableFilters}
                size={'md'}
                classes="filter-button"
                iconPos="left"
                suffixIconHeight={16}
                suffixIconWidth={16}
                onClick={() => {
                  showFilterModalFunc(filterModalTypes.calEnder);
                }}
                suffixIcon={
                  filterData.filterDateRange.isInitialfilterByCalender
                    ? 'close'
                    : ''
                }
                suffixIconClick={() => {
                  let tmpStartFilter = intialFilterQrDateRange;
                  if (currentModule === DEFAULT_MODULE) {
                    tmpStartFilter = qrFilterDateRange;
                  }
                  applyFilter('filterDateRange', tmpStartFilter);
                }}
              >
                <span className="m-text-md-medium text-gray-700">
                  {showCalendarLblFunc()}
                </span>
              </XB_Button>
              <XB_Button
                dataTestId={`${moduleName}-STMT-FLTR`}
                btnType="secondary-gray"
                iconName="filterIcon"
                disabled={disableFilters}
                type="button"
                classes="filter-button ml-3"
                iconPos="left"
                onClick={() => {
                  showFilterModalFunc(filterModalTypes.filTer);
                }}
              >
                <span className="m-text-md-medium text-gray-700">
                  {t('filters')}
                </span>
              </XB_Button>
            </div>
          </div>
        </div>
      </div>
      {showFilterSection()}
    </div>
  );
};

export const RenderClickModal: React.FC<SelectedVal> = ({
  selectedVal,
  showFilterModalFunc,
}: SelectedVal) => {
  const { t } = useTranslation();
  const merchantDataRdx = useAppSelector(
    (state: RootState) =>
      state.getMerchantDetail as ResGetMerchantDetails | undefined
  );
  const bgImgUrl = () => {
    return selectedVal.txnType === transactionTypes[1].value
      ? '/assets/money-receive.svg'
      : '/assets/money-send.svg';
  };

  const auAlert = useAlertBox();
  const copyToClipBoard = (val) => {
    navigator.clipboard.writeText(`${val as string}`);
    auAlert.success(t('copiedSuccessfully'));
  };

  const getTransactionTabData = () => {
    const data: any[] = [];
    data.push({
      key: `${t('referenceNo')}`,
      value: selectedVal.referenceId,
    });

    data.push({
      key: t('transactionDate'),
      value: convertAppDateFormat(
        selectedVal.transactionTime ?? '',
        'YYYYMMDD',
        'DD MMM, YYYY'
      ),
    });

    data.push({
      key: t('transactionTime'),
      value: getFormattedDate(
        selectedVal.transactionTime,
        appConstants.serverDateFormat,
        appConstants.timeWithMedian
      ),
    });

    data.push({
      key: t('to'),
      value: merchantDataRdx?.data?.businessName,
    });
    data.push({
      key: t('from'),
      value: selectedVal.payerName,
    });
    data.push({
      key: t('mobileNumber'),
      value: selectedVal.mobileNumber,
    });
    data.push({
      key: t('transactionAmount'),
      value: Number(selectedVal.amount),
    });
    data.push({ key: t('remarks'), value: selectedVal.remarks });
    return data;
  };

  return (
    <XB_Modal
      headPos="text-left"
      open={true}
      setOpen={() => {
        showFilterModalFunc(BLANK);
      }}
      title={t('transactionDetails')}
      modalBackgroundStyle={{
        backgroundImage: `url(${bgImgUrl()})`,
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 203,
      }}
      body={
        <div className="flex flex-col md:w-[36rem]">
          <div className="flex justify-between">
            <div className="flex-col mt-1 flex w-full ">
              <div className="m-text-sm-regular text-gray-500">
                {t('youHaveReceived')}
              </div>
              <span className="text-green-700 m-display-xl-bold">
                {getINRFormatWithDecimal(Number(selectedVal.amount))}
              </span>
              <span className="m-text-sm-medium text-gray-400">
                {amountTranslator(selectedVal?.amount ?? '').replace(
                  'In Words : ',
                  ''
                )}
              </span>
              <span className="m-text-sm-medium text-gray-700">
                {`on ${convertAppDateFormat(
                  selectedVal.transactionTime ?? '',
                  'YYYYMMDD',
                  'DD MMM, YYYY'
                )} at ${getFormattedDate(
                  selectedVal.transactionTime,
                  appConstants.serverDateFormat,
                  appConstants.timeWithMedian
                )}`}
              </span>
              {selectedVal.settlementStatus === STATUS_QR.SETTLED ? (
                <XB_InlineMessage
                  type="info"
                  title={''}
                  message={`${getINRFormatWithDecimal(
                    Number(selectedVal.amount)
                  )} ${t('settledOn')} ${convertAppDateFormat(
                    selectedVal.transactionTime ?? '',
                    'YYYYMMDD',
                    'DD MMM, YYYY'
                  )} at ${getFormattedDate(
                    selectedVal.transactionTime,
                    appConstants.serverDateFormat,
                    appConstants.timeWithMedian
                  )}`}
                  icon="info-circle"
                  classes="w-full mt-6 inline-message"
                />
              ) : selectedVal.settlementStatus === STATUS_QR.onHold ? (
                <XB_InlineMessage
                  type="warning"
                  title={`${getINRFormatWithDecimal(
                    Number(selectedVal.amount)
                  )} ${t('onHoldContent')}`}
                  message={t('onHoldMessage')}
                  icon="warning"
                  classes="w-full mt-6 inline-message"
                />
              ) : (
                <XB_InlineMessage
                  type="info"
                  title={''}
                  message={`${getINRFormatWithDecimal(
                    Number(selectedVal.amount)
                  )} ${willBeSettled}`}
                  icon="info-circle"
                  classes="w-full mt-6 inline-message"
                />
              )}
            </div>

            <div>
              {selectedVal.settlementStatus === STATUS_QR.SETTLED ? (
                <AU_Badge
                  iconName={'check-circle'}
                  iconClass="status-icon"
                  className={`bg-blue-50 text-blue-700`}
                  contentClass={`m-text-sm-medium ${
                    selectedVal?.settlementStatus as string
                  }-color`}
                >
                  {t('settled')}
                </AU_Badge>
              ) : selectedVal.settlementStatus === STATUS_QR.onHold ? (
                <AU_Badge
                  iconName={'stop-circle'}
                  iconClass="status-icon"
                  iconColor="transparent"
                  className={` bg-warning_50 text-warning_700 w-[5rem]`}
                  contentClass={`m-text-sm-medium ${
                    selectedVal?.settlementStatus as string
                  }-color`}
                >
                  {t('onHold')}
                </AU_Badge>
              ) : (
                <AU_Badge
                  iconName={'arrow-down'}
                  iconClass="status-icon"
                  className={`success-bg-color`}
                  contentClass={`m-text-sm-medium success-color`}
                >
                  {STATUS_QR.INIT}
                </AU_Badge>
              )}
            </div>
          </div>

          <div className="mt-3 mb-3">
            <XB_Divider size="thin"></XB_Divider>
          </div>
          <div className="flex mt-3 justify-between">
            <div className="m-text-sm-regular text-gray-500">{t('to')}</div>
            <div className="m-text-lg-medium text-gray-800 ml-auto flex flex-col">
              <p className="self-end">{merchantDataRdx?.data?.businessName}</p>
              <span className="m-text-md-regular text-gray-600">
                {selectedVal.payeeVpaId}
              </span>
            </div>
          </div>
          <div className="mt-3 flex justify-between">
            <div className="m-text-sm-regular text-gray-500">{t('from')}</div>
            <div className="m-text-lg-medium text-gray-800  ml-auto flex flex-col">
              <p className="self-end"> {selectedVal.payerName}</p>
              <span className="m-text-md-regular text-gray-600">
                {selectedVal.payerVpaId} | UPI Scan & Pay
              </span>
            </div>
          </div>

          <div className="mt-3 mb-3">
            <XB_Divider size="thin"></XB_Divider>
          </div>
          <div className="flex mt-3">
            <div className="m-text-sm-regular text-gray-500">
              {t('mobileNumber')}
            </div>
            <div className="m-text-lg-medium text-gray-800 ml-auto">
              {selectedVal.mobileNumber}
            </div>
          </div>
          <div className="flex mt-3">
            <div className="m-text-sm-regular text-gray-500">
              {t('remarks')}
            </div>
            <div className="m-text-lg-medium text-gray-800 ml-auto">
              {selectedVal.remarks}
            </div>
          </div>

          <div className="mt-3 mb-3">
            <XB_Divider size="thin"></XB_Divider>
          </div>

          <div className="flex mt-3">
            <div className="m-text-sm-regular text-gray-500">
              {t('referenceNo')}
            </div>
            <div className="pop-up-value ml-auto">
              <p className="m-text-lg-medium text-gray-800 flex items-center">
                {selectedVal.referenceId}

                <XB_Button
                  iconName="copyorange"
                  classes="p-0 ml-2 copy-icon"
                  onClick={() => copyToClipBoard(selectedVal.referenceId)}
                  dataTestId="COPY"
                  btnType="tertiary"
                />
              </p>
            </div>
          </div>
        </div>
      }
      actions={
        <AU_StatementPdfDownload
          closeModal={() => {
            showFilterModalFunc(BLANK);
          }}
          data={getTransactionTabData()}
          fileName={`TxnRpt${moment().format('_DD_MM_YYYY_hh_mm')}`}
        />
      }
    />
  );
};
export const filterFooterBtn = (
  applyCancelBtnLbl,
  showFilterModalFunc,
  applyFilter,
  keyToUpdate,
  valueToUpdate
) => {
  const totalDiff = moment(valueToUpdate.endDate).diff(
    moment(valueToUpdate.startDate),
    'days'
  );
  return (
    <div className="flex gap-x-3 justify-end">
      <XB_Button
        dataTestId="CNCL"
        btnType="secondary-gray"
        classes="cancel-button w-3/12"
        onClick={() => {
          showFilterModalFunc(BLANK);
        }}
      >
        {applyCancelBtnLbl.cancel}
      </XB_Button>
      <XB_Button
        dataTestId="APLY"
        btnType="primary"
        classes="w-3/12"
        disabled={totalDiff > 365}
        onClick={() => {
          applyFilter(keyToUpdate, valueToUpdate);
        }}
      >
        {applyCancelBtnLbl.apply}
      </XB_Button>
    </div>
  );
};
export const CalendarModalUIFunc: React.FC<CalenderModalProps> = ({
  currentModule,
  applyFilter,
  showFilterModalFunc,
}: CalenderModalProps) => {
  const [startEndDate, setStartEndDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const { t } = useTranslation();
  const applyCancelBtnLbl = { cancel: t('cancel'), apply: t('apply') };

  return (
    <XB_Modal
      setOpen={() => {
        showFilterModalFunc(BLANK);
      }}
      open={true}
      body={
        <div>
          <AU_DateRangePicker
            id="CLDR"
            moduleType={currentModule}
            callBackFunc={(item) => {
              setStartEndDate({
                startDate: item.startDate,
                endDate: item.endDate,
              });
            }}
          />
        </div>
      }
      actions={filterFooterBtn(
        applyCancelBtnLbl,
        showFilterModalFunc,
        applyFilter,
        'filterDateRange',
        {
          isInitialfilterByCalender: true,
          startDate: startEndDate.startDate,
          endDate: startEndDate.endDate,
        }
      )}
    />
  );
};

export const FilterUI: React.FC<CalenderModalProps> = ({
  applyFilter,
  filterValues,
  showFilterModalFunc,
}: CalenderModalProps) => {
  const { t } = useTranslation();
  const [priceFilter, setPriceFilter] = useState(
    filterValues?.priceRangeFilter
  );
  const disableCheck = () => {
    if (
      priceFilter?.toAmount === '0' ||
      (priceFilter?.fromAmount === BLANK && priceFilter?.toAmount === BLANK)
    )
      return false;

    let tobeReturn =
      priceFilter?.fromAmount === BLANK && priceFilter?.toAmount === BLANK;
    if (priceFilter?.toAmount !== BLANK) {
      tobeReturn =
        Number(priceFilter?.toAmount) >= Number(priceFilter?.fromAmount);
    }
    return tobeReturn;
  };
  return (
    <XB_Modal
      headPos="text-left"
      setOpen={() => {
        showFilterModalFunc(BLANK);
      }}
      title={t('filter')}
      open={true}
      body={
        <div className="flex flex-col md:w-[34rem]">
          <div className="amount-range-container flex flex-col">
            <h5 className="text-gray-700 font-normal text-sm my-3">
              {t('amountRange')}
            </h5>
            <div className="flex justify-evenly">
              <XB_Input
                type="text"
                id="FROM"
                regex={RegEx.onlyDigit}
                label={t('fromCapitalised')}
                autoComplete="off"
                maxLength={inputValidation.maxLengthPrice}
                placeholder={getINRFormat(
                  Number(filterPricePlaceholderValues.fromAmount),
                  false
                )}
                value={
                  priceFilter?.fromAmount !== BLANK
                    ? priceFilter?.fromAmount
                    : BLANK
                }
                onInputChange={(val) => {
                  if (
                    inputValidation.maxAmountToFilter >= Number(val) &&
                    priceFilter
                  ) {
                    setPriceFilter({
                      ...priceFilter,
                      fromAmount: val,
                    });
                  }
                }}
                prefixChild={
                  <AU_Amountdropdown
                    classes={'w-6/12 py-0 my-0'}
                    id="startAmount"
                  />
                }
              />
              <span className="self-center px-4">-</span>
              <XB_Input
                label={t('toCapitalised')}
                id="TO"
                regex={RegEx.onlyDigit}
                maxLength={inputValidation.maxLengthPrice}
                placeholder={getINRFormat(
                  Number(filterPricePlaceholderValues.toAmount),
                  false
                )}
                value={
                  priceFilter?.toAmount !== BLANK
                    ? priceFilter?.toAmount
                    : BLANK
                }
                onInputChange={(val) => {
                  if (
                    inputValidation.maxAmountToFilter >= Number(val) &&
                    priceFilter
                  ) {
                    setPriceFilter({
                      ...priceFilter,
                      toAmount: val,
                    });
                  }
                }}
                prefixChild={
                  <AU_Amountdropdown classes={'w-6/12'} id="endAmount" />
                }
              />
            </div>
          </div>
        </div>
      }
      actions={
        <div className="flex gap-x-3">
          <XB_Button
            dataTestId="CNCL"
            btnType="secondary-gray"
            classes="w-6/12"
            onClick={() => {
              showFilterModalFunc(BLANK);
            }}
          >
            {t('cancel')}
          </XB_Button>
          <XB_Button
            dataTestId="APLY"
            btnType="primary"
            classes="w-6/12"
            onClick={() => {
              if (priceFilter) {
                priceFilter.isIntialPriceFilter =
                  priceFilter?.toAmount !== ''
                    ? booleanValues.true
                    : booleanValues.false;
                applyFilter('priceRangeFilter', priceFilter);
              }
            }}
            disabled={!disableCheck()}
          >
            {t('apply')}
          </XB_Button>
        </div>
      }
    />
  );
};

export const EmailModal: React.FC<EmailModalProp> = ({
  showFilterModalFunc,
  onDownloadPress,
}: EmailModalProp) => {
  const { t } = useTranslation();
  const customerAccounts = useAppSelector(
    (state: RootState) => state.customerAccounts
  );
  const emailAddress =
    customerAccounts?.data?.CustomerAccount?.[0].CustomerBasicInquiry
      ?.EmailAddress;
  const initEmails = emailAddress ? [emailAddress] : [];
  const [emailList, setEmailList] = useState<string[]>(initEmails);
  const [stateDownloadType, setStateDownloadType] = useState<string>(
    downloadFormatOptions.XLS
  );
  const [emailError, setEmailError] = useState(emailAddress?.length === 0);

  const handleEmailOnChange = (index: number, val: string) => {
    const updatedEmails = [...emailList];
    updatedEmails[index] = val;
    setEmailList(updatedEmails);
    if (!RegEx.email.test(val)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  return (
    <XB_Modal
      open={true}
      title={t('requestStatement')}
      subTitle={t('statementSendOnEmail')}
      img={<Icon icon="sendOrangeIcon" color="none" width={48} height={48} />}
      setOpen={() => showFilterModalFunc(BLANK)}
      body={
        <div>
          <>
            {emailList.map((email, index: number) => {
              return (
                <div key={`email-${index.toString()}`} className="mt-2">
                  <XB_Input
                    placeholder={t('enterEmailAddress')}
                    prefixIcon="mail"
                    maxLength={appCharLimit.MAX_EMAIL}
                    minLength={appCharLimit.MIN_EMAIL}
                    regex={RegEx.email}
                    value={email}
                    onChange={(e) => {
                      handleEmailOnChange(index, e.target.value);
                    }}
                    autoComplete="off"
                    id="EML-STMT"
                  />
                </div>
              );
            })}
            <XB_Button
              dataTestId="ADD-ANTR"
              btnType="primary-underline"
              classes="mt-3 no-underline"
              disabled={emailList?.length >= 3}
              onClick={() => {
                if (
                  emailList?.length < 3 &&
                  emailList[emailList?.length - 1] !== ''
                ) {
                  setEmailList([...emailList, '']);
                }
              }}
            >
              {`+ ${t('addAnother')}`}
            </XB_Button>
          </>
          <div className="flex-col">
            <h5 className="mb-2 mt-5 m-text-md-regular">{t('selectFormat')}</h5>
            <div className="flex gap-6">
              {reverseFormatButtons.map((radioItem, index: number) => {
                return (
                  <XB_Radio
                    label={t(radioItem.toLocaleLowerCase())}
                    rdSize="sm"
                    key={`email-${index.toString()}`}
                    id={`EML-${radioItem.toString()}`}
                    isChecked={
                      stateDownloadType === downloadFormatOptions[radioItem]
                    }
                    onClick={(_e) => {
                      setStateDownloadType(downloadFormatOptions[radioItem]);
                    }}
                    name="emailFormatRadio"
                    classes="items-center"
                  />
                );
              })}
            </div>
          </div>
        </div>
      }
      actions={
        <div className="flex gap-1">
          <XB_Button
            dataTestId="CNCL"
            classes="w-6/12"
            btnType="secondary-gray"
            onClick={() => {
              showFilterModalFunc(BLANK);
            }}
          >
            {t('cancel')}
          </XB_Button>
          <XB_Button
            dataTestId="SEND-STMT"
            classes="w-6/12"
            disabled={emailError}
            btnType="primary"
            onClick={() => {
              onDownloadPress(stateDownloadType, true, emailList);
            }}
          >
            {t('sendStatement')}
          </XB_Button>
        </div>
      }
    />
  );
};

export interface ErrorProps {
  isOpen: boolean;
  setOpen: (args) => void;
  data?: {
    mobileNumber?: string;
    refNo?: string;
    emailAddress?: string;
  };
  isSuccess?: boolean;
  tryAgainFn: () => void;
}

export const CrmLeadSuccessModal: React.FC<ErrorProps> = ({
  isOpen,
  setOpen,
  data,
  isSuccess = true,
  tryAgainFn,
}: ErrorProps) => {
  const { t } = useTranslation();
  const auAlert = useAlertBox();
  const handleCopyReqId = () => {
    navigator.clipboard
      .writeText(data?.refNo ?? '')
      .then(() => {
        auAlert.success(t('copiedSuccessfully').toString());
      })
      .catch(() => {
        auAlert.error(t('defaultError').toString());
      });
  };

  return (
    <XB_Modal
      open={isOpen}
      headPos="text-center"
      title={
        isSuccess ? t('requestForAUQRSubmitted') : t('auQRRequestNotSubmitted')
      }
      subTitle={isSuccess ? t('AUQRReqSuccessDesc') : t('AUQRReqFailureDesc')}
      maxWidth="25rem"
      setOpen={(open) => setOpen(open)}
      body={
        <div>
          {isSuccess && (
            <div className="flex flex-col gap-y-3">
              <div className="flex justify-between">
                <h5 className="m-text-md-regular text-gray-600">
                  {t('mobileNumber')}
                </h5>
                <p className="m-text-md-medium text-gray-900">
                  {maskMobileNumber(data?.mobileNumber ?? '')}
                </p>
              </div>
              <div className="flex justify-between">
                <h5 className="m-text-md-regular text-gray-600">
                  {t('emailId')}
                </h5>
                <p className="m-text-md-medium text-gray-900">
                  {maskEmailId(data?.emailAddress ?? '')}
                </p>
              </div>
            </div>
          )}
          <div className="bg-gray-50 rounded border-2 border-gray-200 flex gap-x-3 p-2 mt-2">
            <div>
              <p className="m-text-sm-medium text-gray-600">
                {t('referenceNo')}
              </p>
              <h3 className="m-text-md-medium text-gray-800">
                {data?.refNo ?? ''}
              </h3>
            </div>
            <XB_Button
              dataTestId="COPY-REFN"
              iconName="copyorange"
              btnType="tertiary"
              size="sm"
              classes="cursor-pointer text-orange-600"
              onClick={() => handleCopyReqId()}
            />
          </div>
        </div>
      }
      img={
        <div className="success-icon-div items-center justify-center flex">
          <Icon
            icon={isSuccess ? 'success-icon-component' : 'failedIcon'}
            height={123}
            width={123}
            color="none"
          />
        </div>
      }
      actions={
        <div className="flex flex-col gap-y-3">
          {!isSuccess && (
            <XB_Button
              dataTestId="TRY-AGAN"
              classes="w-full"
              onClick={() => {
                setOpen(false);
                tryAgainFn();
              }}
              btnType="primary"
            >
              {t('tryAgain')}
            </XB_Button>
          )}
          <XB_Button
            dataTestId="CLSE"
            classes="w-full"
            onClick={() => {
              setOpen(false);
            }}
            btnType="secondary-gray"
          >
            {t('close')}
          </XB_Button>
        </div>
      }
    />
  );
};

export interface ModalProps {
  isOpen: boolean;
  setOpen: (args) => void;
  callBack: () => void;
}

export const QrOnboardingNotAllowedModal = ({
  isOpen,
  setOpen,
  callBack,
}: ModalProps) => {
  const { t } = useTranslation();

  return (
    <XB_Modal
      open={isOpen}
      setOpen={(open) => setOpen(open)}
      img={
        <AU_RoundBox boxClass="bg-orange-50">
          <Icon
            width={48}
            height={48}
            className="text-orange-600"
            icon="alerttriangle"
          />
        </AU_RoundBox>
      }
      title={t('restrictionForQrOnboarding')}
      subTitle={t('qrRestrictionForEmployeeDesc')}
      maxWidth="25rem"
      backdropClose={false}
      actions={
        <XB_Button
          classes="w-full"
          dataTestId="CLSE"
          btnType="primary"
          onClick={callBack}
        >
          {t('close')}
        </XB_Button>
      }
    />
  );
};
