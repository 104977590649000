import { useEffect } from 'react';

import { useAppDispatch } from '@core-stores';
import { setBrowserNavigation } from '@src/core-stores/slices/init.slice';

export function useBrowserNavigation(enablePageBrowserNavigation = true) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBrowserNavigation({ page: enablePageBrowserNavigation }));
    return () => {
      dispatch(setBrowserNavigation({ page: false }));
    };
  }, []);
}
