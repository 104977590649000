import { useTranslation } from 'react-i18next';

import { ButtonProps, Icon, XB_Button, XB_Modal } from '@core-components/atoms';
import { useAlertBox } from '@core-hooks';

interface RefErrorModalProps {
  title?: string;
  subTitle?: string;
  refNo?: string;
  leftBtnProps?: ButtonProps;
  rightBtnProps?: ButtonProps;
  isOpen: boolean;
  setOpen: (args) => void;
  isSuccess?: boolean;
}

export const AU_RefErrorModal = ({
  isOpen,
  setOpen,
  title = 'somethingWentWrong',
  subTitle = 'weAreWorkingOnFixing',
  leftBtnProps,
  rightBtnProps,
  refNo,
  isSuccess = false,
}: RefErrorModalProps) => {
  const { t } = useTranslation();
  const auAlert = useAlertBox();

  const handleCopyRefNumber = () => {
    if (refNo) {
      navigator.clipboard
        .writeText(refNo?.trim())
        .then(() => {
          auAlert.success(t('copiedSuccessfully').toString());
        })
        .catch(() => {
          auAlert.error(t('defaultError').toString());
        });
    }
  };

  return (
    <XB_Modal
      open={isOpen}
      setOpen={(open) => setOpen(open)}
      maxWidth="25rem"
      img={
        <Icon
          icon={isSuccess ? 'outline-circle-check' : 'outline-warning'}
          color="none"
          width={48}
          height={48}
        />
      }
      title={t(title)}
      subTitle={t(subTitle)}
      backdropClose={false}
      additionSubTitle={
        refNo ? (
          <div className="bg-gray-50 rounded border-2 border-gray-200 flex gap-x-3 p-2 my-3">
            <div>
              <p className="m-text-sm-medium text-gray-600">
                {t('referenceNo')}
              </p>
              <h3 className="m-text-md-medium text-gray-800">{refNo}</h3>
            </div>
            <XB_Button
              dataTestId="COPY-REFN"
              iconName="copyorange"
              btnType="tertiary-gray"
              size="sm"
              classes="cursor-pointer text-orange-600"
              onClick={() => handleCopyRefNumber()}
            />
          </div>
        ) : null
      }
      actions={
        <div className="flex gap-x-3">
          {leftBtnProps && !isSuccess && (
            <XB_Button
              classes="w-1/2"
              btnType="secondary"
              onClick={() => {
                setOpen(false);
              }}
              {...leftBtnProps}
            >
              {leftBtnProps.children
                ? t(leftBtnProps.children as string)
                : t('cancel')}
            </XB_Button>
          )}

          {rightBtnProps && (
            <XB_Button classes="w-1/2" btnType="primary" {...rightBtnProps}>
              {rightBtnProps.children
                ? t(rightBtnProps.children as string)
                : t('tryAgain')}
            </XB_Button>
          )}
        </div>
      }
    />
  );
};
