import { useEffect, useRef } from 'react';

type ThrottleFunction = (...args: any[]) => void;

export const useThrottle = (
  callbackFn: ThrottleFunction,
  delay: number
): ThrottleFunction => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const throttledCallback: ThrottleFunction = (...args) => {
    if (!timeoutRef.current) {
      callbackFn(...args);
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
      }, delay);
    }
  };

  return throttledCallback;
};
