import IcoMoon, { IconProps } from 'react-icomoon';

import { Icon } from '@core-components/atoms';

export const AU_Ttficon = ({
  type,
  ...props
}: IconProps & { type: 'country' | 'bank' }) => {
  let iconSet;
  if (type === 'country') {
    iconSet = require('./country-selection.json');
  } else {
    iconSet = require('./bank-selection.json');
  }
  const iconList = iconSet.icons.map((item) => {
    return item.properties.name;
  });
  const { icon } = { ...props };
  return (
    <>
      {type !== 'country' && !iconList.includes(icon) ? (
        <Icon
          icon={'bank-outline'}
          className="au-ttficon text-orange-600"
          color="none"
        />
      ) : (
        <IcoMoon
          iconSet={iconSet}
          {...props}
          className="au-ttficon"
          role="au-ttficon"
        />
      )}
    </>
  );
};
