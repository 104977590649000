import { useTranslation } from 'react-i18next';

import { ButtonProps, XB_Button } from '@core-components/atoms';
import { getColor } from '@core-utils';

import './AU_VerticalCard.style.scss';

interface VerticalCardProps {
  key: string;
  bgImgUrl: string;
  title: string;
  subtitle: string;
  offerBackgroundColor?: string;
  offerHeadingColor?: string;
  offerSubheadingColor?: string;
  primaryBtnProps: ButtonProps;
}

export const AU_VerticalCard = ({
  key,
  bgImgUrl,
  title = '',
  subtitle = '',
  offerBackgroundColor = '',
  offerHeadingColor = '#ffffff',
  offerSubheadingColor = '#ffffff',
  primaryBtnProps,
}: VerticalCardProps) => {
  const { t } = useTranslation();
  return (
    <div
      key={key}
      style={{ backgroundColor: getColor(offerBackgroundColor) }}
      className="vertical-card"
      onClick={primaryBtnProps.onClick}
    >
      {bgImgUrl && <img src={bgImgUrl ?? ''} className="vertical-image" />}
      <div className="flex flex-col z-50 relative h-3/5 self-end">
        <div className={`flex flex-col z-50 w-5/5 h-3/5 relative grow mb-4`}>
          <span
            style={{ color: getColor(offerHeadingColor) }}
            className={`text-left title-container pl-2 pr-2 m-text-md-bold text-red-800`}
          >
            {t(title)}
          </span>
          <span
            style={{ color: getColor(offerSubheadingColor) }}
            className={`text-left subtitle-container pl-2 pr-2 m-text-sm-regular text-gray-900`}
          >
            {t(subtitle)}
          </span>
        </div>
        <div className="mt-1 bottom-0 flex flex-row w-full pl-2 pr-2 pb-2">
          {primaryBtnProps.children && (
            <XB_Button
              backgroundColor={getColor(primaryBtnProps.backgroundColor ?? '')}
              classes="px-2 py-1 rounded w-full"
              {...primaryBtnProps}
            >
              {primaryBtnProps.children
                ? t(primaryBtnProps.children as string)
                : ''}
            </XB_Button>
          )}
        </div>
      </div>
    </div>
  );
};
