import { createSlice } from '@reduxjs/toolkit';

export interface IToggleTypeInterface {
  toggleType: 'STORE' | 'BANK';
}

const initialState: IToggleTypeInterface = {
  toggleType: 'STORE',
};

const dashboardTypeSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardType(state, { payload }) {
      state.toggleType = payload;
    },
  },
});
export const { setDashboardType } = dashboardTypeSlice.actions;
export default dashboardTypeSlice.reducer;
