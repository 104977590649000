import { createSlice } from '@reduxjs/toolkit';

import { IResOnboardingDebitCardList } from '../../models/account.model';
import { onboardingDebitCardList } from '../thunk/onboarding-debit-card.thunk';

const initialState: IResOnboardingDebitCardList = {
  loading: false,
  data: undefined,
  error: undefined,
};

const onboardingDebitCardListSlice = createSlice({
  name: 'onboardingDebitCard',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(onboardingDebitCardList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(onboardingDebitCardList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.data = payload.data;
    });

    builder.addCase(onboardingDebitCardList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as undefined;
      state.data = undefined;
    });
  },
});

export default onboardingDebitCardListSlice.reducer;
