export const genderMapping = {
  M: 'Male',
  F: 'Female',
  T: 'Transgender',
};
export enum CommunicationScreen {
  ADDRESSLIST = 'ADDRESSLIST',
  ENTTITYADDRESS = 'ENTTITYADDRESS',
  COMMUNICATIONADDRESS = 'COMMUNICATIONADDRESS',
}
export const addressTypeMapping = {
  COMMUNICATION_ADDRESS: 'COMMUNICATION_ADDRESS',
  REGISTRATION_ADDRESS: 'REGISTRATION_ADDRESS',
};

export const poeConstants = {
  MIN_DOCUMENT_NUMBER: 2,
  MAX_DOCUMENT_NUMBER: 35,
};

export const onboardingConsents = [
  'pan-consent',
  'full-kyc',
  'aadhar-consent',
  'fatca',
  'bank-tnc',
  'dob',
];

export const consentValidTillDate = '2024-08-29T13:05:24.735Z';

export enum qrLimitModel {
  title = 'qrLimitReached',
  subtitle = 'qrLimitReachedDesc',
  action = 'iUnderstand',
}

export enum qrAlreadyLinkedModel {
  title = 'qrAlreadyLinked',
  subtitle = 'pleaseContactNearestBranch',
  action = 'iUnderstand',
}

export enum errorModel {
  title = '',
  subtitle = 'ONB0400',
  action = 'ok',
}

export enum serverErrorModel {
  title = 'internalServerError',
  subtitle = 'apiError',
  action = 'ok',
}

export enum cifErrorModel {
  title = 'error',
  subtitle = 'cifDataNotAvailable',
  action = 'ok',
}
export enum maxOTPAttemptModel {
  title = '',
  subtitle = 'maxOtpAttempts',
  action = 'ok',
}

export enum somethingWrongModel {
  title = 'somethingWentWrong',
  subtitle = '',
  action = 'ok',
}

export enum exitModel {
  title = 'exitJourney',
  subtitle = 'dataLost',
  action = 'saveLater',
}

export const nameErrors = {
  inputValueValidation: 'Value should be between 2 to 85',
  noSpecialChar: 'No special character allowed, Only Dot Allowed',
  noConsecutiveDots: 'No consecutive dots allowed',
  dotNotAllowedInEnd: 'Dot not allowed at the end of the name',
  noConsecutiveSpaces: 'No Consecutive spaces allowed',
};

export const FLAG = {
  Y: 'Y',
  N: 'N',
};

export const cifStaffStatus = {
  NOT_APPLICABLE: 'Not Applicable',
  NOT_VERIFIED: 'Not Verified',
  YES: 'Yes',
  NO: 'No',
};
