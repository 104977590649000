/* eslint-disable react/prop-types */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import { logEvent as eventLog, getAnalytics } from 'firebase/analytics';

import { logError, Platform } from '@core-utils';

let lastLoggedEventHolder = '';
const defaultValues = {
  journeyId: '',
  screenId: '',
  getEventId: (event: string) => event,
  getCustomEventId: (eventId: string) => eventId,
  getLastLoggedEvent: () => {
    // This is intentional
  },
  setJourneyId: (id) => id,
  setScreenId: (id) => id,
  logCustomEvent: (_e: string, _d?: any) => {
    // This is intentional
  },
  logEvent: (_event: React.MouseEvent | string) => {
    // This is intentional
  },
  logScreenView: (_id: string, _name: string) => {
    // This is intentional
  },
  subscribeEvent: (_eventName: string, _callback) => {
    return () => {
      // This is intentional
    };
  },
  publishEvent: (_eventName: string, _data?: any) => {
    // This is intentional
  },
};
const { REACT_APP_LOG_ANALYTICS, REACT_APP_FIREBASE_IDENTIFIER } = process.env;
const logInAnalytics = REACT_APP_LOG_ANALYTICS;
export const EventContext = createContext(defaultValues);

export const EventProvider = ({ children }) => {
  const platform = Platform();
  const [journeyId, setJourneyId] = useState('');
  const [screenId, setScreenId] = useState('');
  const customEventList = ['REG_SEL-LNG', 'REG_SCSS'];
  const { current: events } = useRef({});

  const getEventId = (event: string) => {
    return `${platform}_${journeyId}_${screenId}_${event}`;
  };
  const getCustomEventId = useCallback(
    (event: string) => {
      if (journeyId && screenId) {
        return `${journeyId}_${screenId}_${event}`;
      }
      return '';
    },
    [journeyId, screenId]
  );
  const getLastLoggedEvent = () => {
    return sessionStorage.getItem('X-EVENT-TRACE');
  };

  const logCustomEvent = (screen: string) => {
    if (!customEventList.includes(screen)) {
      return false;
    }
    try {
      const eventId = `${screen}_CSTM`;
      const regex = /[\W]/gm;
      const firebaseEventId = eventId.replace(regex, '_');
      const analytics = getAnalytics();
      const eventName = REACT_APP_FIREBASE_IDENTIFIER
        ? `${REACT_APP_FIREBASE_IDENTIFIER}_${firebaseEventId}`
        : firebaseEventId;
      logInAnalytics && eventLog(analytics, eventName);
    } catch (error) {
      logError('CUSTOM_EVENT ERROR', error);
    }
  };

  const logScreenView = (id: string, name: string) => {
    try {
      const regex = /[\W]/gm;
      const eventName = 'screen_view' as string;
      const screenName = id.replace(regex, '_');
      const analytics = getAnalytics();
      const firebaseScreenId = REACT_APP_FIREBASE_IDENTIFIER
        ? `${REACT_APP_FIREBASE_IDENTIFIER}_${screenName}_ON_LOAD`
        : screenName;
      logInAnalytics &&
        eventLog(analytics, eventName, {
          firebase_screen: firebaseScreenId,
          firebase_screen_class: name,
        });
    } catch (error) {
      logError('CLICK_STREAM ERROR', error);
    }
  };

  const logEvent = (event: React.MouseEvent | string) => {
    const eventId =
      typeof event === 'string'
        ? event
        : (event.target as HTMLElement).getAttribute('data-testid');
    if (eventId) logEventOnAnalatics(eventId);
  };

  const setLastLoggedEvent = (eventId: string) => {
    sessionStorage.setItem('X-EVENT-TRACE', eventId);
  };

  const logEventOnAnalatics = (eventId: string) => {
    const regex = /[\W]/gm;
    const firebaseEventId = eventId.replace(regex, '_');
    if (lastLoggedEventHolder !== firebaseEventId && firebaseEventId) {
      lastLoggedEventHolder = firebaseEventId;
      setLastLoggedEvent(firebaseEventId);
      const analytics = getAnalytics();
      const eventName = REACT_APP_FIREBASE_IDENTIFIER
        ? `${REACT_APP_FIREBASE_IDENTIFIER}_CLICK_STREAM`
        : 'CLICK_STREAM';
      logInAnalytics &&
        eventLog(analytics, eventName, { X_Click_Trace: firebaseEventId });
    }
  };

  const unsubscribeEvent = (eventName, callback) => {
    if (events[eventName]) {
      events[eventName] = events[eventName].filter((cb) => cb !== callback);
    }
  };

  const subscribeEvent = (eventName, callback) => {
    if (!events[eventName]) {
      events[eventName] = [];
    }
    events[eventName].push(callback);
    return () => unsubscribeEvent(eventName, callback);
  };
  const publishEvent = (eventName, data) => {
    if (events[eventName]) {
      events[eventName].forEach((callback) => callback(data));
    }
  };

  return (
    <EventContext.Provider
      value={{
        getEventId,
        getLastLoggedEvent,
        journeyId,
        screenId,
        setJourneyId,
        setScreenId,
        logEvent,
        getCustomEventId,
        logCustomEvent,
        logScreenView,
        subscribeEvent,
        publishEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEventProvider = () => {
  return useContext(EventContext);
};
