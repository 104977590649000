import { useNavigate } from 'react-router-dom';

import { XB_Button } from '@core-components/atoms';
import { useEventProvider } from '@core-providers';
import { BackButtonProps } from '@core-stores';

import './AU_BackButton.style.scss';

export const AU_BackButton = ({
  url,
  onBackBtnAction,
  onClickAction,
  classes,
  ...props
}: BackButtonProps) => {
  const navigation = useNavigate();
  const { publishEvent } = useEventProvider();
  const redirection = () => {
    if (url) {
      navigation(url);
    } else {
      publishEvent('PREV_PAGE');
    }
  };
  return (
    <div className={`back-button ${classes}`}>
      <XB_Button
        dataTestId="BACK"
        backgroundColor="white"
        size="sm"
        iconName="chevron-left"
        onClick={() => {
          if (onClickAction) {
            onClickAction?.();
          } else {
            redirection();
            onBackBtnAction?.();
          }
        }}
        {...props}
      ></XB_Button>
    </div>
  );
};
