export const errorCodes = {
  client: [
    'AUWEB000',
    'AUWEB001',
    'AUWEB002',
    'AUWEB003',
    'AUWEB004',
    'AUWEB005',
    'AUWEB006',
    'AUWEB007',
    'AUWEB008',
    'AUWEB009',
    'AUWEB010',
    'AUWEB011',
    'AUWEB012',
  ],
};
export const statusCode = {
  success: [200, 201, 202],
  unauthorize: [401],
  error: [500],
};
export const errorCodeMapping = {
  ONB1041: 'ONB1041',
  ONB0441: 'ONB0441',
  ONB0439: 'ONB0439',
  ONB0438: 'ONB0438',
  ONB0436: 'ONB0436',
  ONB0437: 'ONB0437',
  ONB0460: 'ONB0460',
  ONB0461: 'ONB0461',
  UMS0421: 'UMS0421',
  ONB0464: 'ONB0464',
  ONB0465: 'ONB0465',
  ONB1009: 'ONB1009',
  ONB0404: 'ONB0404',
  ONB0462: 'ONB0462',
  ONB1011: 'ONB1011',
  ONB1054: 'ONB1054',
  ONB1014: 'ONB1014',
  ONB1055: 'ONB1055',
  ONB1003: 'ONB1003',
  MSTR0400: 'MSTR0400',
  ONB1067: 'ONB1067',
  ZERO: '0',
  ONB0505: 'ONB0505',
  ONB0507: 'ONB0507',
  ONB1002: 'ONB1002',
};
