import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestDebitCardDetail } from '../../models/request-account-services.model';

export const debitCardDetails = createAsyncThunk(
  'api/deposit-service/debit-card',
  async (debitCardData: RequestDebitCardDetail, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.debitCardService.GET_DEBIT_CARD_LIST,
        url: '',
        obj: debitCardData,
      });
      if (response.data.status === 'error') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
