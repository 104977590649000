import { useTranslation } from 'react-i18next';

import { Icon, XB_Button } from '@core-components/atoms';

interface Error500Props {
  retryFunc: () => void;
  title?: string;
  subtitle?: string;
  secondaryBtnProps?: SecondaryBtnProps;
}

interface SecondaryBtnProps {
  label?: string;
  onCLickSecondaryBtn: () => void;
}

const Error500: React.FC<Error500Props> = ({
  retryFunc,
  title = 'somethingWentWrong',
  subtitle = 'weAreWorkingOnFixing',
  secondaryBtnProps = {
    label: 'tryAgain',
    onCLickSecondaryBtn: () => retryFunc(),
  },
}: Error500Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full h-full flex-col justify-center items-center py-14">
      <Icon
        icon={'error-500'}
        width={288}
        height={176}
        className="mb-8"
        color="none"
      />
      <h1 className="m-display-xxl-medium text-gray-800 mt-8">{t(title)}</h1>
      <span className="m-display-sm-regular text-gray-500 mt-2 mb-9">
        {t(subtitle)}
      </span>
      <div className="flex w-full px-8 gap-3 flex-around mt-12">
        <XB_Button
          dataTestId="CONT-SUPT"
          btnType="secondary"
          iconName="contact-branch"
          classes="w-full"
          onClick={() => {
            window?.open(
              t('contactSupportUrl'),
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          {t('contactSupport')}
        </XB_Button>
        <XB_Button
          dataTestId="TRY-AGIN"
          btnType="primary"
          classes="w-full"
          onClick={secondaryBtnProps.onCLickSecondaryBtn}
        >
          {t(secondaryBtnProps?.label ?? 'tryAgain')}
        </XB_Button>
      </div>
    </div>
  );
};

export default Error500;
