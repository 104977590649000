import { createSlice } from '@reduxjs/toolkit';
import { ResponseUpdatePurpose } from '@src/core-stores/models/update-purpose.model';
import { getPurposeDetails } from '@src/core-stores/thunks/purpose.thunk';

const initialState: ResponseUpdatePurpose = {
  loading: false,
  data: undefined,
  error: undefined,
  successfulResponse: undefined,
};

const updatePurposeSlice = createSlice({
  name: 'getPurposeDetails',
  initialState,
  reducers: {
    clearNickname: (state, _: object) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
      state.successfulResponse = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPurposeDetails.pending,
      (state: ResponseUpdatePurpose) => {
        state.loading = true;
        state.successfulResponse = undefined;
        state.error = undefined;
        state.data = undefined;
      }
    );
    builder.addCase(
      getPurposeDetails.fulfilled,
      (state: ResponseUpdatePurpose, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.data = payload.data;
        state.successfulResponse = true;
      }
    );
    builder.addCase(
      getPurposeDetails.rejected,
      (state: ResponseUpdatePurpose, { payload }) => {
        state.loading = false;
        state.error = payload as undefined;
        state.data = undefined;
        state.successfulResponse = false;
      }
    );
  },
});

export const { clearNickname } = updatePurposeSlice.actions;

export default updatePurposeSlice.reducer;
