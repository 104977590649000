import { useEffect } from 'react';

import { useEventProvider } from '@core-providers';

interface HOCProps {
  children: any;
  name: string;
  extraParams:
    | {
        journeyId: string;
        screenId: string;
      }
    | undefined;
}

export const XB_HOC = ({ children, extraParams, name }: HOCProps) => {
  const { setJourneyId, setScreenId, logScreenView, logCustomEvent } =
    useEventProvider();

  useEffect(() => {
    document.getElementById('root_id')?.scrollTo(0, 0);
    if (extraParams) {
      const screen = `${extraParams.journeyId}_${extraParams.screenId}`;
      setJourneyId(extraParams.journeyId);
      setScreenId(extraParams.screenId);
      logScreenView(screen, name);
      logCustomEvent(screen);
    }
  }, [extraParams]);
  return <div>{children}</div>;
};
