import { NomineeData } from '@src/core-stores/models/nominee-detail.model';

export const depositHolderType = { self: 'self', jointHolder: 'jointHolder' };

export const depositConfigTypeKey = 'FE_DEPOSIT_CONFIG';

export const shouldPrincipalAmount = 1000;
export const minAmt = 1;
export const CODE_NOMINEE_NOT_ADDED = '11';
export const jointRelationship = {
  jof: 'JOF',
  joo: 'JOO',
};
export const depositTypeForClosure = {
  FULL: 'FD_FULL',
  PARTIAL: 'FD_PARTIAL',
};
export const PAYOUT_CONFIG = {
  '0': {
    id: 'radioPayoutMaturity',
    strapiKey: 'onMaturity',
    code: 'On Maturity',
    classes: 'payout-maturity md:basis-2/6 whitespace-nowrap',
  },
  '4': {
    id: 'radioPayoutMonthly',
    strapiKey: 'monthly',
    code: 'Monthly',
    classes: 'payout-monthly md:basis-2/6 whitespace-nowrap',
  },
  '6': {
    id: 'radioPayoutQuaterly',
    strapiKey: 'quarterly',
    code: 'Quarterly',
    classes: 'payout-quarterly md:basis-2/6 whitespace-nowrap',
  },
};

export const MERCHANT_APP = 'MERCHANT_APP';
export const FD = 'FD';
export const RD = 'RD';
export const SOT = 'TSD';
export const TEST_ID = 'TETSTS-0901';
export const DATE_FORMAT = 'yyyy-MM-DD';
export const nominees: NomineeData = {
  NomineeName: 'Mocked Nominee',
  Relationship: 'Mocked Nominee',
  DateofBirth: 'Mocked Nominee',
  AddressLine_1: 'Mocked Nominee',
  AddressLine_2: 'Mocked Nominee',
  AddressLine_3: 'Mocked Nominee',
  Pincode: 'Mocked Nominee',
  NomineeMobileNumber: 'Mocked Nominee',
  City_or_District: 'Mocked Nominee',
  State: 'Mocked Nominee',
  NomineeId: 'Mocked Nominee',
  DepositNo: 'Mocked Nominee',
  GuardianName: 'Mocked Nominee',
  Guardian_AddressLine1: 'Mocked Nominee',
  Guardian_AddressLine2: 'Mocked Nominee',
  Guardian_AddressLine3: 'Mocked Nominee',
  Guardian_Pincode: 'Mocked Nominee',
  Guardian_City_or_District: 'Mocked Nominee',
  Guardian_State: 'Mocked Nominee',
  GaurdianPhoneNumber: 'Mocked Nominee',
  GaurdianMobileNumber: 'Mocked Nominee',
  GaurdianEmail: 'Mocked Nominee',
  GaurdianPhoneArea: 'Mocked Nominee',
  GaurdianPhoneCountry: 'Mocked Nominee',
};

export const FE_DEPOSIT_CONFIG = {
  CONFIG_TYPE: 'FE_DEPOSIT_CONFIG',
  MODULE: 'feDepositConfig',
};

export const MockDepositData = {
  AccountType: 'Current Account',
  AccountID: '9876543456',
  Amount: '7800',
  CreatedOn: '24-03-2024',
  MaturityAmount: '8000',
  MaturityOn: '24-03-2025',
  InterestRate: '8%',
  Tenure: {
    days: '0',
    months: '1',
    years: '2',
  },
  PayoutType: 'Monthly',
  CreditAccount: '291286',
  DebitAccount: '123456080',
  Purpose: 'Testing',
  InterestTillDate: '765',
  MonthlyInstallment: '100',
  DepositNumber: '999999',
  TransactionalBranch: '2011',
  CustomerRelationship: 'SOW',
  CloseoutBalance: '87654',
  MaturityInstruction: 'isntruction',
  tdEquiryData: 'TD Enquiry',
  rdEnquiryData: 'R Enquiry',
  creditAccountSelected: '832873232',
  fdPartialwithdrawalAmount: '93003',
  nomineeName: 'Test Nominee',
  nomineeRelationship: 'Brother',
  ProductName: 'SA',
};
