import { createSlice } from '@reduxjs/toolkit';

import { ServiceRequestRdxData } from '../../index';

const initialState: ServiceRequestRdxData = {
  formDataRdx: undefined,
  statusData: undefined,
  positivePayRdx: undefined,
};

const serviceRequestSlice = createSlice({
  name: 'service_request',
  initialState,
  reducers: {
    setFormDataRdx(state, { payload }) {
      state.formDataRdx = payload;
    },
    setStatusData(state, { payload }) {
      state.statusData = payload;
    },
    setPositivePayDataRdx(state, { payload }) {
      state.positivePayRdx = payload;
    },
  },
});

export const { setFormDataRdx, setStatusData, setPositivePayDataRdx } =
  serviceRequestSlice.actions;
export default serviceRequestSlice.reducer;
