import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { XB_Button } from '@core-components/atoms';
import { AU_StatusScreen } from '@core-components/templates';
import { useAlertBox } from '@core-hooks';
import { apply_now_urls } from '@src/core-navigations';

const BusinessBankingStatus: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const auAlert = useAlertBox();

  const handleCopyRefNumber = () => {
    auAlert.success(t('copiedSuccessfully').toString());
    navigator?.clipboard?.writeText(location?.state?.refNo ?? '-/-');
  };

  const renderStatusDescBody = () => {
    return (
      <>
        <div className="txn-details-container">
          <div className="utr-container mt-4 flex justify-between flex-wrap">
            <p className="m-text-lg-regular text-gray-600 whitespace-nowrap">
              {t('referenceId')}
            </p>
            <div className="flex items-center gap-x-1">
              <p className="m-text-lg-medium text-gray-800">
                {location?.state?.refNo}
              </p>
              <XB_Button
                dataTestId="COPY"
                iconName="copyorange"
                btnType="tertiary-gray"
                size="xs"
                classes="cursor-pointer text-orange-600"
                onClick={() => handleCopyRefNumber()}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderReviewRow = (leftData, rightData) => {
    return (
      <div className="review-row-data-container mt-6 flex justify-between">
        <h2 className="m-text-lg-regular text-gray-600">{leftData}</h2>
        <p className="m-text-lg-medium text-gray-800">{rightData}</p>
      </div>
    );
  };
  const renderStatusTypeBody = () => {
    return (
      <div className="w-full">
        {renderReviewRow(t('name'), location?.state?.name)}
        {renderReviewRow(t('mobileNumber'), location?.state?.phoneNo)}
        {renderReviewRow(t('emailId'), location?.state?.email)}
      </div>
    );
  };
  return (
    <>
      <AU_StatusScreen
        backBtnProps={{
          url: apply_now_urls?.apply_now,
        }}
        statusTypeBody={renderStatusTypeBody()}
        statusDescBody={renderStatusDescBody()}
        pageTitle={t('applyForBusinessBanking')}
        bottomBtnProps={{
          dataTestId: 'BACK-HOME',
          children: t('backHome'),
          onClick: () => {
            navigate(apply_now_urls?.apply_now);
          },
        }}
        statusType={'success'}
        statusTypeDesc={t('appliedSuccessfullyForBusinessBanking')}
      />
    </>
  );
};
export default BusinessBankingStatus;
