import { useCallback, useEffect, useState } from 'react';

import i18n from 'i18next';

import { XB_Carousel, XB_Carousel_Slide } from '@core-components/atoms';
import { CrausalImageData } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import { EndPoints, Service } from '@core-services';
import { getStoredLanguage, SessionStorage } from '@core-utils';

export function XB_AuthCarousel() {
  const [carouselData, setCarouselData] = useState<any[]>([]);
  const auAlert = useAlertBox();

  const fetchCarouselDtl = useCallback(async () => {
    try {
      const reqData = {
        language: getStoredLanguage() ?? 'en',
      };
      const params = {
        baseurl: EndPoints.strapiService.getWalkthrough,
        method: 'POST',
        url: '',
        obj: reqData,
      };
      const response = await Service.send(params);
      if (response?.status === 200) {
        setCarouselData(response?.data?.data);
      } else {
        auAlert.error(response.data);
      }
    } catch (error: any) {
      auAlert.error(error.toString());
    }
  }, []);
  useEffect(() => {
    fetchCarouselDtl();
    const onLanguageChanged = () => {
      const accessToken = SessionStorage.getItem('accessToken');
      if (!accessToken) {
        fetchCarouselDtl();
      }
    };
    i18n.on('languageChanged', onLanguageChanged);
    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, [fetchCarouselDtl]);

  return (
    <div className="hidden md:block">
      {carouselData?.length === 0 ? (
        <div className="animate-pulse px-3 py-10">
          <div className="rounded-md bg-slate-200 h-[381px] w-full"></div>
          <div className="rounded-md bg-slate-200 mt-8 mb-2 w-full h-8"></div>
          <div className="rounded-md bg-slate-200 mb-2 w-full h-12"></div>
        </div>
      ) : (
        <XB_Carousel
          carouselName="Register"
          slideLength={carouselData?.length}
          showIndicators={false}
          autoLoopTime={3000}
          animation={false}
        >
          {carouselData?.map((slideData, index) => {
            const { title, description, webResourceUrl } = slideData;

            return (
              <XB_Carousel_Slide key={index}>
                <div className="max-w-[70%] m-auto">
                  <img
                    className="w-full rounded-2xl"
                    src={webResourceUrl}
                    width={CrausalImageData.width}
                    height={CrausalImageData.height}
                    alt={title}
                  />
                </div>
                <div className="max-w-[458x] m-auto  text-center">
                  <h3 className="m-display-xl-bold mb-2">{title}</h3>
                  <p className="m-text-lg-regular h-12">{description}</p>
                </div>
              </XB_Carousel_Slide>
            );
          })}
        </XB_Carousel>
      )}
    </div>
  );
}
