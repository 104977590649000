import { createSlice } from '@reduxjs/toolkit';

import { AUOffersRdxData, getOffersListThunk } from '../../index';

const initialState: AUOffersRdxData = {
  data: undefined,
  error: undefined,
  loading: true,
};

const auOffersSlice = createSlice({
  name: 'au-dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOffersListThunk.fulfilled, (state, { payload }) => {
      state.data = payload.data;
      state.error = payload.error;
      state.loading = payload.loading;
    });
    builder.addCase(getOffersListThunk.pending, (state) => {
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getOffersListThunk.rejected,
      (state, { payload }: { payload: any }) => {
        state.data = undefined;
        state.error = payload;
        state.loading = true;
      }
    );
  },
});

export default auOffersSlice.reducer;
