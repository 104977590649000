import { AUEndPoints, Service } from '@core-services';

import {
  CancePaymentReq,
  IAccountLimitCheckRequest,
  IFeatureLimitCheckRequest,
  ReqGetBankDetailList,
  ReqPaymentFrequency,
  ScheduleTransactionInSeriesReq,
} from '../models/payments.model';

export * from '../stores/thunks/payments.thunk';

export const getPaymentsInSeries = async (
  reqData: ScheduleTransactionInSeriesReq
) => {
  const reqObj = {
    baseurl: AUEndPoints.paymentsService.paymentService.GET_PAYMENTS_IN_SERIES,
    method: 'POST',
    url: '',
    obj: reqData,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const cancelPayment = async (reqData: CancePaymentReq) => {
  const reqObj = {
    baseurl: AUEndPoints.paymentsService.paymentService.CANCEL_PAYMENT,
    method: 'POST',
    url: '',
    obj: reqData,
  };
  const response = await Service.send(reqObj);

  return response.data;
};

export const getPaymentFrequency = async (
  PaymentFrequency: ReqPaymentFrequency
) => {
  const reqObj = {
    baseurl: AUEndPoints.paymentService.PAYMENT_FREQUENCY,
    method: 'POST',
    url: '',
    obj: PaymentFrequency,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const accountWiseLimit = async (
  limitRequest: IAccountLimitCheckRequest,
  userId,
  merchantId
) => {
  const reqObj = {
    baseurl: AUEndPoints.paymentsService.paymentService.ACCOUNT_LIMIT(
      userId,
      merchantId
    ),
    method: 'POST',
    url: '',
    obj: limitRequest,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const featureWiseLimit = async (
  featureLimitRequest: IFeatureLimitCheckRequest
) => {
  const reqObj = {
    baseurl: AUEndPoints.paymentsService.paymentService.FEATURE_LIMIT,
    method: 'POST',
    url: '',
    obj: featureLimitRequest,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const getBankDetailList = async (request: ReqGetBankDetailList) => {
  const reqObject = {
    baseurl: AUEndPoints.merchantService.GET_BANK_DETAIL_LIST,
    method: 'POST',
    url: '',
    obj: request,
  };

  const response = await Service.send(reqObject);
  return response.data;
};
