import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Slider from 'react-slick';

import {
  AU_BannerSlider,
  AU_Linkcard,
  AU_VerticalCard,
  QuickLinksDataProps,
} from '@core-components/molecules';
import { getConcatenatedString, isEmpty, isURL } from '@core-utils';

import '../styles/manage-cards.style.scss';
import '../styles/offer-carousel.style.scss';

interface CardsCarouselProps {
  showArrows: boolean;
  showDots: boolean;
  isInfinite?: boolean;
  isFocusOnSelect: boolean;
  initialSlide?: number;
  sliderSpeed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  variableWidth?: boolean;
  isimageCarausel: boolean;
  isCardCarousel?: boolean;
  isResponsive?: boolean;
  data: any[];
}

export const CardsCarousel = ({
  sliderSpeed = 1000,
  slidesToShow = 3,
  slidesToScroll = 1,
  initialSlide = 0,
  variableWidth = true,
  isInfinite = false,
  isResponsive = false,
  isCardCarousel = false,
  ...props
}: CardsCarouselProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const BannerSliderUI = (data: any[]) => {
    return data?.map((item, index) => (
      <AU_BannerSlider
        key={getConcatenatedString([index])}
        title={item?.title}
        subtitle={item?.subtitle}
        bgImgUrl={item?.imageUrl}
        offerBackgroundColor={
          isEmpty(item?.imageUrl) ? item?.offerBackgroundColor : ''
        }
        primaryBtnProps={{
          dataTestId: '',
          children: item?.primaryCta,
          backgroundColor: item?.primaryCtaBackgroundColor,
          labelColor: item?.primaryCtaLabelColor,
          onClick: () => {
            if (
              item?.primaryRedemptionUrl &&
              !isEmpty(item?.primaryRedemptionUrl)
            ) {
              if (isURL(item?.primaryRedemptionUrl ?? '')) {
                window.open(item?.primaryRedemptionUrl, '_blanks');
              } else {
                navigate(item?.primaryRedemptionUrl ?? '');
              }
            } else item?.onClick();
          },
        }}
        offerHeadingColor={item?.offerHeadingColor}
        offerSubheadingColor={item?.offerSubheadingColor}
        secondaryLinkText={item?.secondaryCta}
        secondaryLinkColor={item?.secondaryCtaLabelColor}
        secondaryLink={item?.secondaryRedemptionUrl}
        otherLinkProps={{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
      />
    ));
  };

  const VerticalCardUI = (data: any[]) => {
    return data?.map((item, index) => (
      <AU_VerticalCard
        key={getConcatenatedString([index])}
        title={item?.title}
        subtitle={item?.subtitle}
        bgImgUrl={item?.imageUrl}
        offerBackgroundColor={
          isEmpty(item?.imageUrl) ? item?.offerBackgroundColor : ''
        }
        offerHeadingColor={item?.offerHeadingColor}
        offerSubheadingColor={item?.offerSubheadingColor}
        primaryBtnProps={{
          dataTestId: '',
          children: item?.primaryCta,
          backgroundColor: item?.primaryCtaBackgroundColor,
          labelColor: item?.primaryCtaLabelColor,
          onClick: () => {
            if (
              item?.primaryRedemptionUrl &&
              !isEmpty(item?.primaryRedemptionUrl)
            ) {
              if (isURL(item?.primaryRedemptionUrl ?? '')) {
                window.open(item?.primaryRedemptionUrl, '_blanks');
              } else {
                navigate(item?.primaryRedemptionUrl ?? '');
              }
            }
          },
        }}
      />
    ));
  };

  const ImageCarousel = (data: any[]) => {
    if (isCardCarousel) {
      return VerticalCardUI(data);
    } else {
      return BannerSliderUI(data);
    }
  };

  const CardTypeCarousel = (data: any[]) => {
    return data?.map((item, index) => {
      return (
        <AU_Linkcard
          key={getConcatenatedString([index, item.title])}
          data={makedata(item)}
          isRoundBox={false}
          isHover={false}
          cardClass="manage_card_link_card"
        />
      );
    });
  };

  const sentenceFormatter = (num) => {
    const numEnding = num.slice(-4);
    return `${t('endingWith')} ${numEnding as string}`;
  };

  const showArrowOnSlideCount = () => {
    const windowWidth = window?.screen?.width;
    return props?.data?.length >= getSlidesCount(windowWidth);
  };

  const getSlidesCount = (windowWidth: number) => {
    if (windowWidth <= 900) return 1;
    else return 3;
  };

  const makedata = (data) => {
    const obj: QuickLinksDataProps = {
      title: data.title,
      description: sentenceFormatter(data.description),
      suffixBtnIcon: true,
      prefixIcon: data.icon,
      prefixIconProps: { width: 46 },
      suffixBtnpath: data.onCardClick,
    };
    return obj;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onImageCarouselClick = (item) => {
    if (item.url) {
      window.open(item.url, '_blank');
    } else if (item.webUri) {
      navigate(item.webUri);
    } else {
      item.onClick();
    }
  };
  const responsiveSlider = [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  const getCardsCarouselStyle = () => {
    let style = 'card-slider-container';
    if (props.showArrows) {
      if (showArrowOnSlideCount()) {
        style = 'offer-slider-container';
      }
    }
    return style;
  };

  return (
    <div className="cards-carousel-component">
      <div className={`${getCardsCarouselStyle()}  flex flex-col`}>
        <Slider
          arrows={props.showArrows}
          dots={props.showDots}
          infinite={isInfinite}
          focusOnSelect={props.isFocusOnSelect}
          initialSlide={initialSlide}
          dir="ltr"
          speed={sliderSpeed}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          variableWidth={variableWidth}
          responsive={isResponsive ? responsiveSlider : null}
        >
          {props.isimageCarausel
            ? ImageCarousel(props.data)
            : CardTypeCarousel(props.data)}
        </Slider>
      </div>
    </div>
  );
};
