import { createSlice } from '@reduxjs/toolkit';

import { ProductCodeState } from '../models/product-code.model';
import { getProductCode } from '../thunks/deposit.thunk';

const initialState: ProductCodeState = {
  status: undefined,
  error: null,
  successfulResponse: undefined,
  productCode: undefined,
  loading: false,
};

const productCodeSlice = createSlice({
  name: 'productcode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductCode.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        productCode: state.productCode,
      } = payload.data);
    });
    builder.addCase(
      getProductCode.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.productCode = undefined;
      }
    );
  },
});

export default productCodeSlice.reducer;
