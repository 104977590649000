import { t } from 'i18next';

import { downloadFormatOptions, xlsx } from '@core-constants';
import { s3DownlaodImg } from '@core-services';
import { MERCHANT_DOCUMENT_TYPE } from '@modules/profile-info/constants/profile-info.constant';

export const downloadFile = (base64File: string, format) => {
  const formatToConvert = format === downloadFormatOptions.XLS ? xlsx : format;
  const linkSource = `data:application/${
    (formatToConvert.toLowerCase() as string) ?? ''
  };base64,${base64File ?? ''}`;
  const downloadLink = document.createElement('a');
  const fileName = `${t('accStmtFileName')}.${
    formatToConvert.toLowerCase() as string
  }`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadAssetsUtil = async (merchantDataRdx) => {
  if (merchantDataRdx?.data) {
    const objRes = merchantDataRdx?.data?.documentDetails?.find(
      (item) => item?.documentType === MERCHANT_DOCUMENT_TYPE
    );
    if (merchantDataRdx?.data?.documentDetails?.length > 0) {
      if (Object?.keys(objRes ?? {}).length > 0) {
        const resData = await s3DownlaodImg({
          awsS3BucketKeyPath: objRes?.s3Url ?? '',
        });
        if (resData instanceof Error) {
          return { resData: '', objLength: Object?.keys(objRes ?? {}).length };
        } else {
          return {
            merchantId: objRes?.merchantId,
            resData,
            objLength: Object?.keys(objRes ?? {}).length ?? 0,
          };
        }
      }
    }
  }
};
