const ones = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen ',
];
const tens = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
];

const getLT20 = (amount: string) => ones[Number(amount)];
const getGT20 = (amount: string) =>
  tens[Number(amount[0])] + ' ' + ones[Number(amount[1])];

export const amountTranslator = (input: number | string): string => {
  const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/;

  const num = Number(input);
  if (isNaN(num) || num === 0) return '';
  const numStr = num.toString();
  if (numStr.length > 9) {
    return '';
  }
  const matchResults = ('000000000' + numStr).slice(-9).match(regex);
  if (matchResults) {
    return 'In Words : ' + handleConversion(matchResults);
  }
  return '';
};

function handleConversion(matchResults: RegExpMatchArray) {
  const [, n1, n2, n3, n4, n5] = matchResults;
  let str = '';
  str += Number(n1) !== 0 ? (getLT20(n1) || getGT20(n1)) + 'crore ' : '';
  str += Number(n2) !== 0 ? (getLT20(n2) || getGT20(n2)) + 'lakh ' : '';
  str += Number(n3) !== 0 ? (getLT20(n3) || getGT20(n3)) + 'thousand ' : '';
  str += Number(n4) !== 0 ? getLT20(n4) + 'hundred ' : '';
  str += Number(n5) !== 0 && str !== '' ? 'and ' : '';
  str += Number(n5) !== 0 ? getLT20(n5) || getGT20(n5) : '';
  return str.charAt(0).toUpperCase() + str.slice(1).trim() + ' rupees only';
}
export const floatAmountTranslator = (amount: string) => {
  const actualAmt = amount.split(',').join('');

  if (actualAmt.split('.').length < 2) {
    return amountTranslator(actualAmt)
      .replace('only', '')
      .replace('In Words : ', '');
  } else {
    return (
      amountTranslator(actualAmt.split('.')[0])
        .replace('only', '')
        .replace('In Words : ', '') +
      (actualAmt.split('.')[0] !== '0' && actualAmt.split('.')[1] !== ''
        ? 'and '
        : '') +
      amountTranslator(actualAmt.split('.')[1].padEnd(2, '0'))
        .replace('rupees', 'paise')
        .replace('In Words : ', '')
        .replace('only', '')
    );
  }
};

export const convertAmountWithoutFormat = (amount: string): number => {
  return Number(amount.replace(/[\D]/g, ''));
};

export const getINRFormat = (
  amount: number,
  isReqCurrencySybmol: boolean
): string => {
  try {
    const value = amount.toLocaleString('en-IN', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'INR',
    });

    if (isReqCurrencySybmol) {
      return value;
    } else {
      return value.slice(1);
    }
  } catch (error) {
    return String(amount);
  }
};
/**
 * Used for show amount with proper format with decimal upto 2 places
 * @param 112123.34
 * @returns 1,12,123.34
 */
export const validateAndFormatAmountInDecimal = (amt: string) => {
  let tempValue = amt.split(/[^0-9.]/).join('');
  if (tempValue.split('.').length > 2) {
    tempValue = tempValue.substring(0, tempValue.length - 1);
  }
  const pre = tempValue.split('.')[0];
  const post = tempValue.split('.')[1];
  tempValue =
    getFormattedAmountString(pre, undefined, true).replace('.00', '') +
    (post !== undefined ? '.' : '') +
    (post ?? '').substring(0, 2);

  if (!amt.split('.')[0].match(/[a-zA-Z]/g)) {
    return tempValue;
  } else {
    return '';
  }
};

/**
 * Used for show amount with proper format
 * @param 1000
 * @returns ₹1,000
 */
export const getINRFormatWithDecimal = (amount: number): string => {
  try {
    if (isNaN(amount)) {
      return '-/-';
    } else {
      return amount.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
    }
  } catch (error) {
    return String(amount);
  }
};

export const convertDecimalFormat = (
  value: number,
  isPercentageSymbol: boolean
): string => {
  try {
    if (isNaN(value)) {
      return '-/-';
    } else {
      if (isPercentageSymbol) {
        return `${value.toFixed(2)}%`;
      } else {
        return value.toFixed(2);
      }
    }
  } catch (error) {
    return String(value);
  }
};

export const getFormattedAmountString = (
  amount: number | string,
  options: {
    forceINRFormat?: boolean;
    currencySymbol?: string;
  } = { forceINRFormat: true },
  isWithoutCurrency = false
): string => {
  amount = Number(amount);

  /** Fail-safe mechanism */
  if (isNaN(amount)) amount = 0;

  const fixedStr = amount.toFixed(2);
  let fmtStr = '';

  try {
    for (
      let i = fixedStr.length - 1,
        decimalFound = false,
        places = options?.forceINRFormat ? [3, 2] : [3],
        placeIndex = 0,
        placeCount = 0;
      i >= 0;
      i--
    ) {
      if (decimalFound) {
        if (places[placeIndex] === placeCount) {
          placeCount = 1;
          if (placeIndex + 1 < places.length) placeIndex++;

          fmtStr = fixedStr[i] + ',' + fmtStr;
          continue;
        } else placeCount++;
      } else {
        decimalFound = fixedStr[i] === '.';
      }
      fmtStr = fixedStr[i] + fmtStr;
    }
  } catch (e) {
    /* Fallback mechanism: Won't work on Android */
    fmtStr = amount.toLocaleString(
      options.forceINRFormat ? 'hi-IN' : undefined
    );
  }

  if (isWithoutCurrency) {
    return fmtStr;
  }

  if (options?.currencySymbol) fmtStr = `${options.currencySymbol} ${fmtStr}`;

  return `₹${fmtStr}`;
};
