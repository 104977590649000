import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReqMakePayment } from '../models/payment-transfer.model';

export const paymentTransfer = createAsyncThunk(
  'PaymentTransfer',
  async (paymentTransferReq: ReqMakePayment, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentsService.paymentService.MAKE_PAYMENT,
        obj: paymentTransferReq,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
