import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  isNTB: boolean;
  isQRCollection: boolean;
  hideNominee: boolean;
} = {
  isNTB: false,
  isQRCollection: false,
  hideNominee: false,
};

const auDashboardDataSlice = createSlice({
  name: 'au-dashboard',
  initialState,
  reducers: {
    setIsNTB(state, { payload }: { payload: any }) {
      state.isNTB = payload;
    },
    setIsQRCollection(state, { payload }: { payload: any }) {
      state.isQRCollection = payload;
    },
    setHideNominee(state, { payload }: { payload: any }) {
      state.hideNominee = payload;
    },
  },
});

export const { setIsNTB, setIsQRCollection, setHideNominee } =
  auDashboardDataSlice.actions;
export default auDashboardDataSlice.reducer;
