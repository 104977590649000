import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { XB_InputMasked } from '@core-components/atoms';
import { appConstants, RegEx } from '@core-constants';
import { useDebounce } from '@core-hooks';
import { getAadhaarByToken } from '@core-services';
import { validateAadhaar } from '@core-utils';

interface InputAadhaarProps {
  id?: string;
  disabled: boolean;
  onInputChange: (aadhaar) => void;
  mobileNumber?: string;
  onError: (err: boolean) => void;
  isReadOnly?: boolean;
  aadhaar?: string;
}

export const XB_InputAadhaar = ({
  id = 'aadhaar',
  disabled,
  onInputChange,
  onError,
  mobileNumber = '',
  isReadOnly = false,
  aadhaar = '',
}: InputAadhaarProps) => {
  const { t } = useTranslation();
  const [aadhaarNumber, setAadhaarNumber] = useState(isReadOnly ? aadhaar : '');
  const [error, setError] = useState('');
  const aadhaarValidate = useDebounce(
    aadhaarNumber,
    appConstants.DEBOUNCE_TIME
  );

  useEffect(() => {
    if (!isReadOnly && aadhaarNumber) {
      const validationError = validateAadhaar(aadhaarNumber).error;
      if (aadhaarValidate?.length > 0 && validationError) {
        setError(validationError);
        onError(true);
      } else {
        setError('');
        onError(false);
      }
    }
  }, [aadhaarValidate, aadhaarNumber, onError]);

  useEffect(() => {
    setError('');
  }, [aadhaarNumber]);

  const onAadhaarChange = (value) => {
    if (!isReadOnly) {
      setAadhaarNumber(value);
    }
  };

  useEffect(() => {
    onInputChange(aadhaarNumber);
  }, [onInputChange, aadhaarNumber]);

  useEffect(() => {
    const getAadhaarNumber = async () => {
      /* istanbul ignore else */
      if (mobileNumber && !isReadOnly) {
        try {
          const res = await getAadhaarByToken({ mobileNumber });
          /* istanbul ignore else */
          if (res.status === 'success') {
            onAadhaarChange(res.data.aadhaarNumber);
          }
        } catch (err) {
          onAadhaarChange('');
        }
      }
    };
    getAadhaarNumber();
  }, []);

  return (
    <XB_InputMasked
      id={id}
      label={t(`enterAadhaarNumberCaps`)}
      placeholder={t(`enterAadhaarNumberSmall`)}
      autoComplete="off"
      msgType={error ? 'error' : ''}
      infoMsgText={t(error)}
      regex={RegEx.onlyDigit}
      maxLength={14}
      preValue={aadhaarNumber}
      getValue={onAadhaarChange}
      disabled={disabled}
      disablePaste
    />
  );
};
