import { useTranslation } from 'react-i18next';

import { AU_RoundBox, Icon, XB_Button, XB_Modal } from '@core-components/atoms';
import { filterModalTypes } from '@modules/so-inventory/constants/so-inventory.constant';
import { ResDownloadFile } from '@modules/so-inventory/models/so-inventory.model';

import { settlementDocumentDownload } from '../modules/settlement-management/services/settlement-management.service';

export interface IHeaderProps {
  awsS3BucketName: string;
  awsS3BucketKeyPath: string;
  awsS3BucketKeyPathFileNameWithExt: string;
}

interface FireEventProps {
  headerData: IHeaderProps;
  fileName: string;
  setShowFilterModalRender: (item) => void;
}
interface DownFireEventProps {
  screenType: string;
  setShowFilterModalRender: (item) => void;
}
interface DownFuncProps {
  fireEvent: (fireEventArg: FireEventProps) => void;
  loadScreen: (renderEvent: DownFireEventProps) => any;
}

export function useDownloadSettlementFile(): DownFuncProps {
  const { t } = useTranslation();

  const downloadFile = (
    base64File: string,
    format: string,
    fileName: string
  ) => {
    const linkSource = `data:application/${format.toLowerCase() ?? ''};base64,${
      base64File ?? ''
    }`;
    const downloadLink = document?.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName ?? 'TxnReceipt';
    downloadLink.click();
  };

  const downloadFunc = async (downloadData: FireEventProps) => {
    downloadData.setShowFilterModalRender(filterModalTypes?.downloadProgess);
    try {
      const downloadAccountStatement: ResDownloadFile =
        await settlementDocumentDownload(downloadData?.headerData);
      if (downloadAccountStatement?.successfulResponse) {
        downloadFile(
          downloadAccountStatement?.data,
          'csv',
          downloadData?.fileName
        );
        downloadData.setShowFilterModalRender('');
      } else {
        downloadData.setShowFilterModalRender(filterModalTypes.downloadFailed);
      }
    } catch (error: any) {
      downloadData.setShowFilterModalRender(filterModalTypes.downloadFailed);
    }
  };

  const fireEvent = (fireEventArg) => {
    if (fireEventArg) {
      downloadFunc(fireEventArg);
    }
  };

  const loadScreen = (renderEvent: DownFireEventProps) => {
    switch (renderEvent.screenType) {
      case filterModalTypes.downloadProgess:
        return downloadProgressModal();
      case filterModalTypes.downloadFailed:
        return downloadFailedModal(renderEvent);
      default:
        return <div></div>;
    }
  };

  const downloadFailedModal = (renderEvent: DownFireEventProps) => {
    return (
      <XB_Modal
        open={true}
        setOpen={() => renderEvent.setShowFilterModalRender('')}
        img={
          <AU_RoundBox boxClass="bg-orange-50">
            <Icon
              width={48}
              height={48}
              className="text-orange-600"
              icon="alerttriangle"
            />
          </AU_RoundBox>
        }
        title={`${t('download')} ${t('failed')}`}
        subTitle={t('defaultError')}
        maxWidth="25rem"
        actions={
          <XB_Button
            classes="w-full"
            dataTestId={'BACK'}
            onClick={() => renderEvent.setShowFilterModalRender('')}
          >
            {t('back')}
          </XB_Button>
        }
      />
    );
  };
  const downloadProgressModal = () => {
    return (
      <XB_Modal
        open={true}
        maxWidth="20.5rem"
        img={
          <Icon
            icon="download-in-progress"
            color="none"
            width={38}
            height={38}
          />
        }
        title={t('downloading')}
        subTitle={t('waitWhileWeFetchStatements')}
      />
    );
  };

  const aualert: DownFuncProps = {
    fireEvent,
    loadScreen,
  };
  return aualert;
}
