export const so_breadcrumb = [
  { url: '/so-dashboard', name: 'Home', id: 1, parents: [] },

  {
    url: '/so-dashboard/raise-requests',
    name: 'Raise Requests',
    parents: [1],
    id: 2,
  },
  {
    url: '/so-request-history',
    name: 'Request History',
    parents: [1],
    id: 4,
  },
  {
    url: '/so-request-history',
    name: 'Request History',
    parents: [1],
    id: 4,
  },
  {
    url: '/bm-allocation',
    name: 'Allocate',
    parents: [1],
    id: 5,
  },
  {
    url: 'allocation-history',
    name: 'Allocation History',
    parents: [1],
    id: 6,
  },
  {
    url: '/so-past-allotment',
    name: 'Past Allotment',
    parents: [1],
    id: 7,
  },
  {
    url: '/so-past-allotment/detail',
    name: 'Past Allotment',
    parents: [7, 1],
    id: 8,
  },
  {
    url: '/settings',
    name: 'Settings',
    parents: [1],
    id: 9,
  },
  {
    url: '/setting/language',
    name: 'language',
    parents: [1, 9],
    id: 10,
  },
  {
    url: '/setting/notification',
    name: 'notification',
    parents: [1, 9],
    id: 11,
  },
];
