import { ButtonTypes, XB_Button } from '@core-components/atoms';

import './AU_ButtonGroup.style.scss';

export interface ButtonListProps {
  buttonText?: string;
  activeIcon?: string;
  inactiveIcon?: string;
}

export interface ActionButtonProps {
  title: string;
  handleClick: () => void;
  iconName: string;
  btnType: ButtonTypes;
  id: string;
}

interface ButtonGroupProps {
  buttonList: ButtonListProps[];
  buttonChangeFunc: (selectedtab: number) => void;
  buttonContentList: any[];
  buttonSelected: number;
  actionButton?: ActionButtonProps;
  _iconList?: any[];
  id: string[];
}

export const AU_ButtonGroup = ({
  buttonList,
  buttonChangeFunc,
  buttonContentList,
  buttonSelected,
  _iconList,
  actionButton,
  id,
}: ButtonGroupProps) => {
  const showButtonList = (item, index) => {
    if (item.inactiveIcon) {
      return (
        <XB_Button
          onClick={() => {
            buttonChangeFunc(index);
          }}
          dataTestId={`${id[index]}-BTN-GRP`}
          key={index}
          iconName={
            index === buttonSelected && item.activeIcon !== undefined
              ? item.activeIcon
              : item.inactiveIcon
          }
          classes={
            buttonSelected === index ? 'active-btn' : 'inactive-btn border-0'
          }
          btnType={buttonSelected === index ? 'secondary' : 'secondary-gray'}
        >
          {item.buttonText}
        </XB_Button>
      );
    } else {
      return (
        <XB_Button
          onClick={() => {
            buttonChangeFunc(index);
          }}
          dataTestId={`${id[index]}-BTN-GRP`}
          key={index}
          btnType={buttonSelected === index ? 'secondary' : 'secondary-gray'}
        >
          {item.buttonText}
        </XB_Button>
      );
    }
  };
  return (
    <div className="group-container">
      <div className="flex justify-between">
        <div className="button-group">
          {buttonList.map((item, index) => showButtonList(item, index))}
        </div>
        <div>
          {actionButton && (
            <XB_Button
              dataTestId={`${actionButton?.id}-ACTN`}
              onClick={actionButton?.handleClick}
              btnType={actionButton?.btnType}
              classes="ml-auto"
            >
              {actionButton?.title}
            </XB_Button>
          )}
        </div>
      </div>
      <div>
        {buttonContentList[buttonSelected]
          ? buttonContentList[buttonSelected]
          : null}
      </div>
    </div>
  );
};
