import { createSlice } from '@reduxjs/toolkit';

import { ITransactionData } from '../../models/profile-info.model';

const initialState = {
  txns: [] as ITransactionData[],
};

const fraudAndDisputeSlice = createSlice({
  name: 'fraudAndDispute',
  initialState,
  reducers: {
    setReportTransactions(state, { payload }) {
      state.txns = [...state.txns, ...payload];
    },
    removeReportTransactions(state) {
      state.txns = [];
    },
  },
});

export const { setReportTransactions, removeReportTransactions } =
  fraudAndDisputeSlice.actions;
export default fraudAndDisputeSlice.reducer;
