import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { truncate } from 'lodash';

import { XB_Button } from '@core-components/atoms';
import { RegEx } from '@core-constants';
import {
  getConcatenatedString,
  getINRFormatWithDecimal,
  isEmpty,
} from '@core-utils';

import './../styles/overview-card.style.scss';

export interface OverviewCardProps {
  cardTitle: string;
  amount: string;
  accountDetails: string;
  iconName: string;
  showBanner: boolean;
  bannerImg?: string;
  isAmountVisible: boolean;
  navigationUrl?: string;
  navigationUri?: string;
}

interface OverviewSectionProps {
  cardsData: OverviewCardProps[];
  leftSectionTitle: string;
  setShowNoDepositModal: (state: boolean) => void;
  CASAAccounts: number;
}

export const OverviewCards = ({
  cardsData,
  leftSectionTitle,
  CASAAccounts,
  setShowNoDepositModal,
}: OverviewSectionProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [maskedIds, setMaskedIds] = useState<any>({});

  const mask = (num: string) => {
    return num.replace(RegEx.masking, 'X');
  };

  const getAmount = (item, index) => {
    return maskedIds[index] ? (
      <h1 className="m-display-md-bold text-gray-900 self-center">
        {getINRFormatWithDecimal(Number(item.amount)).split('.')[0]}.
        <span className="m-text-sm-medium text-gray-900">
          {getINRFormatWithDecimal(Number(item.amount)).split('.')[1]}
        </span>
      </h1>
    ) : (
      <h1 className="m-display-md-bold text-gray-900 self-center">
        {mask(getINRFormatWithDecimal(Number(item.amount))).split('.')[0]}
        <span className="m-text-sm-medium text-gray-900">
          {`.${
            mask(getINRFormatWithDecimal(Number(item.amount))).split('.')[1]
          }`}
        </span>
      </h1>
    );
  };

  const onRedirection = (item: OverviewCardProps, isBanner: boolean) => {
    if (
      item?.cardTitle === 'totalAvailableBalance' ||
      item?.cardTitle === 'totalAmount'
    ) {
      if (isBanner) {
        if (!isEmpty(item?.navigationUrl)) {
          window.open(item?.navigationUrl, '_blank');
        } else if (!isEmpty(item?.navigationUri)) {
          navigate(item?.navigationUri ?? '');
        }
      } else {
        navigate('/accountsummary/home');
      }
    } else if (item?.cardTitle === 'totalDepositAmount') {
      if (isBanner) {
        if (CASAAccounts > 0) {
          if (!isEmpty(item?.navigationUrl)) {
            window.open(item?.navigationUrl, '_blank');
          } else if (!isEmpty(item?.navigationUri)) {
            navigate(item?.navigationUri ?? '');
          }
        } else {
          setShowNoDepositModal(true);
        }
      } else {
        navigate('/deposit/dashboard');
      }
    } else if (item?.cardTitle === 'totalOutstandingAmount') {
      if (isBanner) {
        if (!isEmpty(item?.navigationUrl)) {
          window.open(item?.navigationUrl, '_blank');
        } else if (!isEmpty(item?.navigationUri)) {
          navigate(item?.navigationUri ?? '', {
            state: { backOnLoanDashboard: false },
          });
        }
      } else {
        navigate('/loans');
      }
    }
  };

  const BannerUI = (item: OverviewCardProps) => {
    return (
      <div className="banner-img">
        <img
          src={item.bannerImg}
          onClick={() => {
            onRedirection(item, true);
          }}
          className="rounded-[0.613rem]"
        />
      </div>
    );
  };

  const AccountUI = (item: OverviewCardProps, index: number) => {
    return (
      <div className="card">
        <div className="p-3 flex flex-col gap-3">
          <div className="flex justify-between flex-col">
            <div className="flex justify-between">
              <div>
                <h2 className="m-text-md-regular text-gray-700">
                  {t(item.cardTitle)}
                </h2>
                <div className="overview_section_card_amount_details">
                  <>{getAmount(item, index)}</>

                  <XB_Button
                    iconName={maskedIds[index] ? 'eyeOffCasa' : 'eyeicon'}
                    iconColor={maskedIds[index] ? 'white' : 'text-gray-700'}
                    backgroundColor="white"
                    dataTestId="MASK-AMNT"
                    classes="overview_section_card_mask_eye_icon"
                    btnType="tertiary"
                    onClick={() => {
                      setMaskedIds({
                        ...maskedIds,
                        [index]: !(maskedIds[index] ?? false),
                      });
                    }}
                  />
                </div>
              </div>
              <img
                className="h-[1.5rem] w-[1.5rem]"
                src={item.iconName}
                width={24}
              />
            </div>

            <p className="m-text-sm-medium text-neutral-500 h-5 mt-3">
              <span className="m-text-sm-medium font-medium text-neutral-800">
                {item?.cardTitle === 'totalAvailableBalance' ||
                item?.cardTitle === 'totalAmount'
                  ? `${t('activeAccounts')}: `
                  : ''}
              </span>
              {truncate(item.accountDetails ?? '', {
                length:
                  item?.cardTitle === 'totalAvailableBalance' ||
                  item?.cardTitle === 'totalAmount'
                    ? 30
                    : 45,
              })}
            </p>
          </div>
        </div>
        <div className="overview_section_card_view-btn">
          <XB_Button
            dataTestId="VIEW-ALL"
            btnType="tertiary"
            onClick={() => {
              onRedirection(item, false);
            }}
            classes="w-full"
          >
            {t('viewAll')}
          </XB_Button>
        </div>
      </div>
    );
  };

  const OverviewUI = (item: OverviewCardProps, index) => {
    return (
      <div>{item.showBanner ? BannerUI(item) : AccountUI(item, index)}</div>
    );
  };

  return (
    <section className="overview_card_container">
      <div className="overview_section rounded-xl">
        <h1 className="m-display-sm-bold text-gray-900">
          {t(leftSectionTitle)}
        </h1>

        <div className="cards">
          {cardsData?.map((item, index: number) => {
            return (
              <div
                key={getConcatenatedString([index, item.cardTitle])}
                className="overview-card-container"
              >
                {OverviewUI(item, index)}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="approval_section">
        <h1 className="m-display-sm-bold text-gray-800">
          {t(rightSectionTitle)}
        </h1>
      </div> */}
    </section>
  );
};
