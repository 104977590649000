export const scheduledTransferType = {
  oneTime: 'ONE_TIME',
  recurring: 'RECURRING',
};

export const charLimit = {
  MIN_TNSFR_AMT: 1,
  MAX_TNSFR_AMT: 12,
  MIN_REMARK: 1,
  MAX_REMARK: 30,
  MIN_NO_PMNT: 1,
  MAX_NO_PMNT: 4,
  MIN_PAYEE_NAME: 1,
  MAX_PAYEE_NAME: 100,
};
export const MAX_PAYMENT_ALLOWED = 10;

export const paymentStatus = {
  pending: 'pending',
  paid: 'paid',
  success: 'success',
  failed: 'failed',
  failure: 'failure',
  transferred: 'transferred',
  rejected: 'rejected',
  scheduled: 'scheduled',
  cancelled: 'cancelled',
};

export const transferType = (flow) => {
  switch (flow) {
    case 'ad-hoc':
      return 'QUICK_TRANSFER';
    case 'self':
      return 'TRANSFER_TO_SELF';
    case 'multiple':
      return 'MULTIPLE_PAYMENTS';
    case 'gst':
      return 'TRANSFER_TO_GST';
    default:
      return 'TRANSFER_TO_PAYEE';
  }
};

export const paymentModes = {
  IMPS: 'IMPS',
  NEFT: 'NEFT',
  IFT: 'IFT',
  RTGS: 'RTGS',
};

export const cancelFlagType = {
  FOR_PAYEE: 'FOR_PAYEE',
  CANCEL_ALL_PAYMENT: 'CANCEL_ALL_PAYMENT',
  CANCEL_SINGLE_PAYMENT: 'CANCEL_SINGLE_PAYMENT',
};
export enum errorTypeOption {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  VERIFIED = 'verified',
  BLANK = '',
}
export const scheduleRestriction = {
  DAY: 'days',
  YEARS: 'years',
  ONE_TIME_COUNT: 1,
  RECURRING_COUNT: 90,
};
export const paymentTransferType = {
  QUICK_TNSFR: 'QUICK_TRANSFER',
  TNFR_TO_PAYEE: 'TRANSFER_TO_PAYEE',
  TNSFR_TO_SELF: 'TRANSFER_TO_SELF',
  MULTI_PAYMENTS: 'MULTIPLE_PAYMENTS',
  GST_PAYMENTS: 'TRANSFER_TO_GST',
};
export const paymentFeatureType = {
  QUICK_PAYMENT: 'QUICK_PAYMENT',
};

export const MULTIPLE_PAYMENT_LIMIT = 2;

export const TO_ACC_TYPE = '10';
export const FROM_ACC_TYPE = '10';
export const SOURCE = 'WEB';

export const RS_SYMBOL = '₹';

export const txnType = {
  recent: 'TRANSFERRED',
  scheduled: 'SCHEDULED',
};

export const CHANNEL = 'MERCHANT_APP';
export const REQ_TYPE = 'ESB';
export const FROM_IFSC_CODE = 'AUBL000';
export const TO_IFSC_CODE = 'AU';

export const ERR_CODES = {
  SCHEDULE_FAILED: 'PAYMENT0827',
  COOLING_PERIOD: 'PAYMENT0813',
  HOURLY_LIMIT: 'PAYMENT0856',
  DAILY_LIMIT: 'PAYMENT0857',
};

export const IFSC_FLAG = {
  YES: 'Y',
  NO: 'N',
};

export const ACC_VERIFY_ERR_CODES = ['AS0815', 'AS0816', 'AS0818'];

export const FE_PAYMENT_CONFIG = {
  CONFIG_TYPE: 'FE_PAYMENT_CONFIG',
  MODULE: 'fePaymentConfig',
};

export const TXN_LIMIT_TYPE = {
  DAILY: 'Daily',
  HOURLY: 'Hourly',
};
