export const sowRelation = 'SOW';

export const downloadFormatOptions = {
  PDF: 'PDF',
  CSV: 'CSV',
  XLS: 'EXCEL',
};

export const customerType = 'ETB';
export const leadSource = 'UBL01';
export const dates = [
  '22-05-2023',
  '22-02-2022',
  '22-05-2022',
  '30-05-2022',
  '22-04-2023',
];

export const LoanTypes = [
  'Personal Loan',
  'Home Loan',
  'Business Loan',
  'Agri Term Loan',
  'Car Loan',
  'Gold Loan',
  'Two Wheeler Loan',
];

export const LOAN_TYPES = Object.freeze({
  PERSONAL_LOAN: 'Personal Loan',
  HOME_LOAN: 'Home Loan',
  BUSINESS_LOAN: 'Business Loan',
  AGRI_TERM_LOAN: 'Agri Term Loan',
  CAR_LOAN: 'Car Loan',
  GOLD_LOAN: 'Gold Loan',
  TWO_WHEELER_LOAN: 'Two Wheeler Loan',
});

export const CROSS_SELL_NAME = {
  PERSONAL_LOAN: 'PERSONAL_LOAN',
  MUTUAL_FUND: 'MUTUAL_FUND',
  TRADING: 'TRADING',
  INSURANCE: 'INSURANCE',
  IPO: 'IPO',
  EQUITY: 'EQUITY',
};

export enum errorTypeOption {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  VERIFIED = 'verified',
  BLANK = '',
}
export const CUSTOMER_TYPE = {
  ETB: 'ETB',
  NTB: 'NTB',
};

export const LOAN_OVERDUE_PAYMENT = 'LOAN_OVERDUE_PAYMENT';

export const FE_LOAN_CONFIG_TYPE = {
  FE_LOAN_CONFIG: 'FE_LOAN_CONFIG',
  MODULE: 'feLoanConfig',
};
