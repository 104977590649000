import { InputHTMLAttributes, useEffect, useRef } from 'react';

import moment from 'moment';

import { appConstants } from '@core-constants';

import { XB_Input } from '../input/XB_Input.component';

import './AU_InputDate.style.scss';

export interface InputDateProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  classes?: string;
  name?: string;
  handleChange?: (e: any) => void;
  value?: string;
  _onInputChange?: (val: string) => void;
  msgType?: 'info' | 'success' | 'error' | 'loading' | 'verified' | '';
  infoMsgText?: string;
  defaultPlaceHoler?: string;
  disableInput?: boolean;
}

export const AU_InputDate = ({
  id,
  label,
  classes = '',
  value,
  defaultPlaceHoler = appConstants.dateFormatWithCommaSpace,
  name,
  _onInputChange,
  handleChange,
  msgType = '',
  infoMsgText,
  disableInput,
  ...otherProps
}: InputDateProps) => {
  const dateRef = useRef<HTMLInputElement>(null);

  const setAttrDate = () => {
    const ids: any = dateRef.current;
    if (ids) {
      if (ids.value) {
        ids.setAttribute?.(
          'data-date',
          moment(ids.value, 'YYYY-MM-DD').format(
            appConstants.dateFormatWithCommaSpace
          )
        );
      } else {
        ids.setAttribute?.('data-date', defaultPlaceHoler);
      }
    }
  };
  useEffect(() => {
    setAttrDate();
  }, [value]);

  const otherProperty = {
    onKeyDown: (_e) => {
      return false;
    },
    onChange: (e) => handleChange?.(e),
    name,
  };
  return (
    <div
      onClick={() => {
        if (!disableInput) {
          return dateRef?.current?.showPicker();
        }
      }}
      className={`${
        disableInput ? 'pointer-events-none disabled-date-input' : ''
      }`}
    >
      <XB_Input
        id={id ?? 'INPUT-DATE'}
        type="date"
        ref={dateRef}
        label={label}
        classes={`au-input-date ${classes}`}
        value={value}
        infoMsgText={infoMsgText}
        msgType={msgType}
        {...otherProperty}
        {...otherProps}
      />
    </div>
  );
};
