import './AU_AvatarProfile.style.scss';

interface AvatarProfileProps {
  size?: 'md' | 'lg' | 'sm' | 'xs' | 'xl' | '2xl';
  element: React.JSX.Element;
}

export const AU_AvatarProfile = ({
  size = 'lg',
  element,
}: AvatarProfileProps) => {
  const classArr = ['profile_container'];
  classArr.push(`profile_container_${size}`);
  return <div className={classArr.join(' ')}>{element}</div>;
};
