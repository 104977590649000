import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Service } from '@core-services';
import { logError } from '@core-utils';

export const XB_InfiniteScroll = ({
  data,
  asyncLoad,
  design,
  apiUrl,
  parentDiv,
  loader,
  dataCallback,
  page = 1,
  size = 10,
  pageKey = 'page',
  sizeKey = 'size',
  totalCount,
}: {
  data: any[];
  asyncLoad: boolean;
  design: (v: any, i: number) => React.ReactNode;
  apiUrl: string;
  parentDiv: string;
  dataCallback: (data: any) => any[];
  loader?: React.ReactNode;
  page?: number;
  size?: number;
  pageKey?: string;
  sizeKey?: string;
  totalCount: number;
}) => {
  const [list, setList] = useState(data);
  const [currPage, setCurrPage] = useState(page);
  const [hasMore, setHasMore] = useState(asyncLoad);
  const [onApiCall, setOnApiCall] = useState(false);

  useEffect(() => {
    setList(data);
  }, [data]);

  useEffect(() => {
    if (totalCount <= list.length) setHasMore(false);
  }, [totalCount]);

  const fetchList = async () => {
    setOnApiCall(true);
    const url = `${apiUrl}?${pageKey}=${currPage}&${sizeKey}=${size}`;
    const reqObj = {
      baseurl: url,
      method: 'POST',
      url: '',
      obj: {
        appId: 'EMPLOYEE_APP',
        isReferralGroup: true,
      },
    };
    try {
      const response = await Service.send(reqObj);
      if (response.data.status === 'success') {
        const { totalPages, userGroups } = response.data.data;
        const newCurrPage = currPage + 1;
        setCurrPage(newCurrPage);
        if (currPage === totalPages - 1) setHasMore(false);
        setOnApiCall(false);
        const newData = dataCallback(userGroups);
        const newArray = [...list, ...newData];
        setList(newArray);
      }
    } catch (err) {
      logError('something wrong');
    }
  };

  return (
    <InfiniteScroll
      data-testid="infinite-scroll-component"
      dataLength={list.length}
      next={fetchList}
      hasMore={hasMore}
      loader={onApiCall ? loader ?? <h4>Loading...</h4> : ''}
      scrollableTarget={parentDiv}
    >
      {list.map((v, i) => design(v, i))}
    </InfiniteScroll>
  );
};
