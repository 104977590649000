import { ConfigType } from '@core-constants';
import { AUEndPoints, Service } from '@core-services';

export const getConfig = async (configType: ConfigType, key?: string) => {
  const obj: {
    configType: ConfigType;
    key?: string;
  } = {
    configType,
  };
  if (key) {
    obj.key = key;
  }
  const reqObj = {
    baseurl: AUEndPoints.configService.CONFIG_URL,
    method: 'POST',
    url: '',
    obj,
  };
  const response = await Service.send(reqObj);
  return response.data;
};
