import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AU_Ttficon,
  XB_Button,
  XB_Divider,
  XB_Dropdown,
  XB_Input,
  XB_Radio,
} from '@core-components/atoms';
import {
  XB_CountryDropdown,
  XB_InlineMessage,
  XB_OptionCard,
} from '@core-components/molecules';
import {
  ACCOUNT_TYPES,
  appCharLimit,
  PERMISSIONS,
  RegEx,
  screenNumber,
} from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  accountType,
  AccountVerify,
  CustomerAccountDetails,
  IConfigDataType,
  IfscData,
  ifscSearch,
  internalAccountVerify,
  IPayeeConfig,
  payeeAccountConfirm,
  PayeeCasaAccountsRes,
  payeeCaseAccounts,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import {
  checkPermission,
  customerAccountFilter,
  getDropdownItemsList,
  getFEConfig,
  isEmpty,
  replaceOccuranceWithDelimeter,
  validateAccountNumber,
  validateIFSCInput,
  validateMobileNumber,
} from '@core-utils';
import { getAuBankCityList, getAuBankStateList } from '@modules/apply-now';
import { IBankDetailData } from '@modules/payments/models/payments.model';
import { getBankDetailList } from '@modules/payments/services/payments.service';
import { removePayeeList } from '@src/core-stores/slices/payee-casa-accounts.slice';

import {
  errorTypeOption,
  FE_PAYEE_CONFIG,
  IFSC_FLAG,
  TXN_BRANCH,
} from '../constant/constant';

import '../styles/addbank-sidebar.style.scss';

export interface VerifyBankProps {
  onClick: (...args) => void;
  bankData: {
    BankNameAPI: string;
    BankCategory: number;
    IFSC: string;
    BankDisplayName: string;
    showIfsc: string;
  };
  onSendAccountBankDetails: (e) => void;
  onSendMobileBankDetails: (e, bankData) => void;
  onSendAccountDetailWithIfsc: (e: any) => void;
  arrlen?: number;
}
const VerifyBank: React.FC<VerifyBankProps> = ({
  onClick,
  bankData,
  onSendAccountBankDetails,
  onSendMobileBankDetails,
  onSendAccountDetailWithIfsc,
  arrlen,
}: VerifyBankProps) => {
  const { t } = useTranslation();
  const auAlert = useAlertBox();

  const internalAccountVerification = useAppSelector(
    (internalAccountVerificationState) =>
      internalAccountVerificationState.internalAccountVerification
  );

  const { data: customerAccountsRdx } = useAppSelector(
    (customerAccountsState: RootState) => customerAccountsState.customerAccounts
  );

  const mappedAccounstList = Object.values(customerAccountsRdx ?? []).flat();

  const selectorPayeeAccount = useAppSelector(
    (selectorPayeeAccountState) => selectorPayeeAccountState.payeeCasaAccounts
  );
  const getPayeeAccountConfirmation = useAppSelector(
    (payeeAccConfirmationState) => payeeAccConfirmationState.payeeAccountConfirm
  );
  const getIfscSearch = useAppSelector(
    (ifscSearchListState) => ifscSearchListState.ifscSearchList
  );
  const dispatch = useAppDispatch();
  const getPayeeList = useAppSelector(
    (getPayeeState) => getPayeeState.getPayee
  );
  const getAccountVerify = useAppSelector(
    (accountVerificationState) => accountVerificationState.accountVerification
  );
  const accountTypeListRdx = useAppSelector(
    (accTypeState: RootState) => accTypeState.accType?.data
  );
  const PayeeFeCfgState: IConfigDataType[] =
    useAppSelector(
      (payeeConfigState: RootState) => payeeConfigState?.config?.data
    ) ?? [];

  const payeeConfig: IPayeeConfig = getFEConfig(
    PayeeFeCfgState,
    FE_PAYEE_CONFIG?.CONFIG_TYPE,
    FE_PAYEE_CONFIG?.MODULE
  );

  const maxBankAccountsLimit = Number(payeeConfig?.maxBankAccountsPayee);

  // ___________state_____________
  const [showUi, setShowUi] = useState<boolean>(true);
  const [accountNumber, setShowAccount] = useState(true);
  const [showMobileNumber, setShowMobileNumber] = useState(false);
  const [showIfsc, setShowIfsc] = useState<boolean>(true);
  const [isOtherBankAccount, setOtherBankAccount] = useState<string>('');
  const [reEnterAccount, setReEnterAccount] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [account, setAccount] = useState<string>('');
  const [ifsc, setIfsc] = useState<string>('');
  const [ifscManually, setIfscManually] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isAccountButtonDisabled, SetIsAccountButtonDisabled] =
    useState<boolean>(true);
  const [isMobileButtonDisabled, SetIsMobileButtonDisabled] =
    useState<boolean>(true);
  const [accountMsgs, setAccountMsgs] = useState<any>({});
  const [mobileMsgs, setMobileMsgs] = useState<any>({});
  const [hasIfscMsgs, setHasIfscMsgs] = useState<any>({});
  const [hasIfscMsgsManually, setHasIfscMsgsManually] = useState<any>({});

  const [mobileAccountDetail, setMobileAccountDetail] = useState<any[]>([]);
  const [payeeAccountList, setPayeeAccountList] =
    useState<PayeeCasaAccountsRes>();
  const [otherBankAccountMsgs, setOtherBankAccountMsgs] = useState<any>({});
  const [isButtonDisabled, setIsButtonDisAbled] = useState<boolean>(true);

  const [checklistCount, setCheckListCount] = useState<number>(arrlen ?? 0);
  const [accType, setAccType] = useState<string>();
  const [accTypeManually, setAccTypeManually] = useState<string>('');

  const [screen, setScreen] = useState<string>(screenNumber.FIRST_SCREEN);
  const [isCallApi, setIsCallApi] = useState<boolean>(true);
  const [accountsList, setAccountList] = useState<CustomerAccountDetails[]>([]);
  const [ifscApiRes, setIfscApiRes] = useState<IfscData>();
  const [addressListsMap, setAddressListsMap] = useState<Map<string, any[]>>(
    new Map()
  );
  const [state, setState] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [branch, setBranch] = useState<IBankDetailData | undefined>(
    {} as IBankDetailData
  );
  const [handleDropDownMenus, setHandleDropDownMenus] = useState<{
    state: 'OPEN' | 'CLOSE';
    city: 'OPEN' | 'CLOSE';
    branch: 'OPEN' | 'CLOSE';
  }>({
    state: 'CLOSE',
    city: 'CLOSE',
    branch: 'CLOSE',
  });

  const permissions = useAppSelector(
    (_permissionaState) => _permissionaState.profile.data?.permissions
  );

  // __________Handle Change Account______ state_____
  const handleAccountChange = (val) => {
    const responseAccount = validateAccountNumber(val);
    if (responseAccount.error !== '' && !isAccountButtonDisabled) {
      SetIsAccountButtonDisabled(true);
    }
    setAccountMsgs({ accountNo: responseAccount });
    setAccount(val);
    const isOwnAuAccount = mappedAccounstList?.find(
      (item) => item?.AccountId === val
    );
    if (isOwnAuAccount) {
      setAccountMsgs({
        errorAccount360: t('addPayeeOwnAccountRestrictionMessage'),
      });
    } else if (
      val.length === 16 &&
      checkPermission(
        permissions,
        PERMISSIONS.RBAC_PAYEE.INTERNAL_ACC_VERIFICATION
      )
    ) {
      handleAUAccFetch(val);
    }
  };

  const handleMobileNo = (val) => {
    const responseMobile = validateMobileNumber(val);
    if (responseMobile.error !== '' && !isMobileButtonDisabled) {
      if (payeeAccountList?.data && payeeAccountList?.data?.length > 0) {
        const res = dispatch(removePayeeList());
        setPayeeAccountList(res.payload);
        setCheckListCount(arrlen ?? 0);
        setMobileAccountDetail([]);
      }
      SetIsMobileButtonDisabled(true);
    }

    setMobileMsgs({ mobileNo: responseMobile });
    setMobileNumber(val);
  };

  const handleIfscChange = (val) => {
    const validIfsc = validateIFSCInput(val, 11);
    setHasIfscMsgs({ hasValidIfscMessage: validIfsc });
    setIfsc(val);
  };

  const handleIfscChangeManually = (val) => {
    const validIfsc = validateIFSCInput(val, 11);
    setHasIfscMsgsManually({ hasValidIfscMessage: validIfsc });
    setIfscManually(val);
  };

  const accTypeApi = async () => {
    try {
      const accTypeRes = await dispatch(
        accountType({
          refreshCache: true,
        })
      );
      if (!accTypeRes.payload.successfulResponse) {
        auAlert.error(accTypeRes.payload.displayMessage ?? accTypeRes.payload);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage);
    }
  };

  useEffect(() => {
    accTypeApi();
  }, []);

  const handleOtherBankAccount = (value) => {
    if (value.length < 3) {
      setOtherBankAccountMsgs({
        lengthError: t('invalidAccountNumber'),
      });
    } else {
      setOtherBankAccountMsgs({
        lengthError: '',
      });
    }
    setOtherBankAccount(value);
    setIsButtonDisAbled(true);
  };

  const handleName = (value) => {
    setName(value);
  };

  const handleReEnterAccount = (value) => {
    setReEnterAccount(value);
    setIsButtonDisAbled(true);
  };

  const handleAccChangeType = (value: string) => {
    setAccType(value);
  };

  const handleAccChangeTypeManually = (value: string) => {
    setAccTypeManually(value);
  };

  // __________Handle Change Account__Show error_________

  const handleOtherBankAccountMsgType = (value: string, keyname: any) => {
    let errorType: errorTypeOption = errorTypeOption.BLANK;
    if (getPayeeAccountConfirmation.loading) {
      errorType = errorTypeOption.LOADING;
    } else if (
      value === '' ||
      otherBankAccountMsgs?.[keyname[2]] ||
      otherBankAccountMsgs?.[keyname[3]]
    ) {
      errorType = errorTypeOption.BLANK;
    } else if (otherBankAccountMsgs?.[keyname[0]]) {
      errorType = errorTypeOption.ERROR;
    }
    return errorType;
  };

  const handleOtherBankShowAccountError = (keyname: any) => {
    if (otherBankAccountMsgs[keyname[0]]) {
      return t(otherBankAccountMsgs?.[keyname[0]]);
    }
  };

  const handleAccountMsgType = (value: string, keyname: any) => {
    let errorType: errorTypeOption = errorTypeOption.ERROR;
    if (internalAccountVerification.loading) {
      errorType = errorTypeOption.LOADING;
    } else if (value === '' || accountMsgs?.[keyname[0]]?.error === '') {
      errorType = errorTypeOption.BLANK;
    } else if (accountMsgs?.[keyname[1]]) {
      errorType = errorTypeOption.VERIFIED;
    }
    return errorType;
  };

  const handleShowAccountError = (keyname: any) => {
    if (accountMsgs[keyname[0]]) {
      return t(accountMsgs?.[keyname[0]]?.error);
    } else if (accountMsgs[keyname[1]]) {
      return accountMsgs?.[keyname[1]];
    } else {
      return accountMsgs?.[keyname[2]];
    }
  };

  const handleIfscMsgType = (value: string, keyname: any) => {
    let errorType: errorTypeOption = errorTypeOption.ERROR;
    if (getIfscSearch.loading) {
      errorType = errorTypeOption.LOADING;
    } else if (value === '' || hasIfscMsgs?.[keyname[0]]?.error === '') {
      errorType = errorTypeOption.BLANK;
    } else if (hasIfscMsgs?.[keyname[1]]) {
      errorType = errorTypeOption.VERIFIED;
    }
    return errorType;
  };

  const handleIfscMsgTypeManually = (value: string, keyname: any) => {
    let errorType: errorTypeOption = errorTypeOption.ERROR;
    if (getIfscSearch.loading) {
      errorType = errorTypeOption.LOADING;
    } else if (
      value === '' ||
      hasIfscMsgsManually?.[keyname[0]]?.error === ''
    ) {
      errorType = errorTypeOption.BLANK;
    } else if (hasIfscMsgsManually?.[keyname[1]]) {
      errorType = errorTypeOption.VERIFIED;
    }
    return errorType;
  };

  const handleShowIfscError = (keyname: any) => {
    if (hasIfscMsgs[keyname[0]]) {
      return t(hasIfscMsgs?.[keyname[0]]?.error);
    } else if (hasIfscMsgs[keyname[1]]) {
      return hasIfscMsgs?.[keyname[1]];
    } else {
      return hasIfscMsgs?.[keyname[2]];
    }
  };

  const handleShowIfscErrorManually = (keyname: any) => {
    if (hasIfscMsgsManually[keyname[0]]) {
      return t(hasIfscMsgsManually?.[keyname[0]]?.error);
    } else if (hasIfscMsgsManually[keyname[1]]) {
      return hasIfscMsgsManually?.[keyname[1]];
    } else {
      return hasIfscMsgsManually?.[keyname[2]];
    }
  };

  const handleMobileMsgType = (value: string, keyname: any) => {
    let errorType: errorTypeOption = errorTypeOption.ERROR;
    if (selectorPayeeAccount.loading) {
      errorType = errorTypeOption.LOADING;
    } else if (value === '' || mobileMsgs?.[keyname[0]]?.error === '') {
      errorType = errorTypeOption.BLANK;
    }
    return errorType;
  };

  const handleShowMobileError = (keyname: any) => {
    if (mobileMsgs[keyname[0]]) {
      return t(mobileMsgs?.[keyname[0]]?.error);
    } else if (mobileMsgs[keyname[1]]) {
      return mobileMsgs?.[keyname[1]];
    }
  };

  // _______Show error______api Calls_______________

  const handleAUAccFetch = async (accNumber) => {
    try {
      const acocuntStates = await dispatch(
        internalAccountVerify({
          AccountNumber: accNumber,
          TransactionBranch: TXN_BRANCH,
          ForAddPayee: true,
          PayeeId: '',
        })
      );

      if (acocuntStates.payload.successfulResponse) {
        setAccountMsgs({
          successAccount360: `${t('accountHolderName')} : ${
            acocuntStates.payload?.data?.CustomerResponse.CustomerBasicInquiry
              .CustomerFullName as string
          }`,
        });
        SetIsAccountButtonDisabled(false);
      } else {
        setAccountMsgs({
          errorAccount360: `${acocuntStates.payload as string}`,
        });
        SetIsAccountButtonDisabled(true);
        setScreen(screenNumber.THIRD_SCREEN);
        setIsCallApi(false);
      }
    } catch (e: any) {
      setScreen(screenNumber.THIRD_SCREEN);
      setIsCallApi(false);
    }
  };

  const showAccountList = async (mobileNum) => {
    try {
      if (
        mobileNum.length === 10 &&
        validateMobileNumber(mobileNum).error === ''
      ) {
        const accountList = await dispatch(
          payeeCaseAccounts({
            MobileNumber: mobileNum,
            TransactionBranch: TXN_BRANCH,
          })
        );

        if (accountList.payload.successfulResponse) {
          setPayeeAccountList(accountList.payload);
          SetIsMobileButtonDisabled(false);
        } else {
          setMobileMsgs({
            payeeAccountSuccess: accountList.payload,
          });
          SetIsMobileButtonDisabled(true);
        }
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage);
    }
  };

  const validateBankAccountExist = () => {
    return dispatch(
      payeeAccountConfirm({
        AccountNumber:
          (account || payeeAccountList?.data?.[0].AccountId) ??
          isOtherBankAccount,
      })
    );
  };

  const handlePayeeAccountConfirm = async () => {
    if (accountNumber) {
      internalAccountVerification.data &&
        onSendAccountBankDetails({
          accountNumber:
            internalAccountVerification?.data?.AccountDetail?.[0]?.AccountId,
          ifscCode: '',
          bankName: t('auBank'),
          bankDisplayName: t('auBank'),
          name:
            internalAccountVerification?.data?.CustomerResponse
              ?.CustomerBasicInquiry?.CustomerFullName ?? '',
          accountType: accType,
          verificationFlag: true,
          payeeCustomerId:
            internalAccountVerification?.data?.CustomerResponse
              ?.CustomerBasicInquiry?.CustomerId ?? '',
          showIfsc: bankData?.showIfsc,
        });
    } else {
      mobileAccountDetail &&
        onSendMobileBankDetails(mobileAccountDetail, bankData);
    }
  };

  const handleIfscSearch = async () => {
    if (validateIFSCInput(!isCallApi ? ifscManually : ifsc, 11).error === '') {
      const ifscList = await dispatch(
        ifscSearch({
          ifscCode: !isCallApi ? ifscManually : ifsc,
        })
      );
      if (ifscList.payload.successfulResponse) {
        if (
          bankData?.BankNameAPI?.toLowerCase()?.trim() ===
          ifscList.payload?.data?.bankName?.toLowerCase()?.trim()
        ) {
          setIfscApiRes(ifscList?.payload?.data);
          !isCallApi
            ? setHasIfscMsgsManually({
                successIfsc: ifscList.payload.data.branchName,
              })
            : setHasIfscMsgs({ successIfsc: ifscList.payload.data.branchName });
        } else {
          setHasIfscMsgs({ errorIfsc: t('errorIFSCMismatch') });
          setHasIfscMsgsManually({
            errorIfsc: t('errorIFSCMismatch'),
          });
        }
      } else {
        setHasIfscMsgs({ errorIfsc: t('errorValidIFSCCode') });
        setHasIfscMsgsManually({ errorIfsc: t('errorValidIFSCCode') });
      }
    }
  };

  const handleValidateOtherBankAccount = async () => {
    if (
      (bankData.BankCategory === 1 || bankData.BankCategory === 0) &&
      isOtherBankAccount.length >= 3
    ) {
      try {
        const ValidateAccountRes = await validateBankAccountExist();
        if (ValidateAccountRes.payload.data.validate) {
          setOtherBankAccountMsgs({
            success: t('verified'),
          });
          setIsButtonDisAbled(false);
        } else {
          setOtherBankAccountMsgs({
            payeeAlreadyAdded: ValidateAccountRes.payload.data.message,
          });
        }
      } catch (error: any) {
        auAlert.error(error?.displayMessage);
      }
    }
  };

  const accoutVerifywithImps = () => {
    return dispatch(
      AccountVerify({
        TransactionBranch:
          customerAccountsRdx?.CustomerAccount?.[0]?.CustomerBasicInquiry
            ?.HomeBranch ?? TXN_BRANCH,
        BeneficiaryAccountNo: isOtherBankAccount,
        BeneficiaryIFSCCode: handleIfscType(),
        RemitterAccountNo: accountsList?.[0]?.AccountId ?? '',
      })
    );
  };

  const handleIfscType = () => {
    const ifscForSearchBranch = getIfscForSelectedBranch();
    if (bankData.IFSC) return bankData.IFSC;
    else if (showIfsc) return ifsc;
    else return ifscForSearchBranch;
  };

  const handleOtherBankWithoutIfsc = async () => {
    try {
      const responseData = await accoutVerifywithImps();
      if (responseData.payload.successfulResponse) {
        onSendAccountDetailWithIfsc({
          accountNumber: isOtherBankAccount,
          ifscCode: bankData.IFSC,
          bankName: bankData.BankNameAPI,
          bankDisplayName: bankData.BankDisplayName,
          name: responseData.payload.data.BeneficiaryName,
          accountType: accType,
          verificationFlag: true,
          isRtgsEnabled: true,
          isNeftEnabled: true,
          showIfsc: bankData?.showIfsc,
        });
      } else {
        setOtherBankAccountMsgs({
          failedImpsRes: responseData.payload,
        });
        setScreen(screenNumber.THIRD_SCREEN);
        setIsCallApi(false);
        setIfsc('');
        setIsButtonDisAbled(true);
      }
    } catch (e: any) {
      setScreen(screenNumber.THIRD_SCREEN);
      setIsCallApi(false);
      setOtherBankAccount('');
      setIfsc('');
      setIsButtonDisAbled(true);
    }
  };

  const getIfscForSelectedBranch = () => {
    if (branch) {
      return branch ? branch?.ifscCode : '';
    }
  };

  const getIfscObjectForSearchBranch = () => {
    if (branch) return branch;
  };

  useEffect(() => {
    let accList: CustomerAccountDetails[] = [];
    if (customerAccountsRdx) {
      accList = customerAccountFilter(customerAccountsRdx, [
        ACCOUNT_TYPES.SavingsAccount.code,
        ACCOUNT_TYPES.CurrentAccount.code,
      ]);
      setAccountList(accList);
    }
  }, [customerAccountsRdx]);

  const handleClickWithBranchSearch = async () => {
    try {
      const responseData = await accoutVerifywithImps();

      if (responseData.payload.successfulResponse) {
        onSendAccountDetailWithIfsc({
          accountNumber: isOtherBankAccount,
          ifscCode: getIfscForSelectedBranch(),
          bankName: bankData.BankNameAPI,
          bankDisplayName: bankData.BankDisplayName,
          name: responseData.payload.data.BeneficiaryName,
          accountType: accType,
          screen,
          verificationFlag: true,
          isRtgsEnabled:
            getIfscObjectForSearchBranch()?.srtgs?.toLowerCase()?.trim() ===
            IFSC_FLAG.YES.toLowerCase()?.trim(),
          isNeftEnabled:
            getIfscObjectForSearchBranch()?.sneft?.toLowerCase()?.trim() ===
            IFSC_FLAG.YES.toLowerCase()?.trim(),
          showIfsc: bankData?.showIfsc,
        });
      } else {
        setOtherBankAccountMsgs({
          failedImpsRes: responseData.payload,
        });
        setScreen(screenNumber.THIRD_SCREEN);
        setIsCallApi(false);
        setOtherBankAccount('');
        setIfsc('');
        setBranch(undefined);
        setShowIfsc(true);
        setIsButtonDisAbled(true);
      }
    } catch (err: any) {
      setScreen(screenNumber.THIRD_SCREEN);
      setOtherBankAccount('');
      setIfsc('');
      setBranch(undefined);
      setShowIfsc(true);
      setIsCallApi(false);
      setIsButtonDisAbled(true);
    }
  };

  const handleClickWithIfsc = async () => {
    try {
      const responseData = await accoutVerifywithImps();

      if (responseData.payload.successfulResponse) {
        onSendAccountDetailWithIfsc({
          accountNumber: isOtherBankAccount,
          ifscCode: ifsc,
          bankName: bankData.BankNameAPI,
          bankDisplayName: bankData.BankDisplayName,
          name: responseData.payload.data.BeneficiaryName,
          accountType: accType,
          screen,
          verificationFlag: true,
          isRtgsEnabled:
            getIfscSearch?.data?.srtgs?.toLowerCase()?.trim() ===
            IFSC_FLAG.YES.toLowerCase()?.trim(),
          isNeftEnabled:
            getIfscSearch?.data?.sneft?.toLowerCase()?.trim() ===
            IFSC_FLAG.YES.toLowerCase()?.trim(),
          showIfsc: bankData?.showIfsc,
        });
      } else {
        setOtherBankAccountMsgs({
          failedImpsRes: responseData.payload,
        });
        setScreen(screenNumber.THIRD_SCREEN);
        setIsCallApi(false);
        setIfsc('');
        setBranch(undefined);
      }
      setIsButtonDisAbled(true);
    } catch (err: any) {
      setScreen(screenNumber.THIRD_SCREEN);
      setIsCallApi(false);
      setOtherBankAccount('');
      setIfsc('');
      setBranch(undefined);
    }
  };

  const getIfscForManualUi = () => {
    if (showIfsc && ifscApiRes) {
      return ifscApiRes;
    } else {
      return getIfscObjectForSearchBranch();
    }
  };

  const addBankAccManually = () => {
    onSendAccountDetailWithIfsc({
      accountNumber: isOtherBankAccount,
      ifscCode: bankData.BankCategory === 1 ? bankData?.IFSC : ifscManually,
      bankName: bankData.BankNameAPI,
      bankDisplayName: bankData.BankDisplayName,
      name: name.replace('&', ' and ').replace('  ', ' '),
      accountType: accType,
      verificationFlag: false,
      isNeftEnabled:
        bankData.BankCategory === 1
          ? true
          : getIfscForManualUi()?.sneft?.toLowerCase()?.trim() ===
            IFSC_FLAG.YES.toLowerCase()?.trim(),
      isRtgsEnabled:
        bankData.BankCategory === 1
          ? true
          : getIfscForManualUi()?.srtgs?.toLowerCase()?.trim() ===
            IFSC_FLAG.YES.toLowerCase()?.trim(),
      showIfsc: bankData?.showIfsc,
    });
  };

  const handleFailedImpscSearchByBank = () => {
    onSendAccountDetailWithIfsc({
      accountNumber: isOtherBankAccount,
      ifscCode: getIfscForSelectedBranch(),
      bankName: bankData.BankNameAPI,
      bankDisplayName: bankData.BankDisplayName,
      name,
      accountType: accType,
      verificationFlag: false,
      isRtgsEnabled:
        getIfscObjectForSearchBranch()?.srtgs?.toLowerCase()?.trim() ===
        IFSC_FLAG.YES.toLowerCase()?.trim(),

      isNeftEnabled:
        getIfscObjectForSearchBranch()?.sneft?.toLowerCase()?.trim() ===
        IFSC_FLAG.YES.toLowerCase()?.trim(),
      showIfsc: bankData?.showIfsc,
    });
  };
  // _____________api Calls_______________

  const searchDetailPropsAU = {
    name: 'searchDetailAU',
    onChange: (e) => {
      if (e.target.value === '1') {
        setShowUi(true);
        setShowMobileNumber(false);
        setShowAccount(true);
      } else {
        setShowUi(true);
        setShowAccount(false);
        setShowMobileNumber(true);
        setMobileNumber('');
        setMobileAccountDetail([]);
        setPayeeAccountList(undefined);
        setCheckListCount(arrlen ?? 0);
        setAccount('');
        setAccountMsgs({});
      }
    },
  };

  const searchIfscProps = {
    name: 'searchIfscDetail',
    onChange: (e) => {
      if (e.target.value === '1') {
        setShowUi(true);
        setShowIfsc(true);
        setBranch(undefined);
      } else {
        setShowUi(true);
        setShowIfsc(false);
        initAUBankStateListApi();
      }
      setIfsc('');
      setIfscManually('');
      setHasIfscMsgsManually({});
      setHasIfscMsgs({});
    },
  };

  const payeeAccountListProps = {
    name: 'payeeAccountList',
    onClick: (e) => {
      if (e.target.checked) {
        setCheckListCount((prev) => prev + 1);
        const newval: any[] = [];
        newval.push(...mobileAccountDetail, JSON.parse(e.target.value));
        setMobileAccountDetail(newval);
      } else {
        setCheckListCount((prev) => prev - 1);
        const parsedData = JSON.parse(e.target.value);
        const filterData = mobileAccountDetail.filter(
          (ele) => ele.AccountId !== parsedData.AccountId
        );
        setMobileAccountDetail(filterData);
      }
    },
  };

  const renderBasedBankCategory = () => {
    if (bankData.BankCategory === 1 && isCallApi) {
      return (
        <>
          {otherBankAccountMsgs.payeeAlreadyAdded && (
            <XB_InlineMessage
              icon="info-circle"
              message={otherBankAccountMsgs.payeeAlreadyAdded}
              type="error"
              classes="mt-2 w-full"
            />
          )}
          {otherBankAccountMsgs.failedImpsRes && (
            <XB_InlineMessage
              icon="info-circle"
              message={otherBankAccountMsgs.failedImpsRes}
              type="error"
              classes="mt-2 w-full"
            />
          )}
          <XB_InlineMessage
            icon="info-circle"
            title={t('ifscNotRequired')}
            message={t('noIfscMsg', { name: bankData?.BankNameAPI })}
            type="info"
            classes="mt-2"
          />

          <XB_Button
            dataTestId="ADD-VRFY"
            classes="mt-6 w-full"
            disabled={isButtonDisabled || isEmpty(accType)}
            showLoader={getAccountVerify.loading}
            onClick={() => {
              handleOtherBankWithoutIfsc();
            }}
          >
            {t('addAndVerify')}
          </XB_Button>
        </>
      );
    } else {
      return (
        <div>
          {otherBankAccountMsgs.payeeAlreadyAdded && (
            <XB_InlineMessage
              icon="info-circle"
              message={otherBankAccountMsgs.payeeAlreadyAdded}
              type="error"
              classes="mt-2 w-full"
            />
          )}
          {otherBankAccountMsgs.failedImpsRes && (
            <XB_InlineMessage
              icon="info-circle"
              message={otherBankAccountMsgs.failedImpsRes}
              type="error"
              classes="mt-2 w-full"
            />
          )}
          <div className="flex flex-1 mt-2">
            <XB_Radio
              id="BY-IFSC"
              label={t('enterIfscCode')}
              value="1"
              {...searchIfscProps}
              isChecked={showIfsc}
            />
            <XB_Radio
              id="BY-BRCH-NAME"
              label={t('searchByBranchName')}
              classes="ml-10"
              value="2"
              isChecked={!showIfsc}
              {...searchIfscProps}
            />
          </div>
          {showUi && showIfsc && (
            <div className="mt-6">
              <XB_Input
                id="IFSC-CODE"
                label={t('ifscCode')}
                classes="mt-3"
                onInputChange={(val) => handleIfscChange(val)}
                maxLength={appCharLimit.MAX_IFSC_CODE}
                value={ifsc}
                onBlur={() => {
                  handleIfscSearch();
                }}
                placeholder={t('enterIfscCode')}
                msgType={handleIfscMsgType(ifsc, [
                  'hasValidIfscMessage',
                  'successIfsc',
                  'errorIfsc',
                ])}
                infoMsgText={handleShowIfscError([
                  'hasValidIfscMessage',
                  'successIfsc',
                  'errorIfsc',
                ])}
              />
              <div className="mt-6">
                <XB_Button
                  dataTestId="ADD-VRFY"
                  classes="w-full"
                  onClick={() => {
                    handleClickWithIfsc();
                  }}
                  disabled={isActiveVerifyButton()}
                  showLoader={getAccountVerify.loading}
                >
                  {t('addAndVerify')}
                </XB_Button>
              </div>
            </div>
          )}
          {showUi && !showIfsc && (
            <div className="mt-6">
              <XB_Dropdown
                id="SLCT-STTE"
                items={getDropdownItemsList(
                  'state',
                  'state',
                  addressListsMap.get('states') ?? []
                )}
                placeholderOption={t('selectState')}
                label={t('state')}
                onChange={(val) => handleChangeState(val)}
                search={true}
                classes="min-w-full"
                showDropDownMenus={handleDropDownMenus?.state}
                dropDownclicked={() =>
                  setHandleDropDownMenus({
                    ...handleDropDownMenus,
                    city: 'CLOSE',
                    branch: 'CLOSE',
                    state: 'OPEN',
                  })
                }
              />
              <XB_Dropdown
                id="SLCT-CITY"
                items={getDropdownItemsList(
                  'city',
                  'city',
                  addressListsMap.get('cities') ?? []
                )}
                placeholderOption={t('selectCity')}
                label={t('city')}
                onChange={(val) => handleChangeCity(val)}
                search={true}
                classes="min-w-full mt-6"
                disabled={state === ''}
                showDropDownMenus={handleDropDownMenus?.city}
                dropDownclicked={() =>
                  setHandleDropDownMenus({
                    ...handleDropDownMenus,
                    state: 'CLOSE',
                    branch: 'CLOSE',
                    city: 'OPEN',
                  })
                }
              />
              <XB_Dropdown
                id="SLCT-BNCH"
                items={getDropdownItemsList(
                  'ifscCode',
                  'branchName',
                  addressListsMap.get('branches') ?? [],
                  'ifscCode'
                )}
                placeholderOption={t('selectBranch')}
                label={t('branch')}
                onChange={(val) => handleChangeBranch(val)}
                search={true}
                classes="min-w-full mt-6"
                disabled={city === ''}
                showDropDownMenus={handleDropDownMenus?.branch}
                dropDownclicked={() =>
                  setHandleDropDownMenus({
                    ...handleDropDownMenus,
                    city: 'CLOSE',
                    state: 'CLOSE',
                    branch: 'OPEN',
                  })
                }
              />
              {!isEmpty(branch) && (
                <div className="rounded bg-gray-100 mt-6 p-2 min-w-full">
                  <p className="m-text-sm-regular text-gray-700">{t('ifsc')}</p>
                  <h3 className="m-text-md-medium text-gray-900">
                    {branch?.ifscCode}
                  </h3>
                </div>
              )}

              <div className="mt-6">
                <XB_Button
                  dataTestId="ADD-VRFY"
                  classes="w-full"
                  onClick={() => {
                    handleClickWithBranchSearch();
                  }}
                  disabled={isActiveVerifyButton()}
                  showLoader={getAccountVerify.loading}
                >
                  {t('addAndVerify')}
                </XB_Button>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  const isActiveVerifyButton = () => {
    let isButtonActive = true;
    if (showIfsc) {
      if (
        isCallApi &&
        isOtherBankAccount !== '' &&
        isOtherBankAccount.length > 3 &&
        validateIFSCInput(ifsc, 11).error === '' &&
        hasIfscMsgs.successIfsc &&
        accType &&
        !isButtonDisabled
      ) {
        isButtonActive = false;
      } else if (
        isOtherBankAccount !== '' &&
        isOtherBankAccount.length > 3 &&
        validateIFSCInput(ifsc, 11).error === '' &&
        hasIfscMsgs.successIfsc &&
        name !== '' &&
        isOtherBankAccount === reEnterAccount &&
        accType &&
        !isButtonDisabled
      ) {
        isButtonActive = false;
      }
    } else {
      if (
        isCallApi &&
        !showIfsc &&
        isCallApi &&
        isOtherBankAccount !== '' &&
        isOtherBankAccount.length > 3 &&
        getIfscForSelectedBranch() &&
        accType &&
        !isButtonDisabled
      ) {
        isButtonActive = false;
      } else if (
        isOtherBankAccount !== '' &&
        isOtherBankAccount.length > 3 &&
        getIfscForSelectedBranch() &&
        name !== '' &&
        isOtherBankAccount === reEnterAccount &&
        accType &&
        !isButtonDisabled
      ) {
        isButtonActive = false;
      }
    }
    return isButtonActive;
  };

  const renderInfo = () => {
    return (
      <div className="flex flex-col">
        <div>
          <XB_Input
            id="ACCT-NMBR"
            label={t('accountNumber')}
            maxLength={appCharLimit.MAX_ACC_NUMBER}
            type="text"
            value={isOtherBankAccount}
            regex={RegEx.accountno}
            onInputChange={(val) => {
              if (
                checkPermission(
                  permissions,
                  PERMISSIONS.RBAC_PAYEE.VIEW_VERIFY_PAYEE_ACCT
                )
              ) {
                handleOtherBankAccount(val);
              }
            }}
            disabled={
              !checkPermission(
                permissions,
                PERMISSIONS.RBAC_PAYEE.VIEW_VERIFY_PAYEE_ACCT
              )
            }
            onBlur={() => {
              if (
                checkPermission(
                  permissions,
                  PERMISSIONS.RBAC_PAYEE.VIEW_VERIFY_PAYEE_ACCT
                )
              ) {
                handleValidateOtherBankAccount();
              }
            }}
            msgType={handleOtherBankAccountMsgType(isOtherBankAccount, [
              'lengthError',
              'success',
              'payeeAlreadyAdded',
              'failedImpsRes',
            ])}
            infoMsgText={handleOtherBankShowAccountError(['lengthError'])}
            placeholder={t('enterAccountNumber')}
          />
        </div>
        <div className="flex mt-6">
          <XB_Dropdown
            id="SLCT-ACC-TYPE"
            label={t('accountType')}
            items={getDropdownItemsList(
              'accountCode',
              'accountType',
              accountTypeListRdx ?? []
            )}
            placeholderOption={t('selectAccountType')}
            classes="flex-1 min-w-full"
            onChange={(e) => {
              handleAccChangeType(e);
            }}
          />
        </div>
        <div className="mt-5">{renderBasedBankCategory()}</div>
      </div>
    );
  };

  const disableManualVerifyButton = () => {
    let isButtondisable = false;
    const commonChecks =
      isOtherBankAccount !== reEnterAccount ||
      isOtherBankAccount === '' ||
      name === '' ||
      isButtonDisabled;
    if (bankData.BankCategory === 1) {
      isButtondisable = commonChecks || accTypeManually === '';
    } else if (bankData.BankCategory === 0) {
      isButtondisable =
        commonChecks || accTypeManually === '' || showIfsc
          ? isEmpty(hasIfscMsgsManually.successIfsc)
          : isEmpty(getIfscForSelectedBranch());
    } else {
      isButtondisable = commonChecks;
    }
    return isButtondisable;
  };

  const disableOptionCard = (accountNo: any): any => {
    const arr: any[] = [];
    const isOwnAuAccount = mappedAccounstList?.find(
      (item) => item?.AccountId === accountNo
    ); // check acc is own or not
    getPayeeList.data?.payeeDetailsResponses?.forEach((val: any) => {
      arr.push(...val.BankDetails);
    });
    const isActive = arr.filter((val) => val.AccountNumber === accountNo);
    let returnStatus =
      (isActive?.length && isActive?.length > 0) || isOwnAuAccount;
    if (!returnStatus && checklistCount > maxBankAccountsLimit - 1) {
      const data = mobileAccountDetail.filter(
        (ele) => ele.AccountId === accountNo
      );
      returnStatus = data.length <= 0;
    }
    return returnStatus;
  };

  const handleChangeState = (val) => {
    setState(val);
  };
  const handleChangeCity = (val) => {
    setCity(val);
  };
  const handleChangeBranch = (val) => {
    const branchList = addressListsMap.get('branches');
    const branchObj = branchList?.find(
      (item) => item?.ifscCode?.toLowerCase() === val.toLowerCase()
    );
    setBranch(branchObj);
  };
  const initAUBankStateListApi = async () => {
    try {
      const resStates = await getAuBankStateList();
      if (resStates?.successfulResponse) {
        setAddressListsMap(
          (map) => new Map(map.set('states', resStates?.data))
        );
      } else {
        auAlert.error(resStates?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    }
  };

  const initAUBankCityListApi = async (_state: string) => {
    try {
      const resCities = await getAuBankCityList(_state);
      if (resCities?.successfulResponse) {
        setAddressListsMap((map) => new Map(map.set('cities', resCities.data)));
      } else {
        auAlert.error(resCities?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    }
  };
  const getBankListDetailApi = async (_city: string) => {
    try {
      const resBranches = await getBankDetailList({
        bankName: bankData?.BankNameAPI,
        city: _city,
        state,
      });
      if (resBranches?.successfulResponse) {
        setAddressListsMap(
          (map) => new Map(map.set('branches', resBranches?.data))
        );
      } else {
        auAlert.error(resBranches?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    }
  };

  useEffect(() => {
    if (state) {
      handleChangeCity('');
      handleChangeBranch('');
      initAUBankCityListApi(state);
    }
  }, [state]);

  useEffect(() => {
    if (city) {
      handleChangeBranch('');
      getBankListDetailApi(city);
    }
  }, [city]);

  const renderManualInfo = () => {
    return (
      <>
        <XB_InlineMessage
          type="error"
          icon="info-circle"
          title={t('weAreUnableToVerifyAcc')}
          classes="mb-4 w-full"
        />
        <div>
          <XB_Input
            id="ACCT-HLDR-NAME"
            label={t('accountHolderName')}
            placeholder={t('accountHolderName')}
            type="text"
            regex={RegEx.payeeName}
            autoComplete="false"
            onInputChange={(val) => handleName(val)}
            value={name}
          />
        </div>
        <div className="mt-6">
          <XB_Input
            id="ACCT-NMBR"
            label={t('accountNumber')}
            type="password"
            maxLength={bankData?.BankCategory === 2 ? 16 : 30}
            regex={
              bankData?.BankCategory === 2
                ? RegEx.onlyDigit
                : RegEx.onlyDigitAlpha
            }
            value={isOtherBankAccount}
            onInputChange={(val) => handleOtherBankAccount(val)}
            msgType={handleOtherBankAccountMsgType(isOtherBankAccount, [
              'lengthError',
              'success',
              'payeeAlreadyAdded',
              'failedImpsRes',
            ])}
            infoMsgText={handleOtherBankShowAccountError(['lengthError'])}
            placeholder={t('enterAccountNumber')}
            onBlur={() => {
              if (
                checkPermission(
                  permissions,
                  PERMISSIONS.RBAC_PAYEE.VIEW_VERIFY_PAYEE_ACCT
                )
              ) {
                handleValidateOtherBankAccount();
              }
            }}
          />
          {otherBankAccountMsgs.payeeAlreadyAdded && (
            <XB_InlineMessage
              icon="info-circle"
              message={otherBankAccountMsgs.payeeAlreadyAdded}
              type="error"
              classes="mt-2 w-full"
            />
          )}
        </div>
        <div className="mt-6">
          <XB_Input
            id="RE-ACCT-NMBR"
            label={t('reEnterAccountNumberSmall')}
            maxLength={bankData?.BankCategory === 2 ? 16 : 30}
            regex={
              bankData?.BankCategory === 2
                ? RegEx.onlyDigit
                : RegEx.onlyDigitAlpha
            }
            type="text"
            value={reEnterAccount}
            onInputChange={(val) => handleReEnterAccount(val)}
            msgType={
              isOtherBankAccount !== '' &&
              reEnterAccount !== '' &&
              isOtherBankAccount !== reEnterAccount
                ? 'error'
                : ''
            }
            infoMsgText={
              isOtherBankAccount !== '' &&
              reEnterAccount !== '' &&
              isOtherBankAccount !== reEnterAccount
                ? t('accountNumberDoesNotMatch')
                : ''
            }
            placeholder={t('reEnterAccountNumberSmall')}
            onBlur={() => {
              if (
                checkPermission(
                  permissions,
                  PERMISSIONS.RBAC_PAYEE.VIEW_VERIFY_PAYEE_ACCT
                )
              ) {
                handleValidateOtherBankAccount();
              }
            }}
          />
        </div>
        <div className="flex mt-6">
          <XB_Dropdown
            id="SLCT-ACC-TYPE"
            label={t('accountType')}
            items={getDropdownItemsList(
              'accountCode',
              'accountType',
              accountTypeListRdx ?? []
            )}
            placeholderOption={t('selectAccountType')}
            classes="flex-1 min-w-full"
            onChange={(e) => {
              handleAccChangeTypeManually(e);
            }}
          />
        </div>
        {bankData.BankCategory !== 1 && (
          <div>
            <div className="flex flex-1 mt-6">
              <XB_Radio
                id="BY-IFSC"
                label={t('enterIfscCode')}
                classes=""
                value="1"
                {...searchIfscProps}
                isChecked={showIfsc}
              />
              <XB_Radio
                id="BY-MBLE-NMBR"
                label={t('searchByBranchName')}
                classes="ml-10"
                value="2"
                isChecked={!showIfsc}
                {...searchIfscProps}
              />
            </div>
            {showUi && showIfsc && (
              <div className="mt-6">
                <XB_Input
                  id="IFSC-CODE"
                  label={t('ifscCode')}
                  classes="mt-3"
                  onInputChange={(val) => handleIfscChangeManually(val)}
                  maxLength={appCharLimit.MAX_IFSC_CODE}
                  value={ifscManually}
                  onBlur={() => {
                    handleIfscSearch();
                  }}
                  msgType={handleIfscMsgTypeManually(ifscManually, [
                    'hasValidIfscMessage',
                    'successIfsc',
                    'errorIfsc',
                  ])}
                  infoMsgText={handleShowIfscErrorManually([
                    'hasValidIfscMessage',
                    'successIfsc',
                    'errorIfsc',
                  ])}
                  placeholder={t('enterIfscCode')}
                />
                <XB_Button
                  dataTestId="ADD-VRFY"
                  classes="w-full mt-6"
                  disabled={disableManualVerifyButton()}
                  showLoader={getAccountVerify.loading}
                  onClick={() => {
                    addBankAccManually();
                  }}
                >
                  {t('addAccount')}
                </XB_Button>
              </div>
            )}
            {showUi && !showIfsc && (
              <div className="mt-6">
                <XB_Dropdown
                  id="SLCT-STTE"
                  items={getDropdownItemsList(
                    'state',
                    'state',
                    addressListsMap.get('states') ?? []
                  )}
                  placeholderOption={t('selectState')}
                  label={t('state')}
                  onChange={(val) => handleChangeState(val)}
                  search={true}
                  classes="min-w-full"
                />
                <XB_Dropdown
                  id="SLCT-CITY"
                  items={getDropdownItemsList(
                    'city',
                    'city',
                    addressListsMap.get('cities') ?? []
                  )}
                  placeholderOption={t('selectCity')}
                  label={t('city')}
                  onChange={(val) => handleChangeCity(val)}
                  search={true}
                  classes="min-w-full mt-6"
                  disabled={state === ''}
                />
                <XB_Dropdown
                  id="SLCT-BNCH"
                  items={getDropdownItemsList(
                    'ifscCode',
                    'branchName',
                    addressListsMap.get('branches') ?? [],
                    'ifscCode'
                  )}
                  placeholderOption={t('selectBranch')}
                  label={t('branch')}
                  onChange={(val) => handleChangeBranch(val)}
                  search={true}
                  classes="min-w-full mt-6"
                  disabled={city === ''}
                />
                {!isEmpty(branch) && (
                  <div className="rounded bg-gray-100 mt-6 p-2 min-w-full">
                    <p className="m-text-sm-regular text-gray-700">
                      {t('ifsc')}
                    </p>
                    <h3 className="m-text-md-medium text-gray-900">
                      {branch?.ifscCode}
                    </h3>
                  </div>
                )}

                <XB_Button
                  dataTestId="ADD-VRFY"
                  classes="w-full mt-6 mb-6"
                  disabled={disableManualVerifyButton()}
                  showLoader={getAccountVerify.loading}
                  onClick={() => {
                    handleFailedImpscSearchByBank();
                  }}
                >
                  {t('addAccount')}
                </XB_Button>
              </div>
            )}
          </div>
        )}

        {bankData.BankCategory !== 0 && (
          <XB_Button
            dataTestId="ADD-VRFY"
            classes="w-full mt-6"
            disabled={disableManualVerifyButton()}
            showLoader={getAccountVerify.loading}
            onClick={() => {
              addBankAccManually();
            }}
          >
            {t('addAccount')}
          </XB_Button>
        )}
      </>
    );
  };

  return (
    <div className="selected-bank-container">
      <h2 className="m-text-md-regular text-gray-700 mt-6">
        {t('selectedBank')}
      </h2>
      <div className="selected-bank-name flex items-center">
        <AU_Ttficon
          icon={replaceOccuranceWithDelimeter(
            bankData?.BankDisplayName ?? '',
            RegEx.spaceRegex,
            '_'
          )}
          style={{ height: '1.25rem', width: '1.25rem' }}
          type="bank"
        />

        <h3 className="m-text-lg-regular text-gray-700 ml-2 ">
          {bankData.BankDisplayName}
        </h3>
        <XB_Button
          dataTestId="CNGE"
          btnType="tertiary"
          onClick={onClick}
          classes="ml-auto"
        >
          {t('change')}
        </XB_Button>
      </div>
      <XB_Divider classes="my-6" />
      {bankData.BankNameAPI?.toLowerCase()?.trim() ===
      t('auBank')?.toLowerCase()?.trim() ? (
        <>
          <div className="search-option">
            <h3 className="m-text-md-regular text-gray-700">
              {t('searchWith')}
            </h3>

            <div className="flex flex-1 mt-4">
              <XB_Radio
                id="BY-ACCT-NMBR"
                label={t('byAccountNumber')}
                classes=""
                value="1"
                isChecked={accountNumber}
                {...searchDetailPropsAU}
              />
              <XB_Radio
                id="BY-MBLE-NMBR"
                label={t('byMobileNumber')}
                classes="ml-10"
                value="2"
                isChecked={showMobileNumber}
                {...searchDetailPropsAU}
              />
            </div>
            <div>
              {showUi && accountNumber && (
                <>
                  <div>
                    <XB_Input
                      id="ACCT-NMBR"
                      label={t('accountNumber')}
                      infoMsgText={handleShowAccountError([
                        'accountNo',
                        'successAccount360',
                      ])}
                      msgType={handleAccountMsgType(account, [
                        'accountNo',
                        'successAccount360',
                      ])}
                      placeholder={t('enterAccountNumber')}
                      classes="mt-6"
                      type="text"
                      regex={/^[0-9\b]+$/}
                      maxLength={16}
                      value={account}
                      disabled={
                        !checkPermission(
                          permissions,
                          PERMISSIONS.RBAC_PAYEE.INTERNAL_ACC_VERIFICATION
                        )
                      }
                      onInputChange={(val) => {
                        if (
                          checkPermission(
                            permissions,
                            PERMISSIONS.RBAC_PAYEE.INTERNAL_ACC_VERIFICATION
                          )
                        ) {
                          handleAccountChange(val);
                        }
                      }}
                    />

                    {accountMsgs.errorAccount360 && (
                      <XB_InlineMessage
                        type="error"
                        classes="mt-2 w-full"
                        message={
                          (accountMsgs.errorAccount360 ??
                            internalAccountVerification?.error) as string
                        }
                      />
                    )}
                  </div>

                  <div className="absolute bottom-6 left-6 right-6">
                    <XB_Button
                      dataTestId="NEXT"
                      classes="w-full"
                      disabled={isAccountButtonDisabled}
                      onClick={() => {
                        handlePayeeAccountConfirm();
                      }}
                    >
                      {t('next')}
                    </XB_Button>
                  </div>
                </>
              )}
              {showUi && showMobileNumber && (
                <>
                  <div className="mt-6">
                    <XB_Input
                      id="MBLE-NMBR"
                      type="text"
                      disabled={
                        !checkPermission(
                          permissions,
                          PERMISSIONS.RBAC_PAYEE.VIEW_PAYEE_CASA_ACCTS
                        )
                      }
                      msgType={handleMobileMsgType(mobileNumber, ['mobileNo'])}
                      label={t('mobileNo')}
                      placeholder={t('enterMobileNumber')}
                      regex={/^[0-9\b]+$/}
                      value={mobileNumber}
                      infoMsgText={handleShowMobileError(['mobileNo'])}
                      autoComplete="off"
                      maxLength={appCharLimit.MAX_MOBILE_NUMBER}
                      onInputChange={(val) => {
                        handleMobileNo(val);
                        if (val.length === 10) {
                          if (
                            checkPermission(
                              permissions,
                              PERMISSIONS.RBAC_PAYEE.VIEW_PAYEE_CASA_ACCTS
                            )
                          ) {
                            showAccountList(val);
                          }
                        }
                      }}
                      prefixChild={
                        <div className="flex items-end">
                          <XB_CountryDropdown
                            id="MRCT-CNTY-CODE"
                            disabled={true}
                          />
                          <span className="m-auto">+91</span>
                        </div>
                      }
                    />
                    {mobileMsgs.payeeAccountSuccess && (
                      <XB_InlineMessage
                        type="error"
                        classes="mt-2 w-full"
                        message={
                          selectorPayeeAccount.error as string | undefined
                        }
                      />
                    )}
                  </div>
                  <div>
                    <div className="mt-1">
                      {(payeeAccountList?.data?.length as number) > 0 && (
                        <h3 className="m-text-md-regular text-gray-700 mt-4">
                          {t('bankAccounts')}
                        </h3>
                      )}
                      {checklistCount >= maxBankAccountsLimit && (
                        <XB_InlineMessage
                          type="error"
                          classes="my-2 w-full"
                          message={t('maxPayeeAccountsMessage', {
                            count: maxBankAccountsLimit,
                          })}
                          icon="warning"
                        />
                      )}
                      <div className="bank-accounts-list-container mt-2 gap-2 flex flex-col overflow-y-auto max-h-[100px]">
                        {payeeAccountList?.data &&
                          payeeAccountList?.data?.map((ele, index) => {
                            return (
                              <div key={index}>
                                <XB_OptionCard
                                  id={ele.AccountId ?? ''}
                                  title={ele.CASAAccountName}
                                  description={`A/c No:${ele.AccountId?.replace(
                                    /^[\d]{12}/,
                                    'XX'
                                  )}`}
                                  position="left"
                                  disabled={disableOptionCard(
                                    ele.AccountId as string
                                  )}
                                  value={JSON.stringify(ele)}
                                  cardType="checkbox"
                                  classes="w-full"
                                  {...payeeAccountListProps}
                                />
                              </div>
                            );
                          })}
                      </div>

                      <XB_Button
                        dataTestId="NEXT"
                        classes="w-full mt-6"
                        disabled={
                          isMobileButtonDisabled ||
                          mobileAccountDetail.length === 0
                        }
                        onClick={() => {
                          handlePayeeAccountConfirm();
                        }}
                      >
                        {t('next')}
                      </XB_Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : !isCallApi ? (
        renderManualInfo()
      ) : (
        renderInfo()
      )}
    </div>
  );
};
export default VerifyBank;
