import { EndPoints, Service } from '@core-services';

export const listingService = async (payload) => {
  const reqObj = {
    baseurl: `${EndPoints.admin.listing}`,
    method: 'POST',
    url: '',
    obj: payload,
  };

  const resp = await Service.send(reqObj);

  return resp.data;
};
