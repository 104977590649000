export const CUST_TYPE_102 = '102';
export const CUST_TYPE_103 = '103';
export const CUST_TYPE_145 = '145';
export const CUST_TYPE_161 = '161';
export const NRE_LABEL = 'NRE';

export const arrNRI = [
  { text: 'NRE', value: 'NRE', secondaryText: 'Non-Residential External' },
  { text: 'NRO', value: 'NRO', secondaryText: 'Non-Residential Ordinary' },
];
export const RD_LABEL = 'RD';
export const FD_LABEL = 'FD';
export const SOT_LABEL = 'SOT';
export const TSD_LABEL = 'TSD';

export const PAYOUT_MATURITY = 'On Maturity';
export const PAYOUT_MONTHLY = 'Monthly';
export const PAYOUT_QUARTERLY = 'Quarterly';

export const MATURITY = 'Maturity';
export const MONTHLY = 'Monthly';
export const QUARTERLY = 'Quarterly';
export const RENEW_ON_MATURITY = 'Renew on maturity';

export const MAX_LIMIT_DEPT_AMT = 15;
export const MAX_LIMIT_WITHDRAWL_AMT = 15;
export const MAX_LIMIT_PURPOSE = 16;
export const MIN_LIMIT_PURPOSE = 2;
export const MAX_LIMIT_REFERRAL_CODE = 16;
export const MIN_LIMIT_REFERRAL_CODE = 2;

export const TIME_OFFSET = 'T07:32:10.342Z';

export const monthsHashTable: { [key: string]: string } = {
  JAN: '01',
  FEB: '02',
  MAR: '03',
  APR: '04',
  MAY: '05',
  JUN: '06',
  JUL: '07',
  AUG: '08',
  SEP: '09',
  OCT: '10',
  NOV: '11',
  DEC: '12',
};

export const DEPOSIT_DATA_KEY = {
  FD: 'FDAccounts',
  RD: 'RDAccounts',
  TSD: 'TSDAccounts',
};

export const NRI_VALUES = {
  NRE: 'NRE',
  NRO: 'NRO',
};
