import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeadGenMasterReq } from '@src/core-stores';

export const leadGenMaster = createAsyncThunk(
  'accountType',
  async (leadGenPayload: LeadGenMasterReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.serviceRequest.LEAD_GEN_MASTER,
        obj: leadGenPayload,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
