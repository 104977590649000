import React, { useEffect, useRef } from 'react';

import { useOnClickOutside } from 'core-hooks/use-outside-click.hook';

import { XB_Button } from '../button/XB_Button.component';

import './XB_SideDrawer.style.scss';

interface XBSideDrawerProps {
  closable?: boolean;
  width?: string | number;
  height?: string | number;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  onClose: () => void;
  keyboardEsc?: boolean;
  style?: any;
  visible?: boolean;
  maskClosable?: boolean;
  maskStyle?: any;
  mask?: boolean;
  extended?: boolean;
  children: React.ReactNode;
}

export const XB_SideDrawer: React.FC<XBSideDrawerProps> = ({
  closable = false,
  width,
  height,
  placement = 'right',
  extended = false,
  onClose,
  keyboardEsc = true,
  style,
  visible,
  maskClosable = true,
  maskStyle,
  mask = true,
  children,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const placementClass =
    placement === 'left' || placement === 'right'
      ? 'sidebar-right-left'
      : 'sidebar-top-bottom';
  const extendedClass = extended ? 'extended' : '';
  const backgroundColor = mask ? 'rgba(0, 0, 0, 0.3)' : 'white';
  useOnClickOutside(
    ref,
    maskClosable
      ? onClose
      : () => {
          // This is intentional
        }
  );
  useEffect(() => {
    const listner = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (keyboardEsc) {
      document.addEventListener('keydown', listner);

      return () => {
        document.removeEventListener('keydown', listner);
        document.body.style.overflow = 'visible';
      };
    }
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [visible]);

  return visible ? (
    <div
      data-testid="SID-BAR"
      data-placement={placement}
      className={`sidebar-wrapper-active ${extendedClass}`}
    >
      {
        <div
          style={{
            backgroundColor,
            ...maskStyle,
          }}
          className="mask"
        >
          <div
            ref={(el) => {
              el?.style?.setProperty('width', `${width}`, 'important');
            }}
            style={{ height, ...style }}
            className={`${placementClass}`}
          >
            <div className="sidebar-content">
              {closable && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                  className="sidebar-header"
                >
                  <XB_Button
                    btnType="tertiary-gray"
                    dataTestId="button"
                    iconName="close"
                  />
                </div>
              )}
              <div className="h-full sidebar-body">{children}</div>
            </div>
          </div>
        </div>
      }
    </div>
  ) : null;
};
