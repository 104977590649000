import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUEndPoints, ServerError, Service } from '@src/core-services';

import {
  AddNomineeReq,
  ChangeNomineeReq,
} from '../../models/change-nominee.model';

export const changeNominee = createAsyncThunk(
  '/api/deposit-service/change-nominee',
  async (changeNomineeData: ChangeNomineeReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.CHANGE_NOMINEE,
        url: '',
        obj: changeNomineeData,
      });
      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: error.getErrorMessage(),
        requestId: error.getRequestId(),
      });
    }
  }
);

export const addNominee = createAsyncThunk(
  '/api/deposit-service/add-nominee',
  async (addNomineeData: AddNomineeReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.depositService.ADD_NOMINEE,
        url: '',
        obj: addNomineeData,
      });
      if (response.data.status === 'unauthorized') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        errorMessage: error.getErrorMessage(),
        requestId: error.getRequestId(),
      });
    }
  }
);
