import { AUEndPoints, Service } from '@core-services';

import {
  IBlockHeader,
  IBusinessDetailsReq,
  IFraudAccountBlockReq,
  IFraudAndDisputeSRReq,
  ILogoutHeader,
  IReqConsentFeedback,
  IReqGetBlockUnBlockDevice,
  IReqGetDeactivateDevice,
  IReqGetDeviceDetails,
  IReqLanguageUpdate,
  IReqReadNotifications,
  IReqUpdateMerchantProfile,
  IReqUploadDocument,
  IReqUploadProfilePhoto,
  IupdateBusinessNamereq,
  IUserUpdateUMS,
  ReqCkycDetail,
  ReqFetchCustomerAddress,
  ReqGetMerchantDetails,
  TFraudUPIBlockReq,
} from '../models/profile-info.model';

export const getMerchantDetails = async (reqPayload: ReqGetMerchantDetails) => {
  const reqObject = {
    baseurl: AUEndPoints.merchantService.GET_MERCHANT_DETAILS,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const uploadProfileFile = async (file: any, extraParam) => {
  let queryString = '';
  if (extraParam) {
    queryString += `fileName=${extraParam.fileName as string}&type=${
      extraParam.type as string
    }`;
  }

  const data = new FormData();
  data.append('document', file);
  const reqObj = {
    baseurl: `${AUEndPoints.merchantService.PROFILE_UPDATE}?${queryString}`,
    method: 'POST',
    url: '',
    obj: data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await Service.send(reqObj, headers);
  return response.data;
};

export const uploadProfilePhotoApi = async (
  ReqProfileUpdate: IReqUploadProfilePhoto
) => {
  const reqObj = {
    baseurl: `${AUEndPoints.merchantManagementService.UPDATE_PROFILE_PHOTO}`,
    method: 'POST',
    url: '',
    obj: ReqProfileUpdate,
  };

  const response = await Service.send(reqObj);
  return response.data;
};
export const uploadDocumentApi = async (
  ReqDocumentUpload: IReqUploadDocument
) => {
  const reqObj = {
    baseurl: `${AUEndPoints.merchantManagementService.ADD_DOCUMENT}`,
    method: 'POST',
    url: '',
    obj: ReqDocumentUpload,
  };

  const response = await Service.send(reqObj);
  return response.data;
};

export const getCustomerAddress = async (
  reqPayload: ReqFetchCustomerAddress
) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.FETCH_CUSTOMER_ADDRESS,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);

  return response.data;
};

export const getLogoutProfile = async (header) => {
  const reqObject = {
    baseurl: AUEndPoints.merchantService.LOGOUT,
    method: 'POST',
    url: '',
  };
  const response = await Service.send(reqObject, header);
  return response.data;
};

export const updateMerchantProfile = async (
  reqPayload: IReqUpdateMerchantProfile
) => {
  const reqObj = {
    baseurl: AUEndPoints.merchantManagementService.UPDATE_MERCHANT_PROFILE,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObj);

  return response.data;
};

export const getRelationshipManagerDetails = async (cifId) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.RELATIONSHIP_MANAGER_DETAILS,
    method: 'POST',
    url: '',
    obj: {
      cif: cifId,
    },
  };

  const response = await Service.send(reqObject);

  return response.data;
};

export const getFaqCategoriesList = async () => {
  const reqObject = {
    baseurl: AUEndPoints.strapiService.GET_FAQ_CATEGORIES,
    method: 'GET',
    url: '',
  };
  const response = await Service.send(reqObject);

  return response.data;
};

export const getFaqCategoriesQuestions = async (categoryType) => {
  const reqObject = {
    baseurl: AUEndPoints.strapiService.getFaqQuestions(categoryType),
    method: 'GET',
    url: '',
  };
  const response = await Service.send(reqObject);

  return response.data;
};

// export const updateUserName = async (payload: IupdateUserNamereq) => {
//   const reqObject = {
//     baseurl: AUEndPoints.userManagementService.UPDATE_USERNAME,
//     method: 'POST',
//     url: '',
//     obj: payload,
//   };
//   const response = await Service.send(reqObject);
//   return response.data;
// };

export const updateBusinessName = async (payload: IupdateBusinessNamereq) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.UPDATE_BUSINESSNAME,
    method: 'POST',
    url: '',
    obj: payload,
  };
  const response = await Service.send(reqObject);
  return response.data;
};

export const updateUserData = async (payload: IUserUpdateUMS) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.UPDATE_USER_DATA,
    method: 'POST',
    url: '',
    obj: payload,
  };
  const response = await Service.send(reqObject);
  return response.data;
};

export const getCkycDetail = async (payload: ReqCkycDetail) => {
  const reqObject = {
    baseurl: AUEndPoints.merchantManagementService.CKYC,
    method: 'POST',
    url: '',
    obj: payload,
  };
  const response = await Service.send(reqObject);
  return response.data;
};

export const getLangUpdate = async (reqPayload: IReqLanguageUpdate) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.LANG_UPDATE,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const sendConsentFeedback = async (reqPayload: IReqConsentFeedback) => {
  const reqObject = {
    baseurl: AUEndPoints.consentService.CONSENT_FEEDBACK,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const getDeviceList = async (reqPayload: IReqGetDeviceDetails) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.SESSION_DETAILS,
    method: 'POST',
    url: '',
  };

  const response = await Service.send(reqObject, { userId: reqPayload.userId });
  return response.data;
};

export const getMerchantDeviceList = async (
  reqPayload: IReqGetDeviceDetails
) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.MERCHANT_DETAILS,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject, {
    merchantId: reqPayload.merchantId,
  });
  return response.data;
};

export const getBlockUnBlockDevice = async (
  reqPayload: IReqGetBlockUnBlockDevice,
  header: IBlockHeader
) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.ENABLE_DISABLE,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject, header);
  return response.data;
};

export const getDeactivateDevice = async (
  reqPayload: IReqGetDeactivateDevice
) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.DEACTIVATE,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const getReadNotifications = async (
  reqPayload: IReqReadNotifications
) => {
  const reqObject = {
    baseurl: AUEndPoints.notificationService.READ_NOTIFICATIONS,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};
export const fraudUPIBlock = async (reqPayload: TFraudUPIBlockReq) => {
  const reqObject = {
    baseurl: AUEndPoints.fraudDisputeService.FRAUD_UPI_BLOCK,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const fraudSR = async (reqPayload: IFraudAndDisputeSRReq) => {
  const reqObject = {
    baseurl: AUEndPoints.fraudDisputeService.FRAUD_SR,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const fraudAccountBlock = async (reqPayload: IFraudAccountBlockReq) => {
  const reqObject = {
    baseurl: AUEndPoints.fraudDisputeService.FRAUD_ACCOUNT_BLOCK,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const deviceLogout = async (header: ILogoutHeader) => {
  const reqObject = {
    baseurl: AUEndPoints.merchantService.LOGOUT,
    method: 'POST',
    url: '',
    obj: { request: '' },
  };

  const response = await Service.send(reqObject, header);
  return response.data;
};

export const getBusinessDetails = async (reqPayload: IBusinessDetailsReq) => {
  const reqObj = {
    baseurl: AUEndPoints?.profileService?.GET_BUSINESS_DETAIL,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const updateUserImage = async (data: string) => {
  const bodyData = new FormData();
  bodyData.append('document', data);

  const reqObj = {
    baseurl: `${AUEndPoints.userManagementService.UPDATE_USER_IMG}`,
    method: 'POST',
    url: '',
    obj: bodyData,
  };

  const response = await Service.send(reqObj, {
    'Content-Type': 'multipart/form-data',
  });
  return response.data;
};

export const setDefaultMerchant = async (merchantId) => {
  const reqObject = {
    baseurl: AUEndPoints.userManagementService.DEFAULT_MERCHANT(merchantId),
    method: 'POST',
    url: '',
  };

  const response = await Service.send(reqObject);
  return response.data;
};
