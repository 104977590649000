import { createSlice } from '@reduxjs/toolkit';

import { ResGetSrList } from '../../models/service-request.model';
import { getSrList } from '../thunks/request-list.thunk';

const initialState: ResGetSrList = {
  status: undefined,
  data: undefined,
  error: undefined,
  successfulResponse: undefined,
  loading: true,
};

const getSrListSlice = createSlice({
  name: 'getSrList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSrList.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.successfulResponse = payload.successful_response;
      state.data = payload.data;
      state.error = payload.error;
      state.loading = false;
    });
    builder.addCase(getSrList.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      getSrList.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default getSrListSlice.reducer;
