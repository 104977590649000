interface CardProps {
  children: React.ReactNode;
  isRoundBorder?: boolean;
  showShadow?: boolean;
  isborder?: boolean;
  customClass?: string;
  isFlex?: boolean;
  onClick?: () => void;
  classes?: string;
}

export const XB_Card = ({
  children,
  isRoundBorder = true,
  showShadow = true,
  isborder = true,
  customClass = '',
  onClick,
  classes,
}: CardProps) => {
  return (
    <div
      className={`card bg-white dark:bg-slate-800 cursor-pointer ${classes} ${
        isborder ? 'isborder' : ''
      } ${isRoundBorder ? 'rounded-lg' : ''} ${
        showShadow ? 'shadow-md' : ''
      } ${customClass}`}
      onClick={() => {
        onClick?.();
      }}
    >
      {children}
    </div>
  );
};
