import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AU_Ttficon,
  XB_Divider,
  XB_Input,
  XB_Spinner,
} from '@core-components/atoms';
import { RegEx } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  getBankList,
  RootState,
  ShowBankListRes,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import {
  conditionalReturnValue,
  replaceOccuranceWithDelimeter,
} from '@core-utils';

export interface ShowBankProps {
  onclick: (...data) => void;
  searchVal: string;
  setSearchVal: (e) => void;
}

const ShowAllBanks: React.FC<ShowBankProps> = ({
  onclick,
  searchVal,
  setSearchVal,
}: ShowBankProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auAlert = useAlertBox();
  const [bankListData, setBankListData] = useState<ShowBankListRes>();

  const { loading: bankListLoading } = useAppSelector(
    (state: RootState) => state.bankListDetail
  );

  const bankListfn = async () => {
    try {
      const bankListRes = await dispatch(
        getBankList({
          requestType: 'ESB',
        })
      );
      if (bankListRes.payload.successfulResponse) {
        setBankListData(bankListRes.payload);
      } else {
        auAlert.error(
          bankListRes.payload.displayMessage ?? bankListRes.payload
        );
      }
    } catch (error: any) {
      auAlert.error(error.toString());
    }
  };

  const renderBankList = () => {
    return bankListData?.data
      ?.filter(function (item) {
        return item?.BankDisplayName?.toLowerCase().includes(
          searchVal.toLowerCase()
        );
      })
      .map((ele, index) => {
        return (
          <div key={index} className="mt-4">
            <div className="other-bank-row flex flex-row items-center gap-x-3">
              <AU_Ttficon
                icon={replaceOccuranceWithDelimeter(
                  ele.BankDisplayName ?? '',
                  RegEx.spaceRegex,
                  '_'
                )}
                style={{
                  minHeight: '2rem',
                  minWidth: '2rem',
                  height: '2rem',
                  width: '2rem',
                }}
                type="bank"
              />
              <div className="other-bank-name">
                <p
                  className="m-text-md-medium cursor-pointer text-gray-700"
                  onClick={() => {
                    onclick?.(ele, searchVal, setSearchVal);
                  }}
                >
                  {ele.BankDisplayName}
                </p>
              </div>
            </div>
            <XB_Divider classes="my-4" />
          </div>
        );
      });
  };

  const renderPopularBank = () => {
    return bankListData?.data
      ?.filter((ele) => ele.Popular === 1)
      .map((ele, index) => {
        return (
          <div key={index}>
            <div className="popular-bank-list flex justify-center items-center mb-2 cursor-pointer">
              <AU_Ttficon
                icon={replaceOccuranceWithDelimeter(
                  ele.BankDisplayName ?? '',
                  RegEx.spaceRegex,
                  '_'
                )}
                style={{
                  height: '2rem',
                  width: '2rem',
                  minHeight: '2rem',
                  minWidth: '2rem',
                }}
                type="bank"
                onClick={() => {
                  onclick?.(ele, searchVal, setSearchVal);
                }}
              />
            </div>
            <div className="popular-bank-list flex justify-center items-center ">
              <p
                className="m-text-xs-regular text-black text-center"
                onClick={() => {
                  onclick?.(ele, searchVal, setSearchVal);
                }}
              >
                {ele.BankDisplayName}
              </p>
            </div>
          </div>
        );
      });
  };
  useEffect(() => {
    bankListfn();
  }, []);

  return (
    <div className="banks-list-container">
      <div className="search-bank">
        <XB_Input
          prefixIcon="searchIcon"
          suffixIcon={searchVal !== '' ? 'cross' : ''}
          id="SRCH-BANK"
          placeholder={t('searchPayeeNameBankAccount')}
          classes="search-input"
          regex={RegEx.searchRegex}
          onIconClick={() => setSearchVal('')}
          value={searchVal}
          onInputChange={(val) => {
            setSearchVal(val);
          }}
        />
      </div>

      {bankListLoading ? (
        <div className="flex w-full justify-center items-center mt-16">
          <XB_Spinner size="xl" />
        </div>
      ) : (
        <>
          <div className="popular-banks-container">
            {searchVal.trim() === '' && (
              <div className="mt-6 ">
                <h3 className="m-text-sm-medium text-gray-700">
                  {t('popularBank')}
                </h3>
                <div className="grid grid-cols-4 gap-4 mt-3">
                  {renderPopularBank()}
                </div>
              </div>
            )}
          </div>

          <div className="bank-list-container mt-6">
            <>
              <div>
                <h2 className="m-text-sm-medium text-gray-700">
                  {conditionalReturnValue(
                    searchVal !== '',
                    t('suggestedSearches'),
                    t('otherBanks')
                  )}
                </h2>
                {renderBankList()}
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default ShowAllBanks;
