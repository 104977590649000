import { Icon } from '../icon/XB_Icon.component';

import './AU_Badge.style.scss';

interface BadgeProps {
  prefixIcon?: boolean;
  className?: string;
  children: React.ReactNode;
  onClick?: (e: any) => void;
  iconName?: string;
  iconClass?: string;
  iconHeight?: number;
  suffixiconName?: string;
  iconWidth?: number;
  contentClass?: string;
  bgColor?: string;
  iconColor?: string;
}

export const AU_Badge: React.FC<BadgeProps> = ({
  className = '',
  children,
  onClick,
  iconClass = '',
  suffixiconName,
  iconName,
  iconHeight = 12,
  iconWidth = 12,
  bgColor,
  iconColor,
  contentClass = '',
  ..._props
}: BadgeProps) => {
  return (
    <div
      className={`flex justify-start items-center gap-0 au-badge-container ${className}`}
      onClick={onClick}
      style={{ backgroundColor: bgColor }}
    >
      {iconName && (
        <span className={`au-badge-icon ${iconClass}`}>
          <Icon
            icon={iconName}
            width={iconWidth}
            height={iconHeight}
            color={iconColor}
          />
        </span>
      )}
      {children && (
        <span className={`badge_text text-xs ${contentClass ?? ''}`}>
          {children}
        </span>
      )}

      {suffixiconName && (
        <span className={`au-badge-icon ${iconClass}`}>
          <Icon
            icon={suffixiconName}
            width={iconWidth}
            height={iconHeight}
            color={iconColor}
          />
        </span>
      )}
    </div>
  );
};
