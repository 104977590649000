import { AUEndPoints, Service } from '@core-services';

import { ReqBranchByCity } from '../models/apply-now.model';

export const getAuBankStateList = async () => {
  const reqObject = {
    baseurl: AUEndPoints.applyNow.STATE_LIST,
    method: 'POST',
    url: '',
    obj: {},
  };

  const response = await Service.send(reqObject);

  return response.data;
};

export const getAuBankCityList = async (requestState: string) => {
  const reqObject = {
    baseurl: AUEndPoints.applyNow.CITY_BY_STATE_LIST,
    method: 'POST',
    url: '',
    obj: { state: requestState },
  };

  const response = await Service.send(reqObject);
  return response.data;
};
export const getAuBankStateListLocker = async () => {
  const reqObject = {
    baseurl: AUEndPoints.applyNow.LOCKER_STATE_LIST,
    method: 'POST',
    url: '',
    obj: {},
  };

  const response = await Service.send(reqObject);

  return response.data;
};

export const getAuBankCityListLocker = async (requestState: string) => {
  const reqObject = {
    baseurl: AUEndPoints.applyNow.LOCKER_CITY_BY_STATE_LIST,
    method: 'POST',
    url: '',
    obj: { state: requestState },
  };

  const response = await Service.send(reqObject);
  return response.data;
};

export const getAuBankBranchList = async (reqPayload: ReqBranchByCity) => {
  const reqObject = {
    baseurl: AUEndPoints.applyNow.GET_BRANCH_LIST,
    method: 'POST',
    url: '',
    obj: reqPayload,
  };

  const response = await Service.send(reqObject);

  return response.data;
};
