import { AUEndPoints, EndPoints, Service } from '@core-services';
import {
  AppInitReqData,
  configRequest,
  consentAcceptance,
  getAppIcons,
} from '@core-stores';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { setUserType } from '../slices/init.slice';

const { REACT_APP_API_BASE_URL } = process.env;
const BASE_URL = `${REACT_APP_API_BASE_URL}/init`;

export const initApp = createAsyncThunk(
  'app/init',
  async (initData: AppInitReqData, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: BASE_URL,
        url: '',
        obj: initData,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const initLanguage = createAsyncThunk(
  'app/initLanguage',
  async (
    { languageCode, languageVersion }: AppInitReqData,
    { rejectWithValue }
  ) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: EndPoints.strapiService.filterLang(
          languageCode,
          languageVersion
        ).STRAPI_LANGUAGE_JSON,
        url: '',
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const initLanguageList = createAsyncThunk(
  'init/languageList',
  async (userTypeObj: { userType: string }, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: EndPoints.configService.configs(userTypeObj.userType)
          .languageList,
        url: '',
        obj: ['language_list'],
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const initGlobalConfig = createAsyncThunk(
  'init/globalConfig',
  async (userTypeObj: { userType: string }, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: EndPoints.configService.configs(userTypeObj.userType)
          .allConfig,
        url: '',
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const initDebitCardList = createAsyncThunk(
  'init/getDebitCardImages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: EndPoints.strapiService.getDebitCardImages,
        url: '',
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getTheme = createAsyncThunk(
  'api/getUserTheme',
  async (userGroup: string, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: AUEndPoints.strapiService.getTheme(userGroup),
        url: '',
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const initializeApp = createAsyncThunk(
  'app/initializeApp',
  async (_, { dispatch }) => {
    let appType = process.env.REACT_APP_TYPE ?? 'MERCHANT';
    if (appType === 'MR') {
      appType = 'MERCHANT';
    }

    dispatch(setUserType(appType));
    const data = await dispatch(initGlobalConfig({ userType: appType }));

    sessionStorage.setItem(
      'encryptedUrl',
      JSON.stringify(data?.payload?.data?.encryptionAllowed ?? [])
    );

    sessionStorage.setItem(
      'strapiAuth',
      JSON.stringify(data?.payload?.data?.strapiAuth ?? '')
    );

    dispatch(consentAcceptance('en'));
    // Commenting this API as response of this API we are getting in latest API
    // await dispatch(initLanguageList({ userType: appType }));
    await dispatch(initDebitCardList());
    await dispatch(
      configRequest({
        configType: 'FE_CONFIG',
        key: null,
      })
    );
    await dispatch(getAppIcons({}));

    await dispatch(getTheme('userGroup1'));
  }
);
