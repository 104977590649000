import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IReqOnboardingDebitCardList } from '../../models/account.model';

export const onboardingDebitCardList = createAsyncThunk(
  'api/user-management-service/debit-card-details',
  async (getDebitCardReq: IReqOnboardingDebitCardList, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.userManagementService.ONBOARDING_DEBIT_CARD_LIST,
        url: '',
        obj: getDebitCardReq,
      });
      if (response.data.status === 'error') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        errorCode: error.bucketMessage,
        errorMsg: error.getErrorMessage(),
      });
    }
  }
);
