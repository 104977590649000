import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PayeeAccountConfirmReq } from '@src/core-stores/models/payee-account-confirm.model';

export const payeeAccountConfirm = createAsyncThunk(
  'payeeAccountconfirmation',
  async (PayeeAccountconfirm: PayeeAccountConfirmReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentsService.payeeService.PAYEE_ACCOUNT_CONFIRM,
        obj: PayeeAccountconfirm,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
