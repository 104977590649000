import { createAsyncThunk } from '@reduxjs/toolkit';
import { EndPoints, Service } from '@src/core-services';

export const consentAcceptance = createAsyncThunk(
  'app/consent',
  async (langaugeCode: string, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: EndPoints.strapiService.filterLang(`${langaugeCode}`)
          .getConsentList,
        url: '',
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const consentAcceptedList = createAsyncThunk(
  'app/consentAcceptedList',
  async (
    consentUserObj: {
      userId: string;
      appType: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: EndPoints.masterService.savedUserConsents,
        url: '',
        obj: consentUserObj,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
