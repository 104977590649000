import {
  AlertDialogConfig,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import { groupByObjectsWithKey } from '@core-utils';
import { removeMesaage } from '@src/core-stores/slices/au-alert-container.slice';

import { XB_AlertDialog } from '../alert-dialog/XB_AlertDialog.component';

import './AU_AlertContainer.style.scss';

interface AUAlertContainerProps {
  // add props here....
  classes?: string;
}

export const AU_AlertContainer = ({ classes }: AUAlertContainerProps) => {
  const dispatch = useAppDispatch();
  const alertContainerMessages = useAppSelector(
    (state: RootState) => state.auAlertContainer?.messeges
  );

  const renderMessage = (messages: AlertDialogConfig[]) => {
    const messageGroupByTypeObj = groupByObjectsWithKey(messages, 'position');
    if (messageGroupByTypeObj) {
      const types: any[] = Object.keys(messageGroupByTypeObj);
      return types.map((item) => {
        return (
          <div className={item} key={item}>
            {messageGroupByTypeObj[item].map((datum: AlertDialogConfig) => {
              const modified = {
                ...datum,
                onCloseClick: (val) => {
                  datum?.onCloseClick?.(val);
                  dispatch(removeMesaage(datum.messageId));
                },
              };
              return <XB_AlertDialog key={modified.messageId} {...modified} />;
            })}
          </div>
        );
      });
    } else {
      return '';
    }
  };

  return (
    <div className={`au-alert-container ${classes ?? ''}`}>
      {alertContainerMessages && renderMessage(alertContainerMessages)}
    </div>
  );
};
