import React from 'react';
import { Outlet } from 'react-router';

import { XB_NavigationMenu } from '@core-components/templates';
import { ProtectedRoute } from '@src/modules/common/components';

export const DashboardLayout: React.FC = () => {
  return (
    <XB_NavigationMenu>
      <ProtectedRoute>
        <div className=" min-h-screen">
          <Outlet />
        </div>
      </ProtectedRoute>
    </XB_NavigationMenu>
  );
};
