import { EndPoints, Service } from '@core-services';

export const setUserDetails = async (data, headers) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.setupUserIdentity,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj, headers);
  return response?.data;
};

export const resetUserDetails = async (data, headers) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.resetUserIdentity,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj, headers);
  return response?.data;
};

export const userRegistration = async (data, header) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.userRegistrationV1,
    method: 'POST',
    url: '',
    obj: data,
  };
  const response = await Service.send(reqObj, header);
  return response?.data;
};
