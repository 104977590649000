import { createSlice } from '@reduxjs/toolkit';

import { UpiValidateResponse } from '../models/upi.model';
import { upiValidate } from '../thunks/upi-validate.thunk';

const initialState: UpiValidateResponse = {
  status: undefined,
  successfulResponse: undefined,
  error: undefined,
  loading: false,
  data: undefined,
};
const upiValidateSlice = createSlice({
  name: 'upivalidation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(upiValidate.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        upiData: state.data,
        loading: state.loading,
      } = payload.data);
    });
    builder.addCase(upiValidate.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      upiValidate.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = false;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export default upiValidateSlice.reducer;
