import { AUEndPoints, EndPoints, Service } from '@src/core-services';

import {
  IReqIsAuEmployee,
  ReqDownloadCollection,
  ReqGetDynamicQrCode,
} from '../models/qr-collection.model';

export const settleVpaWise = async (vpaId, merchantId) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.SettleVpa(vpaId, merchantId),
    method: 'POST',
    url: '',
    obj: '',
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const settleAll = async (merchantId) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.SettleAll(merchantId),
    method: 'POST',
    url: '',
    obj: '',
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const checkIsAuEmployee = async (req: IReqIsAuEmployee) => {
  const reqObj = {
    baseurl: EndPoints?.onboardingService?.isAuEmployee,
    method: 'POST',
    url: '',
    obj: req,
  };
  const response = await Service.send(reqObj);
  return response.data;
};

export const getDynamicQrCode = async (req: ReqGetDynamicQrCode) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.GET_DYNAMIC_QR_CODE,
    method: 'POST',
    url: '',
    obj: req,
  };

  const response = await Service.send(reqObj);
  return response.data;
};

export const getCSVDownload = async (req: ReqDownloadCollection) => {
  const reqObj = {
    baseurl: AUEndPoints.settlementService.DownloadCollection,
    method: 'POST',
    url: '',
    obj: req,
  };

  const response = await Service.send(reqObj);
  return response.data;
};

export const s3DownlaodFile = async (header) => {
  try {
    const reqObj = {
      baseurl: AUEndPoints.settlementService.COLLECTION_DOWNLOAD_FILE,
      method: 'POST',
      url: '',
    };

    const response = await Service.send(reqObj, header);

    return response.data;
  } catch (error: any) {
    return error;
  }
};
