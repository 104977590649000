import {
  forwardRef,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useEventProvider } from '@core-providers';

import { Icon } from '../icon/XB_Icon.component';

import './XB_Textarea.styles.scss';

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label?: string;
  classes?: string;
  value?: string;
  onInputChange?: (val: string) => void;
  msgType?: 'info' | 'success' | 'error' | '';
  infoMsgText?: string;
  suffixText?: string;
  minRows?: number;
  maxRows?: number;
  cols?: number;
  regex?: any;
  labelWithIcon?: boolean;
  ref?: React.RefObject<HTMLTextAreaElement>;
}

export const XB_Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function (
    {
      id,
      label,
      classes = '',
      value,
      placeholder,
      onInputChange,
      msgType = '',
      infoMsgText,
      disabled,
      autoFocus,
      regex,
      minRows = 5,
      maxRows = 5,
      cols = 50,
      labelWithIcon,
      ...otherProps
    }: TextareaProps,
    ref
  ) {
    const { getEventId } = useEventProvider();
    const classList = ['textarea'];
    const inputRef = useRef<HTMLDivElement | null>(null);
    const [rows, setRows] = useState(minRows);

    classes && classList.push(classes);

    msgType && classList.push(`textarea--${msgType}`);
    disabled && classList.push('textarea--disabled');

    const handleInputChange = (target: HTMLTextAreaElement) => {
      const newValue = target.value;
      if (regex) {
        const regexRes = regex.test(newValue);
        if (regexRes) {
          onInputChange?.(newValue);
        } else if (!regexRes && newValue !== '') {
          onInputChange?.(value ?? '');
          target.setSelectionRange(target.selectionStart, target.selectionEnd);
        } else onInputChange?.('');
      } else {
        onInputChange?.(newValue);
      }
    };

    useEffect(() => {
      const inputEl = inputRef.current?.getElementsByTagName('textarea')[0];
      const onFocus = () => {
        inputRef.current?.classList.add('textarea--focused');
      };
      const onBlur = () => {
        inputRef.current?.classList.remove('textarea--focused');
      };
      inputEl?.addEventListener('focus', onFocus);
      inputEl?.addEventListener('blur', onBlur);
      return () => {
        inputEl?.removeEventListener('focus', onBlur);
        inputEl?.removeEventListener('blur', onBlur);
      };
    }, []);

    useEffect(() => {
      if (value !== '') {
        const rowLength = value?.split('\n') ?? '';
        if (
          minRows <= rowLength?.length &&
          rowLength?.length <= maxRows &&
          rowLength?.length !== 0
        ) {
          setRows(rowLength?.length);
        }
      }
    }, [value]);

    return (
      <div className={`${classList.join(' ')}`} ref={inputRef}>
        {label && (
          <>
            <label htmlFor={id} className="label-class flex m-text-md-regular">
              {label}
              {labelWithIcon && (
                <span className={`label-icon  ml-2`}>
                  <Icon icon="alertCircle" width={16} height={16} />
                </span>
              )}
            </label>
          </>
        )}
        <div className="input__wrapper m-text-lg-regular">
          <textarea
            id={id}
            data-testid={`${getEventId(id)}-TEXT-AREA`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleInputChange(e.target)}
            disabled={disabled}
            autoFocus={autoFocus}
            ref={ref}
            rows={rows}
            cols={cols}
            className="w-full h-full"
            {...otherProps}
          />
        </div>
        {msgType && (
          <span
            className="input__additional-text block m-text-md-regular pt-1.5"
            id={`${id}-${msgType}`}
          >
            {infoMsgText}
          </span>
        )}
      </div>
    );
  }
);

XB_Textarea.displayName = 'XB_Textarea';
