import { useTranslation } from 'react-i18next';

import { XB_Divider } from '@core-components/atoms';
import { AU_HorizontalBargraph } from '@core-components/molecules';
import { getConcatenatedString } from '@core-utils';
import {
  monthlyTitle,
  quarterlyTitle,
} from '@src/modules/account-services/constants/account-services.constant';
import { BalanceDetail } from '@src/modules/account-services/models/response-account-services.model';

interface AverageMonthlyBalanceGraphProps {
  data: any | undefined;
}

export const AU_AverageMonthlyBalanceGraph = ({
  data,
}: AverageMonthlyBalanceGraphProps) => {
  const { t } = useTranslation();
  const getPercentage = (bal, maxValue) => {
    if (maxValue === 0) {
      return 2;
    }
    let percentage;
    if (bal) {
      percentage = (bal / maxValue) * 100;
    }
    return percentage < 2 ? 2 : percentage;
  };
  const monthlyData: BalanceDetail[] = [];
  const quarterlyData: BalanceDetail[] = [];
  let monthlyMaxVal = 0;
  let quarterlyMaxVal = 0;

  data?.forEach((datum) => {
    if (datum.averageAmountType === monthlyTitle) {
      monthlyData.push(datum);
    }
    if (datum.averageAmountType === quarterlyTitle) {
      quarterlyData.push(datum);
    }
  });

  monthlyData?.forEach((ele) => {
    if (parseInt(ele.avgBalance ?? '') > monthlyMaxVal)
      monthlyMaxVal = parseInt(ele.avgBalance ?? '');
  });

  quarterlyData?.forEach((ele) => {
    if (parseInt(ele.avgBalance ?? '') > quarterlyMaxVal)
      quarterlyMaxVal = parseInt(ele.avgBalance ?? '');
  });

  return (
    <div className="p-4">
      {monthlyData?.length > 0 && (
        <div className="flex flex-col gap-4">
          <h1 className="m-text-lg-bold text-gray-900">
            {t('monthlyAvgBalance')}
          </h1>
          {monthlyData?.map((item, index: number) => {
            return (
              <div
                key={getConcatenatedString([index, item.avgBalance ?? 'key'])}
                className="flex flex-col gap-8"
              >
                <AU_HorizontalBargraph
                  label={item.logMonth ?? ''}
                  value={item.avgBalance ?? ''}
                  percentage={getPercentage(item.avgBalance, monthlyMaxVal)}
                  backgroundColor="blue"
                />
              </div>
            );
          })}
        </div>
      )}
      {monthlyData?.length > 0 && quarterlyData?.length > 0 && (
        <XB_Divider classes="my-6" />
      )}
      {quarterlyData?.length > 0 && (
        <div className="flex flex-col gap-4">
          <h1 className="m-text-lg-bold text-gray-900">
            {t('quartelyAverageBalance')}
          </h1>
          {quarterlyData?.map((item, index: number) => {
            return (
              <div
                key={getConcatenatedString([index, item.avgBalance ?? 'key'])}
                className="flex flex-col gap-8"
              >
                <AU_HorizontalBargraph
                  label={item.logMonth ?? ''}
                  value={item.avgBalance ?? ''}
                  percentage={getPercentage(item.avgBalance, quarterlyMaxVal)}
                  backgroundColor="rose"
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
