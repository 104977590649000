import { vkyc } from '@core-constants';
import { initVideoKyc } from '@modules/qr-onboarding/services';

import { SessionStorage } from './async-storage.util';

export const getVkycStatus = (response) => {
  if (
    (response.data.vtStatus === vkyc.status.OPEN ||
      response.data.vtStatus === vkyc.status.WIP ||
      response.data.vtStatus === vkyc.status.UNABLE ||
      response.data.vtStatus === vkyc.status.DROPPED) &&
    (response.data.workAppStatus === vkyc.status.UNABLE ||
      response.data.workAppStatus === vkyc.status.INITIATED ||
      response.data.workAppStatus === vkyc.status.NA ||
      response.data.workAppStatus === vkyc.status.REOPEN ||
      response.data.workAppStatus === null)
  ) {
    return vkyc.screen.PENDING;
  } else if (
    response.data.vtStatus === vkyc.status.CLOSED &&
    response.data.workAppStatus === vkyc.status.APPROVED
  ) {
    return vkyc.screen.SUCCESS;
  } else if (
    response.data.vtStatus === vkyc.status.CLOSED &&
    (response.data.workAppStatus === vkyc.status.NOT_APPROVED ||
      response.data.workAppStatus === vkyc.status.REJECTED)
  ) {
    return vkyc.screen.REJECTED;
  } else if (
    response.data.vtStatus === vkyc.status.WIP &&
    (response.data.workAppStatus === vkyc.status.SUCCESSFUL ||
      response.data.workAppStatus === vkyc.status.AUDITOR_ASSIGNED ||
      response.data.workAppStatus === vkyc.status.AUDITOR_READY)
  ) {
    return vkyc.screen.INPROGRESS;
  } else if (
    response.data.vtStatus === vkyc.status.WIP &&
    response.data.workAppStatus === vkyc.status.ALL_AGENTS_BUSY
  ) {
    return vkyc.screen.SCHEDULE;
  } else {
    return vkyc.screen.VIDEOKYCSCREEN;
  }
};

export const getInitVideoKYC = async (mobileNumber) => {
  try {
    const response = await initVideoKyc({
      mobileNumber: mobileNumber.split('-')[1],
      readyForVideoCall: 'Y',
    });
    if (response.status === 'success') {
      SessionStorage.setItem('VKYC_URL', response?.data?.[0].redirectUrl);
      return true;
    }
  } catch (err: any) {
    return err.displayMessage;
  }
};
