import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import { XB_Spinner } from '@core-components/atoms';
import { appConstants } from '@core-constants';
import { getBlobUrl } from '@core-services';

import { Icon } from '../../atoms/icon/XB_Icon.component';

import './XB_DnD.style.scss';

export interface FileUploadProps {
  documentName: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  lastModifiedDate: Date;
  name?: string;
}

interface DnDProps {
  handleChange: (_file) => void;
  fileTypes: string[];
  multiple?: boolean;
  onSizeError: (_e) => void;
  uploadedFiles: FileUploadProps[];
  onCloseClick: (_i) => void;
  loader: any;
  typeOfFilesSupportMsg?: string;
  fileSize?: number;
}

export function XB_DnD({
  handleChange,
  fileTypes,
  multiple = true,
  onSizeError,
  uploadedFiles,
  onCloseClick,
  loader,
  typeOfFilesSupportMsg,
  fileSize,
  ...rest
}: DnDProps) {
  const { t } = useTranslation();
  const userBasedURL =
    process.env.REACT_APP_TYPE === appConstants.APP_TYPE.ADMIN
      ? appConstants.AWSBUCKETS.CPC
      : appConstants.AWSBUCKETS.SO;
  const openFileInNewTab = async (file) => {
    const s3Url = file.fileUrl ?? file.s3Url;
    if (s3Url) {
      const url = await getBlobUrl(s3Url, userBasedURL);
      window.open(url, '_blank');
    } else {
      const url = URL.createObjectURL(file);
      window.open(url, '_blank');
    }
  };
  const fileTypeMsg = typeOfFilesSupportMsg ?? t('fileRestriction');

  return (
    <div className="file-uploading-module">
      <FileUploader
        handleChange={handleChange}
        multiple={multiple}
        maxSize={fileSize ?? 1}
        name="file"
        onSizeError={onSizeError}
        types={[...fileTypes, t('JFIF')]}
        {...rest}
      >
        <div className="upload-wrapper cursor-pointer">
          <div className="icon">
            <Icon color="text-gray-900" icon="upload" />
          </div>
          <div>
            <h3>
              {t('dnd')}
              <span className="text-orange-600"> {t('chooseFile')} </span>
              {t('toUpload')}
            </h3>
            <p className="text-gray-500 m-text-md-regular">{fileTypeMsg}</p>
          </div>
        </div>
      </FileUploader>

      <div className="uploaded-files-wrapper">
        {uploadedFiles.map((file, index) => {
          return (
            <div className="uploaded-files" key={index}>
              <div className="flex items-center">
                <Icon
                  color="none"
                  height={16}
                  width={16}
                  icon="eyeopen"
                  className="mt-[4px]"
                />
                <p
                  className="mx-2 text-orange-500 underline cursor-pointer break-all"
                  onClick={() => {
                    openFileInNewTab(file);
                  }}
                >
                  {file.documentName}
                </p>
              </div>
              <>
                {loader[file.documentName] ? (
                  <XB_Spinner size="sm" />
                ) : (
                  <div onClick={() => onCloseClick(index)} className="icon">
                    <Icon
                      color="text-gray-700"
                      height={16}
                      width={16}
                      icon="close"
                      className="cursor-pointer"
                    />
                  </div>
                )}
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
}
