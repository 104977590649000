import { ButtonProps, Icon, XB_Button } from '@core-components/atoms';

import './AU_Broadcast.style.scss';

interface BroadcastProps {
  title?: string;
  message?: string | React.ReactNode;
  icon?: string;
  size?: 'sm' | 'lg';
  classes?: string;
  rightButtons?: ButtonProps[];
  titleClass?: string;
  messageClass?: string;
  iconClass?: string;
}

export const AU_Broadcast = ({
  title,
  message,
  icon,
  classes,
  size,
  rightButtons = [],
  titleClass = '',
  messageClass = '',
  iconClass,
}: BroadcastProps) => {
  const classList = [
    'broadcast-message',
    `broadcast-message--${size}`,
    `${title ? '' : 'items-center'}`,
  ];
  classes && classList.push(classes);

  const showDescriptionContentFunc = () => {
    if (typeof message === 'object') return message;
    return <p className={messageClass}>{message}</p>;
  };

  const rightSideButtonFunc = () => {
    return (
      <div className="mt-2 flex justify-end items-center">
        {rightButtons.map((item, index) => (
          <XB_Button {...item} key={index} />
        ))}
      </div>
    );
  };

  return (
    <div className={`${classList.join(' ')}`}>
      <div className="broadcast-message__icon">
        {icon && (
          <Icon
            icon={icon}
            width={24}
            height={24}
            className={`icon ${iconClass}`}
          />
        )}
      </div>
      <div className="broadcat-message__content w-full">
        {title && <h4 className={titleClass}>{title}</h4>}
        {showDescriptionContentFunc()}
        {rightButtons.length > 0 && rightSideButtonFunc()}
      </div>
    </div>
  );
};
