import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { statusCode } from '@core-constants';
import { Service } from '@core-services';

export const useHttp = () => {
  const [dataParams, setDataParams] = useState({
    isLoading: false,
    errorMsg: '',
    error: false,
    data: [],
  });
  const { t } = useTranslation();
  const sendRequest = useCallback(async (obj) => {
    try {
      setDataParams({ ...dataParams, isLoading: true });

      const res = await Service.send(obj);
      if (statusCode.success.some((code) => res.status === code)) {
        setDataParams({ ...dataParams, data: res.data, isLoading: false });
      } else {
        setDataParams({
          ...dataParams,
          error: true,
          isLoading: false,
          errorMsg: res.error.message || t('AS0500'),
          data: res.data,
        });
      }
    } catch (error: any) {
      setDataParams({
        ...dataParams,
        error: true,
        errorMsg: error || t('AS0500'),
        data: [],
      });
    }
  }, []);

  return {
    sendRequest,
    isLoading: dataParams.isLoading,
    error: dataParams.error,
    errorMessage: dataParams.errorMsg,
    response: dataParams.data as any,
  };
};
