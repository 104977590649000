import { DASHBOARD_TYPE, THEME_TYPE } from '@core-constants';
import { ThemeConfig } from '@core-stores';
import { getThemeFromLocal, Storage } from '@core-utils';

export function applyTheme(theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

const defaultColorPalettes = {
  overlay: 'rgba(18, 18, 28, 0.5)',
  'Blue/25': '#F5FAFF',
  'Blue/50': '#EFF8FF',
  'Blue/100': '#D1E9FF',
  'Blue/200': '#B2DDFF',
  'Blue/300': '#84CAFF',
  'Blue/400': '#53B1FD',
  'Blue/500': '#2E90FA',
  'Blue/600': '#1570EF',
  'Blue/700': '#175CD3',
  'Blue/800': '#1849A9',
  'Blue/900': '#194185',
  'Pink/25': '#FEF6FB',
  'Pink/50': '#FDF2FA',
  'Pink/100': '#FCE7F6',
  'Pink/200': '#FCCEEE',
  'Pink/300': '#FAA7E0',
  'Pink/400': '#F670C7',
  'Pink/500': '#EE46BC',
  'Pink/600': '#DD2590',
  'Pink/700': '#C11574',
  'Pink/800': '#9E165F',
  'Pink/900': '#851651',
  'Rose/25': '#FFF5F6',
  'Rose/50': '#FFF1F3',
  'Rose/100': '#FFE4E8',
  'Rose/200': '#FECDD6',
  'Rose/300': '#FEA3B4',
  'Rose/400': '#FD6F8E',
  'Rose/500': '#F63D68',
  'Rose/600': '#E31B54',
  'Rose/700': '#C01048',
  'Rose/800': '#A11043',
  'Rose/900': '#89123E',
  'Error/25': '#FFFBFA',
  'Error/50': '#FEF3F2',
  'Error/100': '#FEE4E2',
  'Error/200': '#FECDCA',
  'Error/300': '#FDA29B',
  'Error/400': '#F97066',
  'Error/500': '#F04438',
  'Error/600': '#D92D20',
  'Error/700': '#B42318',
  'Error/800': '#912018',
  'Error/900': '#7A271A',
  'Indigo/25': '#F5F8FF',
  'Indigo/50': '#EEF4FF',
  'Indigo/100': '#E0EAFF',
  'Indigo/200': '#C7D7FE',
  'Indigo/300': '#A4BCFD',
  'Indigo/400': '#8098F9',
  'Indigo/500': '#6172F3',
  'Indigo/600': '#444CE7',
  'Indigo/700': '#3538CD',
  'Indigo/800': '#2D31A6',
  'Indigo/900': '#2D3282',
  'Primary/25': '#FFFCFA',
  'Primary/50': '#FEF8F5',
  'Primary/100': '#FEF1EB',
  'Primary/200': '#FDE3D7',
  'Primary/300': '#FAC7AE',
  'Primary/400': '#F8AC86',
  'Primary/500': '#F5905D',
  'Primary/600': '#F37435',
  'Primary/700': '#B65728',
  'Primary/800': '#7A3A1B',
  'Primary/900': '#3D1D0D',
  'Success/25': '#F6FEF9',
  'Success/50': '#ECFDF3',
  'Success/100': '#D1FADF',
  'Success/200': '#A6F4C5',
  'Success/300': '#6CE9A6',
  'Success/400': '#32D583',
  'Success/500': '#12B76A',
  'Success/600': '#039855',
  'Success/700': '#027A48',
  'Success/800': '#05603A',
  'Success/900': '#054F31',
  'Warning/25': '#FFFCF5',
  'Warning/50': '#FFFAEB',
  'Warning/100': '#FEF0C7',
  'Warning/200': '#FEDF89',
  'Warning/300': '#FEC84B',
  'Warning/400': '#FDB022',
  'Warning/500': '#F79009',
  'Warning/600': '#DC6803',
  'Warning/700': '#B54708',
  'Warning/800': '#93370D',
  'Warning/900': '#7A2E0E',
  'BlueGray/25': '#4E5BA6',
  'BlueGray/50': '#F8F9FC',
  'BlueGray/100': '#EAECF5',
  'BlueGray/200': '#D5D9EB',
  'BlueGray/300': '#AFB5D9',
  'BlueGray/400': '#717BBC',
  'BlueGray/500': '#4E5BA6',
  'BlueGray/600': '#3E4784',
  'BlueGray/700': '#363F72',
  'BlueGray/800': '#293056',
  'BlueGray/900': '#101323',
  'Neutrals/dark': '#3D3D3D',
  'Neutrals/25': '#FCFCFD',
  'Neutrals/50': '#F9F9FB',
  'Neutrals/100': '#F2F2F4',
  'Neutrals/200': '#E1E1E3',
  'Neutrals/300': '#D1D2D5',
  'Neutrals/400': '#A3A5AA',
  'Neutrals/500': '#767780',
  'Neutrals/600': '#575965',
  'Neutrals/700': '#3D404D',
  'Neutrals/800': '#222636',
  'Neutrals/900': '#10111A',
  'BlueLight/25': '#F5FBFF',
  'BlueLight/50': '#F0F9FF',
  'BlueLight/100': '#E0F2FE',
  'BlueLight/200': '#B9E6FE',
  'BlueLight/300': '#7CD4FD',
  'BlueLight/400': '#36BFFA',
  'BlueLight/500': '#0BA5EC',
  'BlueLight/600': '#0086C9',
  'BlueLight/700': '#026AA2',
  'BlueLight/800': '#065986',
  'BlueLight/900': '#0B4A6F',

  background: '#ffffff',
  foreground: '#000000',
  primaryLight: '#4d4dff',
  primary: 'blue',
  secondary: 'red',
  secondaryLight: '#ff4d4d',
  textBase: 'white',
  orange: 'orange',
};

interface TheamUtilConfig {
  primary?: string;
  primaryLight?: string;
  secondary?: string;
  secondaryLight?: string;
  textBase?: string;
  orange?: string;
  themConfig?: ThemeConfig;
}

function getColor(theamObj: ThemeConfig | undefined, key) {
  return theamObj?.config?.[
    ''
      .concat(theamObj?.navThemeType?.toLowerCase() ?? DASHBOARD_TYPE.STORE)
      .concat(
        theamObj?.themeType && theamObj?.themeType !== ''
          ? theamObj?.themeType
          : THEME_TYPE.LIGHT
      )
  ]?.[key];
}

// get user nav theme type
export const getNavThemeFromLocal = () => {
  return Storage.getItemAsync('navThemeType');
};

export function createTheme(theamObj: TheamUtilConfig) {
  return {
    '--background':
      getColor(theamObj.themConfig, 'background') ??
      defaultColorPalettes.background,
    '--foreground':
      getColor(theamObj.themConfig, 'foreground') ??
      defaultColorPalettes.foreground,
    '--primary-25':
      getColor(theamObj.themConfig, 'Primary/25') ??
      defaultColorPalettes['Primary/25'],
    '--primary-50':
      getColor(theamObj.themConfig, 'Primary/50') ??
      defaultColorPalettes['Primary/50'],
    '--primary-100':
      getColor(theamObj.themConfig, 'Primary/100') ??
      defaultColorPalettes['Primary/100'],
    '--primary-200':
      getColor(theamObj.themConfig, 'Primary/200') ??
      defaultColorPalettes['Primary/200'],
    '--primary-300':
      getColor(theamObj.themConfig, 'Primary/300') ??
      defaultColorPalettes['Primary/300'],
    '--primary-400':
      getColor(theamObj.themConfig, 'Primary/400') ??
      defaultColorPalettes['Primary/400'],
    '--primary-500':
      getColor(theamObj.themConfig, 'Primary/500') ??
      defaultColorPalettes['Primary/500'],
    '--primary-600':
      getColor(theamObj.themConfig, 'Primary/600') ??
      defaultColorPalettes['Primary/600'],
    '--primary-700':
      getColor(theamObj.themConfig, 'Primary/700') ??
      defaultColorPalettes['Primary/700'],
    '--primary-800':
      getColor(theamObj.themConfig, 'Primary/800') ??
      defaultColorPalettes['Primary/800'],
    '--primary-900':
      getColor(theamObj.themConfig, 'Primary/900') ??
      defaultColorPalettes['Primary/900'],
    '--neutrals-dark':
      getColor(theamObj.themConfig, 'Neutrals/dark') ??
      defaultColorPalettes['Neutrals/dark'],
    '--neutrals-25':
      getColor(theamObj.themConfig, 'Neutrals/25') ??
      defaultColorPalettes['Neutrals/25'],
    '--neutrals-50':
      getColor(theamObj.themConfig, 'Neutrals/50') ??
      defaultColorPalettes['Neutrals/50'],
    '--neutrals-100':
      getColor(theamObj.themConfig, 'Neutrals/100') ??
      defaultColorPalettes['Neutrals/100'],
    '--neutrals-200':
      getColor(theamObj.themConfig, 'Neutrals/200') ??
      defaultColorPalettes['Neutrals/200'],
    '--neutrals-300':
      getColor(theamObj.themConfig, 'Neutrals/300') ??
      defaultColorPalettes['Neutrals/300'],
    '--neutrals-400':
      getColor(theamObj.themConfig, 'Neutrals/400') ??
      defaultColorPalettes['Neutrals/400'],
    '--neutrals-500':
      getColor(theamObj.themConfig, 'Neutrals/500') ??
      defaultColorPalettes['Neutrals/500'],
    '--neutrals-600':
      getColor(theamObj.themConfig, 'Neutrals/600') ??
      defaultColorPalettes['Neutrals/600'],
    '--neutrals-700':
      getColor(theamObj.themConfig, 'Neutrals/700') ??
      defaultColorPalettes['Neutrals/700'],
    '--neutrals-800':
      getColor(theamObj.themConfig, 'Neutrals/800') ??
      defaultColorPalettes['Neutrals/800'],
    '--neutrals-900':
      getColor(theamObj.themConfig, 'Neutrals/900') ??
      defaultColorPalettes['Neutrals/900'],
    '--blue-25':
      getColor(theamObj.themConfig, 'Blue/25') ??
      defaultColorPalettes['Blue/25'],
    '--blue-50':
      getColor(theamObj.themConfig, 'Blue/50') ??
      defaultColorPalettes['Blue/50'],
    '--blue-100':
      getColor(theamObj.themConfig, 'Blue/100') ??
      defaultColorPalettes['Blue/100'],
    '--blue-200':
      getColor(theamObj.themConfig, 'Blue/200') ??
      defaultColorPalettes['Blue/200'],
    '--blue-300':
      getColor(theamObj.themConfig, 'Blue/300') ??
      defaultColorPalettes['Blue/300'],
    '--blue-400':
      getColor(theamObj.themConfig, 'Blue/400') ??
      defaultColorPalettes['Blue/400'],
    '--blue-500':
      getColor(theamObj.themConfig, 'Blue/500') ??
      defaultColorPalettes['Blue/500'],
    '--blue-600':
      getColor(theamObj.themConfig, 'Blue/600') ??
      defaultColorPalettes['Blue/600'],
    '--blue-700':
      getColor(theamObj.themConfig, 'Blue/700') ??
      defaultColorPalettes['Blue/700'],
    '--blue-800':
      getColor(theamObj.themConfig, 'Blue/800') ??
      defaultColorPalettes['Blue/800'],
    '--blue-900':
      getColor(theamObj.themConfig, 'Blue/900') ??
      defaultColorPalettes['Blue/900'],
    '--pink-25':
      getColor(theamObj.themConfig, 'Pink/25') ??
      defaultColorPalettes['Pink/25'],
    '--pink-50':
      getColor(theamObj.themConfig, 'Pink/50') ??
      defaultColorPalettes['Pink/50'],
    '--pink-100':
      getColor(theamObj.themConfig, 'Pink/100') ??
      defaultColorPalettes['Pink/100'],
    '--pink-200':
      getColor(theamObj.themConfig, 'Pink/200') ??
      defaultColorPalettes['Pink/200'],
    '--pink-300':
      getColor(theamObj.themConfig, 'Pink/300') ??
      defaultColorPalettes['Pink/300'],
    '--pink-400':
      getColor(theamObj.themConfig, 'Pink/400') ??
      defaultColorPalettes['Pink/400'],
    '--pink-500':
      getColor(theamObj.themConfig, 'Pink/500') ??
      defaultColorPalettes['Pink/500'],
    '--pink-600':
      getColor(theamObj.themConfig, 'Pink/600') ??
      defaultColorPalettes['Pink/600'],
    '--pink-700':
      getColor(theamObj.themConfig, 'Pink/700') ??
      defaultColorPalettes['Pink/700'],
    '--pink-800':
      getColor(theamObj.themConfig, 'Pink/800') ??
      defaultColorPalettes['Pink/800'],
    '--pink-900':
      getColor(theamObj.themConfig, 'Pink/900') ??
      defaultColorPalettes['Pink/900'],
    '--rose-25':
      getColor(theamObj.themConfig, 'Rose/25') ??
      defaultColorPalettes['Rose/25'],
    '--rose-50':
      getColor(theamObj.themConfig, 'Rose/50') ??
      defaultColorPalettes['Rose/50'],
    '--rose-100':
      getColor(theamObj.themConfig, 'Rose/100') ??
      defaultColorPalettes['Rose/100'],
    '--rose-200':
      getColor(theamObj.themConfig, 'Rose/200') ??
      defaultColorPalettes['Rose/200'],
    '--rose-300':
      getColor(theamObj.themConfig, 'Rose/300') ??
      defaultColorPalettes['Rose/300'],
    '--rose-400':
      getColor(theamObj.themConfig, 'Rose/400') ??
      defaultColorPalettes['Rose/400'],
    '--rose-500':
      getColor(theamObj.themConfig, 'Rose/500') ??
      defaultColorPalettes['Rose/500'],
    '--rose-600':
      getColor(theamObj.themConfig, 'Rose/600') ??
      defaultColorPalettes['Rose/600'],
    '--rose-700':
      getColor(theamObj.themConfig, 'Rose/700') ??
      defaultColorPalettes['Rose/700'],
    '--rose-800':
      getColor(theamObj.themConfig, 'Rose/800') ??
      defaultColorPalettes['Rose/800'],
    '--rose-900':
      getColor(theamObj.themConfig, 'Rose/900') ??
      defaultColorPalettes['Rose/900'],
    '--error-25':
      getColor(theamObj.themConfig, 'Error/25') ??
      defaultColorPalettes['Error/25'],
    '--error-50':
      getColor(theamObj.themConfig, 'Error/50') ??
      defaultColorPalettes['Error/50'],
    '--error-100':
      getColor(theamObj.themConfig, 'Error/100') ??
      defaultColorPalettes['Error/100'],
    '--error-200':
      getColor(theamObj.themConfig, 'Error/200') ??
      defaultColorPalettes['Error/200'],
    '--error-300':
      getColor(theamObj.themConfig, 'Error/300') ??
      defaultColorPalettes['Error/300'],
    '--error-400':
      getColor(theamObj.themConfig, 'Error/400') ??
      defaultColorPalettes['Error/400'],
    '--error-500':
      getColor(theamObj.themConfig, 'Error/500') ??
      defaultColorPalettes['Error/500'],
    '--error-600':
      getColor(theamObj.themConfig, 'Error/600') ??
      defaultColorPalettes['Error/600'],
    '--error-700':
      getColor(theamObj.themConfig, 'Error/700') ??
      defaultColorPalettes['Error/700'],
    '--error-800':
      getColor(theamObj.themConfig, 'Error/800') ??
      defaultColorPalettes['Error/800'],
    '--error-900':
      getColor(theamObj.themConfig, 'Error/900') ??
      defaultColorPalettes['Error/900'],
    '--indigo-25':
      getColor(theamObj.themConfig, 'Indigo/25') ??
      defaultColorPalettes['Indigo/25'],
    '--indigo-50':
      getColor(theamObj.themConfig, 'Indigo/50') ??
      defaultColorPalettes['Indigo/50'],
    '--indigo-100':
      getColor(theamObj.themConfig, 'Indigo/100') ??
      defaultColorPalettes['Indigo/100'],
    '--indigo-200':
      getColor(theamObj.themConfig, 'Indigo/200') ??
      defaultColorPalettes['Indigo/200'],
    '--indigo-300':
      getColor(theamObj.themConfig, 'Indigo/300') ??
      defaultColorPalettes['Indigo/300'],
    '--indigo-400':
      getColor(theamObj.themConfig, 'Indigo/400') ??
      defaultColorPalettes['Indigo/400'],
    '--indigo-500':
      getColor(theamObj.themConfig, 'Indigo/500') ??
      defaultColorPalettes['Indigo/500'],
    '--indigo-600':
      getColor(theamObj.themConfig, 'Indigo/600') ??
      defaultColorPalettes['Indigo/600'],
    '--indigo-700':
      getColor(theamObj.themConfig, 'Indigo/700') ??
      defaultColorPalettes['Indigo/700'],
    '--indigo-800':
      getColor(theamObj.themConfig, 'Indigo/800') ??
      defaultColorPalettes['Indigo/800'],
    '--indigo-900':
      getColor(theamObj.themConfig, 'Indigo/900') ??
      defaultColorPalettes['Indigo/900'],
    '--success-25':
      getColor(theamObj.themConfig, 'Success/25') ??
      defaultColorPalettes['Success/25'],
    '--success-50':
      getColor(theamObj.themConfig, 'Success/50') ??
      defaultColorPalettes['Success/50'],
    '--success-100':
      getColor(theamObj.themConfig, 'Success/100') ??
      defaultColorPalettes['Success/100'],
    '--success-200':
      getColor(theamObj.themConfig, 'Success/200') ??
      defaultColorPalettes['Success/200'],
    '--success-300':
      getColor(theamObj.themConfig, 'Success/300') ??
      defaultColorPalettes['Success/300'],
    '--success-400':
      getColor(theamObj.themConfig, 'Success/400') ??
      defaultColorPalettes['Success/400'],
    '--success-500':
      getColor(theamObj.themConfig, 'Success/500') ??
      defaultColorPalettes['Success/500'],
    '--success-600':
      getColor(theamObj.themConfig, 'Success/600') ??
      defaultColorPalettes['Success/600'],
    '--success-700':
      getColor(theamObj.themConfig, 'Success/700') ??
      defaultColorPalettes['Success/700'],
    '--success-800':
      getColor(theamObj.themConfig, 'Success/800') ??
      defaultColorPalettes['Success/800'],
    '--success-900':
      getColor(theamObj.themConfig, 'Success/900') ??
      defaultColorPalettes['Success/900'],
    '--warning-25':
      getColor(theamObj.themConfig, 'Warning/25') ??
      defaultColorPalettes['Warning/25'],
    '--warning-50':
      getColor(theamObj.themConfig, 'Warning/50') ??
      defaultColorPalettes['Warning/50'],
    '--warning-100':
      getColor(theamObj.themConfig, 'Warning/100') ??
      defaultColorPalettes['Warning/100'],
    '--warning-200':
      getColor(theamObj.themConfig, 'Warning/200') ??
      defaultColorPalettes['Warning/200'],
    '--warning-300':
      getColor(theamObj.themConfig, 'Warning/300') ??
      defaultColorPalettes['Warning/300'],
    '--warning-400':
      getColor(theamObj.themConfig, 'Warning/400') ??
      defaultColorPalettes['Warning/400'],
    '--warning-500':
      getColor(theamObj.themConfig, 'Warning/500') ??
      defaultColorPalettes['Warning/500'],
    '--warning-600':
      getColor(theamObj.themConfig, 'Warning/600') ??
      defaultColorPalettes['Warning/600'],
    '--warning-700':
      getColor(theamObj.themConfig, 'Warning/700') ??
      defaultColorPalettes['Warning/700'],
    '--warning-800':
      getColor(theamObj.themConfig, 'Warning/800') ??
      defaultColorPalettes['Warning/800'],
    '--warning-900':
      getColor(theamObj.themConfig, 'Warning/900') ??
      defaultColorPalettes['Warning/900'],
    '--blueGray-25':
      getColor(theamObj.themConfig, 'BlueGray/25') ??
      defaultColorPalettes['BlueGray/25'],
    '--blueGray-50':
      getColor(theamObj.themConfig, 'BlueGray/50') ??
      defaultColorPalettes['BlueGray/50'],
    '--blueGray-100':
      getColor(theamObj.themConfig, 'BlueGray/100') ??
      defaultColorPalettes['BlueGray/100'],
    '--blueGray-200':
      getColor(theamObj.themConfig, 'BlueGray/200') ??
      defaultColorPalettes['BlueGray/200'],
    '--blueGray-300':
      getColor(theamObj.themConfig, 'BlueGray/300') ??
      defaultColorPalettes['BlueGray/300'],
    '--blueGray-400':
      getColor(theamObj.themConfig, 'BlueGray/400') ??
      defaultColorPalettes['BlueGray/400'],
    '--blueGray-500':
      getColor(theamObj.themConfig, 'BlueGray/500') ??
      defaultColorPalettes['BlueGray/500'],
    '--blueGray-600':
      getColor(theamObj.themConfig, 'BlueGray/600') ??
      defaultColorPalettes['BlueGray/600'],
    '--blueGray-700':
      getColor(theamObj.themConfig, 'BlueGray/700') ??
      defaultColorPalettes['BlueGray/700'],
    '--blueGray-800':
      getColor(theamObj.themConfig, 'BlueGray/800') ??
      defaultColorPalettes['BlueGray/800'],
    '--blueGray-900':
      getColor(theamObj.themConfig, 'BlueGray/900') ??
      defaultColorPalettes['BlueGray/900'],
    '--blueLight-25':
      getColor(theamObj.themConfig, 'BlueLight/25') ??
      defaultColorPalettes['BlueLight/25'],
    '--blueLight-50':
      getColor(theamObj.themConfig, 'BlueLight/50') ??
      defaultColorPalettes['BlueLight/50'],
    '--blueLight-100':
      getColor(theamObj.themConfig, 'BlueLight/100') ??
      defaultColorPalettes['BlueLight/100'],
    '--blueLight-200':
      getColor(theamObj.themConfig, 'BlueLight/200') ??
      defaultColorPalettes['BlueLight/200'],
    '--blueLight-300':
      getColor(theamObj.themConfig, 'BlueLight/300') ??
      defaultColorPalettes['BlueLight/300'],
    '--blueLight-400':
      getColor(theamObj.themConfig, 'BlueLight/400') ??
      defaultColorPalettes['BlueLight/400'],
    '--blueLight-500':
      getColor(theamObj.themConfig, 'BlueLight/500') ??
      defaultColorPalettes['BlueLight/500'],
    '--blueLight-600':
      getColor(theamObj.themConfig, 'BlueLight/600') ??
      defaultColorPalettes['BlueLight/600'],
    '--blueLight-700':
      getColor(theamObj.themConfig, 'BlueLight/700') ??
      defaultColorPalettes['BlueLight/700'],
    '--blueLight-800':
      getColor(theamObj.themConfig, 'BlueLight/800') ??
      defaultColorPalettes['BlueLight/800'],
    '--blueLight-900':
      getColor(theamObj.themConfig, 'BlueLight/900') ??
      defaultColorPalettes['BlueLight/900'],
  };
}

export const setAppTheme = (theamObj: TheamUtilConfig) => {
  const themeType = getThemeFromLocal() ?? '';
  const navThemeType = getNavThemeFromLocal() ?? '';
  theamObj = {
    ...theamObj,
    themConfig: {
      ...theamObj.themConfig,
      themeType,
      navThemeType,
    },
  };
  applyTheme(createTheme(theamObj));
  Storage.setItemAsync('navThemeType', navThemeType);
  Storage.setItemAsync('theme', themeType);
};

export const setNavThemeType = (name: string) => {
  Storage.setItemAsync('navThemeType', name);
};
