import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { logError } from '@core-utils';
import firebaseConfig from '@src/config/firebaseConfig';

const { REACT_APP_FIREBASE_VAPID_KEY } = process.env;

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
    });
    if (currentToken) {
      logError('current token for client: ', currentToken);
    } else {
      logError(
        'No registration token available. Request permission to generate one.'
      );
    }
    return currentToken;
  } catch (error: any) {
    logError('An error occurred while retrieving token. ', error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
