import { useEffect } from 'react';

import { XB_Button } from '@core-components/atoms';

import './AU_SideDrawer.style.scss';

interface SideDrawerProps {
  children: React.ReactNode;
  open?: boolean;
  setOpen?: (boolean) => void;
  classes?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onCloseDrawer?: () => void;
}

interface HeadingBarProps {
  onClose?: (boolean) => void;
  children?: React.ReactNode;
  isGrayHeader?: boolean;
}
export const AU_SideDrawer = ({
  children,
  header,
  footer,
  open = false,
  setOpen,
  classes,
  onCloseDrawer,
}: SideDrawerProps) => {
  /**
   * @description for close on press esc button
   */
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpen?.(false);
        onCloseDrawer?.();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <div
      className={`outer_container  ${
        open ? 'outer_container-fade-in' : ' outer_container-fade-out'
      } ${classes}`}
      onClick={() => {
        setOpen?.(false);
        onCloseDrawer?.();
      }}
    >
      <div
        className={`${
          open ? 'drawer_open_container ' : 'drawer_close_container'
        }
          drawer-container`}
        onClick={(e) => e.stopPropagation()}
      >
        <header>{header}</header>
        <div
          className={`${
            footer ? 'drawer_body_with_footer' : 'drawer_body'
          } px-6 pt-6 mb-6 overflow-y-scroll`}
        >
          {children}
        </div>
        <footer>{footer}</footer>
      </div>
    </div>
  );
};

export const HeadingBar = ({
  onClose,
  children,
  isGrayHeader,
}: HeadingBarProps) => {
  const isGrayHeaderClass = isGrayHeader ? 'gray-header' : '';
  return (
    <div className={`heading_bar ${isGrayHeaderClass}`}>
      {children}
      <XB_Button
        dataTestId="CROSS-BTN"
        size="sm"
        backgroundColor="#fff"
        onClick={onClose}
        classes={`ml-auto text-gray-700 ${
          isGrayHeader ? 'bg-neutrals-100' : ''
        }`}
        iconName="cross"
      ></XB_Button>
    </div>
  );
};
