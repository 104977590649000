import { AUEndPoints, Service } from '.';

interface IReqPayload {
  logs: string;
}

export const logService = async (payload: IReqPayload) => {
  const reqObj = {
    baseurl: `${AUEndPoints.logService.LOGS}`,
    method: 'POST',
    url: '',
    obj: payload,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
