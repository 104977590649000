import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AU_Loader, XB_Button } from '@core-components/atoms';
import { appConstants } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  consentAcceptedList,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import { saveConsent } from '@modules/common/services';
import Error500 from '@modules/debit-card/screens/error-500.screen';
import {
  nextConsentPage,
  updateAcceptedList,
  updateshowConsent,
} from '@src/core-stores/slices/consent-acceptance.slice';

import logo from '@src/assets/images/AuLogo.png';
import image from '@src/assets/images/News-amico 1.png';

interface ConsentPageProps {
  isList: boolean;
  isVideo: boolean;
  pageNo: number;
  consentPageData: any;
  showConsentList: boolean[];
}

interface ConsentAcceptanceProps {
  consentObj: {
    first: string;
    second: string;
    third: string;
  };
}

const ConsentPage = ({
  isList = false,
  isVideo = false,
  pageNo = 1,
  consentPageData,
  showConsentList,
}: ConsentPageProps) => {
  const { loading: isConsentLoading, currentPage } = useAppSelector(
    (state) => state.concentData
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useAppDispatch();
  const userID = useAppSelector((state) => state.auth?.userId);
  const auAlert = useAlertBox();
  const [youtubeID] = useState<string>(
    consentPageData?.mediaUrl.split('=')?.[1]
  );
  const { t } = useTranslation();
  const LegalAgreementList = [
    'acceptDemoVideo',
    'acceptBestPractices',
    'acceptSecurityTips',
  ];
  const userConsentObj = {
    userId: userID,
    capturedBy: userID,
    appType: appConstants.APP_IDENTIFIER.WEB_MERCHANT,
    platformInfo: {
      additionalProp1: '',
      additionalProp2: '',
      additionalProp3: '',
    },
    consent: appConstants.CONSENT_STATUS.GRANTED,
    validTill: '',
    consentVersionId: consentPageData?.version?.versionId,
  };

  const handlePage = () => {
    (async () => {
      try {
        setButtonLoading(true);
        const res = await saveConsent(userConsentObj);

        if (res?.successfulResponse) {
          dispatch(nextConsentPage());
          dispatch(
            updateAcceptedList({
              capturedBy: '',
              platformInfo: {
                additionalProp1: '',
                additionalProp2: '',
                additionalProp3: '',
              },
              status: appConstants.CONSENT_STATUS.GRANTED,
              grantedAt: `${Date.now()}`,
              validTill: `${new Date().setFullYear(
                new Date().getFullYear() + 1
              )}`,
              consentVersionId: consentPageData?.version?.versionId,
            })
          );
        } else {
          auAlert.error(res?.error?.message);
        }
      } catch (err: any) {
        auAlert.error(err.displayMessage);
      } finally {
        setButtonLoading(false);
      }
    })();
  };

  if (isConsentLoading) {
    return (
      <div className="z-[999] absolute  bg-slate-100">
        <AU_Loader />;
      </div>
    );
  }

  return (
    <div className="z-[999] fixed top-0 right-0 left-0 bottom-0 bg-slate-100 overflow-auto">
      <div className="h-20 px-2 pl-6 pr-2 bg-gradient-to-r rounded-b-xl from-[#9A48A9] to-[#4D2454]">
        <img className="pt-2 h-3/4" src={logo} alt="au-logo" />
      </div>

      <div className="flex w-10/12 flex-col mx-auto sm:flex-row-reverse items-center sm:items-start  pl-2 pt-10 sm:pt-20 md:pt-30  sm:pl-10  md:gap-x-30    lg:gap-x-64  text-[0.50rem] sm:text-sm lg:text-base sm:gap-x-10">
        <div className=" h-72 lg:h-96  md:h-80">
          <img className="h-full" src={image} alt="concent image" />
        </div>
        <div className=" w-full md:w-5/12 flex flex-col gap-5">
          <div>
            <p className="text-neutrals-900 m-text-lg-bold">
              {t('importantAdvisory')}
            </p>
            <span className="rounded text-blue-700  px-3 py-1  bg-blue-50 m-text-md-medium">
              {` ${pageNo} of ${showConsentList.filter((el) => el).length}`}
            </span>
          </div>
          <div className="gap-2 flex flex-col">
            <p className="text-neutrals-700  m-display-lg-bold">
              {consentPageData?.name}
            </p>

            {isList && (
              <ul
                className="list-disc
              m-text-md-regular pl-5 "
              >
                {consentPageData?.version?.content?.map((content, i) => (
                  <li key={i}>{content.text}</li>
                ))}
              </ul>
            )}
            {isVideo && (
              <iframe
                className=" rounded-2xl h-[235px] w-full bg-warning-50  
                "
                src={`https://www.youtube.com/embed/${youtubeID}?autoplay=0`}
              ></iframe>
            )}

            {isVideo && (
              <a
                className=" underline  
                m-text-md-regular text-primary-600 "
                href={consentPageData?.mediaUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t('clickHereToAccessUserGuide')}
              </a>
            )}
          </div>
          <div className="gap-1 w-4/5 mt-5">
            <p className="text-sm text-neutrals-700  font-normal">
              {t(LegalAgreementList[currentPage - 1])}
            </p>
            <XB_Button
              size="lg"
              dataTestId="GO-BACK-TO-LGN"
              btnType="primary"
              classes={`w-full mt-2  ${pageNo === 3 ? '' : 'px-5'}`}
              onClick={() => handlePage()}
              showLoader={buttonLoading}
            >
              {pageNo === 3 || showConsentList.filter((el) => el).length === 1
                ? t('accept')
                : t('acceptAndNext')}
              {!(
                pageNo === 3 || showConsentList.filter((el) => el).length === 1
              ) && <span className="ml-2"> &gt;</span>}
            </XB_Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AU_ConsentAcceptance = ({
  consentObj,
}: ConsentAcceptanceProps) => {
  const dispatch = useAppDispatch();

  const {
    data: consentData,
    loading: isLoading,
    error: isError,
    currentPage,
    userAcceptedList,
    showConsentList,
  } = useAppSelector((state) => state.concentData);

  const userID = useAppSelector((state) => state.auth?.userId);

  const checkForConsentExpire = (validTill: string): boolean => {
    return new Date(validTill) > new Date();
  };

  const latestWebConsentList = [
    consentData?.[consentObj.first],
    consentData?.[consentObj.second],
    consentData?.[consentObj.third],
  ];
  const userAcceptedversionList = userAcceptedList?.map(
    (el) => el.consentVersionId
  );
  function checkForConsentVersionUpdationAcceptedList() {
    latestWebConsentList?.forEach((consent, i) => {
      const check = userAcceptedversionList.includes(
        consent?.version.versionId
      );

      if (check) {
        const index = userAcceptedversionList.indexOf(
          consent?.version.versionId
        );

        if (checkForConsentExpire(userAcceptedList[index].validTill)) {
          if (currentPage === i + 1) dispatch(nextConsentPage());
          dispatch(updateshowConsent(i));
        }
      }
    });
  }
  function concentAcceptedListAPI() {
    dispatch(
      consentAcceptedList({
        userId: userID,
        appType: appConstants.APP_IDENTIFIER.WEB_MERCHANT,
      })
    );
  }

  function showErrorPage() {
    return (
      <>
        <div className="z-[999] absolute top-0 right-0 left-0 bottom-0 bg-slate-100">
          <Error500 retryFunc={() => concentAcceptedListAPI()} />
        </div>
      </>
    );
  }

  useEffect(() => {
    checkForConsentVersionUpdationAcceptedList();
  }, [dispatch, currentPage]);

  const handlePageNumber = () => {
    let pageNo = 1;
    if (showConsentList[0] && showConsentList[1]) {
      pageNo = 3;
    } else if (!showConsentList[0] && showConsentList[1]) {
      pageNo = 2;
    }
    return pageNo;
  };

  return (
    <div>
      {isError && !isLoading && showErrorPage()}

      {currentPage === 1 && showConsentList?.[0] && (
        <ConsentPage
          isList={true}
          pageNo={1}
          isVideo={consentData[consentObj?.first]?.mediaType}
          consentPageData={consentData[consentObj?.first]}
          showConsentList={showConsentList}
        />
      )}
      {currentPage === 2 && showConsentList?.[1] && (
        <ConsentPage
          isList={true}
          isVideo={false}
          pageNo={showConsentList[0] ? 2 : 1}
          consentPageData={consentData[consentObj?.second]}
          showConsentList={showConsentList}
        />
      )}
      {currentPage === 3 && showConsentList?.[2] && (
        <ConsentPage
          isList={true}
          isVideo={false}
          pageNo={handlePageNumber()}
          consentPageData={consentData[consentObj?.third]}
          showConsentList={showConsentList}
        />
      )}
    </div>
  );
};
