import { useEffect, useState } from 'react';

import './AU_Toggle.style.scss';

interface ToggleProps {
  isToggle: boolean;
  title?: string;
  description?: string;
  size: 'sm' | 'md';
  textSize: 'md' | 'lg';
  disabled?: boolean;
  onChange: (boolean) => void;
}

export const AU_Toggle = ({
  isToggle = false,
  title,
  description,
  size,
  textSize,
  disabled = false,
  onChange,
}: ToggleProps) => {
  const [isToggleOpen, setIsToggleOpen] = useState(isToggle);
  const classList = ['toggle_container', `toggle_container_${size}`];
  isToggleOpen
    ? classList.push('toggle_container_orange')
    : classList.push('toggle_container_gray');
  disabled && classList.push('toggle_container_disabled');
  const handleClick = () => {
    setIsToggleOpen((prev) => !prev);
    onChange(false);
  };
  useEffect(() => {
    setIsToggleOpen(isToggle);
  }, [isToggle]);
  return (
    <div className="container_fluid">
      <div className="content_container">
        <h1 className={`m-text-${textSize}-regular text-gray-700`}>{title}</h1>
        <p className={`m-text-${textSize}-regular text-gray-500`}>
          {description}
        </p>
      </div>
      <div
        className={`${classList.join(' ')} cursor-pointer`}
        onClick={handleClick}
      >
        <div
          className={
            isToggleOpen ? `knob_${size} active_${size}` : `knob_${size}`
          }
        ></div>
      </div>
    </div>
  );
};
