import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedVerticalBranch: undefined,
};

const inventoryManagementSlice = createSlice({
  name: 'inventoryManagement',
  initialState,
  reducers: {
    setSelectedVerticalBranch(state, { payload }: any) {
      state.selectedVerticalBranch = payload;
    },
  },
});
export const { setSelectedVerticalBranch } = inventoryManagementSlice.actions;
export default inventoryManagementSlice.reducer;
