export const maskEntity = (
  len,
  maskingStartfrom,
  maskingEndFrom,
  val
): string => {
  if (!val) return '_empty_';
  return (
    `${val?.slice(0, maskingStartfrom) as string}${'x'.repeat(
      len - maskingStartfrom - maskingEndFrom
    )}${val?.slice(-maskingEndFrom) as string}` ?? '_empty_'
  );
};
