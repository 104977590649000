import { ReactNode, useEffect, useState } from 'react';

import { Icon, XB_Divider } from '@core-components/atoms';
import { isEmpty } from '@src/core-utils';

import './XB_Accordion.style.scss';

interface AccordionItem {
  title: string | ReactNode;
  headerContent?: ReactNode;
  content: ReactNode;
  active: boolean;
  isIconClose?: boolean;
  titleClass?: string;
}

export const XB_Accordion = ({
  list,
  isOpenFirst = true,
  isOpenAll = false,
}: {
  list: AccordionItem[];
  isOpenFirst?: boolean;
  isOpenAll?: boolean;
}) => {
  const checkActive = (index, active): boolean => {
    if (isOpenAll) {
      return isOpenAll;
    } else if (isOpenFirst) {
      return index === 0;
    }
    return active;
  };

  return (
    <div className="accordion-container">
      {list.map(
        (
          {
            title,
            content,
            isIconClose,
            titleClass,
            active,
            headerContent,
          }: any,
          index: number
        ) => (
          <div key={index}>
            <XB_AccordionItem
              title={title}
              headerContent={headerContent}
              content={content}
              active={checkActive(index, active)}
              isIconClose={isIconClose}
              titleClass={titleClass}
            />
            {index < list.length - 1 && <XB_Divider classes="my-6" />}
          </div>
        )
      )}
    </div>
  );
};

const XB_AccordionItem = ({
  title,
  content,
  active,
  titleClass = '',
  headerContent,
}: AccordionItem) => {
  const [isActive, setIsActive] = useState<boolean>(active);
  useEffect(() => {
    setIsActive(active);
  }, [active]);
  return (
    <div className="accordion__item ">
      <div
        className={`accordion__title py-2 rounded-md pl-2 ${titleClass}`}
        onClick={() => setIsActive((prev) => !prev)}
      >
        <div className="flex items-center flex-wrap">
          <h2 className="m-text-lg-bold text-gray-800 me-2">{title}</h2>
          {!isEmpty(headerContent) && headerContent}
        </div>
        <div>
          <Icon
            width={40}
            icon="chevron-down"
            className={`text-gray-900 ease-linear duration-300 ${
              isActive ? 'rotate-180' : ''
            }`}
          />
        </div>
      </div>
      <div className={`accordion__content ${isActive ? 'show' : 'hide'}`}>
        {content}
      </div>
    </div>
  );
};
