import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { XB_OptionCard } from 'core-components/molecules';

import {
  AU_Badge,
  AU_Loader,
  AU_RoundBox,
  AU_SideDrawer,
  AU_ToggleOptions,
  HeadingBar,
  Icon,
  XB_Button,
  XB_Divider,
  XB_Modal,
  XB_SectionInfo,
  XB_SideDrawer,
} from '@core-components/atoms';
import { DASHBOARD_TYPE, NotificationReadStatus } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import { s3DownlaodImg } from '@core-services';
import {
  AuthState,
  customerAccounts,
  getUserProfile,
  initializeApp,
  logout,
  merchantDetails,
  RootState,
  store,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import {
  getConcatenatedString,
  getFirstLastChar,
  isEmpty,
  logError,
  Storage,
} from '@core-utils';
import InactivityDetector from '@modules/common/components/inactivity-detector.component';
import { NOTIFICATION_READ_STATUS } from '@modules/profile-info/constants/profile-info.constant';
import { IResNotifications } from '@modules/profile-info/models/profile-info.model';
import {
  getReadNotifications,
  setDefaultMerchant,
} from '@modules/profile-info/services/profile-info.service';
import { getNotifications } from '@modules/profile-info/stores/thunks/profile-setting.thunk';
import noDataImg from '@src/assets/images/no-notification.svg';
import { setNavThemeType as setNavThemeTypeRdx } from '@src/core-stores/slices/init.slice';
import { setUserProfileImg } from '@src/core-stores/slices/user-profile-image.slice';
import { CIF } from '@src/mocks/deposit-opening.mock';
import { setNavThemeType } from '@src/themes/utils.theme';

import './AU_TopNavbar.style.scss';

interface TopNavbarProps {
  handleNavbarOpen?: () => void;
  isDashboard?: boolean;
}

export interface IMerchantImgData {
  data: string;
  merchantId: string;
}

interface IProfileDropDown {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setShowProfileModal: Dispatch<SetStateAction<boolean>>;
  setOpenConfirmModal: Dispatch<SetStateAction<boolean>>;
  userImgData: string;
}
export const AU_TopNavbar = ({
  handleNavbarOpen,
  isDashboard = true,
}: TopNavbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auAlert = useAlertBox();
  const dispatch = useAppDispatch();

  const [storeToggle, setStoreToggle] = useState<boolean>(true);
  const [notificationsSideBar, setNotificationsSideBar] =
    useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [enableLoader, setEnableLoader] = useState(false);
  const [unReadCount, setUnReadCount] = useState<number>(0);

  const cif = CIF() as string;
  const [width, setWidth] = useState<number>(window.innerWidth);

  const userProfileImage = useAppSelector(
    (state: RootState) => state?.userProfileImage?.img
  );
  const profileRdxData = useAppSelector((state) => state.profile.data);
  const notificationsSelector = useAppSelector(
    (state) => state?.getNotifications?.data
  );

  useEffect(() => {
    if (notificationsSelector) {
      setUnReadCount(
        notificationsSelector?.filter(
          (item) => item?.readStatus === NOTIFICATION_READ_STATUS?.UNREAD
        )?.length
      );
    }
  }, [notificationsSelector]);

  const [notificationsData, setNotificationsData] =
    useState<IResNotifications[]>();
  const { globalConfig } = useAppSelector((state: RootState) => state.init);

  const appIcons = useAppSelector((state: RootState) => state.getAppIcons);
  const profileDetailsSlice = useAppSelector((state) => state?.profile?.data);

  const downloadUserAssets = async () => {
    try {
      const downloadResult = await s3DownlaodImg({
        awsS3BucketKeyPath: profileRdxData?.profileImage,
      });
      if (downloadResult?.data) {
        dispatch(setUserProfileImg(downloadResult?.data));
      }
    } catch (e: any) {}
  };

  const handleModalClick = () => {
    setShowProfileModal((prev) => !prev);
  };

  useEffect(() => {
    if (profileRdxData && 'profileImage' in profileRdxData) {
      downloadUserAssets();
    }
  }, [profileRdxData]);

  // for default dashboard
  useEffect(() => {
    if (
      profileDetailsSlice?.defaultDashboard &&
      profileDetailsSlice?.defaultDashboard === DASHBOARD_TYPE.BANK
    ) {
      setStoreToggle(false);
    } else {
      setStoreToggle(true);
    }
  }, []);

  const cifBottomSheet = () => {
    return (
      <div className="sidedrawer_mobile_bottom_sheet">
        <XB_SideDrawer
          onClose={() => setShowProfileModal(false)}
          placement="bottom"
          visible={showProfileModal}
          closable={true}
        >
          <section className="sidedrawer_profile_container">
            <div className="bottom_sheet_profile_wrapper overflow-auto">
              <div className="mt-2 w-full">
                <div className="profile_wrapper_inner !w-full">
                  <ProfileDropDown
                    setLoading={setLoading}
                    setShowProfileModal={setShowProfileModal}
                    setOpenConfirmModal={setOpenConfirmModal}
                    userImgData={userProfileImage ?? ''}
                  />
                </div>
              </div>
            </div>
          </section>
        </XB_SideDrawer>
      </div>
    );
  };

  const apiHeader = {
    sessionId: sessionStorage.getItem('sessionId') ?? '',
    refreshToken: sessionStorage.getItem('refreshToken') ?? '',
  };

  const getNotificationsApi = async () => {
    setLoading(true);
    try {
      const resNotifications = await dispatch(
        getNotifications({
          userId: profileRdxData?.userId ?? '',
        })
      );
      if (resNotifications?.payload?.successfulResponse) {
        setNotificationsData(resNotifications?.payload?.data);
        setNotificationsSideBar(true);
      } else {
        auAlert.error(
          resNotifications?.payload?.displayMessage ??
            (resNotifications?.payload as string)
        );
      }
    } catch (e: any) {
      auAlert.error(e?.displayMessage);
    } finally {
      setLoading(false);
    }
  };

  const getReadNotificationsApi = async (id) => {
    setLoading(true);
    try {
      const resReadNotifications = await getReadNotifications({
        id,
        readAll: false,
      });
      if (resReadNotifications?.successfulResponse) {
        auAlert.success(resReadNotifications?.data?.message);
        await getNotificationsApi();
      } else {
        auAlert.error(resReadNotifications?.error?.message as string);
      }
    } catch (e: any) {
      auAlert.error(e?.displayMessage);
    } finally {
      setLoading(false);
    }
  };

  const apiLogout = async (forced = false) => {
    setEnableLoader(true);

    const response = dispatch(logout(apiHeader));
    response
      .unwrap()
      .then(async (_resp) => {
        store.dispatch({ type: 'USER_LOGOUT' });
        setTimeout(() => {
          dispatch(setNavThemeTypeRdx(DASHBOARD_TYPE.STORE));
          if (forced) {
            auAlert.warning(t('dueToInactivityLoggedOut').toString());
          } else {
            auAlert.success(t('logoutSuccessfully').toString());
          }
        }, 50);
        dispatch(initializeApp());
        setNavThemeType(DASHBOARD_TYPE.STORE);
        Storage.setItemAsync('LANGUAGE', 'en');
        Storage.setItemAsync('LANGUAGE-NAME', 'English');
        navigate('/');
      })
      .catch((err) => {
        auAlert.error(err?.displayMessage);
      });
  };
  const handleClickLogout = () => {
    setEnableLoader(true);
    apiLogout();
  };
  const useOutsideClick = (callback: () => void) => {
    const clickRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          clickRef.current &&
          !clickRef.current.contains(event.target as Node)
        ) {
          callback();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [callback]);

    return clickRef;
  };

  const ref = useOutsideClick(() => {
    if (window.innerWidth > 580) {
      setShowProfileModal(false);
    }
  });

  const confirmationModal = useMemo(
    () => (
      <>
        <XB_Modal
          headPos="text-left"
          title={t('logout')}
          subTitle={t('logOutMessage')}
          maxWidth="25rem"
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          img={
            <AU_RoundBox boxClass="bg-orange-50">
              <Icon
                width={48}
                height={48}
                className="text-orange-600"
                icon="alerttriangle"
              />
            </AU_RoundBox>
          }
          actions={
            <div className="flex">
              <XB_Button
                dataTestId="NO"
                btnType="secondary-gray"
                classes="w-1/2 mr-2"
                onClick={() => {
                  setOpenConfirmModal(false);
                  setEnableLoader(false);
                }}
              >
                {t('cancel')}
              </XB_Button>
              <XB_Button
                dataTestId="YES"
                btnType="primary"
                classes="w-1/2 ml-2"
                showLoader={enableLoader}
                onClick={() => {
                  handleClickLogout();
                }}
              >
                {t('logout')}
              </XB_Button>
            </div>
          }
        />
      </>
    ),
    [openConfirmModal, enableLoader]
  );

  const getTimeDiffernce = (Time) => {
    const timeStart = new Date(Time);
    const timeEnd = new Date();
    const diffMs = Math.abs(timeEnd.getTime() - timeStart.getTime());
    const totalMinutes = Math.floor(diffMs / (1000 * 60));
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes - days * 24 * 60) / 60);
    const minutes = totalMinutes - days * 24 * 60 - hours * 60;

    return { days, hours, minutes };
  };

  const showNotificationSideBar = () => {
    return (
      <AU_SideDrawer
        classes="z-20"
        open={notificationsSideBar}
        header={
          <HeadingBar
            isGrayHeader={true}
            onClose={() => {
              setNotificationsSideBar(false);
            }}
          >
            <XB_SectionInfo
              title={t('notifications')}
              description={`${unReadCount ?? 0} ${t('unread')}`}
              classes="m-display-sm-bold text-neutral-700"
            />
          </HeadingBar>
        }
      >
        <div>
          {notificationsData?.length === 0 ? (
            <div className="p-10">
              <div className="flex justify-center items-center">
                <img
                  src={noDataImg}
                  alt="no-payee-bg"
                  width={200}
                  height={200}
                />
              </div>
              <div className="text-center mt-8 flex flex-col">
                <span className="m-text-lg-regular text-neutral-700">
                  {t('noNotificationYet')}
                </span>
              </div>
            </div>
          ) : (
            notificationsData?.map((notif, index: number) => {
              return (
                <>
                  <Fragment key={getConcatenatedString([index, notif])}>
                    <div
                      className="notification cursor-pointer"
                      onClick={() => {
                        notif.readStatus !== NotificationReadStatus &&
                          getReadNotificationsApi(notif.id);
                      }}
                    >
                      <AU_RoundBox>
                        <Icon icon="bell" width={48} height={48} color="none" />
                      </AU_RoundBox>
                      <div className="notification_content_section justify-between flex w-full">
                        <div className="notification_title_and_time_section flex-col flex w-full">
                          <div className="flex justify-between w-full">
                            <h1
                              className={`text-neutral-900 w-full ${
                                notif.readStatus
                                  ? 'm-text-md-bold'
                                  : 'm-text-md-medium'
                              }`}
                            >
                              {notif.subject
                                ? notif.subject
                                : t('notification')}
                            </h1>
                            <div className="notification_time flex gap-2 items-center">
                              <p className="m-text-xs-medium text-neutrals-500 w-max">
                                {getTimeDiffernce(notif.createdDate).days > 0
                                  ? ` ${
                                      getTimeDiffernce(notif.createdDate).days
                                    } days `
                                  : ''}
                                {getTimeDiffernce(notif.createdDate).hours > 0
                                  ? ` ${
                                      getTimeDiffernce(notif.createdDate).hours
                                    } hours `
                                  : ''}
                                {getTimeDiffernce(notif.createdDate).minutes > 0
                                  ? ` ${
                                      getTimeDiffernce(notif.createdDate)
                                        .minutes
                                    } min ago `
                                  : ''}
                              </p>
                              {notif.readStatus !== NotificationReadStatus && (
                                <div className="read-icon">
                                  {notif.readStatus !==
                                  NotificationReadStatus ? (
                                    <Icon
                                      icon="greendot"
                                      height={8}
                                      width={8}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <p className="m-text-sm-medium text-neutral-500 w-full">
                            {notif.body}
                          </p>
                        </div>
                      </div>
                    </div>
                    {index !== notificationsData.length - 1 && (
                      <XB_Divider classes="py-3" />
                    )}
                  </Fragment>
                </>
              );
            })
          )}
        </div>
      </AU_SideDrawer>
    );
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <>
      {loading && <AU_Loader fullLoader={true} />}
      <InactivityDetector
        timeout={parseInt(globalConfig?.webUserInactivityTime)}
        onInactive={() => {
          setEnableLoader(true);
          apiLogout(true);
        }}
      />
      <div className="top_navbar">
        {confirmationModal}
        {showNotificationSideBar()}
        {showProfileModal && width < 580 && cifBottomSheet()}
        <div className="left_content">
          {isDashboard && (
            <div className="hamburger-sidebar-icon" onClick={handleNavbarOpen}>
              <Icon
                icon="humberger-close-icon"
                color="#fff"
                className="cursor-pointer"
              />
            </div>
          )}
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate('/mr-dashboard');
            }}
          >
            <img src={appIcons?.data?.auLogo?.image} className="rounded" />
          </div>

          {isDashboard && (
            <div className="dashboard_toggle">
              <AU_ToggleOptions
                setToggle={setStoreToggle}
                storeToggle={storeToggle}
              />
            </div>
          )}
        </div>
        <div className="right_content">
          <div className="list_wrapper gap-3 sm:gap-8">
            <li
              className="cursor-pointer support-icon"
              onClick={() => navigate('/profile/support')}
            >
              <Icon icon="help-circle" color="none" />
            </li>
            <li
              className="cursor-pointer"
              onClick={() => {
                getNotificationsApi();
              }}
            >
              <div>
                <Icon icon="bell" color="none" />
                {unReadCount > 0 && (
                  <p className="absolute w-3 h-3 bg-green-400 rounded-full text-white top-[22px] text-center ml-[10px] text-[10px]"></p>
                )}
              </div>
            </li>
            <li
              className="cursor-pointer flex gap-2"
              onClick={() => {
                handleModalClick();
              }}
            >
              {!isEmpty(userProfileImage) ? (
                <img
                  className="rounded-[50%] w-[3rem] h-[3rem] align-middle !hidden sm:!block object-cover"
                  src={`data:image/png;base64,${userProfileImage}`}
                />
              ) : (
                <>
                  <span className="name_box align-middle m-display-sm-medium text-primary-600 bg-white  !hidden sm:!flex">
                    <NameInitialsUI />
                  </span>
                </>
              )}

              <Icon icon="chevron-down" className="text-white self-center" />
            </li>
          </div>
          {showProfileModal && width > 580 && (
            <div className={`profile_wrapper ${cif ? 'gap-2' : ''}`} ref={ref}>
              <ProfileDropDown
                setLoading={setLoading}
                setShowProfileModal={setShowProfileModal}
                setOpenConfirmModal={setOpenConfirmModal}
                userImgData={userProfileImage ?? ''}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const NameInitialsUI = () => {
  const dedupeLiteSlice = useAppSelector(
    (state) => state?.auDedupeLite.dedupeLite
  );
  const { isNTB } = useAppSelector((state: RootState) => state?.auDashboard);
  const resAuthProfile = useAppSelector((state: RootState) => state.auth) as
    | AuthState
    | undefined;
  return isNTB
    ? getFirstLastChar((resAuthProfile?.name as string) ?? '')
    : getFirstLastChar(dedupeLiteSlice?.customerFullName ?? '');
};

export const ProfileDropDown = ({
  setLoading,
  setShowProfileModal,
  setOpenConfirmModal,
  userImgData,
}: IProfileDropDown) => {
  const dispatch = useAppDispatch();

  const { isNTB } = useAppSelector((state: RootState) => state?.auDashboard);
  const resAuthProfile = useAppSelector((state: RootState) => state.auth) as
    | AuthState
    | undefined;
  const auAlert = useAlertBox();
  const cif = CIF() as string;

  const profileRdxData = useAppSelector((state) => state.profile.data);
  const dedupeLiteSlice = useAppSelector(
    (state) => state?.auDedupeLite.dedupeLite
  );
  const CustomerNameUI = isNTB
    ? resAuthProfile?.name
    : dedupeLiteSlice?.customerFullName;

  const ShowCif = () => {
    return !isNTB && cif;
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dashboardType = useAppSelector((state) => state.dashboardTypeToggle);
  const [merImages, setMerImages] = useState<{ [key: string]: string }>();

  const switchProfile = (merchantKey: string) => {
    if (dashboardType.toggleType === DASHBOARD_TYPE.STORE) {
      setLoading(true);
    }
    dispatch(getUserProfile({ merchantId: merchantKey }))
      .unwrap()
      .then((responseProfile) => {
        if (responseProfile?.successfulResponse) {
          if (!isEmpty(profileRdxData?.cifNumber)) {
            dispatch(
              customerAccounts({ CustomerId: profileRdxData?.cifNumber ?? '' })
            );
          }
          dispatch(
            merchantDetails({
              merchantId: merchantKey,
            })
          );
        } else {
          auAlert.error(
            responseProfile?.payload?.displayMessage ?? responseProfile?.payload
          );
        }
      })
      .then(() => {
        navigate('/mr-dashboard');
      })
      .catch((error) => {
        auAlert.error(error?.displayMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const otherProps = {
    name: 'cifselection',
    onChange: (e) => {
      switchProfile(e.target.value.split(' ')[0]);
      setShowProfileModal(false);
    },
  };

  const mrImage = async (key: string, path: string) => {
    try {
      const downloadResult = await s3DownlaodImg({
        awsS3BucketKeyPath: path,
      });
      if (downloadResult?.data) {
        setMerImages((prev) => ({ ...prev, [key]: downloadResult?.data }));
      }
    } catch (e: any) {
      logError(e);
    }
  };
  useEffect(() => {
    profileRdxData?.merchants &&
      Object.entries(profileRdxData?.merchants).forEach((entry) => {
        const [key, value] = entry;
        value?.profileImageUrl && mrImage(key, value?.profileImageUrl);
      });
  }, [profileRdxData]);

  const setDefaultMerchantApi = async (defaultMerchantId) => {
    setLoading(true);
    try {
      const resDefaultMerchant = await setDefaultMerchant(defaultMerchantId);
      if (resDefaultMerchant?.successfulResponse) {
        auAlert.success(
          t('setDefaultmessage', {
            businessName:
              profileRdxData?.merchants[defaultMerchantId].businessName ?? '',
            cif: profileRdxData?.merchants[defaultMerchantId]?.entityCif,
          })
        );

        dispatch(getUserProfile({ merchantId: '' }));
      } else {
        auAlert.error(resDefaultMerchant?.error?.message as string);
      }
    } catch (e: any) {
      auAlert.error(e?.displayMessage);
    } finally {
      setLoading(false);
      setShowProfileModal(false);
    }
  };
  return (
    <>
      <div className="flex profile_box">
        <div className="inner_wrapper flex">
          {!isEmpty(userImgData) ? (
            <img
              className="rounded-[50%] w-[3.25rem] h-[3.25rem] align-middle !hidden sm:!block object-cover"
              src={`data:image/png;base64,${userImgData}`}
            />
          ) : (
            <span className="name_box align-middle m-display-sm-medium text-primary-600 bg-white  !hidden sm:!flex">
              <NameInitialsUI />
            </span>
          )}

          <span className="ml-1 mt-auto mb-auto">
            <h1 className="m-text-md-medium text-gray-700">
              {CustomerNameUI ?? ''}
            </h1>
            <span className="m-text-lg-regular text-gray-400">{ShowCif()}</span>
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-1 w-full">
        {profileRdxData &&
          Object.entries(profileRdxData?.merchants).length > 1 && (
            <p className="text-gray-500 m-text-sm-bold">{t('switchProfile')}</p>
          )}
        {profileRdxData &&
          Object.entries(profileRdxData?.merchants).length > 1 &&
          Object.entries(profileRdxData?.merchants).map(
            ([key, value], index) => {
              const tempValue = `${key} ${value?.entityCif}`;

              return (
                <div className="flex flex-col pl-2 mt-2" key={index}>
                  <XB_OptionCard
                    id={`key-${index}`}
                    key={index}
                    radioType="fill"
                    isChecked={profileRdxData?.activeMerchantId === key}
                    title={value?.businessName ?? t('merchant')}
                    description={value?.entityCif ?? ''}
                    value={tempValue}
                    iconSize="lg"
                    classes="gap-4 mt-2"
                    primaryMerchant={
                      profileRdxData?.merchants[key].default ? (
                        <AU_Badge
                          iconName={'greendot'}
                          iconClass="status-icon"
                          className={`success-bg-color max-w-full`}
                          contentClass={`m-text-sm-medium success-color`}
                        >
                          {t('primary')}
                        </AU_Badge>
                      ) : (
                        ''
                      )
                    }
                    cardIcon={
                      <span
                        className={`${
                          !isEmpty(merImages?.[key])
                            ? ''
                            : 'name_box align-middle m-display-sm-medium text-primary-600 bg-white !hidden sm:!flex'
                        }`}
                      >
                        {!isEmpty(merImages?.[key]) ? (
                          <img
                            src={`data:image/png;base64,${merImages?.[key]}`}
                            className="rounded-[50%] w-[3.25rem] h-[3.25rem] object-cover"
                          />
                        ) : (
                          <>
                            {getFirstLastChar(
                              value?.businessName ?? t('merchant')
                            )}
                          </>
                        )}
                      </span>
                    }
                    content={
                      !profileRdxData?.merchants[key].default &&
                      profileRdxData?.activeMerchantId === key ? (
                        <XB_Button
                          dataTestId="NO"
                          btnType="primary-underline"
                          classes="w-full mr-2 justify-start mt-2"
                          onClick={() => {
                            setDefaultMerchantApi(key);
                          }}
                        >
                          {t('setasPrimary')}
                        </XB_Button>
                      ) : (
                        ''
                      )
                    }
                    {...otherProps}
                  />
                </div>
              );
            }
          )}
        {cif && (
          <div
            className="flex flex-col items-center cursor-pointer ml-auto mt-2"
            onClick={() => {
              setShowProfileModal(false);
              navigate('/profile/cif-addition');
            }}
          >
            <p className="m-text-sm-regular text-primary-600">
              <span>+</span> {t('addCif')}
            </p>
          </div>
        )}
      </div>

      <XB_Divider classes="mt-2 w-full" />
      <div
        className="flex mt-2 cursor-pointer w-full"
        onClick={() => {
          setShowProfileModal(false);
          navigate('/profile/home');
        }}
      >
        <Icon icon="setting-circle" color="none" />
        <h2 className="m-text-lg-medium text-gray-800 ml-3">{t('setting')}</h2>
      </div>
      <XB_Divider classes="mt-2 w-full" />
      <div
        className="flex mt-2 logout_btn cursor-pointer"
        onClick={() => {
          setOpenConfirmModal(true);
          setShowProfileModal(false);
        }}
      >
        <Icon icon="logout" color="none" width={20} height={20} />
        <h2 className="m-text-lg-medium ml-3 text-red-500">{t('logout')}</h2>
      </div>
    </>
  );
};
