import { createSlice } from '@reduxjs/toolkit';

import { SaveBackModalCofig } from '../models/common.model';

interface BackModalConfig {
  config: SaveBackModalCofig | undefined;
  isConfirmClicked: boolean;
  isCancelClicked: boolean;
}

const initialState: BackModalConfig = {
  config: undefined,
  isConfirmClicked: false,
  isCancelClicked: false,
};
const saveBackModalSlice = createSlice({
  name: 'saveBackModal',
  initialState,
  reducers: {
    setSaveBackModalConfig(state, { payload }) {
      state.config = { ...state.config, ...payload };
    },
    setShowSaveBackModal(state, { payload }) {
      state.config = { ...state.config, show: payload };
    },
    setOnConfirmClick(state, { payload }) {
      state.isConfirmClicked = payload;
    },
    setOnCancelClick(state, { payload }) {
      state.isCancelClicked = payload;
    },
  },
});

export const {
  setSaveBackModalConfig,
  setShowSaveBackModal,
  setOnConfirmClick,
  setOnCancelClick,
} = saveBackModalSlice.actions;
export default saveBackModalSlice.reducer;
