import { useTranslation } from 'react-i18next';

import { AU_Badge, Icon } from '@core-components/atoms';

interface VerifiedCardProps {
  onBack: () => void;
  title?: string;
  subtitle?: string;
  name?: string;
  details?: string;
  isVerified?: boolean;
  isReadOnly?: boolean;
}

export const XB_VerifiedCard = ({
  title,
  subtitle,
  name,
  details,
  isVerified,
  onBack,
  isReadOnly = false,
}: VerifiedCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="block max-w-sm bank-account-card">
      <div className="flex justify-between mb-4">
        <div>
          <p className="m-text-lg-medium text-gray-600">{title}</p>
          <p className="m-text-lg-regular text-gray-500">{subtitle}</p>
          <p className="m-text-lg-regular text-gray-500">{name}</p>
          <p className="m-text-lg-regular text-gray-500">{details}</p>
        </div>
        {isVerified && (
          <div className="text-sm font-medium text-blue-600 ">
            <AU_Badge
              bgColor="#ECFDF3"
              className="card__badge"
              iconName={'check'}
              iconClass="text-green-500 font-medium text-xs"
            >
              <span className="text-green-500 font-medium text-xs">
                {t('verified')}
              </span>
            </AU_Badge>
          </div>
        )}
      </div>
      {!isReadOnly && (
        <div
          className="flex space-x-2 max-w-fit items-center cursor-pointer"
          onClick={onBack}
        >
          <Icon icon="pencilicon" width={14} height={14} />
          <span className="text-orange-600 m-text-md-medium">{t('edit')}</span>
        </div>
      )}
    </div>
  );
};
