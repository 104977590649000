import { createSlice } from '@reduxjs/toolkit';

import { PayeeCasaAccountsRes } from '../models/payee-casa-accounts.model';
import { payeeCaseAccounts } from '../thunks/payee-casa-accounts.thunk';

const initialState: PayeeCasaAccountsRes = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};
const payeeCasaAccountsList = createSlice({
  name: 'payeeCasaAccounts',
  initialState,
  reducers: {
    removePayeeList(state) {
      state.data = undefined;
      state.error = undefined;
      state.loading = false;
      state.successfulResponse = undefined;
      state.status = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(payeeCaseAccounts.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        bankListData: state.data,
        loading: state.loading,
      } = payload.data);
    });
    builder.addCase(payeeCaseAccounts.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      payeeCaseAccounts.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});
export const { removePayeeList } = payeeCasaAccountsList.actions;

export default payeeCasaAccountsList.reducer;
