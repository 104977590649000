import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import {
  RecentTransactionsReq,
  ReqCoolingPeriod,
} from '@modules/payments/models/payments.model';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const payments = createAsyncThunk(
  'payment-service/getpayments',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.depositService.SHOW_BANK_LIST,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getFreqPayeeList = createAsyncThunk(
  'api/payments-service/freq-payee-list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentsService.paymentService.FREQ_PAYEE_LIST,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getRecentTransactionsList = createAsyncThunk(
  'api/payments-service/get-recent-transaction-list',
  async (
    recentTransactionsReqObj: RecentTransactionsReq,
    { rejectWithValue }
  ) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentsService.paymentService.GET_TRANSACTION_LIST,
        obj: recentTransactionsReqObj,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getScheduledTransactionsList = createAsyncThunk(
  'api/payments-service/get-scheduled-transaction-list',
  async (
    recentTransactionsReqObj: RecentTransactionsReq,
    { rejectWithValue }
  ) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentsService.paymentService.GET_TRANSACTION_LIST,
        obj: recentTransactionsReqObj,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);

export const getCoolingPeriod = createAsyncThunk(
  'CoolingPeriod',
  async (coolingPeriodObj: ReqCoolingPeriod, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.paymentService.COOLING_PERIOD,
        obj: coolingPeriodObj,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
