import secureLocalStorage from 'react-secure-storage';

import { logout } from '@core-stores';
import { SessionStorage } from '@core-utils';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './slices';
import { authRefreshToken } from './thunks/login.thunk';

let apiCallAttempt = 1;
let intervalId;

const logoutUser = async () => {
  const apiHeader = {
    sessionId: sessionStorage.getItem('accessToken') ?? '',
    refreshToken: sessionStorage.getItem('refreshToken') ?? '',
  };
  await store.dispatch(logout(apiHeader));
};

const authMiddleware = (_store) => (next) => (action) => {
  const { globalConfig } = _store.getState().init;
  if (action.type.match('user/login/fulfilled')) {
    secureLocalStorage.setItem('loggedInUser', action.payload.username);
    setIntervalForRefreshToken(
      action?.payload?.token?.expiresIn,
      globalConfig?.webUserAutoRefreshThreshold
    );
  }
  if (
    action.type.match('user/logout/fulfilled') ||
    action.type.match('user/logout/rejected')
  ) {
    clearInterval(intervalId);
    secureLocalStorage.removeItem('loggedInUser');
    SessionStorage.removeItem('accessToken');
    SessionStorage.removeItem('refreshToken');
    SessionStorage.removeItem('expiresIn');
    SessionStorage.removeItem('tokenType');
    SessionStorage.removeItem('refreshExpiresIn');
  }
  if (action.type.match('user/refreshtoken/fulfilled')) {
    clearInterval(intervalId);
    setIntervalForRefreshToken(
      action?.payload?.data?.expiresIn,
      globalConfig?.webUserAutoRefreshThreshold
    );
  }
  if (action.type.match('user/refreshtoken/rejected')) {
    clearInterval(intervalId);
    if (apiCallAttempt < Number(globalConfig?.defaultRetryCount)) {
      apiCallAttempt = apiCallAttempt + 1;
      callRefreshTokenApi();
    } else {
      logoutUser();
    }
  }
  if (action.type.match('user/refreshtoken/pending')) {
    clearInterval(intervalId);
  }
  return next(action);
};

const setIntervalForRefreshToken = (
  tokeneEpires,
  webUserAutoRefreshThreshold = '90'
) => {
  const number = webUserAutoRefreshThreshold;
  const result = tokeneEpires * (parseInt(number) / 100) * 1000;
  intervalId = setInterval(() => {
    callRefreshTokenApi();
  }, result);
};

const callRefreshTokenApi = async () => {
  const resp = await store.dispatch(authRefreshToken());
  const { data } = resp.payload;
  if (data?.accessToken) {
    SessionStorage.setItem('accessToken', data.accessToken ?? '');
    SessionStorage.setItem('refreshToken', data.refreshToken ?? '');
    SessionStorage.setItem('tokenType', data.tokenType ?? '');
    SessionStorage.setItem('expiresIn', data.expiresIn);
    SessionStorage.setItem('refreshExpiresIn', data.refreshExpiresIn);
    apiCallAttempt = 1;
  }
};

const rehydrateStore = () => {
  const loggedInUser = secureLocalStorage.getItem('loggedInUser');
  if (loggedInUser) {
    setIntervalForRefreshToken(SessionStorage?.getItem('expiresIn') ?? '');
    return {
      ...rootReducer,
      auth: {
        loggedIn: true,
        username: loggedInUser,
      },
    };
  }
  return {};
};
export const store = configureStore({
  reducer: rootReducer,
  preloadedState: rehydrateStore(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
