import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { v4 } from 'uuid';

import { AU_AlertContainer } from '@core-components/molecules';
import { AU_ChangesSaveModal } from '@core-components/templates';
import { DASHBOARD_TYPE, FCM_TOKEN_KEY } from '@core-constants';
import {
  Navigation,
  RedirectRouteType,
  RoutePropsType,
} from '@core-navigations';
import {
  AppDispatch,
  ILanguageType,
  initializeApp,
  initLanguage,
  RootState,
  setIsNTB,
  ThemeConfig,
} from '@core-stores';
import {
  getStoredLanguage,
  logError,
  onMessageListener,
  requestForToken,
  SessionStorage,
  setLanguageTranslations,
  Storage,
} from '@core-utils';
import i18n, { translationVersion } from '@i18n';

import { setNavThemeType as setNavThemeTypeRdx } from './core-stores/slices/init.slice';
import { setAppTheme, setNavThemeType } from './themes/utils.theme';

import 'react-toastify/dist/ReactToastify.min.css';

function App({
  routes,
  redirectRoutes,
  browserNavigation = false,
}: {
  routes: RoutePropsType[];
  redirectRoutes: RedirectRouteType[];
  browserNavigation: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const analytics = getAnalytics();
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState({
    title: '',
    body: '',
    image: '',
  });
  const [token, setToken] = useState(false);

  const {
    language: langResponse,
    available_languages,
    theme,
  }: {
    language: ILanguageType | undefined;
    available_languages: any;
    theme: ThemeConfig;
  } = useSelector<RootState>((state: RootState) => state.init) as any;

  useEffect(() => {
    setNavThemeType(DASHBOARD_TYPE.STORE);
    setTimeout(() => {
      dispatch(setNavThemeTypeRdx(DASHBOARD_TYPE.BANK));
    }, 50);
  }, []);

  const handleInitApp = useCallback(
    ({
      languageCode,
      languageVersion,
    }: {
      languageCode: string;
      languageVersion: string;
    }) => {
      dispatch(
        initLanguage({
          languageCode,
          languageVersion,
        })
      );
    },
    [dispatch]
  );

  const generateDeviceId = () => {
    const uuid = v4();
    const STORAGE_NAME = 'browser_id';
    const existingID = SessionStorage.getItem(STORAGE_NAME);
    if (existingID === null || existingID === undefined) {
      SessionStorage.setItem(STORAGE_NAME, uuid);
    }
  };

  const setAnalyticsUserDeviceId = () => {
    setUserProperties(analytics, {
      device_id: SessionStorage.getItem('browser_id') as string,
    });
  };

  useEffect(() => {
    if (!token) {
      requestForToken().then((currentToken) => {
        SessionStorage.setItem(FCM_TOKEN_KEY, currentToken ?? '');
        setToken(!!currentToken);
      });
    }
  }, [token]);

  useEffect(() => {
    dispatch(initializeApp());

    const isNTB = JSON.parse(Storage.getItemAsync('isNTB') ?? 'false');
    if (isNTB) {
      dispatch(setIsNTB(isNTB));
    }
  }, [dispatch]);

  useEffect(() => {
    if (available_languages) {
      const initTranslations = () => {
        const lang = getStoredLanguage() ?? 'en';
        const currentLanguage = available_languages.filter(
          (langg) => langg.language.code === lang
        );
        const version = currentLanguage[0]?.language.latestVersion;
        if (parseFloat(version) > parseFloat(translationVersion)) {
          handleInitApp({
            languageCode: lang,
            languageVersion: version,
          });
        }
      };
      initTranslations();
    }
  }, [handleInitApp, available_languages]);

  useEffect(() => {
    onMessageListener()
      .then((payload: any) => {
        setShow(true);
        setNotifications({
          title: payload?.data?.title,
          body: payload?.data?.body,
          image: payload?.data?.image,
        });
      })
      .catch((err) => {
        logError(err);
      });

    if (langResponse) {
      if (langResponse?.translations !== undefined) {
        setLanguageTranslations(
          {
            languageCode: langResponse.languageCode,
            languageVersion: langResponse.languageVersion,
            languageName: langResponse.languageName,
            translations: langResponse.translations,
          },
          i18n
        );
      }
    }
  }, [langResponse, show]);

  useEffect(() => {
    function Display() {
      return (
        <div className="flex">
          <div className="float-left mr-8">
            <h4>{notifications.title}</h4>
            <p>{notifications.body}</p>
          </div>
          {notifications.image && (
            <div className="float-right">
              <img className="w-10 h-10 rounded" src={notifications.image} />
            </div>
          )}
        </div>
      );
    }
    if (notifications.body !== '') toast.info(<Display />);
  }, [notifications]);

  useEffect(() => {
    // personaDetector();
    generateDeviceId();
    setAnalyticsUserDeviceId();
  }, []);

  // intentionally commented below code

  // const checkRefreshCount = () => {
  //   const reloadCount = Storage.getItemAsync('reloadCount');
  //   if (Number(reloadCount) === 101) {
  //     showBabkModal.show({
  //       confirmBtnLbl: t('ok'),
  //       title: t('sessionExpRefreshMsg'),
  //       onConfirmAction: () => {
  //         Storage.setItemAsync('reloadCount', '');
  //         location.href = '/';
  //       },
  //       onCancelAction: () => {
  //         Storage.setItemAsync('reloadCount', '');
  //         location.href = '/';
  //       },
  //       isShowCancelBtn: false,
  //     });
  //   } else {
  //     Storage.setItemAsync('reloadCount', '101');
  //   }
  // };

  // useEffect(() => {
  //   window.onpopstate = (e) => {
  //     history.forward();
  //     history.replaceState(undefined, '', location.href);
  //     showBabkModal.show({
  //       confirmBtnLbl: t('continue'),
  //       title: t('sessionExpMsg'),
  //       onConfirmAction: () => {
  //         Storage.setItemAsync('reloadCount', '');
  //         location.href = '/';
  //       },
  //     });
  //   };
  //   checkRefreshCount();
  //   return () => {
  //     window.onpopstate = null;
  //   };
  // }, []);
  useEffect(() => {
    if (theme?.config) {
      setAppTheme({ themConfig: theme });
    }
  }, [theme?.config, theme.navThemeType, theme.themeType]);

  return (
    <>
      <Navigation
        routes={routes}
        redirectRoutes={redirectRoutes}
        browserNavigation={browserNavigation}
      />
      <ToastContainer />
      <AU_AlertContainer />
      <AU_ChangesSaveModal />
    </>
  );
}

export default App;
