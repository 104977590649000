import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RootState,
  SaveBackModalCofig,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import {
  setOnCancelClick,
  setOnConfirmClick,
  setSaveBackModalConfig,
  setShowSaveBackModal,
} from '@src/core-stores/slices/save-back-modal.slice';

interface BackModalProps {
  show: (config?: SaveBackModalCofig) => void;
}
export function useShowBackModal(): BackModalProps {
  const [configState, setConfigState] = useState<
    SaveBackModalCofig | undefined
  >();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isCancelClicked, isConfirmClicked } = useAppSelector(
    (state: RootState) => state.saveBackModal
  );

  const defaultCancel = () => {
    dispatch(setOnCancelClick(false));
    dispatch(setShowSaveBackModal(false));
  };
  const onCancel = () => {
    configState?.onCancelAction?.();
    defaultCancel();
  };
  const defaultConfirm = () => {
    dispatch(setOnConfirmClick(false));
    dispatch(setShowSaveBackModal(false));
  };
  const onCofirm = () => {
    configState?.onConfirmAction?.();
    defaultConfirm();
  };

  useEffect(() => {
    if (isConfirmClicked) {
      onCofirm();
    }
    if (isCancelClicked) {
      onCancel();
    }
  }, [isCancelClicked, isConfirmClicked]);

  const show = (config?: SaveBackModalCofig) => {
    setConfigState(config);

    const obj: SaveBackModalCofig = {
      show: config?.show ?? true,
      title: config?.title ?? t('leaveThisPage').concat('?'),
      subTitile: config?.subTitile ?? t('informationProvidedMayNotSave'),
      icon: config?.icon ?? 'warningmodal',
      confirmBtnLbl: config?.confirmBtnLbl ?? t('leaveThisPage'),
      cancelBtnLbl: config?.cancelBtnLbl ?? t('stayHere'),
      isShowCancelBtn: config?.isShowCancelBtn ?? true,
    };
    dispatch(setSaveBackModalConfig(obj));
  };
  return { show };
}
