import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@src/core-components/atoms';

import './AU_BankAccountAccordian.style.scss';

interface AUBankAccountAccordianProps {
  // add props here....
  title: string;
  staticContainerElement: React.JSX.Element;
  dynamicContainerElement?: React.JSX.Element;
  otherUiBlocks?: React.JSX.Element;
}

export const AU_BankAccountAccordian = ({
  title,
  staticContainerElement,
  dynamicContainerElement,
  otherUiBlocks,
}: AUBankAccountAccordianProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const arr = ['dynamic_container'];
  if (open) {
    arr.push('open_dynamic_container');
  }
  return (
    <section className="accordian_container mt-5">
      <div className="flex">
        <h1 className="m-text-lg-regular text-gray-900">{title}</h1>
        <div
          className="flex items-center align-middle ml-auto"
          onClick={handleClick}
        >
          <p className="m-text-md-medium text-gray-600">{t('change')}</p>
          <Icon
            icon="chevron-up-arrow"
            color="none"
            height={16}
            width={16}
            className={open ? 'rotate_icon_up' : 'rotate_icon_down'}
          />
        </div>
      </div>

      <div className="static_container">{staticContainerElement}</div>
      <div className={arr.join(' ')}>{dynamicContainerElement}</div>

      {otherUiBlocks}
    </section>
  );
};
