import { createSlice } from '@reduxjs/toolkit';

interface IAuOverViewCard {
  cardTitle: string;
  navigationUrl: string | null;
  iconName: string;
  amount: string;
  accountDetails: string;
  showBanner: boolean;
  isAmountVisible: boolean;
  visible: boolean;
  bannerImg: string;
}

interface IAuOverViewCardSlice {
  overviewCardList: IAuOverViewCard[] | undefined;
}
const initialState: IAuOverViewCardSlice = {
  overviewCardList: undefined,
};

const auOverviewSlice = createSlice({
  name: 'auOverviewSlice',
  initialState,
  reducers: {
    setOverviewList(state, { payload }) {
      state.overviewCardList = payload;
    },
  },
});

export const { setOverviewList } = auOverviewSlice.actions;

export default auOverviewSlice.reducer;
