import {
  loanStmtNavigation,
  statementNavigation,
} from '@src/modules/au-dashboard/constants/au-dashboard.constant';

import { PERMISSIONS } from './permissions.constant';

const {
  RBAC_ADMIN_USER_GROUP,
  RBAC_ADMIN_AUTH_GROUP,
  RBAC_ADMIN_USERS,
  RBAC_ROLES,
} = PERMISSIONS;

export const navLink = {
  navMenuBanking: [
    {
      label: 'accounts',
      optionMenu: [
        {
          optionMenuIcon: 'notification',
          title: 'operativeAccounts',
          pathname: '/accountsummary/home',
          rbac: PERMISSIONS.RBAC_ACCOUNT.VIEW_MODULE_CASA,
        },
        {
          optionMenuIcon: 'notification',
          title: 'loanAccounts',
          pathname: '/loans',
          rbac: PERMISSIONS.RBAC_LOAN.VIEW_MODULE_LOAN,
        },
        {
          optionMenuIcon: 'notification',
          title: 'depositAccounts',
          pathname: '/deposit/dashboard',
          rbac: PERMISSIONS.RBAC_DEPOSIT.VIEW_MODULE_DEPOSIT,
        },
      ],
      prefixIconName: 'bank-outline',
      suffixIconName: 'chevron-up-arrow',
      isDropdown: true,
    },
    {
      label: 'payments',
      optionMenu: [
        {
          optionMenuIcon: 'notification',
          title: 'moneyTransfer',
          pathname: '/payments/moneytransfer',
          rbac: PERMISSIONS.RBAC_PAYMENTS.VIEW_MODULE_PAYMENT,
        },
        {
          optionMenuIcon: 'notification',
          title: 'managePayee',
          pathname: '/payees',
          rbac: PERMISSIONS.RBAC_PAYEE.VIEW_MODULE_PAYEE,
        },
        {
          optionMenuIcon: 'notification',
          title: 'gstPayment',
          pathname: '/payments/gst',
          rbac: PERMISSIONS.RBAC_PAYMENTS.VIEW_MODULE_TAX,
        },
      ],
      prefixIconName: 'payment',
      suffixIconName: 'chevron-up-arrow',
      isDropdown: true,
    },
    {
      label: 'cards',
      optionMenu: [
        {
          optionMenuIcon: 'notification',
          title: 'debitCards',
          pathname: '/debit-card',
          rbac: PERMISSIONS.RBAC_DEBIT_CARD_SERVICE.VIEW_MODULE_DEBIT,
        },
        // {
        //   optionMenuIcon: 'notification',
        //   title: 'creditCards',
        //   pathname: '/credit-card',
        //   rbac: PERMISSIONS.RBAC_DEBIT_CARD_SERVICE.VIEW_MODULE_DEBIT,
        // },
      ],
      prefixIconName: 'card',
      suffixIconName: 'chevron-up-arrow',
      isDropdown: true,
    },

    {
      label: 'Statements',
      optionMenu: [
        {
          optionMenuIcon: 'notification',
          title: 'accountStatement',
          pathname: statementNavigation,
          rbac: PERMISSIONS.RBAC_ACCOUNT.VIEW_MODULE_CASA,
        },
        {
          optionMenuIcon: 'notification',
          title: 'loanStatement',
          pathname: loanStmtNavigation,
          rbac: PERMISSIONS.RBAC_LOAN.VIEW_MODULE_LOAN,
        },
      ],
      prefixIconName: 'statement-service',
      suffixIconName: 'chevron-up-arrow',
      isDropdown: true,
    },
    {
      label: 'InvestAndInsure',
      prefixIconName: 'percentagesquare',
      optionMenu: [
        {
          optionMenuIcon: 'notification',
          title: 'insurance',
          pathname: '/insurance',
          rbac: PERMISSIONS.RBAC_INVEST.VIEW_MODULE_INSURANCE,
        },
        {
          optionMenuIcon: 'notification',
          title: 'investment',
          pathname: '/investment',
          rbac: PERMISSIONS.RBAC_INVEST.VIEW_MODULE_INVESTMENT,
        },

        {
          optionMenuIcon: 'notification',
          title: 'deposits',
          pathname: '/deposit/dashboard',
          rbac: PERMISSIONS.RBAC_DEPOSIT.VIEW_MODULE_DEPOSIT,
        },
      ],
      isDropdown: true,
      suffixIconName: 'chevron-up-arrow',
    },
    {
      label: 'serviceRequest',
      isDropdown: true,
      optionMenu: [
        {
          optionMenuIcon: 'notification',
          title: 'raiseNewRequest',
          pathname: '/service-request',
          rbac: PERMISSIONS.RBAC_CSR.VIEW_MODULE_CSR,
        },
        {
          optionMenuIcon: 'card',
          title: 'trackRequest',
          pathname: '/service-request/track-service-req',
          rbac: PERMISSIONS.RBAC_CSR.VIEW_MODULE_CSR,
        },
      ],
      suffixIconName: 'chevron-up-arrow',
      prefixIconName: 'contact-branch',
    },
    {
      label: 'applyNow',
      isDropdown: false,
      labelPathname: '/apply-now',
      prefixIconName: 'apply-now',
    },
    {
      label: 'videoBanking',
      isDropdown: false,
      // labelPathname: '',
      prefixIconName: 'video-banking',
    },
  ],

  navMenuStore: [
    {
      label: 'collections',
      prefixIconName: 'collection-store',
      isDropdown: false,
      optionMenu: [],
      labelPathname: '/qr-collection/qr-collection-detail',
      suffixIconName: '',
    },
    {
      label: 'Settlements',
      prefixIconName: 'settlement-store',
      isDropdown: false,
      optionMenu: [],
      suffixIconName: '',
      labelPathname: '/qr-settlement',
    },
  ],
};

export const HOME_NAV_TITLE_DATA = {
  navMenuAdminInventory: {
    nameKey: 'inventory',
    url: '/',
  },
  navMenuAdminEntitlement: {
    nameKey: 'entitlementManagement',
    url: '/',
  },
  navMenuAdminLimitManagement: {
    nameKey: 'limitManagement',
    url: '/',
  },
  navMenuAdminSettlementManagement: {
    nameKey: 'settlement',
    url: '/',
  },
};

export const userGroupList = {
  navMenuAdminInventory: [
    {
      labelKey: '',
      path: '',
      children: [
        {
          labelKey: 'generateVpaId',
          path: '/generate-vpa',
          isDisabled: false,
        },
        {
          labelKey: 'customerRequest',
          path: '/customer-request',
          isDisabled: false,
        },
        {
          labelKey: 'printingStatus',
          path: '/printing-status',
          isDisabled: false,
        },

        {
          labelKey: 'allocationHistory',
          path: '/allocation-history',
          isDisabled: false,
        },
        {
          labelKey: 'verticals',
          path: '/vertical-allocation',
          isDisabled: false,
        },
      ],
    },
  ],
  navMenuSo: [
    {
      labelKey: '',
      path: '',
      children: [
        {
          labelKey: 'dashboard',
          path: '/so-dashboard',
          isDisabled: false,
        },
        {
          labelKey: 'requestHistory',
          path: '/so-request-history',
          isDisabled: false,
        },
        {
          labelKey: 'pastAllotment',
          path: '/so-past-allotment',
          isDisabled: false,
        },
      ],
    },
  ],
  navMenuBm: [
    {
      labelKey: '',
      path: '',
      children: [
        {
          labelKey: 'dashboard',
          path: '/bm-dashboard',
          isDisabled: false,
        },
        {
          labelKey: 'allocationHistory',
          path: '/allocation-history',
          isDisabled: false,
        },
        {
          labelKey: 'requestHistory',
          path: '/bm-request-history',
          isDisabled: false,
        },
        {
          labelKey: 'pastAllotment',
          path: '/bm-past-allotment',
          isDisabled: false,
        },
        {
          labelKey: 'customerRequestList',
          path: '/bm-customer-requests',
          isDisabled: false,
        },
      ],
    },
  ],
  navMenuSettlement: [
    {
      labelKey: '',
      path: '',
      children: [
        {
          labelKey: 'reportDownload',
          path: '/settlement-management/report-download',
          isDisabled: false,
        },
        {
          labelKey: 'statusChargeFileUpload',
          path: '/settlement-management/status-file-upload',
          isDisabled: false,
        },
        {
          labelKey: 'reportSearchMenu',
          path: '/settlement-management/report-search',
          isDisabled: false,
        },
        {
          labelKey: 'transactionUpdate',
          path: '/settlement-management/txn-status-update',
          isDisabled: false,
        },
      ],
    },
  ],
  navMenuAdminEntitlement: [
    {
      labelKey: 'employee',
      path: '',
      children: [
        {
          labelKey: 'role',
          path: '/entitlement/employee/roles-list',
          isDisabled: false,
          permission: [RBAC_ROLES.VIEW],
          connectedPath: [
            '/entitlement/employee/roles-list',
            '/entitlement/employee/role-details',
            '/entitlement/employee/create-role',
            '/entitlement/employee/clone-role',
            '/entitlement/employee/modify-role',
          ],
        },
        {
          labelKey: 'Users',
          path: '/entitlement/employee/users',
          isDisabled: false,
          permission: [RBAC_ADMIN_USERS.VIEW],
          connectedPath: [
            '/entitlement/employee/users',
            '/entitlement/employee/create-users',
            '/entitlement/employee/modify-users',
            '/entitlement/employee/create-users-details',
            '/entitlement/employee/modify-users-details',
            '/entitlement/employee/user-details',
          ],
        },
        {
          labelKey: 'userAuthGroups',
          path: '/entitlement/employee/user-auth-group-list',
          connectedPath: [
            '/entitlement/employee/user-auth-group-list',
            '/entitlement/employee/user-auth-group-list',
            '/entitlement/employee/user-group-details',
            '/entitlement/employee/auth-group-details',
            '/entitlement/employee/modify-group',
            '/entitlement/employee/add-group',
          ],
          isDisabled: false,
          permission: [RBAC_ADMIN_AUTH_GROUP.VIEW, RBAC_ADMIN_USER_GROUP.VIEW],
        },
      ],
    },
    {
      labelKey: 'merchant',
      path: '',
      children: [
        {
          labelKey: 'role',
          path: '/entitlement/merchant/roles-list',
          connectedPath: [
            '/entitlement/merchant/roles-list',
            '/entitlement/merchant/create-role',
            '/entitlement/merchant/role-details',
            '/entitlement/merchant/modify-role',
            '/entitlement/merchant/clone-role',
          ],
          isDisabled: false,
          permission: [RBAC_ROLES.VIEW],
        },
        {
          labelKey: 'Merchant Details',
          path: '/entitlement/merchant/merchant-list',
          connectedPath: [
            '/entitlement/merchant/merchant-list',
            '/entitlement/merchant/details',
            '/entitlement/merchant/user-details',
            '/entitlement/merchant/add-user',
            '/entitlement/merchant/mr-user-details',
            '/entitlement/merchant/mr-user-account-map',
            '/entitlement/merchant/mr-user-role-map',
            '/entitlement/merchant/mr-user-auth-group-map',
            '/entitlement/merchant/view',
            '/entitlement/merchant/mr-user-creation-review',
          ],
          isDisabled: false,
          permission: [PERMISSIONS.RBAC_ADMIN_MERCHANT_DETAILS.VIEW],
        },
        {
          labelKey: 'Auth Group',
          path: '/entitlement/auth/list',
          connectedPath: [
            '/entitlement/auth/list',
            '/entitlement/auth/creategroup',
            '/entitlement/auth/details',
          ],

          isDisabled: false,
          permission: [PERMISSIONS.RBAC_ADMIN_AUTH_GROUP.VIEW],
        },
      ],
    },
  ],

  navMenuAdminLimitManagement: [
    {
      labelKey: 'Select One',
      path: '',
      children: [
        {
          labelKey: 'Corporate/Merchant Level Daily Limit',
          path: '/entitlement/employee/roles-list',
          isDisabled: false,
        },
        {
          labelKey: 'User-wise Limit',
          path: '/entitlement/employee/roles-list1',
          isDisabled: true,
        },
        {
          labelKey: 'Global Cutoff',
          path: '/entitlement/employee/roles-list2',
          isDisabled: true,
        },
        {
          labelKey: 'Global Exception',
          path: '/entitlement/employee/users',
          isDisabled: false,
        },
        {
          labelKey: 'Corporate Authorization ',
          path: '/entitlement/employee/roles-list4',
          isDisabled: true,
        },
        {
          labelKey: 'Channel Level Daily Limit',
          path: '/entitlement/employee/roles-list5',
          isDisabled: true,
        },
        {
          labelKey: 'Account Level Daily Limit',
          path: '/entitlement/employee/roles-list6',
          isDisabled: true,
        },
      ],
    },
  ],
};
