import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { DebitCardSummaryRequest } from '../../models/debit-card.model';

export const getDebitCardList = createAsyncThunk(
  'api/debit-card-service/debit-card-details',
  async (getDebitCardData: DebitCardSummaryRequest, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: AUEndPoints.debitCardService.GET_DEBIT_CARD_LIST,
        url: '',
        obj: getDebitCardData,
      });
      if (response.data.status === 'error') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        errorCode: error.bucketMessage,
        errorMsg: error.getErrorMessage(),
      });
    }
  }
);
