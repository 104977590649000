import { ReactNode, useEffect, useRef, useState } from 'react';

import { Icon } from '@core-components/atoms';
import { useEventProvider } from '@core-providers';

import './XB_Searchinput.style.scss';

interface SearchInputProps {
  id: string;
  label?: string;
  placeholder?: string;
  classes?: string;
  value?: string;
  onInputChange?: (val: string) => void;
  suffixIcon?: string;
  prefixIcon?: string;
  prefixChild?: React.ReactNode;
  regex?: any;
  place;
  selectedValue?: string[] | string;
  type: string;
  onIconClick?: () => void;
  onSendValue?: (...args) => void;
  items?: Array<{
    text?: string;
    value: string;
    icon?: ReactNode;
    secondaryText?: string;
  }>;
  children?: React.JSX.Element;
}

export const XB_Searchinput = ({
  id,
  label,
  classes = '',
  value,
  placeholder,
  type = 'text',
  onInputChange,
  suffixIcon,
  prefixIcon,
  children,
  onSendValue,

  regex,
  items,
  onIconClick,
  ...otherProps
}: SearchInputProps) => {
  const { getEventId } = useEventProvider();
  const inputRef = useRef<HTMLDivElement | null>(null);
  const classList = ['input'];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedIndex, setSelectedIndexState] = useState(-1);

  classes && classList.push(classes);
  suffixIcon && classList.push('input--s-icon');
  prefixIcon && classList.push('input--p-icon');

  const drawerOpenClassName = () => {
    let str = 'input_Drawewr_blank';
    if (!isDrawerOpen) {
      str = str + ' hidden';
    }
    return str;
  };

  const handleInputChange = (target: HTMLInputElement) => {
    const newValue = target.value;
    if (regex) {
      const regexRes = regex.test(newValue);
      if (regexRes) {
        onInputChange?.(newValue);
        setIsDrawerOpen(true);
      } else if (!regexRes && newValue !== '') {
        onInputChange?.(value ?? '');
        target.setSelectionRange(target.selectionStart, target.selectionEnd);
      } else {
        onInputChange?.('');
      }
    } else if (!newValue.length) {
      onInputChange?.('');
      setIsDrawerOpen(false);
      setSelectedIndexState(-1);
    } else {
      onInputChange?.(newValue);
      setIsDrawerOpen(true);
    }
  };

  useEffect(() => {
    const inputEl = inputRef.current?.getElementsByTagName('input')[0];
    inputEl?.addEventListener('focus', () => {
      inputRef.current?.classList.add('input--focused');
    });
    inputEl?.addEventListener('blur', () => {
      inputRef.current?.classList.remove('input--focused');
    });
  }, []);

  return (
    <div className={`${classList.join(' ')}`} ref={inputRef}>
      {label && (
        <label htmlFor={id} className="label-class">
          {label}
        </label>
      )}
      <div className="input__search__wrapper">
        {prefixIcon && (
          <span className="p-icon">
            <Icon icon={prefixIcon} width={20} height={20} />
          </span>
        )}
        <input
          id={id}
          data-testid={`${getEventId(id)}-INPT-SRCH`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleInputChange(e.target)}
          {...otherProps}
        />
        {value && value?.length > 0 && suffixIcon && (
          <span
            onClick={() => {
              onIconClick?.();
              setIsDrawerOpen(false);
              setSelectedIndexState(-1);
            }}
          >
            <Icon icon={suffixIcon} width={14} height={14} />
          </span>
        )}
      </div>
      {isDrawerOpen && items?.length && value?.length ? (
        <div className="input__drawer">
          {items.map((ele, index) => {
            return (
              <div
                key={index}
                className={`${index === 0 ? 'mt-0' : ''}  flex  ${
                  index === selectedIndex ? 'data_wrappera_active' : ''
                } data-wrapper`}
                onClick={() => {
                  setSelectedIndexState(index);
                  onSendValue?.(ele.value);
                  setIsDrawerOpen(false);
                }}
              >
                <span className="flex justify-center align-middle mr-3">
                  {ele.icon}
                </span>
                <div>
                  <h2 className="m-text-lg-regular text-gray-900">
                    {ele.value}
                  </h2>
                  <p className="m-text-lg-regular text-gray-500">
                    {ele.secondaryText}
                  </p>
                </div>
                {selectedIndex === index && (
                  <div className="text-orange-500 ml-auto mt-auto mb-auto">
                    <Icon icon="check" width={18} height={18} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        value &&
        value.length > 0 && (
          <div className={drawerOpenClassName()}>{children}</div>
        )
      )}
    </div>
  );
};
