import { EndPoints, Service } from '@core-services';

export const getQRJourneySteps = async (
  userType: string,
  customerType: string
) => {
  const reqObj = {
    baseurl: EndPoints.strapiService.getQRJourneySteps(userType, customerType),
    method: 'GET',
    url: '',
  };
  const response = await Service.send(reqObj);
  return response?.data;
};
