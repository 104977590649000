import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { XB_Button, XB_Divider, XB_Modal } from '@core-components/atoms';
import { AU_PopupMenu, XB_OptionCard } from '@core-components/molecules';
import { RD_LABEL } from '@core-constants';
import { useAppDispatch } from '@core-stores';
import { depositFDLabel } from '@src/mocks/deposit-opening.mock';

import { Account } from '../../../modules/deposit';
import { setCurrentDeposit } from '../../../modules/deposit/stores/slices/deposit-summary.slice';

import './AU_DepositSummaryLayout.style.scss';

interface AUDepositSummaryLayoutProps {
  accountType: string;
  accountNumber: string;
  accountAmount: string;
  accountMaturityAmount: string;
  createdOn: string;
  maturityOn: string;
  maturityLabel: string;
  interestRate: string;
  interestRateLabel: string;
  buttonLabel: string;
  onViewBtnClick?: (val: any) => void;
  borderColor: string;
  itemData: any;
  disableMenu?: boolean;
}

interface PopupMenuItem {
  text: string;
  value: string;
  icon?: string;
  color?: string;
}

export const AU_DepositSummaryLayout = ({
  accountType,
  accountNumber,
  accountAmount,
  accountMaturityAmount,
  maturityLabel,
  createdOn,
  maturityOn,
  interestRate,
  interestRateLabel,
  onViewBtnClick,
  buttonLabel,
  borderColor,
  itemData,
  disableMenu = true,
}: AUDepositSummaryLayoutProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getPopupMenuItems = () => {
    let popupMenuItems: PopupMenuItem[] = [];
    popupMenuItems = [
      {
        text: t('closeAccount'),
        icon: 'close-account',
        value: 'close',
        color: 'none',
      },
    ];

    if (accountType === t('sotNumber')) {
      let resultArr: PopupMenuItem[] = [];
      resultArr = popupMenuItems.slice(0, -1);
      return resultArr;
    } else return popupMenuItems;
  };

  const [close, setClose] = useState<boolean>(false);
  const [isModalProceedBtn, setIsModalProceedBtn] = useState(true);
  const [closureType, setClosureType] = useState<string>('');
  const [selectedDeposit, setSelectedDeposit] = useState<Account>();

  useEffect(() => {
    setSelectedDeposit(itemData);
  }, [itemData.AccountID]);

  const handleItemClick = (value) => {
    if (value === 'close' && accountType === t('fdNumber')) {
      setClose(true);
    } else {
      moveToClosure(RD_LABEL);
    }
  };

  const otherPropsClosure = {
    name: 'Closure',
    onChange: (e: any) => {
      setClosureType(e.target.value);
      setIsModalProceedBtn(false);
    },
  };

  function hideClosureModal() {
    setClose(false);
    setIsModalProceedBtn(true);
  }

  function moveToClosure(depositType?: string) {
    dispatch(
      setCurrentDeposit({
        ...selectedDeposit,
      })
    );
    if (depositType === RD_LABEL) {
      navigate('/deposit/closure/rd');
    } else if (closureType === t('partialClosureCaps')) {
      navigate('/deposit/closure/fd-partial');
    } else {
      navigate('/deposit/closure/fd-full');
    }
  }

  const renderCloseModal = () => {
    return (
      <XB_Modal
        setOpen={(open) => setClose(open)}
        open={close}
        headPos="text-left"
        title={t('closingFD')}
        maxWidth="480px"
        body={
          <div className="flex flex-col gap-y-3">
            <XB_OptionCard
              id="partialClosure"
              title={t('partialClosureCaps')}
              radioType="dot"
              value={t('partialClosureCaps')}
              position="left"
              description={t('partialClosureDesc')}
              classes="flex-1 gap-4"
              isChecked={closureType === t('partialClosureCaps')}
              disabled={
                Number(depositFDLabel.amountMinMax[0].value) ===
                Number(accountAmount.replaceAll(/[^0-9.]/g, ''))
              }
              {...otherPropsClosure}
            />
            <XB_OptionCard
              id="fullClosure"
              title={t('fullClosure')}
              radioType="dot"
              position="left"
              value={t('fullClosure')}
              isChecked={closureType === t('fullClosure')}
              description={t('fullClosureDesc')}
              classes="flex-1"
              {...otherPropsClosure}
            />
          </div>
        }
        actions={
          <div className="flex gap-4">
            <XB_Button
              dataTestId="CNCL"
              classes="grow"
              onClick={hideClosureModal}
              btnType="secondary-gray"
            >
              {t('cancel')}
            </XB_Button>
            <XB_Button
              dataTestId="PRCD"
              classes="grow"
              btnType="primary"
              disabled={isModalProceedBtn}
              onClick={() => moveToClosure()}
            >
              {t('proceed')}
            </XB_Button>
          </div>
        }
      />
    );
  };

  return (
    <>
      <div>
        <div className={`deposit-info-main-container ${borderColor}`}>
          <div className="acctype-acc-number flex pl-4">
            <p>
              <span className="m-text-xs-regular text-gray-500">
                {`${accountType} : `}
              </span>
              <span className="m-text-xs-regular text-gray-600">
                {accountNumber}
              </span>
            </p>
          </div>

          <div className="deposit-items-container flex gap-3 mt-2 pl-4">
            <div className="createdon-maturity-container flex flex-1 gap-x-3 justify-between items-start">
              <div className="created-on-container gap-y-0.5">
                <h2 className="m-display-sm-bold text-gray-800">
                  {accountAmount}
                </h2>
                <p>
                  <span className="m-text-md-regular text-gray-500">
                    {createdOn}
                  </span>
                </p>
              </div>

              <div className="maturity-container gap-y-0.5">
                <h3 className="m-display-sm-medium text-gray-800">
                  {accountMaturityAmount}
                </h3>
                <p className="m-text-md-regular text-gray-500">
                  <span>{maturityLabel} </span>
                  <span>{maturityOn}</span>
                </p>
              </div>
            </div>

            <div className="interestrate-btn-container flex flex-1 justify-between items-start">
              <div className="interest-rate-container gap-y-0.5">
                <h3 className="m-display-sm-medium text-gray-800">
                  {interestRate}
                </h3>
                <p className="m-text-md-regular text-gray-500">
                  {interestRateLabel}
                </p>
              </div>
              <div className="flex">
                <XB_Button
                  classes="button-view"
                  dataTestId="VIEW"
                  btnType="secondary-gray"
                  size="sm"
                  iconName="eyeicon"
                  iconPos="left"
                  onClick={onViewBtnClick}
                >
                  {buttonLabel}
                </XB_Button>
                {!disableMenu && (
                  <AU_PopupMenu
                    textSize="md"
                    iconSize={18}
                    items={getPopupMenuItems()}
                    onItemClick={(val) => handleItemClick(val)}
                  />
                )}
              </div>
              {close && renderCloseModal()}
            </div>
          </div>
        </div>
        <XB_Divider classes="my-6" />
      </div>
    </>
  );
};
