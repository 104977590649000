import { ButtonProps } from '@core-components/atoms';
import { getUserGroupService } from '@core-services';
import { isEmpty, logError } from '@core-utils';
import {
  action,
  actionType,
  activeScreenMapping,
  bankUserMapping,
  businessTypeMapping,
  customerTypeMapping,
  fieldStatus,
  fieldValueColorMapping,
  highlightedHeadingMapping,
  highlightsName,
  miscConstants,
  placeContentBottom,
  restrictedCheckPointsMapping,
  showadditionalHeading,
} from '@modules/admin-portal/constants';
import {
  ActionableViewableProperties,
  CheckpointActionable,
  CheckPointComment,
  Comment,
  HighglightReferUserData,
  HighlightThread,
  ThreadResponse,
} from '@modules/admin-portal/models';

import { RightListDetailItem } from '../components/list-detail/AU_ListDetail.component';
import { LoggedInUserGrpType } from '../screens/case-management/case-management.screen';
import {
  IPermissionTable,
  ResRoleDetailRole,
  ResRoleFeatureData,
} from './../models/entitle-management.model';

export const threshold = 70;

export const makeCheckPointHide = ({
  matchScore,
  fieldBased,
  activeScreen,
}) => {
  if (activeScreen?.screen === activeScreenMapping.CASEREVIEW) return false;
  else {
    if (
      Number(matchScore) > threshold ||
      Object.entries(restrictedCheckPointsMapping).find(
        (values) =>
          values[0] === fieldBased.field && values[1] === fieldBased.value
      )
    )
      return true;

    return false;
  }
};
export const getLength = (data) => {
  let len = 0;
  data.forEach((val) => {
    if (val.isDisplayable === miscConstants.yes) {
      len = len + 1;
    }
  });

  return len;
};
export const isPdfDoc = (url: string) =>
  url?.split('/').pop()?.includes('.pdf');
export const getFieldStatus = (key): string => {
  switch (key) {
    case action.approve:
      return fieldStatus.approved;
    case action.rework:
      return fieldStatus.pending;
    case action.resend:
      return fieldStatus.resolved;
    case action.refer:
      return fieldStatus.pending;
    default:
      return '';
  }
};

export const sortComments = (checkpointComment: Comment) => {
  const commentArray: Comment[] = [];
  let current = checkpointComment;
  while (current) {
    if (!current.replies[0]) {
      commentArray.push(current);
      return commentArray.reverse();
    }
    const { replies, ...replyComment } = current.replies[0];
    commentArray.push({
      ...current,
      replies: [{ ...replyComment, replies: [] }],
    });
    current = replies[0];
  }
  return commentArray.reverse();
};
export const showAdditionalHeading = (checkpointId) => {
  return showadditionalHeading.includes(checkpointId);
};
export const getDate = () => {
  return (
    new Date().toLocaleDateString('en-US', { day: 'numeric' }) +
    ' ' +
    new Date().toLocaleTimeString('en-us')
  );
};
export const uniqueElem = (array, key) => {
  return array.filter((elem, index) => {
    return array.findLastIndex((s) => s[key] === elem[key]) === index;
  });
};

export const getSolePropValues = (
  customerType: string,
  businessType: string,
  fieldId: string
) => {
  const {
    profCatInd,
    deptCatInd,
    emplntCatInd,
    emplrCatInd,
    riskCatInd,
    mcc,
    amlMatch,
    cbs,
  } = highlightsName;
  const fieldToHide = [
    profCatInd,
    deptCatInd,
    emplrCatInd,
    emplntCatInd,
    riskCatInd,
    mcc,
    amlMatch,
    cbs,
  ];
  return !(
    customerType === customerTypeMapping.SOLEPROP &&
    businessType === businessTypeMapping.ETB &&
    fieldToHide.includes(fieldId.toString())
  );
};
interface IsObjectEmptyProps<T> {
  myObj: T;
}
export function isObjectEmpty<T extends object>({
  myObj,
}: IsObjectEmptyProps<T>): boolean {
  // eslint-disable-next-line array-callback-return
  return Object.keys(myObj).some((objKey) => {
    if (myObj[objKey] === 'object') {
      return isObjectEmpty(myObj[objKey]);
    } else if (
      Array.isArray(myObj[objKey]) ||
      typeof myObj[objKey] === 'string'
    ) {
      return myObj[objKey].length > 0;
    }
  });
}
export function uniqueArray(array) {
  return array?.filter((el, index, self) => {
    return self?.indexOf(el) === index;
  });
}
export const getHighlightedHeading = (status: string, heading: string) => {
  if (highlightedHeadingMapping[status]?.includes(heading)) {
    return ' m-text-md-bold text-warning_600 mt-4 mb-4 ml-4';
  }
  return ' m-text-md-bold text-black mt-4 mb-4 ml-4';
};
export const getFieldNameColor = (status: string, fieldId: string) => {
  if (fieldValueColorMapping[status]?.includes(fieldId.toString())) {
    return 'text-red-600';
  }
  return 'text-gray-500';
};
const payload = (allUUids) => {
  return allUUids.map((userOrGroupId) => {
    return {
      userOrGroupId,
    };
  });
};

export const getThreadData = (threads: ThreadResponse[]) => {
  const allReferUUIds = threads?.map((Ids) => {
    const splitedIds = Ids?.assigneeId?.split(',');
    return [...(splitedIds || [])].flat();
  });

  const allUUids = uniqueArray([
    ...(threads?.map(
      (ids) => ids.commentedBy ?? ids.uploadedBy ?? ids.actionedBy
    ) || []),
    ...(allReferUUIds || []).flat(),
  ]).filter(Boolean);

  return new Promise((resolve, reject) => {
    void (async () => {
      try {
        const response = await getUserGroupService({
          userOrGroupIds: payload(allUUids),
        });
        if (response.status === 'success') {
          const flattenUserGrp = [
            ...response.data.employeeUsers,
            ...response.data.groupDetails,
            ...response.data.merchantUsers,
          ];
          const updatedThreadData = threads?.map((thData) => {
            const splittedIds = thData?.assigneeId?.split(',');
            return {
              ...thData,
              referUserIds: splittedIds?.map(
                (ids) => flattenUserGrp.find((resp) => resp.id === ids)?.name
              ),
              referGroup: thData.assigneeGroup,
              fieldType: 'CHECKPOINT',

              userGroup: flattenUserGrp.find(
                (resp) =>
                  resp.id === thData.commentedBy ||
                  resp.id === thData.uploadedBy
              )?.userGroupData,
              userName: flattenUserGrp.find(
                (resp) =>
                  resp.id === thData.commentedBy ||
                  resp.id === thData.uploadedBy ||
                  resp.id === thData.actionedBy
              )?.name,
            };
          });

          resolve(updatedThreadData);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Request failed');
        }
      } catch (error) {
        logError('ERROR in thread Response', error);
        reject(error);
      }
    })();
  });
};
// Thread making for highlight
export const getHighlightThread = (highlightThread: HighlightThread[]) => {
  const allReferUUIds = highlightThread?.map((Ids) => {
    const splitedIds = Ids?.assigneeID?.split(',');
    return [...(splitedIds || [])].flat();
  });
  const allUUids = uniqueArray([
    ...(highlightThread?.map((ids) => ids.assignerID ?? ids.createdAt) || []),
    ...(allReferUUIds || []).flat(),
  ]).filter(Boolean);
  return new Promise((resolve, reject) => {
    void (async () => {
      try {
        const response = await getUserGroupService({
          userOrGroupIds: payload(allUUids),
        });
        if (response.status === 'success') {
          const flattenUserGrp = [
            ...response.data.employeeUsers,
            ...response.data.groupDetails,
            ...response.data.merchantUsers,
          ];
          const updatedThreadData = highlightThread?.map((thData) => {
            const splittedIds = thData?.assigneeID?.split(',');
            return {
              ...thData,
              referUserIds: splittedIds?.map(
                (ids) => flattenUserGrp.find((resp) => resp.id === ids)?.name
              ),
              referGroup: thData.assigneeGroup,
              status: thData.caseFieldAction,
              statusOfField: thData.status,
              checkpointVersion: thData.caseFieldVersion,
              commentedAt: thData.createdAt,
              assigneeId: thData.assigneeID,
              fieldType: thData.fieldType,

              userName: flattenUserGrp.find(
                (resp) => resp.id === thData.assignerID
              )?.name,
            };
          });

          resolve(updatedThreadData);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Request failed');
        }
      } catch (error) {
        logError('ERROR in thread Response', error);
        reject(error);
      }
    })();
  });
};

export const getReasonsOrDocsList = (
  reasonsList: Array<{ text: string; value: string }>,
  selectedReasonsList: string[]
): string[] => {
  const currentSelectedReasonsList: string[] = [];
  const selectedReasonsIndexSet: Set<number> = new Set();
  if (!selectedReasonsList.length || !reasonsList.length) return [];
  for (const reason of selectedReasonsList) {
    if (!reason) continue;
    /* if reasons is not present in master list skip current iteration */
    const reasonMasterIndex = reasonsList.findIndex((rsn) =>
      rsn.value.includes(reason)
    );
    if (
      reasonMasterIndex === -1 ||
      selectedReasonsIndexSet.has(reasonMasterIndex)
    )
      continue;
    /* Add reason in selected reasons list */
    currentSelectedReasonsList.push(reasonsList[reasonMasterIndex].value);
    selectedReasonsIndexSet.add(reasonMasterIndex);
  }
  return currentSelectedReasonsList;
};
export const getContentBottom = (items) => {
  return placeContentBottom.includes(items?.checkpointId.toString()) // placing data below if col is solprop
    ? 'justify-end'
    : '';
};
/* Section for which to show checkbox as checked */
export const checkedHighlightsSection = ['Terms and Conditions'];

/* Get checkpoint actionable group from checkpoint actionable viewable */
export const getActionableGroup = (
  actionableList: CheckpointActionable[],
  key: string,
  value: string
): string[] => {
  const actionableGroupList: string[] = [];
  const actionableGroup = actionableList.find(
    (elm) => elm[key] === value
  )?.assigneeGroup;
  if (actionableGroup) actionableGroupList.push(actionableGroup);
  return actionableGroupList;
};

/* Get case level actionable group from checkpoint actionable viewable */
export const getCaseActionableGroup = (
  checkpointActionable: CheckpointActionable[],
  currentUserId: string
): string[] => {
  const actionableGroupList: string[] = [];
  checkpointActionable.forEach((checkpoint) => {
    if (
      checkpoint.assigneeGroup !== bankUserMapping.CPC_USER &&
      checkpoint.assigneeId.includes(currentUserId)
    )
      actionableGroupList.push(checkpoint.assigneeGroup);
  });
  return actionableGroupList;
};

/* Get highlights actionable group based on fieldId */
export const getAssignerGroupForRefer = (
  type: string,
  checkpointActionable: CheckpointActionable[],
  currentUserId: string,
  checkpointOrFieldId: string
) => {
  switch (type) {
    case miscConstants.highlight: {
      return getActionableGroup(
        checkpointActionable,
        'fieldId',
        checkpointOrFieldId
      );
    }
    case miscConstants.case: {
      return getCaseActionableGroup(checkpointActionable, currentUserId);
    }
    default: {
      return getActionableGroup(
        checkpointActionable,
        'checkpointId',
        checkpointOrFieldId
      );
    }
  }
};

/* Get Actionable and Viewable Groups of user from case level actionable viewable */
export const getAssignerGroup = (
  caseActionableViewableList: ActionableViewableProperties[],
  userGroups: LoggedInUserGrpType[]
) => {
  const userGroupsSet = new Set<string>();
  userGroups.forEach((grp) => userGroupsSet.add(grp.name));
  const viewableGroup: Set<string> = new Set();
  const actionableGroup: Set<string> = new Set();
  caseActionableViewableList.forEach((elm) => {
    if (userGroupsSet.has(elm.assigneeGroup)) {
      elm.caseType === actionType.ACTIONABLE
        ? actionableGroup.add(elm.assigneeGroup)
        : viewableGroup.add(elm.assigneeGroup);
    }
  });
  return {
    actionable: Array.from(actionableGroup),
    viewable: Array.from(viewableGroup),
  };
};

/* Get group of users based on case status and case actionable viewable */
export const getUserGroup = (
  caseStatus: string,
  caseActionableViewableList: ActionableViewableProperties[],
  userGroups: LoggedInUserGrpType[]
) => {
  const { actionable, viewable } = getAssignerGroup(
    caseActionableViewableList,
    userGroups
  );
  if (caseStatus === action.refer && actionable.length > 1) {
    return actionable.filter(
      (grpName) => grpName === bankUserMapping.CPC_USER
    )[0];
  }
  if (actionable.length) {
    return actionable[0];
  }
  return viewable.length ? viewable[0] : userGroups[0].name;
};

export const getReferAssigneeIdAndGroup = (
  checkpointsComment: CheckPointComment[],
  highlightReferData: HighglightReferUserData,
  type: string
) => {
  const allReferGroupsMap = {};
  const allReferUserId = {};
  highlightReferData.assigneeGroup?.forEach((grp) => {
    allReferGroupsMap[grp] = true;
  });
  highlightReferData.assigneeId?.forEach(
    (usrId) => (allReferUserId[usrId] = true)
  );
  if (type === miscConstants.highlight) {
    return {
      group: Object.keys(allReferGroupsMap),
      userIds: Object.keys(allReferUserId),
    };
  }
  checkpointsComment.forEach((caseCheckpointField) => {
    caseCheckpointField.assigneeGroup?.forEach((grp) => {
      allReferGroupsMap[grp] = true;
    });
    caseCheckpointField.assigneeId?.forEach((usrId) => {
      allReferUserId[usrId] = true;
    });
  });
  return {
    group: Object.keys(allReferGroupsMap),
    userIds: Object.keys(allReferUserId),
  };
};

export const getActiveRefereeIds = (
  threadData: ThreadResponse[] | undefined,
  currentUserId: string | undefined
) => {
  const checkpointInfoMap: { [id: string]: ThreadResponse } = {};
  const checkpointToAssigneeMap: { [id: string]: string[] } = {};
  threadData?.forEach((val) => {
    if (val.status !== action.refer) return;
    if (
      !checkpointInfoMap[val.checkpointId] ||
      checkpointInfoMap[val.checkpointId].checkpointVersion <
        val.checkpointVersion
    ) {
      checkpointInfoMap[val.checkpointId] = val;
      if (val.actionedBy !== currentUserId) {
        checkpointToAssigneeMap[val.checkpointId] = [];
      } else {
        checkpointToAssigneeMap[val.checkpointId] = val.assigneeId.split(',');
      }
    }
  });
  return Array.from(new Set(Object.values(checkpointToAssigneeMap).flat()));
};

export const getActionableUserIds = (
  checkpointActionable: CheckpointActionable[]
) => {
  const assigneeIdMap = {};
  checkpointActionable.forEach((chk) => {
    if (chk.assigneeGroup !== bankUserMapping.CPC_USER) {
      chk.assigneeId.forEach((id) => {
        assigneeIdMap[id] = true;
      });
    }
  });
  return Object.keys(assigneeIdMap);
};

export const featureDataByGroup = (featuresArr, isActive = '') => {
  const extractedData = {};

  featuresArr?.forEach((feature) => {
    if (isActive === 'active') {
      if (feature?.active || feature?.isActive) {
        const permissions = feature?.permissions;

        permissions?.forEach((permission) => {
          const featureName = permission?.featureName;
          if (featureName) {
            if (!extractedData[featureName]) {
              extractedData[featureName] = [];
            }

            extractedData[featureName]?.push(permission);
          }
        });
      }
    } else {
      const permissions = feature?.permissions;

      permissions?.forEach((permission) => {
        const featureName = permission?.featureName;
        if (featureName) {
          if (!extractedData[featureName]) {
            extractedData[featureName] = [];
          }

          extractedData[featureName]?.push(permission);
        }
      });
    }
  });
  return extractedData;
};

export const resetToggleForAll = (items) => {
  items?.forEach((element: any) => {
    Object.assign(element, { isToggle: false });
  });
};

export const selectToggleAllChild = (items, isSelected = false) => {
  items?.forEach((element: any) => {
    Object.assign(element, { isToggle: isSelected });
  });
};

export const TempFeatureListDataObj = (
  featureListData,
  checkActive = ''
): IPermissionTable[] => {
  const arr: any = {};
  const groupByList = featureDataByGroup(
    featureListData?.features,
    checkActive
  );

  Object.keys(groupByList)?.forEach((featureName) => {
    arr[featureName] = [];
    groupByList?.[featureName]?.forEach((permissionObj) => {
      if (
        permissionObj?.isToggle &&
        !Object.values(groupByList)?.includes(permissionObj)
      ) {
        arr?.[featureName].push(permissionObj);
      }
    });
  });
  return !isEmpty(arr)
    ? tableDataForPermissionWithFeatureName(arr)
    : ([] as IPermissionTable[]);
};
export const tableDataForPermissionWithFeatureName = (tempFeatureListData) => {
  const entries = Object.entries(tempFeatureListData);
  let SNo = 0;
  const tableData: any = [];
  for (const entry of entries) {
    if (!isEmpty(entry[1])) {
      tableData.push({
        SNo: ++SNo,
        featureName: entry[0],
        permissions: entry[1],
      });
    }
  }
  return tableData;
};
export const makeSelectedPermission = (apiRes, featureListData) => {
  Object.keys(featureDataByGroup(featureListData?.features))?.forEach(
    (setItem) => {
      featureDataByGroup(apiRes?.features, 'active')?.[setItem]?.forEach(
        (setPermission) => {
          const permissionSelected = featureDataByGroup(
            featureListData?.features,
            'active'
          )?.[setItem]?.find(
            (permissionItr) => setPermission?.id === permissionItr?.id
          );
          if (permissionSelected) {
            Object.assign(permissionSelected, { isToggle: true });
          }
        }
      );
    }
  );
};
export const makeNotSelectedPermission = (apiRes, featureListData) => {
  Object.keys(featureDataByGroup(featureListData?.features))?.forEach(
    (setItem) => {
      featureDataByGroup(featureListData?.features)?.[setItem]?.forEach(
        (setPermission) => {
          if (setPermission) {
            Object.assign(setPermission, { isToggle: false });
          }
        }
      );
      featureDataByGroup(apiRes?.features)?.[setItem]?.forEach(
        (setPermission) => {
          const permissionSelected = featureDataByGroup(
            featureListData?.features
          )?.[setItem]?.find(
            (permissionItr) => setPermission?.id === permissionItr?.id
          );
          if (permissionSelected) {
            Object.assign(permissionSelected, { isToggle: true });
          }
        }
      );
    }
  );
};

export const makeAllSelectedPrmFtr = (featureListData, isSelect = false) => {
  Object.keys(featureDataByGroup(featureListData?.features))?.forEach(
    (setItem) => {
      featureDataByGroup(featureListData?.features)?.[setItem]?.forEach(
        (setPermission) => {
          if (setPermission) {
            Object.assign(setPermission, { isToggle: isSelect });
          }
        }
      );
    }
  );
};
export const checkAllSelectedPrmFtr = (featureListData) => {
  const arr: any = [];
  Object.keys(featureDataByGroup(featureListData?.features))?.forEach(
    (setItem) => {
      featureDataByGroup(featureListData?.features)?.[setItem]?.forEach(
        (setPermission) => {
          if (setPermission) {
            arr.push(setPermission);
          }
        }
      );
    }
  );
  return arr;
};

export const getTempFeatureListData = (
  tableDataState: IPermissionTable[] | ResRoleDetailRole[]
): Array<{ permissionId: string }> => {
  const arr: Array<{ permissionId: string }> = [];

  tableDataState?.forEach((ele) => {
    ele?.permissions?.forEach((item) => {
      if (item?.isToggle) {
        arr.push({ permissionId: item?.id });
      }
    });
  });

  return arr;
};

export const getTempDetailsFeatureListData = (
  tableDataState: IPermissionTable[] | ResRoleFeatureData[]
): Array<{ permissionId: string }> => {
  const arr: Array<{ permissionId: string }> = [];

  tableDataState?.forEach((ele) => {
    ele?.permissions?.forEach((item) => {
      arr.push({ permissionId: item?.id });
    });
  });

  return arr;
};

export const generateSerialNumber = (array: any[]) => {
  return array?.map((item, index) => {
    return Object.assign(item, { serial: index + 1 });
  });
};

export const getMapFromObject = (
  list: Array<{
    title?: string;
    id: string;
    detailMap: any;
    showHeaderBtn?: boolean;
    buttonProps?: ButtonProps | null;
    rightCustomUI?: React.JSX.Element;
    hasRightCustomUI?: boolean;
    badgeStatus?: string;
  }>
): RightListDetailItem[] => {
  return list.map((item, index) => {
    return {
      title: item?.title ?? '',
      showHeaderBtn: item.showHeaderBtn ?? false,
      btnProps: item?.buttonProps,
      detailKeyValueList: Object.keys(item.detailMap).map((key) => {
        return {
          subtitle: key,
          description: item.detailMap[key],
        };
      }),
      rightCustomUI: item?.rightCustomUI,
      hasRightCustomUI: item?.hasRightCustomUI,
      badgeStatus: item?.badgeStatus,
    };
  });
};
