import React, { useEffect, useState } from 'react';

import { XB_Spinner } from '../spinner/XB_Spinner.component';

import './AU_Loader.style.scss';

interface AULoaderProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  fullLoader?: boolean;
}

export const AU_Loader: React.FC<AULoaderProps> = ({
  size = 'xl',
  className,
  fullLoader,
}) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    const rootElement = document.getElementById('root_id');
    if (!rootElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { inlineSize, blockSize } = entries[0].contentBoxSize[0];
      setDimensions({ width: inlineSize, height: blockSize });
    });

    resizeObserver.observe(rootElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const backDropStyles = () => {
    if (fullLoader) {
      return {};
    } else if (dimensions.width === 0 && dimensions.height === 0) {
      return { height: '100%', width: '100%' };
    }
    return dimensions;
  };

  const loaderContainerStyles = () => {
    if (fullLoader) {
      return {};
    } else if (dimensions.height === 0) {
      return { height: '100%' };
    }
    return { height: dimensions.height };
  };

  return (
    <div
      className={`fixed backdrop-blur-sm ${
        fullLoader ? ' top-0 left-0 bottom-0 right-0' : ''
      } z-[999] h-full  w-full  ${className}`}
      style={backDropStyles()}
    >
      <div className="flex loader_container" style={loaderContainerStyles()}>
        <XB_Spinner size={size} />
      </div>
    </div>
  );
};
