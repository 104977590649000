import {
  AU_AdminSideNavbar,
  AU_AdminTopNavbar,
} from '@core-components/organisms';

interface NavigationMenuProps {
  onRoot?: () => void;
  leftNavigation?: boolean;
  children: string | React.JSX.Element | React.JSX.Element[];
}
export const AU_AdminNavbarLayout = ({
  children,
  onRoot,
  leftNavigation = true,
}: NavigationMenuProps) => {
  return (
    <div className="grid h-screen relative grid-rows-[auto 1fr] overflow-auto">
      <AU_AdminTopNavbar onRoot={onRoot} />
      <div className="flex overflow-hidden">
        {leftNavigation && <AU_AdminSideNavbar />}
        <div
          className={`w-screen ${
            leftNavigation ? 'p-4 overflow-y-scroll' : ''
          }`}
          id="root_id"
        >
          {children}
        </div>
      </div>
    </div>
  );
};
