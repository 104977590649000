import { createSlice } from '@reduxjs/toolkit';

import { ResProfileData } from '../models/profile-details.model';
import { getUserProfile } from '../thunks/login.thunk';

const initialState: ResProfileData = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
};
const profileDetails = createSlice({
  name: 'profileDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state: ResProfileData) => {
      state.loading = true;
      state.successfulResponse = undefined;
      state.error = undefined;
      state.data = undefined;
    });
    builder.addCase(
      getUserProfile.fulfilled,
      (state: ResProfileData, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.data = payload.data;
        state.successfulResponse = true;
      }
    );
    builder.addCase(
      getUserProfile.rejected,
      (state: ResProfileData, { payload }) => {
        state.loading = false;
        state.error = payload as any;
        state.data = undefined;
        state.successfulResponse = false;
      }
    );
  },
});

export default profileDetails.reducer;
