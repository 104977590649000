import { ReactElement, useEffect, useRef } from 'react';

import { Icon } from '@core-components/atoms/icon/XB_Icon.component';
import { useEventProvider } from '@core-providers';

import { XB_Button } from '../button/XB_Button.component';

import './XB_Modal.style.scss';

interface ModalProps {
  dataTestId?: string;
  title?: string;
  actions?: React.ReactNode;
  open?: boolean;
  setOpen?: (boolean) => void;
  subTitle?: string;
  additionSubTitle?: React.ReactNode;
  body?: React.ReactNode;
  img?: ReactElement<typeof Icon | HTMLImageElement>;
  modalBackgroundStyle?: React.CSSProperties;
  headPos?: 'text-left' | 'text-center';
  headPosClasses?: string;
  maxWidth?: string;
  onModalClose?: () => void;
  backdropClose?: boolean;
  isManualClose?: boolean;
}

export const XB_Modal = ({
  dataTestId,
  title,
  actions,
  open = false,
  setOpen,
  subTitle,
  additionSubTitle,
  body,
  img,
  modalBackgroundStyle = {},
  headPos = 'text-left',
  headPosClasses,
  maxWidth = 'auto',
  onModalClose,
  backdropClose = true,
  isManualClose = false,
}: ModalProps) => {
  const { getEventId } = useEventProvider();
  const id = dataTestId ? `${getEventId(dataTestId)}-MDL` : 'BKGD-MDL';
  const modalRef = useRef(null);

  const handleOutSideClose = () => {
    window.onclick = (event) => {
      if (event.target.contains(modalRef.current)) {
        setOpen?.(false);
      }
    };
  };

  /**
   * @description for close on press esc button
   */

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpen?.(false);
        onModalClose?.();
      }
    };
    window.addEventListener('keydown', close);
    if (open) {
      window.document.body.style.overflow = 'hidden';
    }

    return () => {
      window.document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', close);
    };
  }, []);

  useEffect(() => {
    return () => {
      onModalClose?.();
    };
  }, []);

  return (
    <>
      {open ? (
        <div
          ref={modalRef}
          data-testid={id}
          className={`modal fixed top-0 right-0 bottom-0 left-0 z-[10000]`}
          onClick={() => {
            backdropClose && handleOutSideClose();
          }}
        >
          <div
            className="p-4 m-4 modal-content md:p-6"
            style={{ maxWidth, ...modalBackgroundStyle }}
          >
            <div className={`mb-8 ${headPos} ${headPosClasses}`}>
              {img && <div className="mb-5">{img}</div>}
              <h2 className="mb-2 text-lg font-medium text-gray-900">
                {title}
              </h2>
              {subTitle && (
                <h6 className="text-sm font-normal text-gray-500">
                  <div dangerouslySetInnerHTML={{ __html: subTitle }} />
                </h6>
              )}
              {isManualClose && (
                <XB_Button
                  classes="close-icon"
                  dataTestId="MDL-CLSE"
                  iconName="close"
                  btnType="tertiary-gray"
                  onClick={() => {
                    setOpen?.(false);
                  }}
                />
              )}
              {additionSubTitle}
            </div>
            {body && <div className="mb-8">{body}</div>}
            <div className="action-classes">{actions}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
