export const inputLength = {
  PAN: 10,
  TAN: 10,
  GST: 15,
  PINCODE_IND: 6,
  MOBILE_NUMBER_IND: 10,
  MOBILE_NUMBER_OUTSIDE: 15,
  UPI_MAX_LENGTH: 120,
  NAME_MAX_LENGTH: 32,
  EMAIL_MAX_LENGTH: 120,
  ACCOUNT_NUMBER_MAX_LENGTH: 120,
  AU_ACCOUNT_MAX_LENGTH: 16,
  BILLING_NAME: 100,
  SHIPPING_NAME: 100,
  ADDRESS_LINE_MAX: 35,
  INTERNATIONAL_PINCODE: 10,
  INTERNATIONAL_CITY: 35,
  INTERNATIONAL_STATE: 35,
  INTERNATIONAL_COUNTRY: 35,
  CITY_IND_MAX: 35,
  STATE_IND_MAX: 35,
  COUNTRY_IND_MAX: 35,
  YEAR_MONTH_COUNT: 12,
  COMMON_INPUT_LENGTH: 35,
};
