import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { XB_Checkbox } from '@core-components/atoms';
import { XB_Button } from '@core-components/atoms/button/XB_Button.component';
import { XB_Chip } from '@core-components/atoms/chip/XB_Chip.component';
import { XB_Divider } from '@core-components/atoms/divider/XB_Divider.component';
import { Icon } from '@core-components/atoms/icon/XB_Icon.component';
import { XB_Input } from '@core-components/atoms/input/XB_Input.component';
import { XB_InfiniteScroll } from '@core-components/organisms';
import { checkboxState } from '@core-constants';
import { useEventProvider } from '@core-providers';

import { XB_Accordion } from '../accordion/XB_Accordion.component';

import './XB_DropdownMultilevel.styles.scss';

interface DropdownProps {
  id: string;
  label?: string;
  classes?: string;
  msgType?: 'info' | 'success' | 'error' | '';
  infoMsgText?: string;
  disabled?: boolean;
  items: any;
  placeholderOption?: string;
  selectedValue?: string[] | string;
  multiSelect?: boolean;
  onChange?: (val: any, list) => void;
  actionBtnText?: string | ReactNode;
  search?: boolean;
  actionBtnHandler?: () => void;
  siblingDisable?: boolean;
  apiUrl?: string;
  asyncLoad?: boolean;
  dataCallback?: (data: any) => any[];
  totalCount?: number;
}

export const XB_DropdownMultilevel: React.FC<DropdownProps> = ({
  id,
  label,
  classes,
  onChange,
  msgType,
  infoMsgText,
  disabled,
  items,
  selectedValue,
  placeholderOption,
  multiSelect = false,
  actionBtnText,
  search = false,
  siblingDisable = false,
  apiUrl = '',
  asyncLoad = false,
  actionBtnHandler,
  dataCallback = () => [],
  totalCount = 0,
}: DropdownProps) => {
  const SelectActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    PageDown: 6,
    PageUp: 7,
    Previous: 8,
    Select: 9,
    Type: 10,
  };
  const { getEventId } = useEventProvider();
  const classList = ['dropdown multiSelect'];
  classes && classList.push(classes);
  disabled && classList.push('disabled');
  const [value, setValue] = useState(
    Array.isArray(selectedValue) ? [...selectedValue] : []
  );
  const [showMenu, setShowMenu] = useState(false);
  const comboxRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [itemsList, setItemsList] = useState(items);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [selectedItems, setSelectedItems] = useState<
    Array<{ id: string; text: string }>
  >([]);
  const [anyChecked, setAnyChecked] = useState('');

  const handleMenuOpenKeyPress = useCallback((menuOpen, key, altKey) => {
    if (menuOpen) {
      if (key === 'ArrowUp' && altKey) {
        return SelectActions.CloseSelect;
      } else if (key === 'ArrowDown' && !altKey) {
        return SelectActions.Next;
      } else if (key === 'ArrowUp') {
        return SelectActions.Previous;
      } else if (key === 'PageUp') {
        return SelectActions.PageUp;
      } else if (key === 'PageDown') {
        return SelectActions.PageDown;
      } else if (key === 'Escape' || key === 'Tab') {
        return SelectActions.Close;
      } else if (key === 'Enter' || key === ' ') {
        return SelectActions.CloseSelect;
      }
    }
  }, []);
  const getActionFromKey = useCallback((event, menuOpen) => {
    const { key, altKey, ctrlKey, metaKey } = event;
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']; // all keys that will do the default open action
    // handle opening when closed
    if (!menuOpen && openKeys.includes(key)) {
      return SelectActions.Open;
    }

    // home and end move the selected option when open or closed
    if (key === 'Home') {
      return SelectActions.First;
    }
    if (key === 'End') {
      return SelectActions.Last;
    }

    // handle typing characters when open or closed
    if (
      key === 'Backspace' ||
      key === 'Clear' ||
      (key === ' ' && search) ||
      (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)
    ) {
      return SelectActions.Type;
    }

    // handle keys when open
    return handleMenuOpenKeyPress(menuOpen, key, altKey);
  }, []);

  const getUpdatedIndex = useCallback(
    (currentIndex: number, maxIndex, action) => {
      const pageSize = 3; // used for pageup/pagedown
      switch (action) {
        case SelectActions.First:
          return 0;
        case SelectActions.Last:
          return maxIndex;
        case SelectActions.Previous:
          return Math.max(0, currentIndex - 1);
        case SelectActions.Next:
          return Math.min(maxIndex, currentIndex + 1);
        case SelectActions.PageUp:
          return Math.max(0, currentIndex - pageSize);
        case SelectActions.PageDown:
          return Math.min(maxIndex, currentIndex + pageSize);
        default:
          return currentIndex;
      }
    },
    []
  );

  const selectDropdownOption = useCallback(
    (optionValue) => {
      let selectionValue: string[] = [];
      if (multiSelect) {
        const indexOfValue = value.indexOf(optionValue);
        selectionValue =
          indexOfValue > -1
            ? [
                ...value.filter((item) => {
                  return item !== optionValue;
                }),
              ]
            : [...value, optionValue];

        setTimeout(() => {
          setShowMenu(true);
        }, 0);
      } else {
        selectionValue = [optionValue];
      }
      setValue(selectionValue);
      if (!multiSelect) setShowMenu(false);
    },
    [value, multiSelect, setShowMenu, setValue, onChange]
  );
  const updateDropdownState = useCallback(
    (open: boolean) => {
      if (showMenu === open) {
        return;
      }
      setShowMenu(open);
      comboxRef?.current?.setAttribute('aria-expanded', open.toString());
    },
    [showMenu, comboxRef, setShowMenu]
  );

  const onComboKeyDown = useCallback(
    (event) => {
      const max = itemsList.length - 1;
      const action = getActionFromKey(event, showMenu);

      switch (action) {
        case SelectActions.Last:
        case SelectActions.First:
          updateDropdownState(true);
        // intentional fallthrough
        case SelectActions.Next:
        case SelectActions.Previous:
        case SelectActions.PageUp:
        case SelectActions.PageDown:
          event.preventDefault();
          setCurrentIdx(getUpdatedIndex(currentIdx, max, action));
          break;
        case SelectActions.CloseSelect:
          event.preventDefault();
          return selectDropdownOption(itemsList[currentIdx]?.value);
        case SelectActions.Close:
          event.preventDefault();
          return updateDropdownState(false);
        case SelectActions.Open:
          event.preventDefault();
          return updateDropdownState(true);
      }
    },
    [
      itemsList,
      showMenu,
      currentIdx,
      updateDropdownState,
      getUpdatedIndex,
      setCurrentIdx,
      selectDropdownOption,
    ]
  );

  useEffect(() => {
    comboxRef?.current?.addEventListener('keydown', onComboKeyDown);
    return () => {
      comboxRef?.current?.removeEventListener('keydown', onComboKeyDown);
    };
  }, [onComboKeyDown]);

  useLayoutEffect(() => {
    setItemsList(items);
  }, [items]);

  useLayoutEffect(() => {
    const handleOutSideClick = (e) => {
      if (!comboxRef?.current?.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handleOutSideClick);

    return () => {
      window.removeEventListener('click', handleOutSideClick);
    };
  }, []);

  useLayoutEffect(() => {
    setValue(Array.isArray(selectedValue) ? [...selectedValue] : []);
    setCurrentIdx(items.findIndex((obj) => obj.value === selectedValue));
  }, [selectedValue]);

  const pushIfNotEveryChecked = (isEveryChecked, selectedChip, item) => {
    if (!isEveryChecked) {
      selectedChip.push({ id: item.id, text: item.text });
    }
  };

  useEffect(() => {
    const selectedChip: Array<{ id: string; text: string }> = [];
    const returnJson = {};
    let checkid = '';
    itemsList?.forEach((group: any) => {
      const isAnyChecked = group?.childrens.some(
        (i) => i.checked === 'checked'
      );
      if (siblingDisable && isAnyChecked) checkid = group.id;
      const isEveryChecked = group?.childrens.every(
        (i) => i.checked === 'checked'
      );
      if (isEveryChecked) {
        selectedChip.push({ id: group.id, text: group.text });
      }
      if (isAnyChecked) {
        const userarr: string[] = [];
        group?.childrens?.forEach((item) => {
          if (item.checked !== 'unchecked') {
            userarr.push(item.value);
            pushIfNotEveryChecked(isEveryChecked, selectedChip, item);
          }
        });
        returnJson[group.value] = userarr;
      }
    });
    if (checkid) setAnyChecked(checkid);
    else setAnyChecked('');
    setSelectedItems(selectedChip);

    onChange?.(returnJson, itemsList);
  }, [itemsList]);
  useLayoutEffect(() => {
    if (showMenu && search) {
      document
        .getElementById(`${id}-search-parent`)
        ?.classList?.remove('hidden');
      document.getElementById(`${id}-search`)?.focus();
    }
    if (!showMenu && search) {
      document.getElementById(`${id}-search-parent`)?.classList?.add('hidden');
      (document.getElementById(`${id}-search`) as HTMLInputElement).value = '';
      onSearch('');
    }
    if (!showMenu) {
      setCurrentIdx(items.findIndex((obj) => obj.value === selectedValue));
    }
  }, [showMenu]);
  // Tree selection and deselection of multilevel code --- start
  function updateCheckedStatus(data, targetId, status) {
    const targetObject = findObjectById(data, targetId);
    if (!targetObject) {
      return;
    }
    const parentObject = getParentObject(data, targetObject);
    if (!parentObject) {
      return;
    }
    const siblings = parentObject.childrens;
    const allSiblingsChecked = siblings.every(
      (sibling) => sibling.checked === checkboxState.CHECKED
    );
    const allSiblingsUnChecked = siblings.every(
      (sibling) => sibling.checked === checkboxState.UNCHECKED
    );

    if (allSiblingsChecked) parentObject.checked = checkboxState.CHECKED;
    else if (allSiblingsUnChecked)
      parentObject.checked = checkboxState.UNCHECKED;
    else parentObject.checked = checkboxState.INDETERMINATE;

    if (parentObject.id !== 'root') {
      updateCheckedStatus(data, parentObject.id, status);
    }
    return data;
  }

  function getParentObject(data, childObject) {
    for (const obj of data) {
      for (const child of obj.childrens) {
        if (child === childObject) {
          return obj;
        }
      }
      if (obj.childrens.length > 0) {
        const result = getParentObject(obj.childrens, childObject);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const findObjectById = (array, targetId, key = 'id', condition = 'equal') => {
    for (const obj of array) {
      const isFound =
        condition === 'equal'
          ? obj[key] === targetId
          : obj[key].toLowerCase().includes(targetId.toLowerCase());
      if (isFound) {
        return obj;
      }
      if (obj.childrens && obj.childrens.length > 0) {
        const result = findObjectById(obj.childrens, targetId, key, condition);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  function updateCheckedValueById(arr, findId, newValue) {
    const oldObj = [...arr];
    for (const obj of oldObj) {
      if (obj.id === findId) {
        obj.checked = newValue;
        updateChildren(obj.childrens, newValue);
      } else if (obj.childrens) {
        updateCheckedValueById(obj.childrens, findId, newValue);
      }
    }
    return oldObj;
  }

  function updateChildren(childrens, newValue) {
    if (!childrens || childrens.length === 0) return;
    for (const child of childrens) {
      child.checked = newValue;
      updateChildren(child.childrens, newValue);
    }
  }
  // Tree selection and deselection of multilevel code --- end

  const onOptionClicked = (isChecked: boolean, checkedID) => {
    // Tree selection call for child and parent tree --- start
    const foundObject = findObjectById(itemsList, checkedID);
    if (foundObject) {
      const checked = isChecked
        ? checkboxState.CHECKED
        : checkboxState.UNCHECKED;
      const newChildObj = updateCheckedValueById(itemsList, checkedID, checked);
      setItemsList(newChildObj);
      if (checkedID.indexOf('-') > -1) {
        const newParentObj = updateCheckedStatus(
          newChildObj,
          checkedID,
          isChecked
        );
        setItemsList(newParentObj);
      }
    }
    // Tree selection call for child and parent tree --- end
  };

  const toggleDropdown = () => {
    setShowMenu((prev) => !prev);
  };

  const onSearch = (val) => {
    if (val.length >= 2) {
      let searchedList: any = [];
      for (const item of items) {
        const foundObject = findObjectById([item], val, 'text', 'include');
        if (foundObject) {
          searchedList = [...searchedList, item];
          setItemsList(searchedList);
        }
      }
      if (searchedList.length === 0) {
        setItemsList([]);
        setError(t(`noItemsFound`));
      }
    } else {
      setItemsList(items);
    }
    if (val.length === 0) {
      setError('');
    }
  };
  const getDropdownValue = () => {
    const filledDropdownValue = () => {
      return (
        <div className="dropdown-tags">
          {selectedItems.map((option) => (
            <XB_Chip
              key={option.id}
              classes="dropdown-tag-item"
              rightIcon="close"
              onClick={() => {
                if (disabled) return;
                onOptionClicked(false, option.id);
              }}
            >
              {option.text}
            </XB_Chip>
          ))}
        </div>
      );
    };
    return <div className="dropdown__value">{filledDropdownValue()}</div>;
  };

  const siblingDisableFn = (item) => {
    if (siblingDisable && anyChecked)
      return !String(item.id).startsWith(anyChecked);
    return false;
  };

  const createDropdown = (ddItems) => {
    return ddItems.map((item) => {
      const marginLeft =
        item?.id?.indexOf('-') === -1
          ? 0
          : (item?.id?.replace(/-/g, '').length - 1) * 20;
      return (
        <>
          {item.childrens.length === 0 ? (
            <>
              <div
                className="px-4 py-3 hover:bg-gray-50"
                style={{ marginLeft }}
              >
                <XB_Checkbox
                  height={20}
                  width={20}
                  id={`${id}-option-${String(item.id)}`}
                  key={`${id}-option-${String(item.id)}`}
                  onClick={(event) =>
                    onOptionClicked(event.currentTarget.checked, item.id)
                  }
                  label={item.text}
                  checked={
                    item.checked === checkboxState.CHECKED ||
                    item.checked === checkboxState.INDETERMINATE
                  }
                  indeterminate={item.checked === checkboxState.INDETERMINATE}
                  disabled={siblingDisableFn(item)}
                />
              </div>
            </>
          ) : (
            createAccordion(item)
          )}
        </>
      );
    });
  };

  const createAccordion = (item) => {
    return (
      <div key={item.id}>
        <XB_Accordion
          isOpenFirst={false}
          isOpenAll={searchValue.length > 1}
          list={[
            {
              title: createDropdown([
                {
                  value: item.value,
                  text: item.text,
                  id: item.id,
                  checked: item.checked,
                  childrens: [],
                },
              ]),
              content: createDropdown(item.childrens),
              active: false,
              isIconClose: true,
              titleClass: 'pr-3 hover:bg-gray-50',
            },
          ]}
        />
      </div>
    );
  };
  return (
    <>
      <div
        className={`${classList.join(' ')}`}
        aria-label={label}
        id={`dropdown_parent-${id}`}
        ref={comboxRef}
      >
        {label && (
          <label htmlFor={id} className="m-text-md-regular mb-1.5 block">
            {label}
          </label>
        )}
        <div
          className={`dropdown__wrapper ${showMenu ? 'expanded' : 'collapsed'}`}
        >
          {search && (
            <div
              id={`${id}-search-parent`}
              className="absolute z-10 hidden w-full"
            >
              <XB_Input
                id={`${id}-search`}
                value={searchValue}
                placeholder={placeholderOption}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  return onSearch(e.target.value);
                }}
                prefixIcon="search"
                suffixIcon={searchValue !== '' ? 'close' : ''}
                autoComplete="false"
                onIconClick={() => {
                  setSearchValue('');
                  return onSearch('');
                }}
              />
            </div>
          )}
          <div
            aria-controls={`${id}-list`}
            aria-expanded="false"
            aria-haspopup="listbox"
            aria-labelledby="combo1-label"
            id={id}
            data-testid={`${getEventId(id)}-DDN`}
            className={`dropdown__input ${
              search ? 'dropdown__searchable' : ''
            }`}
            role="dropdown"
            tabIndex={0}
            onClick={() => {
              !disabled && toggleDropdown();
            }}
          >
            <>
              {getDropdownValue()}
              <span className="dropdown__chevron">
                <Icon icon="chevron-down" width={20} height={20} />
              </span>
            </>
          </div>
          <div
            className="dropdown__list"
            role="listbox"
            id={`${id}-list`}
            aria-labelledby="combo1-label"
            tabIndex={-1}
          >
            {actionBtnText && (
              <>
                <XB_Button
                  btnType="tertiary"
                  size="sm"
                  className="dropdown__action"
                  onClick={actionBtnHandler}
                  dataTestId={'CRET-BTN'}
                >
                  {actionBtnText}
                </XB_Button>
                <XB_Divider />
              </>
            )}
            {itemsList.length === 0 ? (
              <h2 className="px-4 py-3 text-gray-800 cursor-not-allowed m-text-lg-bold">
                {t('noUserOrGroupFound')}
              </h2>
            ) : (
              ''
            )}
            {asyncLoad ? (
              <XB_InfiniteScroll
                data={itemsList}
                asyncLoad={asyncLoad}
                design={(v) => createAccordion(v)}
                apiUrl={apiUrl}
                dataCallback={(data) => dataCallback?.(data)}
                parentDiv={`${id}-list`}
                totalCount={totalCount}
                loader={
                  <h2 className="px-4 py-3 text-gray-800 cursor-not-allowed m-text-lg-bold">
                    Loading...
                  </h2>
                }
              />
            ) : (
              itemsList.map((item) => createAccordion(item))
            )}
          </div>
        </div>

        {msgType && (
          <span className="error" id={`${id}-error`}>
            {infoMsgText}
          </span>
        )}
      </div>
      {error && (
        <div className="mt-3 text-gray-300 m-text-lg-regular">{error}</div>
      )}
    </>
  );
};
