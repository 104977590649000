import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReqGetSrList } from '@src/modules/service-request/models/service-request.model';

export const getSrList = createAsyncThunk(
  'api/csr-service/get-sr-list',
  async (getListData: ReqGetSrList, { rejectWithValue }) => {
    try {
      const response = await Service?.send({
        method: 'POST',
        baseurl: AUEndPoints?.serviceRequest?.GET_SR_LIST,
        url: '',
        obj: getListData,
      });
      if (response?.data?.status === 'error') {
        const error = handleError(response?.data);
        throw new ServerError(error);
      }
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error?.getErrorMessage());
    }
  }
);
