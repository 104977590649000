import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AU_Loader,
  XB_Button,
  XB_Dropdown,
  XB_Input,
} from '@core-components/atoms';
import { XB_CountryDropdown } from '@core-components/molecules';
import { AU_RefErrorModal, AU_ScreenLayout } from '@core-components/templates';
import { appConstants, inputLength, PERMISSIONS, RegEx } from '@core-constants';
import { useAlertBox } from '@core-hooks';
import {
  AuthState,
  crmLeadGen,
  CustomerAccountsResponseState,
  leadGenMaster,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@core-stores';
import {
  checkPermission,
  getDropdownItemsList,
  getFormattedDate,
  maskEmailId,
  maskMobileNumber,
  validateEmail,
  validateInternationalMobile,
  validateMobileNumber,
} from '@core-utils';
import {
  CUSTOMER_TYPE,
  leadSource,
} from '@modules/loan/constants/loan-details.constants';
import { apply_now_urls } from '@src/core-navigations';
import { CIF } from '@src/mocks/deposit-opening.mock';

import {
  BANK_NAME,
  CITY,
  errorTypeOption,
  productId,
} from '../../constants/apply-now.constant';
import {
  getAuBankBranchList,
  getAuBankCityListLocker,
  getAuBankStateListLocker,
} from '../../index';
import {
  ApplyForLockerInterface,
  ResBranchItem,
} from '../../models/apply-now.model';

const ApplyForLocker: React.FC = () => {
  const { t } = useTranslation();
  const auAlert = useAlertBox();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showRefErrModal, setShowRefErrModal] = useState<{
    show?: boolean;
    refNo?: string;
  }>({
    show: false,
    refNo: '',
  });
  const [errorMsgs, setErrorMsgs] = useState<any>({});
  const [countryCode, setCountryCode] = useState('+91');
  const [removeData, setRemoveData] = useState<{
    name: boolean;
    email: boolean;
    mobno: boolean;
  }>({
    name: true,
    email: true,
    mobno: true,
  });
  const changeFormData = (key: string, val: string | undefined) => {
    setLockerState((prev) => {
      return { ...prev, [key]: val };
    });
  };
  const [leaddata, setLeadData] = useState<any>();
  const resAuthProfile = useAppSelector((state: RootState) => state?.auth) as
    | AuthState
    | undefined;

  const isNTB = useAppSelector((state: RootState) => state?.auDashboard?.isNTB);
  const customerAccounts = useAppSelector(
    (state: RootState) => state?.customerAccounts
  ) as CustomerAccountsResponseState | undefined;

  const permissions = useAppSelector(
    (state) => state?.profile?.data?.permissions
  );

  const customerAccountsData =
    customerAccounts?.data?.CustomerAccount?.[0]?.CustomerBasicInquiry;

  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [mobileNumberForApi, setMobileNumberForApi] = useState<string>(
    isNTB
      ? (resAuthProfile?.mobileNumber as string)
      : (customerAccountsData?.MobileNumber as string)
  );
  const [emailForApi, setEmailForApi] = useState<string>(
    isNTB
      ? (resAuthProfile?.emailAddress as string)
      : (customerAccountsData?.EmailAddress as string)
  );

  const originalMobileNumber = isNTB
    ? (resAuthProfile?.mobileNumber as string)
    : (customerAccountsData?.MobileNumber as string);

  const [loading, setLoading] = useState<{
    buttonLoading: boolean;
    pageLoading: boolean;
  }>({
    buttonLoading: false,
    pageLoading: false,
  });

  const [lockerState, setLockerState] = useState<ApplyForLockerInterface>({
    name: isNTB ? resAuthProfile?.name : customerAccountsData?.CustomerFullName,
    email: maskEmailId(
      isNTB
        ? (resAuthProfile?.emailAddress as string)
        : (customerAccountsData?.EmailAddress as string)
    ),
    mobNo: maskMobileNumber(
      isNTB
        ? (resAuthProfile?.mobileNumber as string)
        : (customerAccountsData?.MobileNumber as string)
    ),
    state: '',
    city: '',
    branch: '',
  } as ApplyForLockerInterface);

  const [addressListsMap, setAddressListsMap] = useState<Map<string, any[]>>(
    new Map()
  );

  const leadGenMasterList = async () => {
    setLoading({
      ...loading,
      pageLoading: true,
    });
    try {
      const masterData = await dispatch(leadGenMaster({ refreshCache: true }));
      if (masterData?.payload?.successfulResponse) {
        const loanType = masterData?.payload?.data?.find(
          (item) => item?.productId === productId?.applyLocker
        );
        setLeadData(loanType);
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
        auAlert.error(masterData?.payload as string);
      }
    } catch (err: any) {
      auAlert.error(err?.displayMessage);
      setButtonDisable(false);
    } finally {
      setLoading({
        ...loading,
        pageLoading: false,
      });
    }
  };

  const checkMobileUpdate = () => {
    return originalMobileNumber === mobileNumberForApi
      ? customerAccountsData?.PAN
      : '';
  };

  const handleCrmLeadApiCall = async () => {
    setLoading({
      ...loading,
      buttonLoading: true,
    });

    const formattedDOB = getFormattedDate(
      customerAccountsData?.BirthDateText ?? '',
      appConstants?.YYYYMMDD_WH,
      appConstants?.DDMMYYYY_WH
    );
    const reqObj = {
      LayoutId: leaddata?.productLayoutId,
      name: lockerState?.name,
      DateOfBirth: formattedDOB ?? '',
      PanNumber: checkMobileUpdate(),
      dob: formattedDOB,
      ProductCategoryID: leaddata?.productCategoryId,
      ProductID: leaddata?.productId,
      MobilePhone: mobileNumberForApi,
      StampCustName: lockerState?.name,
      country: customerAccountsData?.CustomerAddress?.Country ?? '',
      cif: CIF() as string,
      TypeofCustomerSA: isNTB ? CUSTOMER_TYPE?.NTB : CUSTOMER_TYPE?.ETB,
      CurrentState: lockerState.state,
      ShortName: customerAccountsData?.CustomerName?.ShortName,
      CurrentCity: lockerState.city,
      TerritoryID: lockerState.branchCode,
      CurrentAddressLine1: '',
      CurrentAddressLine2: '',
      CurrentAddressLine3: '',
      Email: emailForApi,
      LeadSource: leadSource,
      pinCode: lockerState.pinCode,
    };
    try {
      const crmLeadData = await dispatch(crmLeadGen(reqObj));
      if (crmLeadData?.payload?.successfulResponse) {
        navigate(apply_now_urls?.apply_for_locker_status, {
          state: {
            name: lockerState?.name?.trim(),
            email: lockerState?.email,
            phoneNo: lockerState?.mobNo,
            state: lockerState?.state,
            city: lockerState?.city,
            branch: lockerState?.branch,
            refNo: crmLeadData?.payload?.data?.LeadID,
          },
        });
      } else {
        setShowRefErrModal({
          ...showRefErrModal,
          show: true,
          refNo: crmLeadData?.payload?.requestId,
        });
      }
    } catch (error: any) {
      setShowRefErrModal({
        ...showRefErrModal,
        show: true,
        refNo: error?.requestId,
      });
    } finally {
      setLoading({
        ...loading,
        buttonLoading: false,
      });
    }
  };

  const msgTypeHandle = (value: string, keyname: string) => {
    let errorType: errorTypeOption = errorTypeOption?.BLANK;
    if (
      value !== '' &&
      errorMsgs?.[keyname]?.error !== '' &&
      errorMsgs?.[keyname]?.error !== undefined
    ) {
      errorType = errorTypeOption?.ERROR;
    } else {
      if (errorMsgs?.[keyname]) {
        const removeKey = (key, { [key]: _, ...rest }) => rest;
        setErrorMsgs((prev) => removeKey(keyname, prev));
      }
    }

    return errorType;
  };

  const showErrorMsg = (keyname: string) => {
    let errorMsg = t(errorMsgs?.[keyname]?.error);
    if (errorMsgs?.[keyname]?.errorOption) {
      const errorOptions = Object.keys(errorMsgs?.[keyname]?.errorOption);
      errorOptions?.forEach((errorToShow) => {
        errorMsg = errorMsg?.replace(
          '{{' + errorToShow + '}}',
          errorMsgs?.[keyname]?.errorOption[errorToShow]
        );
      });
    }
    return errorMsg ?? '';
  };

  useEffect(() => {
    leadGenMasterList();
    initAUBankStateListApi();
    if (lockerState?.name?.length) {
      setRemoveData({ ...removeData, name: true });
    }
    if (lockerState?.email?.length) {
      setRemoveData({ ...removeData, email: true });
    }
    if (lockerState?.mobNo?.length) {
      setRemoveData({ ...removeData, mobno: true });
    }
  }, []);

  useEffect(() => {
    if (lockerState?.state) {
      handleChangeCity('');
      handleChangeBranch('');
      initAUBankCityListApi(lockerState?.state);
    }
  }, [lockerState?.state]);

  useEffect(() => {
    if (lockerState?.city) {
      handleChangeBranch('');
      initAUBranchListByCityApi(lockerState?.city);
    }
  }, [lockerState?.city]);

  const initAUBankStateListApi = async () => {
    try {
      setLoading({
        ...loading,
        pageLoading: true,
      });
      const resStates = await getAuBankStateListLocker();
      if (resStates?.successfulResponse) {
        setAddressListsMap(
          (map) => new Map(map?.set('states', resStates?.data))
        );
      } else {
        auAlert.error(resStates?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    } finally {
      setLoading({
        ...loading,
        pageLoading: false,
      });
    }
  };

  const initAUBankCityListApi = async (state: string) => {
    try {
      setLoading({
        ...loading,
        pageLoading: true,
      });
      const resCities = await getAuBankCityListLocker(state);
      if (resCities?.successfulResponse) {
        setAddressListsMap(
          (map) => new Map(map?.set('cities', resCities.data))
        );
      } else {
        auAlert.error(resCities?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    } finally {
      setLoading({
        ...loading,
        pageLoading: false,
      });
    }
  };

  const initAUBranchListByCityApi = async (city: string) => {
    try {
      setLoading({
        ...loading,
        pageLoading: true,
      });
      const resBranches = await getAuBankBranchList({
        filterType: CITY,
        filterValue: city,
        bankName: BANK_NAME,
      });
      if (resBranches?.successfulResponse) {
        setAddressListsMap(
          (map) => new Map(map?.set('branches', resBranches?.data))
        );
      } else {
        auAlert.error(resBranches?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    } finally {
      setLoading({
        ...loading,
        pageLoading: false,
      });
    }
  };

  const handleInputChangeName = (val) => {
    changeFormData('name', val);
  };

  const handleInputChangeEmail = (val) => {
    const responseEmail = validateEmail(val);
    setErrorMsgs({ ...errorMsgs, email: responseEmail });
    setEmailForApi(val);
    changeFormData('email', val);
  };

  const handleChangeState = (val) => {
    changeFormData('state', val);
  };
  const handleChangeCity = (val) => {
    changeFormData('city', val);
  };
  const handleChangeBranch = (val) => {
    const branchList: ResBranchItem[] = addressListsMap?.get('branches') ?? [];
    const selectedBranch: ResBranchItem | undefined = branchList.find(
      (e) => e.branchCode === val
    );
    if (selectedBranch) {
      changeFormData('branch', selectedBranch?.branchName ?? '');
      changeFormData('branchCode', selectedBranch?.branchCode ?? '');
      changeFormData('pinCode', selectedBranch?.pinCode ?? '');
    }
  };

  const handleMobileNo = (val) => {
    const responseMobile = validateMobileNumber(val);
    const international = validateInternationalMobile(val);
    setErrorMsgs({
      ...errorMsgs,
      mobileNo: countryCode === '+91' ? responseMobile : international,
    });
    setMobileNumberForApi(val);
    changeFormData('mobNo', val);
  };

  const disableBookLockerBtn = (): boolean => {
    if (checkPermission(permissions, PERMISSIONS?.RBAC_CSR?.CRM_LEAD_GEN)) {
      return (
        !lockerState?.name ||
        !lockerState?.mobNo ||
        !lockerState?.email ||
        !lockerState?.state ||
        !lockerState?.city ||
        buttonDisable ||
        !lockerState?.branch ||
        Object.keys(errorMsgs).length > 0
      );
    } else {
      return true;
    }
  };

  const onDeleteName = (e) => {
    if ((e?.key === 'Backspace' || e?.key === 'Delete') && removeData?.name) {
      setTimeout(() => {
        changeFormData('name', '');
      }, 0);
      setRemoveData({ ...removeData, name: false });
    }
  };

  const onDeleteEmail = (e) => {
    if ((e?.key === 'Backspace' || e?.key === 'Delete') && removeData?.email) {
      setTimeout(() => {
        changeFormData('email', '');
      }, 0);
      setRemoveData({ ...removeData, email: false });
    }
  };

  const onDeleteMob = (e) => {
    if ((e?.key === 'Backspace' || e?.key === 'Delete') && removeData?.mobno) {
      setTimeout(() => {
        changeFormData('mobNo', '');
      }, 0);
      setRemoveData({ ...removeData, mobno: false });
    }
  };

  const tapoutPayeeName = () => {
    changeFormData('name', lockerState?.name?.trim());
  };

  const renderLeftContainer = () => {
    return (
      <div className="locker-main-container w-full">
        <h3 className="m-text-lg-bold text-gray-900">
          {t('fillUpTheBelowDetails')}
        </h3>
        <div className="input-container">
          <XB_Input
            id="NAME"
            type="text"
            label={t('name')}
            placeholder={t('enterName')}
            value={lockerState?.name}
            onInputChange={(val) => {
              handleInputChangeName(val?.replace('  ', ''));
            }}
            onKeyDown={(e) => {
              onDeleteName(e);
            }}
            regex={RegEx?.onlyAlpha}
            onBlur={() => tapoutPayeeName()}
            classes="max-w-xs mt-6"
          />
          <XB_Input
            id="MBLE-NMBR"
            classes="max-w-xs mt-6"
            label={t('mobileNumber')}
            placeholder={t('enterMobileNumber')}
            type="phone"
            msgType={msgTypeHandle(lockerState?.mobNo, 'mobileNo')}
            regex={RegEx?.onlyDigit}
            autoComplete="off"
            infoMsgText={showErrorMsg('mobileNo')}
            maxLength={
              countryCode === '+91'
                ? inputLength?.MOBILE_NUMBER_IND
                : inputLength?.MOBILE_NUMBER_OUTSIDE
            }
            value={lockerState?.mobNo}
            onInputChange={(val) => {
              handleMobileNo(val);
            }}
            onKeyDown={(e) => {
              onDeleteMob(e);
            }}
            prefixChild={
              <div className="flex">
                <XB_CountryDropdown
                  id="MRCT-CNTY-CODE"
                  onChange={(val) => {
                    setCountryCode(val);
                    changeFormData('mobNo', '');
                  }}
                />
                <span className="m-text-lg-regular code">{countryCode}</span>{' '}
              </div>
            }
          />
          <XB_Input
            type="text"
            id="EML"
            regex={RegEx?.email_valid_input}
            placeholder={t('enterEmailId')}
            label={t('emailId')}
            classes="max-w-xs mt-6"
            maxLength={inputLength?.EMAIL_MAX_LENGTH}
            msgType={msgTypeHandle(lockerState?.email, 'email')}
            value={lockerState?.email}
            infoMsgText={showErrorMsg('email')}
            onInputChange={(val) => handleInputChangeEmail(val)}
            onKeyDown={(e) => {
              onDeleteEmail(e);
            }}
          />
          <XB_Dropdown
            id="SLCT-STTE"
            items={getDropdownItemsList(
              'state',
              'state',
              addressListsMap?.get('states') ?? []
            )}
            placeholderOption={t('selectState')}
            label={t('state')}
            onChange={(val) => handleChangeState(val)}
            search={true}
            classes="max-w-xs mt-6"
          />
          <XB_Dropdown
            id="SLCT-CITY"
            items={getDropdownItemsList(
              'city',
              'city',
              addressListsMap?.get('cities') ?? []
            )}
            placeholderOption={t('selectCity')}
            label={t('city')}
            onChange={(val) => handleChangeCity(val)}
            search={true}
            classes="max-w-xs mt-6"
            disabled={lockerState?.state === ''}
          />
          <XB_Dropdown
            id="SLCT-BNCH"
            items={getDropdownItemsList(
              'branchCode',
              'branchName',
              addressListsMap?.get('branches') ?? []
            )}
            placeholderOption={t('selectBranch')}
            label={t('branch')}
            onChange={(val) => handleChangeBranch(val)}
            search={true}
            classes="max-w-xs mt-6"
            disabled={lockerState?.city === ''}
          />
          <XB_Button
            dataTestId="SBMT"
            btnType="primary"
            disabled={disableBookLockerBtn()}
            classes="w-[12.5rem] debit-card-continue-btn mt-6"
            onClick={() => {
              if (
                checkPermission(
                  permissions,
                  PERMISSIONS?.RBAC_CSR?.CRM_LEAD_GEN
                )
              ) {
                handleCrmLeadApiCall();
              }
            }}
            showLoader={loading?.buttonLoading}
          >
            {t('bookLocker')}
          </XB_Button>
        </div>
      </div>
    );
  };
  return (
    <div className="apply-for-locker-container">
      {loading?.pageLoading && <AU_Loader />}
      {showRefErrModal?.show && (
        <AU_RefErrorModal
          isOpen={showRefErrModal?.show}
          setOpen={(val) =>
            setShowRefErrModal({ ...showRefErrModal, show: val })
          }
          refNo={showRefErrModal?.refNo}
          rightBtnProps={{
            dataTestId: 'TRY-AGIN',
            children: 'tryAgain',
            onClick: () => {
              setShowRefErrModal({ ...showRefErrModal, show: false });
              handleCrmLeadApiCall();
            },
          }}
          leftBtnProps={{
            dataTestId: 'CNCL',
            children: 'cancel',
            onClick: () => {
              setShowRefErrModal({ ...showRefErrModal, show: false });
            },
          }}
        />
      )}
      <AU_ScreenLayout
        isShowBackBtn={true}
        leftContainer={renderLeftContainer()}
        rightContainer={<></>}
        leftContainerClasses="bg-white p-4"
        pageHeading={t('applyForLocker')}
      />
    </div>
  );
};

export default ApplyForLocker;
