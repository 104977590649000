import { EndPoints, Service } from '@core-services';

export const validateKarza = async (email, reqToken) => {
  const reqObj = {
    baseurl: EndPoints.userMgmtService.email(email).karzaValidate,
    method: 'POST',
    url: '',
    reqToken,
  };
  const resp = await Service.send(reqObj);
  return resp.data;
};
