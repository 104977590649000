import { createSlice } from '@reduxjs/toolkit';

interface IAuDedupeLite {
  customerFullName: string;
}

interface IAuDedupeLiteSlice {
  dedupeLite: IAuDedupeLite | undefined;
}
const initialState: IAuDedupeLiteSlice = {
  dedupeLite: undefined,
};

const auDedupeLiteSlice = createSlice({
  name: 'auDedupeLiteSlice',
  initialState,
  reducers: {
    setDedupeLite(state, { payload }) {
      state.dedupeLite = payload;
    },
  },
});

export const { setDedupeLite } = auDedupeLiteSlice.actions;

export default auDedupeLiteSlice.reducer;
