import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  XB_Button,
  XB_Checkbox,
  XB_Chip,
  XB_Divider,
  XB_Input,
} from '@core-components/atoms';

import './AU_Multiselect.style.scss';

interface AUMultiselectProps {
  id: string;
  placeholderOption?: string;
  selectedValue?: string[] | string;
  items: Array<{
    text?: string;
    value: string;
  }>;
  onChange?: (val) => void;
  selectAllFn?: (_val) => void;
  clearAllFn?: () => void;
}

export const AU_Multiselect = ({
  id,
  items,
  selectedValue = '',
  placeholderOption,
  onChange,
  selectAllFn,
  clearAllFn,
}: AUMultiselectProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [itemsList, setItemsList] = useState<
    Array<{
      text?: string;
      value: string;
    }>
  >(items);
  const [value, setValue] = useState<string[]>(
    Array.isArray(selectedValue) ? [...selectedValue] : [selectedValue]
  );
  const [selectedItems, setSelectedItems] = useState<
    Array<{
      text?: string;
      value: string;
      icon?: ReactNode;
      secondaryText?: string;
    }>
  >([]);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (items.length > 0) setItemsList(items);
    else setError(t(`noItemsFound`));
  }, [items]);

  useEffect(() => {
    const selectedOptions = itemsList.filter((item) =>
      value.includes(item.value)
    );
    setSelectedItems(selectedOptions);
  }, [value]);

  const onSearch = (val) => {
    if (val.length >= 1) {
      const searchedList = items.filter(
        (i) =>
          i.value.toLowerCase().includes(val.toLowerCase()) ||
          i.text?.toLowerCase().includes(val.toLowerCase())
      );
      setItemsList(searchedList);
      if (searchedList.length === 0) {
        setError(t(`noItemsFound`));
      } else {
        setError('');
      }
    } else {
      setItemsList(items);
    }
    if (val.length === 0) {
      setError('');
    }
  };

  const onOptionClicked = useCallback(
    (optionValue) => {
      let selectionValue: string[] = [];
      const indexOfValue = value.indexOf(optionValue);
      selectionValue =
        indexOfValue > -1
          ? [
              ...value.filter((item) => {
                return item !== optionValue;
              }),
            ]
          : [...value, optionValue];
      setValue(selectionValue);
      onChange?.(selectionValue.join(','));
    },
    [value, setValue, onChange]
  );

  const clearAllHandle = () => {
    setSelectedItems([]);
    setValue([]);
    clearAllFn?.();
  };

  const selectAllHandle = () => {
    setSelectedItems(items);
    setValue(items.map((el) => el.value));
    selectAllFn?.(items.map((el) => el.value).join(','));
  };

  const selectedDropdownValue = () => {
    return (
      <div className="multiselect-dropdown-tags">
        {selectedItems.map((option) => (
          <XB_Chip
            key={option.value}
            classes="dropdown-tag-item"
            rightIcon="close"
            onClick={() => {
              onOptionClicked(option.value);
            }}
          >
            {option?.text}
          </XB_Chip>
        ))}
      </div>
    );
  };

  return (
    <div className="au_multiselect">
      <div className="multiselect__wrapper">
        <XB_Input
          id={`${id}-search`}
          value={searchValue}
          placeholder={placeholderOption}
          onChange={(e) => {
            setSearchValue(e.target.value);
            return onSearch(e.target.value);
          }}
          prefixIcon="search"
          suffixIcon={searchValue !== '' ? 'close' : ''}
          autoComplete="false"
          onIconClick={() => {
            setSearchValue('');
            return onSearch('');
          }}
        />
        {selectAllFn && (
          <>
            <div className="selectall-container flex justify-between px-2 mt-3 ">
              <XB_Checkbox
                key="select-all"
                id="select-all"
                label={t('selectAll')}
                classes="m-text-lg-regular text-gray-900 multiselect__item"
                checked={
                  Array.isArray(selectedItems)
                    ? selectedItems.length === items.length
                    : items.length === 1
                }
                onClick={selectAllHandle}
              />
              <XB_Button
                dataTestId="clear-all"
                btnType="tertiary-gray"
                onClick={clearAllHandle}
              >
                {t('clearAll')}
              </XB_Button>
            </div>
            <XB_Divider />
          </>
        )}
        <div className="selected-ddn-value">{selectedDropdownValue()}</div>
        <div className="multiselect-list" role="listbox">
          {itemsList.map((item, idx) => {
            return (
              <div
                key={`${id}-multiselect-${idx}`}
                className="multiselect__item"
              >
                <XB_Checkbox
                  key={`${id}-multiselect-${idx}`}
                  id={`${id}-multiselect-${idx}`}
                  label={item.text}
                  classes="m-text-lg-regular text-gray-900 multiselect__item"
                  checked={value.includes(item.value)}
                  onClick={(_event) => {
                    onOptionClicked(item.value);
                  }}
                />
              </div>
            );
          })}
        </div>
        {error && (
          <div className="mt-3 text-gray-300 m-text-lg-regular">{error}</div>
        )}
      </div>
    </div>
  );
};
