export const CIFSelectionType = {
  CameraAndDebit: 'CameraAndDebit',
  CameraOnly: 'CameraOnly',
  NoCameraOnlyDebit: 'NoCameraOnlyDebit',
  SingleDebitCard: 'SingleDebitCard',
  MultipleDebitCards: 'MultipleDebitCards',
  NoCameraNoDebit: 'NoCameraNoDevit',
  MPinNoCameraNoDebit: 'MPinNoCameraNoDebit',
  ETBSolePropSelection: 'ETBSolePropSelection',
  MobileRegisteredSoleProp: 'MobileRegisteredSoleProp',
  CIFSelection: 'CIFSelection',
};

export const customerTypes = {
  INDIVIDUAL: 'IND',
  SOLEPROP: 'SOLPROP',
  IndividualKeys: [
    'IND',
    '100',
    '102',
    '103',
    '105',
    '108',
    '129',
    '130',
    '157',
    '158',
    '161',
    '301',
    '302',
    '502',
    '503',
    '504',
    '505',
  ],
  SolePropKeys: '111',
  SolePropTypes: ['111', 'SOLPROP'],
};
export const BlockStatus = {
  N: 'N',
  Y: 'Y',
};
