import { OTPEventType, OTPJourneyType } from '@core-constants';
import { EndPoints, Service } from '@core-services';

import { OTPSend, OTPValidate } from './models/otp.model';

export const sendOtp = async (
  otpObject: OTPSend,
  otpJourney = OTPJourneyType.PRE_REGISTRATION
) => {
  if (!Object.hasOwn(otpObject, 'eventType')) {
    otpObject.eventType = OTPEventType.PRE_REGISTRATION_APP_OTP_SMS;
  }
  const reqObj = {
    baseurl:
      Object.hasOwn(otpObject, 'customerId') ||
      Object.hasOwn(otpObject, 'emailAddress')
        ? EndPoints.notificationService.sendOTPOnEmail
        : EndPoints.notificationService.sendOTP,
    method: 'POST',
    url: '',
    obj: otpObject,
  };
  const response = await Service.send(reqObj, { otpJourney });
  return response.data;
};

export const validateOtp = async (
  otpObject: OTPValidate,
  otpJourney = OTPJourneyType.PRE_REGISTRATION
) => {
  const reqObj = {
    baseurl: EndPoints.notificationService.validateOTP,
    method: 'POST',
    url: '',
    obj: otpObject,
  };
  const response = await Service.send(reqObj, { otpJourney });
  response.data.statusCode = response.status;
  return response.data;
};
