export const userGroup = {
  SO_USER: 'Inventory SO',
  BM_USER: 'Inventory BM',
  ADMIN_USER: 'Inventory ADMIN',
};

export const tableData = {
  startPage: 0,
  tableSize: 10000,
};
