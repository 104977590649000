import { createSlice } from '@reduxjs/toolkit';

import { RedirectViewReportPropType } from './../../models/so-inventory.model';

const initialState: RedirectViewReportPropType = {
  kitType: undefined,
  employeeName: undefined,
  employeeCode: undefined,
  pastAllocatedDetail: undefined,
  isFromDashboard: undefined,
};
const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    selectedStatementAccount(state, { payload }) {
      state.employeeName = payload.employeeName;
      state.employeeCode = payload.employeeCode;
      state.kitType = payload.KitType;
    },
    clearSelectedStatementAccount(state) {
      state.employeeName = undefined;
      state.employeeCode = undefined;
      state.kitType = undefined;
    },
    setPastAllocatedData(state, { payload }) {
      state.pastAllocatedDetail = payload;
    },
    setFromDashboardPage(state, { payload }) {
      state.isFromDashboard = payload;
    },
  },
});

export const {
  selectedStatementAccount,
  clearSelectedStatementAccount,
  setPastAllocatedData,
  setFromDashboardPage,
} = inventorySlice.actions;
export default inventorySlice.reducer;
