import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { XB_Button } from '@core-components/atoms';
import { appConstants } from '@core-constants';

import { Step, XB_Step } from './XB_Step.component';

import './XB_Stepper.styles.scss';

interface StepperProps {
  steps: Step[];
  stepComponent: any;
  activeComponent: string;
  isReadOnly?: boolean;
  onStepChanged?: (stepId: string) => void;
  onClose: () => void;
}

export const XB_Stepper = ({
  steps,
  stepComponent,
  activeComponent,
  isReadOnly = false,
  onStepChanged,
  onClose,
}: StepperProps) => {
  const { t } = useTranslation();
  const [stepMap, setStepMap] = useState({});
  const [activeStep, setActiveStep] = useState('');
  const [changedStep, setChangedStep] = useState('');
  const toggleStepExpand = (stepId: string) => {
    if (window.innerWidth < appConstants.BREAKPOINTS.MOBILE) {
      if (stepId !== activeStep) {
        onStepChanged?.(stepMap[stepId].subStep[0].stepId);
        setChangedStep(stepId);
      }
    } else {
      const map = { ...stepMap };
      Object.keys(map).forEach((key) => {
        if (key === stepId) {
          map[key].expanded = !map[key].expanded;
        } else {
          map[key].expanded = false;
        }
      });
      setStepMap(map);
    }
  };

  const reduceSubsteps = (subSteps) => {
    let isStepError = false;
    return subSteps.reduce(
      (acc, subStep) => {
        if (subStep.stepStatus === 'done') {
          acc.completed++;
        }
        if (subStep.stepStatus === 'error') {
          isStepError = true;
        }
        if (isStepError) {
          acc.status = 'error';
        } else {
          if (acc.completed === subSteps.length) {
            acc.status = 'done';
          } else {
            acc.status = 'active';
          }
        }

        return { ...acc };
      },
      { completed: 0, status: 'todo' }
    );
  };

  useEffect(() => {
    let activeStepId = '';
    if (isReadOnly) {
      const map = steps.reduce((stepObj, step) => {
        const stepStatus = step.subStep.reduce(
          (acc, subStep) => {
            let { completed, status } = acc;
            if (subStep.stepStatus === 'active') {
              status = 'active';
            }
            completed++;
            return { completed, status };
          },
          { completed: 0, status: 'done' }
        );
        if (stepStatus.status === 'active') {
          activeStepId = step.stepId;
        }
        return {
          ...stepObj,
          [step.stepId]: {
            ...step,
            completed: stepStatus.completed,
            status: stepStatus.status,
            expanded: false,
          },
        };
      }, {});
      activeStepId = changedStep || activeStepId;
      if (activeStepId) {
        map[activeStepId].expanded = true;
        setChangedStep('');
        setActiveStep(activeStepId);
        setStepMap(map);
      }
    } else {
      let isActiveStep = false;
      let isExpandedStep = !Object.keys(stepMap).length;
      const map = steps.reduce((stepObj, step) => {
        const stepStatus = reduceSubsteps(step.subStep);
        if (!isExpandedStep && stepMap[step.stepId]?.expanded) {
          isExpandedStep = true;
        }
        if (
          !isActiveStep &&
          isExpandedStep &&
          (stepStatus.status === 'active' || stepStatus.status === 'error')
        ) {
          isActiveStep = true;
          activeStepId = step.stepId;
        }
        return {
          ...stepObj,
          [step.stepId]: {
            ...step,
            completed: stepStatus.completed,
            status: stepStatus.status,
            expanded: false,
          },
        };
      }, {});
      activeStepId = changedStep || activeStepId;
      if (activeStepId) {
        map[activeStepId].expanded = true;
        setChangedStep('');
        setActiveStep(activeStepId);
        setStepMap(map);
      }
    }
  }, [steps]);

  return (
    <div className="stepper flex flex-col sm:flex-row sm:gap-4 sm:p-6 md:py-4 h-full">
      <div className="stepper__nav relative sm:mb-0 sm:overflow-y-auto flex sm:flex-col justify-between">
        <div className="stepper__steps-container flex flex-row sm:flex-col mt-2 mb-8 gap-6 sm:gap-0 sm:ml-0 justify-evenly w-full">
          {Object.keys(stepMap).map((key) => {
            const step = stepMap[key];
            return (
              <XB_Step
                step={step}
                key={key}
                onStepChanged={(stepId, subStepId) => {
                  onStepChanged?.(subStepId);
                  setChangedStep(stepId);
                }}
                status={step.status}
                completedSteps={step.completed}
                expanded={step.expanded}
                onToggle={toggleStepExpand}
              />
            );
          })}
        </div>
        <div className="sm:py-4 stepper__close mb-8 mt-2 sm:my-0 self-stretch">
          <XB_Button
            dataTestId="CLSE"
            btnType="secondary-gray"
            classes="w-full"
            onClick={onClose}
            iconName="close"
            iconPos="right"
          >
            {t('close')}
          </XB_Button>
        </div>
      </div>
      <div className="stepper__content bg-white rounded-xl flex flex-col grow">
        {activeComponent && stepComponent[activeComponent]}
      </div>
    </div>
  );
};
