import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { XB_Button } from '@core-components/atoms';

import errorImage from '@src/assets/images/404-error-with-animal.png';
import logo from '@src/assets/images/AuLogo.png';

function PageNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleContactSupport = () => {
    window?.open(t('contactSupportUrl'), '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <div className="h-20 px-2 pl-6 pr-2 bg-gradient-to-r rounded-b-xl from-[#9A48A9] to-[#4D2454]">
        <img className="pt-2 h-3/4" src={logo} alt="au-logo" />
      </div>
      <div className="flex flex-col sm:flex-row-reverse items-center sm:items-start h-48 pl-2 pt-10 sm:pt-20 md:pt-44  sm:pl-10   md:gap-x-64 md:pl-[120px] text-[0.50rem] sm:text-sm lg:text-base gap-x-10">
        <div className=" h-72 lg:h-96  md:h-80">
          <img className="h-full" src={errorImage} alt="" />
        </div>
        <div className="w-4/5 sm:w-2/5 flex flex-col gap-5">
          <div>
            <p className="text-neutrals-900 font-black">
              {t('pageIsTakingBreakDesc')}
            </p>
          </div>
          <div className="gap-2 flex flex-col">
            <p className="text-gray-700 m-text-lg-regular">
              {t('weCantFindPageDesc')}
            </p>
            <p className="text-gray-700 m-text-lg-regular">
              {t('hereWhatYouCanDoNext')}
            </p>
            <ul className="list-disc mt-2 text-gray-700 m-text-lg-regular">
              <li className="mt-2">{t('backToThePreviousPageDesc')}</li>
              <li className="mt-2">{t('visitOurLoginPageToFindYourWay')} </li>
              <li className="mt-2">
                {t('needImmediateAssistance')}{' '}
                <span
                  className="text-[#9A48A9] cursor-pointer underline"
                  onClick={handleContactSupport}
                >
                  {t('Contact Us')}
                </span>
              </li>
            </ul>
          </div>
          <div>
            <XB_Button
              dataTestId="GO-BACK-TO-LGN"
              btnType="primary"
              onClick={() => navigate('/auth/login')}
            >
              {t('goBackToLoginScreen')}
            </XB_Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default PageNotFound;
