import { useTranslation } from 'react-i18next';

import { XB_Button } from '../button/XB_Button.component';
import { Icon } from '../icon/XB_Icon.component';

interface SectionInfoProps {
  title?: string;
  description?: string;
  classes?: string;
  prefixIconName?: string;
  surfixIconName?: string;
  isExpended?: boolean | true;
  iconClass?: string;
}

export const XB_SectionInfo = ({
  title,
  description,
  prefixIconName,
  surfixIconName,
  isExpended,
  iconClass,
}: SectionInfoProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="section-mobile-left">
        {title && (
          <div>
            <h1 className="class flex align-middle m-text-lg-bold text-gray-900">
              <span className="flex items-center justify-center">
                {prefixIconName && (
                  <span className={iconClass}>
                    <Icon
                      icon={prefixIconName}
                      width={20}
                      height={20}
                      className="mr-1"
                    />
                  </span>
                )}
              </span>
              {`${t(title)}`}
            </h1>
          </div>
        )}
        {description && (
          <p className="text-gray-600 m-text-md-regular mb-1 mt-1">
            {t(description)}
          </p>
        )}
      </div>
      <div className="items-center justify-content-center mobile-dropdown-div">
        <span className="items-center justify-center mobile-dropdown-span">
          {surfixIconName && (
            <XB_Button
              dataTestId="COLL-PAY"
              backgroundColor="white"
              size="sm"
              classes={isExpended ? 'active-drop-icon' : ' inactive-drop-icon'}
            >
              <Icon
                icon={surfixIconName}
                width={20}
                height={20}
                className="mr-1 drop-icon"
              />
            </XB_Button>
          )}
        </span>
      </div>
    </>
  );
};
