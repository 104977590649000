import { createSlice } from '@reduxjs/toolkit';

import {
  AmountCreditAccount,
  ClosureFormData,
  PenaltyCreditAccount,
  ResponseTdEnquiry,
} from '../../models/response-deposit.model';

const initialState = {
  penaltyCreditAccount: {} as PenaltyCreditAccount,
  paritalClosureTDEnquireData: {} as ResponseTdEnquiry,
  amountCreditAccount: {} as AmountCreditAccount,
  tdEnquiryData: {} as ResponseTdEnquiry,
  closureFormData: {} as ClosureFormData,
};

const tdEnquirySlice = createSlice({
  name: 'tdEnquiry',
  initialState,
  reducers: {
    setTdEnquiry(state, { payload }) {
      state.penaltyCreditAccount = payload;
    },
    setParitalClosureTDEnquireData(state, { payload }) {
      state.paritalClosureTDEnquireData = payload;
    },
    setAmountCreditAccount(state, { payload }) {
      state.amountCreditAccount = payload;
    },
    setTdEnquiryData(state, { payload }) {
      state.tdEnquiryData = payload;
    },
    setClosureFormData(state, { payload }) {
      state.closureFormData = payload;
    },
  },
});

export const {
  setTdEnquiry,
  setParitalClosureTDEnquireData,
  setAmountCreditAccount,
  setTdEnquiryData,
  setClosureFormData,
} = tdEnquirySlice.actions;
export default tdEnquirySlice.reducer;
