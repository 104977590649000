import { EndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const validateResumeJourney = createAsyncThunk(
  'api/onboarding-service/resume/journey/v1/validate-and-get-details',
  async (
    data: { mobileNumber: string; cifNumber?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: EndPoints.onboardingService.validateResumeV1,
        obj: data,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        !response.data.successfulResponse
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        err: error.getErrorMessage(),
        server: error.getServerMessage(),
        code: error.getErrorCode(),
      });
    }
  }
);
