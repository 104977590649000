export const bm_breadcrumb = [
  { url: '', name: 'Home', id: 1, parents: [] },

  {
    url: '/bm-dashboard',
    name: 'Inventory',
    parents: [1],
    id: 2,
  },
  {
    url: '/bm-raise-request',
    name: 'Raise Request',
    parents: [2, 1],
    id: 3,
  },
  {
    url: '/view-report',
    name: 'View Report',
    parents: [2, 1],
    id: 4,
  },
  {
    url: '/bm-allocation',
    name: 'Allocate',
    parents: [2, 1],
    id: 6,
  },
  {
    url: '/allocation-history',
    name: 'Allocation History',
    parents: [2, 1],
    id: 7,
  },
  {
    url: '/full-report',
    name: 'Inventory Report',
    parents: [2, 1],
    id: 8,
  },
  {
    url: '/bm-request-history',
    name: 'Request History',
    parents: [2, 1],
    id: 9,
  },
  {
    url: '/bm-past-allotment',
    name: 'Past Allotment',
    parents: [2, 1],
    id: 10,
  },
  {
    url: '/bm-past-allotment-detail',
    name: 'Past Allotment',
    parents: [10, 2, 1],
    id: 11,
  },
];
