import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AU_Linkcard } from '@core-components/molecules';
import { AU_ScreenLayout } from '@core-components/templates';
import { PERMISSIONS, redirectionUTM } from '@core-constants';
import { apply_now_urls } from '@core-navigations';
import { RootState, useAppSelector } from '@core-stores';
import { checkPermission, getConcatenatedString } from '@core-utils';

const ApplyNowDashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const permissions = useAppSelector(
    (state) => state?.profile?.data?.permissions
  );
  const { isNTB } = useAppSelector((state: RootState) => state?.auDashboard);

  const applyNowTypes = [
    {
      title: t('businessBanking'),
      path: apply_now_urls?.business_banking,
      vcIcon: 'briefcase',
      isURL: false,
      description: t('startYourSuccessJourney'),
      rbac: checkPermission(permissions, PERMISSIONS?.RBAC_CSR?.CRM_LEAD_GEN),
    },
    {
      title: t('locker'),
      path: apply_now_urls?.apply_for_locker,
      vcIcon: 'locker',
      isURL: false,
      description: t('secureYourValuableToday'),
      rbac: checkPermission(permissions, PERMISSIONS?.RBAC_CSR?.CRM_LEAD_GEN),
    },
    {
      title: t('currentAccount'),
      path: isNTB
        ? t('redirectionUTMCA').concat(
            redirectionUTM.CAMPAIGN.dashboardApplyNow
          )
        : t('ETB_CA_URL'),
      vcIcon: 'bank-outline',
      isURL: true,
      description: t('simplifyYourTransactions'),
      rbac: true,
    },
    {
      title: t('savingAccount'),
      path: t('redirectionUTMSA').concat(
        redirectionUTM.CAMPAIGN.dashboardApplyNow
      ),
      vcIcon: 'saving-account',
      isURL: true,
      description: t('secureYourFinancialFuture'),
      rbac: true,
    },
    // {
    //   title: t('fastag'),
    //   path: '',
    //   vcIcon: 'fastag',
    //   isURL: false,
    //   description: t('travelSmarterFaster'),
    //   rbac: true,
    // },
    {
      title: t('businessLoan'),
      path: t('UBLUrl'),
      vcIcon: 'business-loan-apply-now',
      isURL: true,
      description: t('fuelYourBusinessGrowth'),
      rbac: true,
    },
  ];

  const redirectOrNavigate = (args1, args3) => {
    if (args3) {
      window?.open(args1, '_blank', 'noopener,noreferrer');
    } else {
      navigate(args1 ?? '');
    }
  };

  const cardData = (...args) => {
    return {
      title: args[0],
      suffixBtnIcon: true,
      suffixBtnpath: () => redirectOrNavigate(args[1], args[3]),
      titleClass: 'm-text-lg-medium text-gray-800',
      prefixIcon: args[2],
      description: args[4],
      disabled: !args[5],
    };
  };

  const renderLeftContainer = () => {
    return (
      <div className="w-full grid gap-3 grid-cols-2">
        {applyNowTypes?.map((item) => {
          return (
            <div key={item?.title}>
              <AU_Linkcard
                key={getConcatenatedString([item?.title])}
                data={cardData(
                  item?.title,
                  item?.path,
                  item?.vcIcon,
                  item?.isURL,
                  item?.description,
                  item?.rbac
                )}
                isRoundBox={true}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="service-request-dashboard-screen">
      <AU_ScreenLayout
        isShowBackBtn={false}
        pageHeading={t('applyNow')}
        leftContainer={renderLeftContainer()}
        leftContainerClasses="p-4 bg-white rounded-xl"
        rightContainer={<></>}
      />
    </div>
  );
};
export default ApplyNowDashboard;
