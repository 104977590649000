export enum OTPJourneyType {
  PRE_REGISTRATION = 'PRE_REGISTRATION',
  POST_REGISTRATION = 'POST_REGISTRATION',
  ONBOARDING = 'ONBOARDING',
  DEPOSIT = 'DEPOSIT',
  PAYEE = 'PAYEE',
  PAYMENT = 'PAYMENT',
  LINK_PAYEE = 'LINK_PAYEE',
  DEBIT_CARD = 'DEBIT_CARD',
  SERVICE_REQUEST = 'SERVICE_REQUEST',
  PROFILE = 'PROFILE',
  POST_LOGIN = 'POST_LOGIN',
}

export enum OTPEventType {
  PRE_REGISTRATION_APP_OTP_SMS = 'PRE_REGISTRATION_APP_OTP_SMS',
  FD_OPEN = 'FD_OPEN',
  FD_FULL_CLOSE = 'FD_FULL_CLOSE',
  FD_PARTIAL_CLOSE = 'FD_PARTIAL_CLOSE',
  RD_OPEN = 'RD_OPEN',
  RD_CLOSE = 'RD_CLOSE',
  TSD_OPEN = 'TSD_OPEN',
  NOMINEE_ADD = 'NOMINEE_ADD',
  PAYEE_ADD = 'PAYEE_ADD',
  PAYEE_UPDATE = 'PAYEE_UPDATE',
  PAYEE_DEACTIVATE = 'PAYEE_DEACTIVATE',
  IFT_PAYMENT = 'IFT_PAYMENT',
  SELF_PAYMENT = 'SELF_PAYMENT',
  IMPS_PAYMENT = 'IMPS_PAYMENT',
  NEFT_PAYMENT = 'NEFT_PAYMENT',
  RTGS_PAYMENT = 'RTGS_PAYMENT',
  TAX_PAYMENT = 'TAX_PAYMENT',
  SCHEDULED_PAYMENT = 'SCHEDULED_PAYMENT',
  MULTIPLE_PAYMENT = 'MULTIPLE_PAYMENT',
  CANCEL_SCHEDULED_PAYMENT = 'CANCEL_SCHEDULED_PAYMENT',
  CANCEL_SCHEDULED_PAYMENT_IN_SERIES = 'CANCEL_SCHEDULED_PAYMENT_IN_SERIES',
  SET_ALLOWANCE_OTP = 'SET_ALLOWANCE_OTP',
  WITHDRAW_ALLOWANCE_OTP = 'WITHDRAW_ALLOWANCE_OTP',
  REVOKE_ALLOWANCE = 'REVOKE_ALLOWANCE',
  CANCEL_ALLOWANCE = 'CANCEL_ALLOWANCE',
  VIEW_DEBIT_CARD_DETAILS = 'VIEW_DEBIT_CARD_DETAILS',
  SET_CARD_PIN = 'SET_CARD_PIN',
  RESET_CARD_PIN = 'RESET_CARD_PIN',
  CARD_LIMIT_SET = 'CARD_LIMIT_SET',
  BLOCK_DEBIT_CARD = 'BLOCK_DEBIT_CARD',
  UNBLOCK_DEBIT_CARD = 'UNBLOCK_DEBIT_CARD',
  STOP_CHEQUE = 'STOP_CHEQUE',
  RAISE_FRAUD = 'RAISE_FRAUD',
  UPDATE_COMM_ADDRESS = 'UPDATE_COMM_ADDRESS',
  UPDATE_EMAIL = 'UPDATE_EMAIL',
  CKYC_VIEW = 'CKYC_VIEW',
  DEVICE_BLOCK = 'DEVICE_BLOCK',
  DEVICE_UNBLOCK = 'DEVICE_UNBLOCK',
  DEACTIVATE_DEVICE = 'DEACTIVATE_DEVICE',
  LOAN_REPAYMENT = 'LOAN_REPAYMENT',
  VIEW_CVV_DEBIT_CARD = 'VIEW_CVV_DEBIT_CARD',
  REISSUE_DEBIT_CARD = 'REISSUE_DEBIT_CARD',
  LOGIN_VERIFICATION_OTP = 'LOGIN_VERIFICATION_OTP',
}
