import React from 'react';

import CryptoJS from 'crypto-js';

import {
  ACCOUNT_STATUS_CODES,
  defaultContryData,
  Items,
  RegEx,
  STATUS_MATCH_KEYS,
} from '@core-constants';
import { CustomerAccountDetails, CustomerAccountsData } from '@core-stores';
import { ACCOUNT_STATUS_ORDER_MAP } from '@modules/account-services/constants/account-services.constant';
import { AccountSummaryDetails } from '@modules/account-services/models/response-account-services.model';

import countryData from '../core-constants/country-list-constants.json';
import { isEmpty } from './obj-util';

export const trimVal = (val: string) => {
  if (val) {
    return val.trim();
  }
  return '';
};

/**
 * @author Sunil Jangid - 323361
 * concanate the address
 * @param delimeter string
 * @param add1 string
 * @param add2 string
 * @param add3 string
 * @param city string
 * @param state string
 * @param pincode string
 * @returns string
 */
export const getConcanetedAddress = (
  add1: string,
  add2: string,
  add3?: string,
  city?: string,
  state?: string,
  pincode?: string,
  delimeter?: string
) => {
  if (!delimeter) {
    delimeter = ',';
  }

  const addressLine = (add) => {
    let str = '';
    if (add) {
      str = `${delimeter as string} ${add as string}`;
    }
    return str;
  };

  const handleSingleValue = (val) => {
    let str = '';
    if (val) {
      str = ` - ${val as string}`;
    }
    return str;
  };

  return `${add1}${addressLine(add2)}${addressLine(add3)}${addressLine(
    city
  )}${handleSingleValue(state)}${handleSingleValue(pincode)}`;
};

export const getFirstLastChar = (input) => {
  const fullName = input || '';
  const words = fullName.split(' ');
  const len = words.length;
  const firstChar = words[0].charAt(0).toUpperCase();
  let lastChar = words[0].charAt(1).toUpperCase();
  if (len > 1) {
    lastChar = words[len - 1].charAt(0).toUpperCase();
  }
  return firstChar.concat(lastChar);
};

const sortVal = (value) => {
  return value.sort((a: any, b: any) => {
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    } else {
      return a.localeCompare(b);
    }
  });
};

/**
 * @author Sunil Jangid - 323361
 * @description get country config by code, dial_code e: input = 91 or IN will return India config from country list
 * @param input
 * @returns number
 */
export const getCountryByCodeOrName = (input) => {
  input = input || '';
  const data = countryData.find(
    (item) =>
      item.code.toLocaleUpperCase() === input.toLocaleUpperCase() ||
      item.dial_code.toLocaleUpperCase() === input.toLocaleUpperCase()
  );
  return data ?? defaultContryData;
};

/**
 * @description replace placeholder with passed values ex: relaceValueWithPlaceHolder(my name is $name$ and age is $age$, "sj", 1) result my name is sj and age is 1
 * @param input
 * @param args
 * @returns string
 */
export const relaceValueWithPlaceHolder = (input: string, ...args: any[]) => {
  input = input || '';
  const placeHolders = input.match(RegEx.placeHolderRegex);
  args?.forEach((item, index) => {
    if (placeHolders?.[index]) {
      input = input.replace(placeHolders?.[index], item);
    }
  });
  return input;
};

function checkBoolean(first, second): boolean {
  if (typeof first === 'boolean' && typeof second === 'boolean') {
    return first === second;
  }
  return false;
}

function checkNumber(first, second): boolean {
  if (typeof first === 'number' && typeof second === 'number') {
    return first === second;
  }
  return false;
}

function checkString(isCaseSensetiveCheck, first, second): boolean {
  if (typeof first === 'string' && typeof second === 'string') {
    return isCaseSensetiveCheck
      ? first === second
      : first.toLocaleLowerCase() === second.toLocaleLowerCase();
  }
  return false;
}

function checkObject(first, second): boolean {
  if (typeof first === 'object' && typeof second === 'object') {
    if (first === second) {
      return true;
    }
    if (Array.isArray(first) && Array.isArray(second)) {
      return JSON.stringify(sortVal(first)) === JSON.stringify(sortVal(second));
    }
    if (Object.keys(first).length === Object.keys(second).length) {
      const sortedFirst = Object.keys(first)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = first[key];

          return accumulator;
        }, {});

      const sortedSecond = Object.keys(first)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = second[key];

          return accumulator;
        }, {});
      return JSON.stringify(sortedFirst) === JSON.stringify(sortedSecond);
    }
  }
  return false;
}

/**
 * @description compare two input for stirng , boolean, number, array, json
 * @param first
 * @param second
 * @param isCaseSensetive
 * @returns boolean
 */
export const compare = (
  first: string | number | object | boolean | undefined,
  second: string | number | object | boolean | undefined,
  isCaseSensetive?: boolean
): boolean => {
  try {
    const isCaseSensetiveCheck = isCaseSensetive ?? false;
    const conditions: boolean[] = [];

    if (!first || !second) {
      return false;
    }

    conditions.push(checkBoolean(first, second));
    conditions.push(checkNumber(first, second));
    conditions.push(checkString(isCaseSensetiveCheck, first, second));
    conditions.push(checkObject(first, second));

    return conditions.reduce((arg1, arg2) => arg1 || arg2);
  } catch (error) {
    throw Error('Something went wrong');
  }
};

/**
 * @description get array of items in form of {text, value, secondaryText}
 * @param valueKey string
 * @param textKey string
 * @param list any[]
 * @param secondaryTextKey string
 * @param icon JSX.Element
 * @returns array of Items[]
 */
export const getDropdownItemsList = (
  valueKey: string,
  textKey: string,
  list: any[],
  secondaryTextKey?: string | string[],
  icon?: React.JSX.Element
) => {
  const itemArrAccount: Items[] = [];
  if (!isEmpty(list)) {
    list.forEach((item) => {
      let textKeyToShow;
      if (typeof secondaryTextKey === 'string') {
        textKeyToShow = !isEmpty(secondaryTextKey)
          ? item[secondaryTextKey]
          : undefined;
      } else {
        secondaryTextKey?.every((key) => {
          if (isEmpty(item[key])) {
            return true;
          }
          textKeyToShow = item[key];
          return false;
        });
      }

      const obj: Items = {
        value: typeof item === 'object' ? item[valueKey] : item,
        text: typeof item === 'object' ? item[textKey] : item,
        secondaryText: textKeyToShow ?? undefined,
        icon,
      };
      itemArrAccount.push(obj);
    });
  }
  return itemArrAccount;
};

/**
 * @description to check if any of value is exist
 * @param conditions
 * @returns
 */
export function isAnyExist(...conditions: any) {
  return conditions.reduce((arg1, arg2) => arg1 || arg2);
}

/**
 * @description Encode pin
 * @param a
 * @param b
 * @returns
 */
export const XOR_hex = (a: string, b: string) => {
  let res = '';

  let i = a.length;

  let j = b.length;

  while (i-- > 0 && j-- > 0)
    res =
      (parseInt(a.charAt(i), 16) ^ parseInt(b.charAt(j), 16)).toString(16) +
      res;

  return res.toUpperCase();
};

/**
 * @author Sunil Jangid - 323361
 * @description it will group by array of objects based on key
 * @param list any[]
 * @param key string
 * @returns any[]
 */
export const groupByObjectsWithKey = (list: any[], key: string): object => {
  if (list) {
    return list.reduce((initial, item) => {
      if (item[key] in initial) {
        initial[item[key]] = [...initial[item[key]], item];
      } else {
        initial[item[key]] = [item];
      }
      return initial;
    }, {});
  } else {
    return {};
  }
};

/**
 * @description to get superscript for number
 * @param paymentNumber number
 * @returns superscript for number
 */
export const superScriptNumber = (paymentNumber: number | string) => {
  if (Math.floor((Number(paymentNumber) % 100) / 10) === 1) return 'th';
  switch (Number(paymentNumber) % 10) {
    case 1:
      return 'st';

    case 2:
      return 'nd';

    case 3:
      return 'rd';

    default:
      return 'th';
  }
};
/**
 * @description replace occurences with delimeter
 * @param str
 * @param occure
 * @param delimeter
 * @returns string
 */
export const replaceOccuranceWithDelimeter = (
  str: string,
  occure: string | RegExp,
  delimeter: string
) => {
  let result = str;
  try {
    if (str) {
      result = str.toLowerCase().replaceAll(occure, delimeter).trim();
    }
  } catch (error) {
    throw Error('Something went wrong');
  }
  return result;
};

/**
 * @author Sunil-Jangid(323361)
 * @description get no of pages based on total count and page size
 * @param totalCount
 * @param pageSize
 * @returns number
 */
export const getNoOfPages = (totalCount: number, pageSize: number) => {
  if (!totalCount || !pageSize) {
    return 0;
  }
  return Math.ceil(totalCount / pageSize);
};

/**
 * @author Sunil-Jangid(323361)
 * @description get masked value for input eg: XX34 or 34XX
 * @param input string | number
 * @param len number
 * @param masklen number
 * @param leftToRight boolean
 * @param maskWith string
 */
export const maskInputWithValue = (
  input: string | number,
  len = 3,
  maskLen = 2,
  leftToRight = true,
  maskWith = 'X'
) => {
  if (input) {
    let temp = input.toString();
    if (temp.length < len || len < maskLen) {
      return input;
    }
    if (leftToRight) {
      temp = temp.slice(temp.length - (len - maskLen), temp.length);
      temp = maskWith.repeat(maskLen).concat(temp);
      return temp;
    } else {
      temp = temp.slice(0, len - maskLen);
      temp = temp.concat(maskWith.repeat(maskLen));
      return temp;
    }
  } else {
    return '';
  }
};

/**
 * @author Ravi Saini(316718)
 * @description get customer account filtered
 * @param accountList
 * @param keysToFilter
 * @param statusSortKeys
 * @returns filtered Array
 */

export const customerAccountConfigFilter = (
  dataList: CustomerAccountDetails[] | undefined,
  filterKey: string,
  filterValue: string[]
): CustomerAccountDetails[] => {
  if (isEmpty(dataList)) {
    return [];
  } else if (isEmpty(filterValue) && !isEmpty(dataList)) {
    return dataList ?? [];
  } else {
    const list = dataList?.filter((item) => {
      return filterValue.includes(item[filterKey ?? 'CurrentStatus']);
    });
    return list?.length ? list : [];
  }
};

export const customerAccountFilter = (
  accountList: CustomerAccountsData | undefined,
  keysToFilter: string[],
  statusSortKeys: string[] = [],
  statusMatchKey: string = STATUS_MATCH_KEYS.CurrentStatus
) => {
  if (!accountList) return [];
  let concatArr = accountList?.[keysToFilter[0]];
  let i = 1;
  while (i < keysToFilter.length) {
    concatArr = concatArr.concat(accountList?.[keysToFilter[i]]);
    i++;
  }

  let j = 0;
  let sortedArr: any[] = [];
  while (j < statusSortKeys.length) {
    const tmpStatusArr = ACCOUNT_STATUS_CODES[statusSortKeys[j]];
    sortedArr = sortedArr.concat(
      concatArr.filter((item: CustomerAccountDetails) =>
        tmpStatusArr.includes(item[statusMatchKey])
      )
    );
    if (j === statusSortKeys.length - 1) {
      concatArr = sortedArr;
    }
    j++;
  }

  return concatArr;
};

export const sortAccountsOnStatus = (
  accSummary: AccountSummaryDetails,
  otherAccSummary: AccountSummaryDetails
) => {
  const accSummaryStatus = accSummary.AccountStatus;
  const otherAccSummaryStatus = otherAccSummary.AccountStatus;
  let orderOfAccSummary = 0;
  let orderOfOtherAccSummary = 1;
  ACCOUNT_STATUS_ORDER_MAP.forEach((item: string[], key: number) => {
    if (item.includes(accSummaryStatus)) {
      orderOfAccSummary = key;
    }
    if (item.includes(otherAccSummaryStatus)) {
      orderOfOtherAccSummary = key;
    }
  });
  return orderOfAccSummary - orderOfOtherAccSummary;
};

/**
 * @author Harshita(340789)
 * @description Mask Card Number
 * @param cardNumber
 * @returns masked Card Number
 */

export const maskCardNumber = (cardNumber: string) => {
  return cardNumber?.replace(/(\d{4})\d+(\d{4})/, '$1 XXX XXX $2');
};

/**
 * @author Harshita(323361)
 * @description concatation strings
 * @param any[] array of strings
 * @returns return string
 */

export const getConcatenatedString = (args: any[], seperator = '-') => {
  if (args) {
    return args.join(seperator);
  } else {
    return '';
  }
};

/**
 * @author Shashank(299345)
 * @description Mask string from in between
 * @param targetString string to be masked
 * @param startIndex starting index for masking
 * @param endIndex end index for masking
 * @param maskChar character to be used in masking
 * @returns masked string
 */

export const maskStringInBetween = (
  targetString: string,
  startIndex: number,
  endIndex: number,
  maskChar: string
): string => {
  const maskedSubstring = maskChar.repeat(endIndex - startIndex + 1);
  return targetString
    .substring(0, startIndex)
    .concat(maskedSubstring)
    .concat(targetString.substring(endIndex + 1));
};

export const capitalizeFirstChar = (str: string) => {
  let result;
  try {
    if (str) {
      result = str.toLocaleLowerCase().trim();
      result = result[0].toUpperCase().concat(result.slice(1));
    }
  } catch (error) {
    throw Error('Something went wrong');
  }
  return result;
};

type Params = Record<string, string | number | boolean | undefined | null>;
export const buildUrl = (baseUrl: string, params: Params) => {
  const filteredParams: Params = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== null
    )
  );

  const queryString = new URLSearchParams(
    filteredParams as Record<string, string>
  ).toString();
  return baseUrl + (queryString ? '?' + queryString : '');
};

export const conditionalReturnValue: any = (
  condition: any,
  truthyReturnValue: any,
  falsyReturnValue: any
) => {
  return condition ? truthyReturnValue : falsyReturnValue;
};

export const formatMobileNo = (str: string) => {
  let result;
  str = str?.trim();
  if (!str?.includes('-')) {
    result = `+91-${str}`;
  } else {
    result = str;
  }
  return result;
};

export const mobileWithoutCountryCode = (mob: string) => {
  if (mob.length > 10) {
    return mob.slice(-10);
  } else {
    return mob;
  }
};

export const mobileWithCountryCode = (mob: string, countryCode: string) => {
  if (mob.length > 10) {
    return mob;
  } else {
    return `${countryCode}${mob}`;
  }
};

function generateRandomString() {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomString = '';
  for (let i = 0; i < 21; i++) {
    const randomByte = CryptoJS.lib.WordArray.random(1);
    const charIndex = randomByte.words[0] % charset.length;
    randomString += charset[charIndex];
  }
  return randomString;
}
export const generateFeTxnId = () => {
  return `WEB${generateRandomString()}`;
};

export const flushUserSession = () => {
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('employeeId');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('name');
  sessionStorage.removeItem('userType');
  sessionStorage.removeItem('userProfile');
};

export const convertStringToObject = (str: string) => {
  return JSON.parse(str);
};

export const getSha256HashedValue = (value: string) => {
  if (value) {
    const hash: string = CryptoJS.SHA256(value).toString();
    return hash;
  }
};

export const maskEmailId = (emailid: string, defaultPlaceHolder = '') => {
  const email = emailid?.trim();
  if (email?.length === 0) {
    return defaultPlaceHolder;
  }
  const index = email?.indexOf('@');
  if (index < 5) {
    return email;
  }
  const first = email?.slice(0, index);
  const last = email?.slice(index + 1);
  const maskedEmail = first?.slice(0, 4) + 'x'.repeat(first?.length - 4);

  return maskedEmail + '@' + last;
};

export const maskMobileNumber = (mobnumber: string) => {
  const mobNo = mobnumber?.trim();
  if (mobNo?.length === 0) {
    return '';
  }
  return (
    mobNo?.substring(0, 2) +
    'x'.repeat(mobNo?.length - 4) +
    mobNo?.substring(mobNo?.length - 2)
  );
};

export function floatConversion(num: string): string {
  if (num.includes('.')) {
    if (num.split('.')[1].length === 1) {
      return num + '0';
    } else if (num.split('.')[1].length === 0) return num + '00';
    else return num;
  }
  return num + '.00';
}

export const getColor = (colorCode: string) => {
  if (isEmpty(colorCode)) return '';
  if (colorCode.includes('#')) {
    return colorCode;
  } else {
    return `#${colorCode}`;
  }
};

export const isURL = (input: string): boolean => {
  const urlPattern = /^(?:(?:https?|ftp):\/\/|www\.)\S+$/i;

  return urlPattern.test(input);
};
