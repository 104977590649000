import { Icon } from '@core-components/atoms';

export interface SubStep {
  stepId: string;
  stepStatus: 'todo' | 'active' | 'error' | 'done';
  completionStatus?: boolean;
  error?: boolean;
  stepLabel: string;
  onStepChanged?: (subStepId: string) => void;
}

export function XB_SubStep({
  stepId,
  stepLabel,
  stepStatus,
  onStepChanged,
  completionStatus = false,
}: SubStep) {
  const subStepClass = completionStatus ? 'done' : '';
  return (
    <div
      className={`sub-step sub-step--${stepStatus} ${subStepClass}`}
      onClick={() => {
        onStepChanged?.(stepId);
      }}
    >
      <Icon
        icon={`${stepStatus === 'done' ? 'check' : 'dot'}`}
        height={12}
        width={12}
        className={`hidden sm:inline `}
      />
      <span>{stepLabel}</span>
    </div>
  );
}
