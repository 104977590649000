import { appConstants } from '@core-constants';
import { EndPoints, Service } from '@core-services';
import { buildUrl } from '@core-utils';

export const uploadFile = async (
  file: File,
  metadata: {
    mobileNumber?: string;
    cifNumber?: string;
    fileName?: string;
  },
  fileHeader: object,
  config: object = {}
) => {
  const queryParams = {
    fileName: file?.name,
    mobileNumber: metadata?.mobileNumber,
    cifNumber: metadata?.cifNumber,
  };
  const url = buildUrl(EndPoints.onboardingService.uploadFile, queryParams);
  const data = new FormData();
  data.append('document', file);
  const reqObj = {
    baseurl: url,
    method: 'POST',
    url: '',
    obj: data,
  };
  const headers = {
    ...fileHeader,
    'Content-Type': 'multipart/form-data',
  };

  const response = await Service.send(reqObj, headers, config);
  return response.data;
};

export const uploadFileCaseManagement = async (
  file: File,
  metadata: {
    caseId: string;
  },
  fileHeader: object,
  config: object = {}
) => {
  let userType;

  if (process.env.REACT_APP_TYPE === appConstants.APP_TYPE.ADMIN) {
    userType = appConstants.APP_TYPE.CPC;
  } else if (process.env.REACT_APP_TYPE === appConstants.APP_TYPE.SO) {
    userType = appConstants.APP_TYPE.SO;
  } else {
    userType = 'MERCHANT';
  }

  const data = new FormData();
  data.append('document', file);
  const reqObj = {
    baseurl: `${EndPoints.admin.fileUpload}?fileName=${file?.name}&caseId=${metadata?.caseId}`,
    method: 'POST',
    url: '',
    obj: data,
  };
  const headers = {
    ...fileHeader,
    userType,
    'Content-Type': 'multipart/form-data',
  };

  const response = await Service.send(reqObj, headers, config);
  return response.data;
};

export const deleteFile = async (fileHeader: any, config: object = {}) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.deleteFile,
    method: 'POST',
    url: '',
    obj: '',
  };

  const headers = {
    ...fileHeader,
  };

  const response = await Service.send(reqObj as any, headers, config);
  return response.data;
};

export const fileMetadata = async (body: object = {}) => {
  const reqObj = {
    baseurl: EndPoints.onboardingService.fileMetadata,
    method: 'POST',
    url: '',
    obj: body,
  };

  const response = await Service.send(reqObj);
  return response.data;
};

export const downloadFile = async (url: string, bucket: string) => {
  const parts = url.split('/');
  const params = {
    bucketName: parts[0],
    bucketKeyPath: parts[1] + '/' + parts[2] + '/' + parts[3],
    fileName: parts[4],
  };
  const reqObj = {
    baseurl: EndPoints.onboardingService.downloadFile,
    method: 'POST',
    url: '',
  };
  const headers = {
    awsS3BucketName: params.bucketName,
    awsS3BucketKeyPath: params.bucketKeyPath,
    awsS3BucketKeyPathFileNameWithExt: params.fileName,
  };
  try {
    const response = await Service.send(reqObj, headers);
    return response.data;
  } catch (e) {
    return false;
  }
};

export const base64ImageToBlob = (str) => {
  const pos = str?.indexOf(';base64,');
  const type = str?.substring(5, pos);
  const b64 = str?.substr(Number(pos) + 8);
  const imageContent = atob(b64);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  return new Blob([buffer], { type });
};

export const getBlobUrl = async (str, bucket) => {
  const fileExt = str.split('.').pop();
  const getMimeType = () => {
    if (fileExt === 'pdf') {
      return 'application/pdf';
    }
    return fileExt === 'blob' ? 'image/png' : 'image/'.concat(fileExt);
  };
  const fileType = getMimeType();
  let fileResp;
  if (window.location.href.includes('onboarding')) {
    fileResp = await downloadFile(str, bucket);
  } else {
    const splitUrl = str?.split('/');
    const caseManagementBucket = splitUrl
      .slice(1, splitUrl.length - 1)
      .join('/');
    fileResp = await downloadFileCaseManagement(str, caseManagementBucket);
  }
  if (fileResp && fileResp.status === 'success') {
    const blobFile = base64ImageToBlob(
      'data:'.concat(fileType, ';base64,', fileResp.data)
    );
    return URL.createObjectURL(blobFile);
  } else {
    return '';
  }
};

export const downloadFileCaseManagement = async (
  url: string,
  bucket: string
) => {
  const reqObj = {
    baseurl: EndPoints.caseManagement.downloadFile,
    method: 'POST',
    url: '',
  };

  const parts = url.split('/');
  const params = {
    bucketName: parts[0],
    bucketKeyPath: parts[1] + '/' + parts[2] + '/' + parts[3],
    fileName: parts[4],
  };

  const headers = {
    awsS3BucketName: params.bucketName,
    awsS3BucketKeyPath: params.bucketKeyPath,
    awsS3BucketKeyPathFileNameWithExt: params.fileName,
  };
  try {
    const response = await Service.send(reqObj, headers);
    return response.data;
  } catch (e) {
    return false;
  }
};
