import { ReactNode } from 'react';

import moment from 'moment';

import {
  AllocatedStocksStatusData,
  StockSummaryDataType,
} from '../models/so-inventory.model';

interface KitTypeDropDownType {
  text?: string;
  value: string;
  icon?: ReactNode;
  secondaryText?: string;
}

export interface SoInventoryPageStateType {
  kitType: KitTypeDropDownType[] | undefined;
  stockSummary: StockSummaryDataType | undefined;
  allocationStocks: AllocatedStocksStatusData[] | undefined;
}

export const dropDownInventoryList = [
  {
    value: '0',
    text: 'Kit A',
    secondaryText: 'QR',
  },
  {
    value: '1',
    text: 'Kit B',
    secondaryText: 'QR',
  },
  {
    value: '2',
    text: 'Kit C',
    secondaryText: 'QR',
  },
];

export const kitType = {
  GENERIC: 'Generic',
  CUSTOMIZE: 'Custom',
};

export const kitTypeData = {
  GENERIC: 'GENERIC',
  CUSTOMIZE: 'CUSTOM',
};

export const inventoryDataTable = [
  {
    name: 'Ramesh Agarwal',
    code: '345678',
    allocate: '10,000',
    unsigned: '10,000',
    vpakit: '10,000',
  },
  {
    name: 'Ramesh Agarwal',
    code: '345678',
    allocate: '10,000',
    unsigned: '10,000',
    vpakit: '10,000',
  },
  {
    name: 'Ramesh Agarwal',
    code: '345678',
    allocate: '10,000',
    unsigned: '10,000',
    vpakit: '10,000',
  },
  {
    name: 'Ramesh Agarwal',
    code: '345678',
    allocate: '10,000',
    unsigned: '10,000',
    vpakit: '10,000',
  },
  {
    name: 'Ramesh Agarwal',
    code: '345678',
    allocate: '10,000',
    unsigned: '10,000',
    vpakit: '10,000',
  },
  {
    name: 'Ramesh Agarwal',
    code: '345678',
    allocate: '10,000',
    unsigned: '10,000',
    vpakit: '10,000',
  },
];

export const inventoryReportDataTable = [
  {
    no: '1',
    name: 'QR',
    code: 'A',
    allocate: '10000',
    unAllocate: '20000',
    assigned: '10000',
    unsigned: '50000',
    destroyed: '10000',
    lost: '10000',
  },
  {
    no: '2',
    name: 'QR',
    code: 'B',
    allocate: '10000',
    unAllocate: '10000',
    assigned: '20000',
    unsigned: '10000',
    destroyed: '15000',
    lost: '10000',
  },
  {
    no: '3',
    name: 'Sound Box',
    code: 'C',
    allocate: '10000',
    unAllocate: '10000',
    assigned: '10000',
    unsigned: '30000',
    destroyed: '10000',
    lost: '10000',
  },
  {
    no: '4',
    name: 'QR',
    code: 'D',
    allocate: '20000',
    unAllocate: '10000',
    assigned: '10000',
    unsigned: '10000',
    destroyed: '11000',
    lost: '10000',
  },
  {
    no: '5',
    name: 'QR',
    code: 'A',
    allocate: '10000',
    unAllocate: '10000',
    assigned: '15000',
    unsigned: '10000',
    destroyed: '10000',
    lost: '10000',
  },
  {
    no: '6',
    name: 'QR',
    code: 'A',
    allocate: '10000',
    unAllocate: '10000',
    assigned: '10000',
    unsigned: '35000',
    destroyed: '10000',
    lost: '10000',
  },
  {
    no: '7',
    name: 'QR',
    code: 'A',
    allocate: '10000',
    unAllocate: '10000',
    assigned: '10000',
    unsigned: '10000',
    destroyed: '10000',
    lost: '10000',
  },
  {
    no: '8',
    name: 'QR',
    code: 'A',
    allocate: '10000',
    unAllocate: '10000',
    assigned: '10000',
    unsigned: '10000',
    destroyed: '10000',
    lost: '10000',
  },
  {
    no: '9',
    name: 'QR',
    code: 'A',
    allocate: '10000',
    unAllocate: '10000',
    assigned: '10000',
    unsigned: '10000',
    destroyed: '10000',
    lost: '10000',
  },
  {
    no: '10',
    name: 'QR',
    code: 'A',
    allocate: '10000',
    unAllocate: '1200',
    assigned: '10000',
    unsigned: '10000',
    destroyed: '10000',
    lost: '11000',
  },
  {
    no: '11',
    name: 'QR',
    code: 'D',
    allocate: '11500',
    unAllocate: '1200',
    assigned: '11400',
    unsigned: '13600',
    destroyed: '15600',
    lost: '11000',
  },
];
export const soRequestHistoryList = [
  {
    requestId: '#1235465',
    date: '2023-06-10T15:00:00',
    productKitType: 'QR',
    productKitCode: 'A',
    for: 'Branch',
    quantity: '1100',
    status: 'New',
  },
  {
    requestId: '#5425445',
    date: '2023-06-12T14:00:00',
    productKitType: 'Casa Kit',
    productKitCode: 'A',
    for: 'Customer:Ramesh Prakash',
    quantity: '1200',
    status: 'Inprogress',
  },
  {
    requestId: '#1245625',
    date: '2023-06-12T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'A',
    for: 'Customer:Raju Panchal',
    quantity: '2200',
    status: 'Closed',
  },
  {
    requestId: '#1245625',
    date: '2023-06-12T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'C',
    for: 'Branch',
    quantity: '2200',
    status: 'Closed',
  },
  {
    requestId: '#1245625',
    date: '2023-06-12T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'A',
    for: 'Branch',
    quantity: '2200',
    status: 'Closed',
  },
  {
    requestId: '#1245625',
    date: '2023-06-12T10:30:00',
    productKitType: 'Sound Box',
    productKitCode: 'A-mangal@aubank',
    for: 'Customer:Raju Panchal',
    quantity: '2200',
    status: 'Closed',
  },
  {
    requestId: '#1245625',
    date: '2023-06-12T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'B',
    for: 'Branch',
    quantity: '2200',
    status: 'Closed',
  },
  {
    requestId: '#5656566',
    date: '2023-06-12T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'A',
    for: 'Branch',
    quantity: '2200',
    status: 'Closed',
  },
  {
    requestId: '#1245625',
    date: '2023-07-12T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'A',
    for: 'Customer:Raju Panchal',
    quantity: '2200',
    status: 'Inprogress',
  },
  {
    requestId: '#8544555',
    date: '2023-06-14T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'A',
    for: 'Customer:Raju Panchal',
    quantity: '2200',
    status: 'Closed',
  },
  {
    requestId: '#8544555',
    date: '2023-03-14T10:30:00',
    productKitType: 'Casa Kit',
    productKitCode: 'A',
    for: 'Customer:Mukesh Khan',
    quantity: '2200',
    status: 'New',
  },
];

export const headerButtonsList = [
  {
    testId: 'ACTV-RQST',
    btnType: 'tertiary',
    text: 'activeRequest',
    btnSize: 'md',
  },
  { testId: 'RQST-KIT', btnType: 'primary', text: 'requestKit', btnSize: 'md' },
  { testId: 'ALOC-KIT', btnType: 'secondary', text: 'allocate', btnSize: 'md' },
];

export const dateFormats = {
  dateFormatToShow: 'DD, MMM YYYY',
  dateFormatToSendInApi: 'DDMMMYYYY',
  dateFormatToSendInLoanApi: 'YYYYMMDD',
  timeFormat: 'hh:mm A',
};

export const KIT_FOR = {
  CUSTOMER: 'CUSTOMER',
  BRANCH: 'BRANCH',
};

export const VPA_PREFIX_INPUT_VAL = {
  MIN: 3,
  MAX: 30,
  PLACEHOLDER: 'e.g. aubankjaipur',
};

export const BM_HISTORY_STATUS = {
  CANCELLED: 'Cancelled',
  NEW: 'New',
  FORWARDED: 'In progress',
  GENERATING: 'Generating',
  GENERATED: 'Generating',
  PRINTING: 'Printing',
  PRINTED: 'Generating',
  PENDING: 'Pending',
  PARTIAL_SUCCESS: 'Generating',
  SENT: 'In-Transit',
  RECEIVED: 'Accepted',
  ALLOCATED_SO: 'Allocated',
};

export const BM_HISTORY_BTN = {
  CANCELLED: 'Cancelled',
  NEW: 'Forward',
  FORWARDED: 'Accept',
  GENERATING: 'Accept',
  GENERATED: 'Accept',
  PRINTING: 'Accept',
  PRINTED: 'Accept',
  PARTIAL_SUCCESS: 'Generating',
  SENT: 'Accept',
  RECEIVED: 'Allocate',
  ALLOCATED_SO: 'Allocated to SO',
};

export const SO_HISTORY_STATUS = {
  CANCELLED: 'CANCELLED',
  NEW: 'Pending BM Approval',
  FORWARDED: 'Approved by BM',
  GENERATING: 'GENERATING',
  GENERATED: 'GENERATED',
  PRINTING: 'Printing',
  PRINTED: 'PRINTED',
  PARTIAL_SUCCESS: 'GENERATING',
  SENT: 'In-Transit',
  RECEIVED: 'received by BM',
  ALLOCATED_SO: 'CLOSED',
  ALLOCATED: 'ALLOCATED',
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
};

export const requestList = [
  {
    Product: 'QR',
    KitType: 'Kit A',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Quantity: '1,000',
    Email: 'merchantau@auabnk',
  },
  {
    Product: 'QR',
    KitType: 'Kit A',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Quantity: '1,000',
    Email: 'merchantau@auabnk',
  },
  {
    Product: 'QR',
    KitType: 'Kit A',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Quantity: '1,000',
    Email: 'merchantau@auabnk',
  },
  {
    Product: 'QR',
    KitType: 'Kit A',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Quantity: '1,000',
    Email: 'merchantau@auabnk',
  },
];

export const AllocatedStocksList = [
  {
    BranchName: 'string',
    BranchCode: 'string',
    EmployeeName: 'Ramesh Agarwal',
    EmployeeCode: '34567897',
    AllocatedVpa: 0,
    UnassignedVpa: 0,
    AssignedVpa: 0,
  },
  {
    BranchName: 'string',
    BranchCode: 'string',
    EmployeeName: 'Ramesh Agarwal 1',
    EmployeeCode: '34567897',
    AllocatedVpa: 0,
    UnassignedVpa: 0,
    AssignedVpa: 0,
  },
];

export const pastAllotmentHistory = [
  {
    AllocationId: '#12382928',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Product: 'QR',
    KitType: 'A - zomatojpr@aubank',
    quantity: 1000,
    idView: true,
  },
  {
    AllocationId: '#12382928',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Product: 'QR',
    KitType: 'A - zomatojpr@aubank',
    quantity: 1000,
    idView: true,
  },
  {
    AllocationId: '#12382928',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Product: 'QR',
    KitType: 'A - zomatojpr@aubank',
    quantity: 1000,
    idView: true,
  },
  {
    AllocationId: '#12382928',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Product: 'QR',
    KitType: 'A - zomatojpr@aubank',
    quantity: 1000,
    idView: true,
  },
  {
    AllocationId: '#12382928',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Product: 'QR',
    KitType: 'A - zomatojpr@aubank',
    quantity: 1000,
    idView: false,
  },
  {
    AllocationId: '#12382928',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Product: 'QR',
    KitType: 'A - zomatojpr@aubank',
    quantity: 1000,
    idView: false,
  },
  {
    AllocationId: '#12382928',
    Date: moment().format('DD MMM, YYYY'),
    Time: moment().format('hh:mm A'),
    Product: 'QR',
    KitType: 'A - zomatojpr@aubank',
    quantity: 1000,
    idView: false,
  },
];

export const VPA_HANDLER_INPUT_DATA = '@auBank';
export const userData = [
  {
    Id: '1',
    User: 'Ravi',
  },
  {
    Id: '2',
    User: 'Rahul',
  },
  {
    Id: '3',
    User: 'Raman',
  },
];

export const pastAllotmentDisableBtnStatus = [
  'LOST',
  'DAMAGE_SOLD',
  'DAMAGE',
  'SOLD',
  'LOST_SOLD',
];

export const inventoryStatus = {
  open: 0,
  requested: 1,
  ready: 2,
  inTransit: 3,
  received: 4,
  lost: 5,
  damage: 6,
  sold: 7,
  lostSold: 8,
  damageSold: 9,
};

export const branchFilter = {
  filterType: 'BANK_NAME',
  bankName: 'AU SMALL FINANCE BANK LIMITED',
};

export const inventoryStatusString = {
  0: 'open',
  1: 'requested',
  2: 'ready',
  3: 'inTransit',
  4: 'received',
  5: 'Lost',
  6: 'Destroyed',
  7: 'sold',
  8: 'lostSold',
  9: 'damageSold',
  10: 'damage',
};

export const toShowStatus = ['PENDING'];

export const filterModalTypes = {
  downloadProgess: 'downloadprogess',
  downloadFailed: 'downloadfailed',
};

export interface DataTypeDateRange {
  startDate: string | Date;
  endDate: string | Date;
  isSearchApplied?: boolean;
}

export const SO_MAX_KIT_QUANTITY = 100;
export const SO_CUSTOM_MAX_KIT_QUANTITY = 1000;
export const BM_MAX_KIT_QUANTITY = 500;
export const BM_CUSTOM_MAX_KIT_QUANTITY = 500;

export const INVENTORY = 'inventory';

export const onBoarding = 'onboarding.qr.access';

export const FORWARDED = 'FORWARDED';

export const ALLOCATED_SO = 'ALLOCATED_SO';

export const PAST_ALLOTMENT_STATUS = {
  LOST: 'LOST',
  DAMAGE: 'DAMAGE',
};

export const ALL = 'ALL';
