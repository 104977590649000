import { SessionStorage, Storage } from './async-storage.util';

const { matchMedia, navigator } = window;

// getting html element
const getHtmlElement = () => {
  return document.getElementsByTagName('html')[0];
};

// get user theme
export const getThemeFromLocal = () => {
  return Storage.getItemAsync('theme');
};

// detecting system theme and updating html element
export const themeDetector = () => {
  const htmlEle: HTMLElement = getHtmlElement();
  const storedTheme =
    getThemeFromLocal() ??
    (matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  if (storedTheme) {
    htmlEle.setAttribute('data-theme', storedTheme);
    htmlEle.classList.toggle(storedTheme);
  }
};

// set system theme and updating html element
export const toggleTheme = () => {
  const htmlEle: HTMLElement = getHtmlElement();
  const currentTheme = htmlEle.getAttribute('data-theme');
  const targetTheme = currentTheme === 'light' ? 'dark' : 'light';
  htmlEle.setAttribute('data-theme', targetTheme);
  htmlEle.classList.toggle('dark');
  Storage.setItemAsync('theme', targetTheme);
};

// system network check
export const networkDetector = () => {
  return navigator.onLine;
};

// persona check and set
export const personaDetector = () => {
  const htmlEle: HTMLElement = getHtmlElement();
  const persona = SessionStorage.getItem('persona');
  if (persona) htmlEle.setAttribute('data-view', persona);
};
