import './AU_RoundBox.style.scss';

interface RoundBoxProps {
  height?: string;
  width?: string;
  boxClass?: string;
  children?: React.ReactNode;
}

export const AU_RoundBox = ({
  height = '2.5rem',
  width = '2.5rem',
  boxClass,
  children,
}: RoundBoxProps) => {
  return (
    <div style={{ height, width }} className={`round-box ${boxClass ?? ''}`}>
      {children}
    </div>
  );
};
