import { AdminStore } from '@modules/admin-portal/models';

export const adminstore: AdminStore = {
  actionableViewable: [],
  viewableSOUserId: '',

  assignmentData: {
    actionedBy: '',
    assigneeId: '',
    assigneeType: '',
    assigneeGroup: '',
    assignerGroup: [],
    managerDetails: {
      name: '',
      employeeId: '',
      employeeUUid: '',
      group: [
        {
          id: '12',
          name: 'sid',
          description: 'desc',
          active: false,
          groupType: 'gp',
        },
      ],
    },
  },
  amlAssignementInfo: {
    isAssignedCalled: false,
    callReassignAPI: () => {
      // This is intentional
    },
  },
  caseManagement: {
    currentCPCUserId: '',
    disableAllActions: true,
    canSubmitCase: false,
    reviewHighlights: [],
    referAssigmentConfig: false,
    roleBasedAccess: {
      checkpointActionable: [],
      checkpointViewable: [],
    },
    threadData: [],
    highlightThread: [],
    highlightReferData: {
      assigneeGroup: [],
      assigneeId: [],
      assignedUserNames: [],
    },
    dependentCheckpointMap: {},
    dependentFieldsMap: {},
    highlightsAction: '',
    caseHeaderDetails: {
      caseId: '',
      status: '',
      onboardingId: '',

      assignedUsers: [
        {
          userId: '',
          name: '',
          role: '',
        },
      ],
      caseCreatedBy: {
        userId: '',
        userType: '',
        userDetails: {
          userId: '',
          userName: '',
          userBranchCode: '',
          userBranchName: '',
          userMobileNumber: '',
          reportingManager: '',
          reportingManagerPhoneNumber: '',
          userGroupDataList: [],
        },
      },
    },
    caseRejectReason: {
      comment: '',
    },
    allHighlightFields: [],
    customerType: '',
    businessType: '',
    journeyType: '',
    checkpointsComment: [],
    caseDetailsUploadedDocs: [],
    resendCheckPoint: [],
    callActionableViewable: () => {
      // This is intentional
    },
    caseCheckPointsField: [],
    caseHighlights: [],
    resendHighlightedFields: [],
    caseComment: '',
    commentErrorMessage: '',
    livePhotoData: [],
    isChecked: false,
    nextAssignment: {
      assigneeId: '',
      nextGroup: '',
      nextAssigneeType: '',
    },
    commonCommentThread: {
      commentId: '',
      commentText: '',
      commentType: '',
      commentedAt: '',
      replies: [],
      userId: '',
      userName: '',
    },
  },
};
