const parseAttributes = (obj, xml) => {
  obj.attributes = {};
  for (let j = 0; j < xml.attributes.length; j++) {
    const attribute = xml.attributes.item(j);
    obj.attributes[attribute.nodeName] = attribute.nodeValue;
  }
};

const xmlParser = (xml: any) => {
  let obj: any = {};

  if (xml.nodeType === 1) {
    if (xml.attributes.length > 0) {
      parseAttributes(obj, xml);
    }
  } else if (xml.nodeType === 3) {
    obj = xml.nodeValue;
  }

  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const nodeName = item.nodeName;
      if (typeof obj[nodeName] === 'undefined') {
        obj[nodeName] = xmlParser(item);
      } else {
        if (typeof obj[nodeName].push === 'undefined') {
          const old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlParser(item));
      }
    }
  }
  return obj;
};

export const xmlToJson = (text: any) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');
  return xmlParser(xmlDoc);
};
