import { createSlice } from '@reduxjs/toolkit';

import { FreqPayeeListRdxDto } from '../../models/payments.model';
import { getFreqPayeeList } from '../thunks/payments.thunk';

const initialState: FreqPayeeListRdxDto = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: [],
  selectedPayee: undefined,
};

const freqPayeeSlice = createSlice({
  name: 'freq-payee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFreqPayeeList.pending, (state) => {
      state.status = undefined;
      state.error = undefined;
      state.loading = true;
      state.successfulResponse = undefined;
      state.data = undefined;
    });
    builder.addCase(
      getFreqPayeeList.fulfilled,
      (state, { payload }: { payload: any }) => {
        ({
          status: state.status,
          error: state.error,
          successfulResponse: state.successfulResponse,
          data: state.data,
        } = payload);
        state.loading = false;
      }
    );
    builder.addCase(
      getFreqPayeeList.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});
export default freqPayeeSlice.reducer;
