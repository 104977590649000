import { ReqPaymentTransfer } from '@core-stores';
import { createSlice } from '@reduxjs/toolkit';

import { payments } from '../thunks/payments.thunk';

const initialState: any = {
  multiplePaymentList: [] as ReqPaymentTransfer[],
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    addPaymentToList(state, { payload }) {
      state.multiplePaymentList.push(payload);
    },
    removePaymentToList(state, { payload }) {
      state.multiplePaymentList = state.multiplePaymentList?.filter(
        (_item: ReqPaymentTransfer, index) => index !== payload
      );
    },
    updatePaymentToList(state, { payload }) {
      state.multiplePaymentList = state.multiplePaymentList?.map(
        (item: ReqPaymentTransfer, index) => {
          if (index === parseInt(payload?.tempPaymentId)) {
            return Object.assign(item, payload?.paymentRequestObj);
          }
          return item;
        }
      );
    },
    resetPaymentList(state, { payload }) {
      state.multiplePaymentList = payload ?? [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      payments.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.status = payload.data?.status;
        state.error = payload.data?.error;
        state.successfulResponse = payload.data?.successful_response;
        state.NomineeDetails = payload.data?.data?.NomineeDetails;
        state.loading = false;
        state.acNo = payload.acNo;
        state.TransactionBranch = payload.transactionBranch;
      }
    );
    builder.addCase(
      payments.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.NomineeDetails = undefined;
      }
    );
  },
});

export const {
  addPaymentToList,
  removePaymentToList,
  updatePaymentToList,
  resetPaymentList,
} = paymentsSlice.actions;
export default paymentsSlice.reducer;
