import { t } from 'i18next';

import { appCharLimit, FORM_EMAIL_LIMIT, RegEx } from '@core-constants';
import { trimVal } from '@core-utils';
import { AUBL } from '@src/modules/admin-portal/constants';
import { nameErrors } from '@src/modules/qr-onboarding/constant';

import {
  badWordCatch,
  findWords,
  restrictDomains,
  restrictNames,
} from './restricted-words.util';
import { validateAlgo } from './verhoeff.util';

interface ValidationResponse {
  error: string;
  errorOption: object;
}

export function validatePayeeName(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'lengthError';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (/[^a-zA-Z \d]/.test(val)) {
    error = 'consecutiveSpecialChar';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  }
  return { error, errorOption };
}

export function validateFullName(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';

  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (/\d+/.test(val)) {
    error = 'numberNotAllowed';
  } else if (/[^a-zA-Z. ]/.test(val)) {
    error = 'onlyDotAllowed';
  } else if (val?.includes('.') && val?.lastIndexOf('.') === val?.length - 1) {
    error = 'dotNotAllowedAtEnd';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (val?.match(/[.]{2,}/g)?.length) {
    error = 'noConsecutiveDot';
  }
  return { error, errorOption };
}

export function validateAddressPayee(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (val?.match(/[,]{2,}/g)?.length) {
    error = 'noConsecutiveComma';
  }
  return { error, errorOption };
}

export function validateMinMaxLength(
  val: string,
  minLength: number,
  maxLength: number
) {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  }
  return { error, errorOption };
}

export function validateCityState(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (/[\d]$/.test(val)) {
    error = 'numberNotAllowed';
  } else if (/[^a-zA-Z.\-/& ]/.test(val)) {
    error = 'onlyAllowedCityPayee';
  } else if (val?.includes('.') && val?.lastIndexOf('.') === val?.length - 1) {
    error = 'dotNotAllowedAtEnd';
  } else if (val?.includes('.') && val?.indexOf('.') === 0) {
    error = 'dotNotAllowedStartingName';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (val?.match(/[.]{2,}/g)?.length) {
    error = 'noConsecutiveDot';
  }
  return { error, errorOption };
}

export function validateBusinessName(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  }
  return { error, errorOption };
}

export function validateEmail(
  val: string,
  maxLength = FORM_EMAIL_LIMIT
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length > maxLength) {
    error = `maximumLengthAllowed`;
    errorOption.maxLength = maxLength;
  } else if (val?.length < 0 || !RegEx.formValidationEmailRegex.test(val)) {
    error = 'enterValidEmail';
  }
  return { error, errorOption };
}

export function validateAdminEmail(
  val: string,
  maxLength = FORM_EMAIL_LIMIT
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length > maxLength) {
    error = `maximumLengthAllowed`;
    errorOption.maxLength = maxLength;
  } else if (val?.length < 0 || !RegEx.emailAdminRegex.test(val)) {
    error = 'enterValidEmail';
  }
  return { error, errorOption };
}

export function validateUpiID(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < 0 || !/^[\w.-]+@[\w.-]+$/.test(val)) {
    error = 'invalidUPI';
  }
  return { error, errorOption };
}

export function validatePAN(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length !== 0 && (!RegEx.payeePan.test(val) || val?.length !== 10)) {
    error = 'invalidPAN';
  }
  return { error, errorOption };
}

export function validateGST(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length !== 0 && (!RegEx.payeeGst.test(val) || val?.length !== 15)) {
    error = 'invalidGST';
  }
  return { error, errorOption };
}
export function validateTAN(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length !== 0 && (!RegEx.tan.test(val) || val?.length !== 10)) {
    error = 'invalidTAN';
  }
  return { error, errorOption };
}

export function validateMobileNumber(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (!/^[6-9]\d*$/.test(val)) {
    error = 'mobileNumberShould';
  } else if (val?.length < 10 || val?.length > 10) {
    error = 'enterDigitMobileNumber';
  }
  return { error, errorOption };
}

export function validateInternationalMobile(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < 7 || val?.length > 15) {
    error = 'enterDigitInterNationalMobileNumber';
  }
  return { error, errorOption };
}

export function validateAccountNumber(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < 16 || val?.length > 16) {
    error = 'accountLength';
  }
  return { error, errorOption };
}

export function validateBranchCode(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < 4 && val?.length > 6) {
    error = 'branchLength';
  }
  return { error, errorOption };
}

export function validateOtherBankAccNumber(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < 3) {
    error = 'invalidAccountNo';
  }
  return { error, errorOption };
}

export function validateUsernameInput(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (/(?=[!@#$%])/.test(val)) {
    error = 'noSpecialCharacter';
  } else if (/[^a-zA-Z0-9!@#$% ]/.test(val)) {
    error = 'invalidEntryPleaseTryAgain';
  } else if (!/^(?=.*[a-zA-Z])[^\r\n]*$/.test(val)) {
    error = 'usernameNotStartWithNumber';
  } else if (/^\d/.test(val)) {
    error = 'usernameNotStartWithNumber';
  }
  return { error, errorOption };
}

export function validateConfirmPassword(
  enterPwd: string,
  confirmPwd: string
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (enterPwd !== confirmPwd) {
    error = 'pwdNotMatch';
  }

  return { error, errorOption };
}

export function validatePassword(
  val: string,
  minLength: number,
  maxLength: number,
  userName: string,
  _confirmPwd: string
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'pwdMinChar';
  } else if (userName === val) {
    error = 'pwdAndUsername';
  } else if (!/(?=.*[A-Z])(?=.*[a-z])/.test(val)) {
    error = 'oneUpperandOneLowerCase';
  } else if (/[^a-zA-Z0-9!@#$% ]/.test(val)) {
    error = 'specialCharNotAllowed';
  } else if (!/(?=.*?[!@#$%])/.test(val)) {
    error = 'specialCharacterPwd';
  } else if (!/(?=\d)/.test(val)) {
    error = 'onenumericPwd';
  } else if (
    !/^(?!.*(?:0(?=12)|1(?=23)|2(?=34)|3(?=45)|4(?=56)|5(?=67)|6(?=78)|7(?=89))).*$/.test(
      val
    )
  ) {
    error = 'sequentialNotAllowed';
  } else if (
    /(?:(?:(?:[aA](?=[bB])|[bB](?=[cC])|[cC](?=[dD])|[dD](?=[eE])|[eE](?=[fF])|[fF](?=[gG])|[gG](?=[hH])|[hH](?=[iI])|[iI](?=[jJ])|[jJ](?=[kK])|[kK](?=[lL])|[lL](?=[mM])|[mM](?=[nN])|[nN](?=[oO])|[oO](?=[pP])|[pP](?=[qQ])|[qQ](?=[rR])|[rR](?=[sS])|[sS](?=[tT])|[tT](?=[uU])|[uU](?=[vV])|[vV](?=[wW])|[wW](?=[xX])|[xX](?=[yY])|[yY](?=[zZ])|[zZ](?=[aA])){2}|(?:[zZ](?=[yY])|[yY](?=[xX])|[xX](?=[wW])|[wW](?=[vV])|[vV](?=[uU])|[uU](?=[tT])|[tT](?=[sS])|[sS](?=[rR])|[rR](?=[qQ])|[qQ](?=[pP])|[pP](?=[oO])|[oO](?=[nN])|[nN](?=[mM])|[mM](?=[lL])|[lL](?=[kK])|[kK](?=[jJ])|[jJ](?=[iI])|[iI](?=[hH])|[hH](?=[gG])|[gG](?=[fF])|[fF](?=[eE])|[eE](?=[dD])|[dD](?=[cC])|[cC](?=[bB])|[bB](?=[aA])){2}|(?:[9](?=[8])|[8](?=[7])|[7](?=[6])|[6](?=[5])|[5](?=[4])|[4](?=[3])|[3](?=[2])|[2](?=[1])|[1](?=[0])|[0](?=[9])){2}|(?:[0](?=[1])|[1](?=[2])|[2](?=[3])|[3](?=[4])|[4](?=[5])|[5](?=[6])|[6](?=[7])|[7](?=[8])|[8](?=[9])|[9](?=[0])){2})[a-zA-Z0-9])/.test(
      val
    )
  ) {
    error = 'sequentialNotAllowed';
  }
  return { error, errorOption };
}
export function validateEnterPassword(
  val: string,
  minLength: number,
  maxLength: number,
  _confirmPwd: string
) {
  let error = '';
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'eightCharMin';
  } else if (!/(?=\d)/.test(val)) {
    error = 'atLeastOneNumber';
  } else if (!/(?=[!@#$%])/.test(val)) {
    error = 'atleastOneSymbol';
  } else if (!/[A-Z]/.test(val)) {
    error = 'oneUppercaseLetter';
  }
  return { error };
}

export function validateUserName(val: string, minLength: number) {
  let error = '';
  if (val?.length < minLength) {
    error = 'characterMinimum';
  } else if (!RegEx.atLeastOneCharacter.test(val)) {
    error = 'atLeastOneCharacter';
  }
  return { error };
}

export function validatePan(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  const regpan = /[A-Z]{5}\d{4}[A-Z]{1}/;
  if (val?.length !== 10 || !regpan.test(val)) {
    error = 'invalidPanEntered';
  } else {
    if (val?.[3] !== 'P') {
      error = 'enterIndividualPan';
    }
  }
  return { error, errorOption };
}

export function validateAadhaar(val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (
    (val?.length > 0 && val?.length < 12) ||
    (val?.length === 12 && !validateAlgo(val))
  ) {
    error = 'pleaseEnterValidAadhaarNumber';
  }
  return { error, errorOption };
}

export function validatePinCode(
  pinCode: string,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (
    pinCode?.length < maxLength ||
    /^[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]*$/.test(pinCode)
  ) {
    error = 'invalidPincode';
  }
  return { error, errorOption };
}

export function validateAddress(address: string, minLength: number) {
  let error = '';
  const errorOption: any = {};
  if (address?.length < minLength) {
    error = 'addressMinLength';
  } else if (!/^[^!@#$%^&*()[\]_+=':?;~<>]*$/.test(address)) {
    error = 'specialCharactersAllowed';
  } else if (address?.match(/[\\/,.-]{2,}/g)?.length) {
    error = 'noConsecutiveSpecialChar';
  } else if (address?.includes('.') && address?.indexOf('.') === 0) {
    error = 'dotNotAllowedStartingAddress';
  } else if (address?.match(/[ ]{2,}/)) {
    error = 'noConsecutiveSpace';
  }
  return { error, errorOption };
}

export function validateDisplayName(
  displayName: string,
  minLength: number,
  maxLength: number,
  wordCheckArr?: any,
  isregexApply?: boolean
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  const val = trimVal(displayName);
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (isregexApply && /[^a-zA-Z0-9.&,/_()\-' ]/.test(val)) {
    error = 'displayNameError';
  } else if (
    isregexApply &&
    val?.includes('.') &&
    val?.lastIndexOf('.') === val?.length - 1
  ) {
    error = 'dotNotAllowedAtEnd';
  } else if (isregexApply && val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (isregexApply && val?.match(/[.]{2,}/g)?.length) {
    error = 'noConsecutiveDot';
  } else if (
    wordCheckArr &&
    findWords(trimVal(val), wordCheckArr, 'negativeKeywords')
  ) {
    error = 'invalidQRName';
  }
  return { error, errorOption };
}

export function validateEntityName(
  value: string,
  minLength: number,
  maxLength: number,
  wordCheckArr: any
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  const val = trimVal(value);
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (/[^a-zA-Z0-9. ]/.test(val)) {
    error = 'onlyDotAllowed';
  } else if (val?.includes('.') && val?.lastIndexOf('.') === val?.length - 1) {
    error = 'dotNotAllowedAtEnd';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (val?.match(/[.]{2,}/g)?.length) {
    error = 'noConsecutiveDot';
  } else if (findWords(trimVal(val), wordCheckArr, 'negativeKeywords')) {
    error = 'validBusinessName';
  } else if (/^\d+$/.test(val)) {
    error = 'notVaildEntityName';
  }
  return { error, errorOption };
}

export function validateEntityDesc(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (val?.match(/[.]{2,}/g)?.length) {
    error = 'noConsecutiveDot';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (badWordCatch(trimVal(val))) {
    error = 'enterValidDescription';
  } else if (/[^a-zA-Z0-9 .\n]/.test(val)) {
    error = 'noSpecialCharacterAllowed';
  }
  return { error, errorOption };
}

export function validateEntityUrl(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength) {
    error = 'minimumLengthAllowed';
  } else if (val?.length > maxLength) {
    error = 'maximumLengthAllowed';
  }
  return { error, errorOption };
}

export function validateFamilyName(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength) {
    error = 'minimumLengthAllowed';
  } else if (val?.match(/\d/)) {
    error = 'numberNotAllowed';
  } else if (/[^a-zA-Z0-9 ]/.test(val)) {
    error = 'noSpecialCharacterAllowed';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (restrictNames(val)) {
    error = 'enterValidName';
  } else if (val?.length > maxLength) {
    error = 'maximumLengthAllowed';
  }
  return { error, errorOption };
}
export function validateIFSCInput(
  val: string,
  minLength: number,
  checkAUAccount = false
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength) {
    error = t('ifscLengthError').replace('{{minLength}}', minLength.toString());
    errorOption.minLength = minLength;
  } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(val)) {
    error = 'firstFourBeingAlphaChar5thDigitIs0';
  } else if (val.includes(AUBL) && checkAUAccount) {
    error = 'AU account not allowed';
  }
  return { error, errorOption };
}
export function validateConfirmAccountNo(
  enterAcc: string,
  confirmAcc: string
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (!/^[0-9\ba-zA-Z]+$/.test(confirmAcc)) {
    error = 'invalidAccountNo';
  } else if (enterAcc !== confirmAcc) {
    error = 'accountNumberDoesNotMatch';
  }

  return { error, errorOption };
}

export function validateAccountNo(
  enterAcc: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (enterAcc?.length < minLength || enterAcc?.length > maxLength) {
    error = 'enterValidAccountNumber';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (!/^[0-9\ba-zA-Z]+$/.test(enterAcc)) {
    error = 'invalidAccountNo';
  }
  return { error, errorOption };
}
export function validateDocNo(
  value: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (value?.length < minLength || value?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (!/^[0-9\b a-zA-Z-]+$/.test(value)) {
    error = 'noSpecialCharacterAllowed';
  } else if (
    value?.includes('-') &&
    value?.lastIndexOf('-') === value?.length - 1
  ) {
    error = 'dashNotAllowedEndName';
  } else if (value?.includes('-') && value?.indexOf('-') === 0) {
    error = 'dashNotAllowedStartingName';
  } else if (value?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  } else if (value?.match(/[-]{2,}/g)?.length) {
    error = 'noConsecutiveDashAllowed';
  }
  return { error, errorOption };
}
export function validateBeneficiaryName(
  val: string,
  minLength: number,
  maxLength: number
) {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = `enterValidNameBetMinToMaxChar`;
  } else if (val?.match(/\d/)) {
    error = 'numberNotAllowed';
  } else if (/[^a-zA-Z0-9 ]/.test(val)) {
    error = 'noSpecialCharacterAllowed';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  }
  return { error, errorOption };
}

export function validateVpaSuggest(
  val: string,
  minLength: number,
  maxLength: number
) {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength) {
    error = `minimumLengthAllowed`;
  } else if (val?.length > maxLength) {
    error = `maximumLengthAllowed`;
  } else if (/[^a-zA-Z0-9.-]/.test(val)) {
    error = 'noSpecialCharSpaceAllowed';
  }
  return { error, errorOption };
}

export function validateVpa(val: string, minLength: number, maxLength: number) {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength) {
    error = `customVpaError`;
  } else if (val?.length > maxLength) {
    error = `customVpaError`;
  } else if (/[^A-Za-z0-9.\-_]/.test(val)) {
    error = 'onlyDotHypenAllowed';
  }
  return { error, errorOption };
}
export function validateEmailChk(
  val: string,
  maxLength: number,
  list
): ValidationResponse {
  let error = '';
  const errorOption: any = {};

  if (val?.length > maxLength) {
    error = 'maximumLengthAllowed';
  } else if (!RegEx.formValidationEmailRegex.test(val)) {
    error = 'enterValidEmail';
  } else if (restrictDomains(val, list, 'domainName')) {
    error = 'enterCorrectEmail';
  }
  return { error, errorOption };
}

export function isRequired(key: string, val: string): ValidationResponse {
  let error = '';
  const errorOption: any = {};

  if (!val) {
    error = `${key} cannot be empty`;
  }

  return { error, errorOption };
}
export function breaklImageURL(url: any) {
  const parts = url.split('/');
  const firstPart = parts[0];
  const secondPart = parts.slice(1, -1).join('/');
  const thirdPart = parts[parts.length - 1];
  return { firstPart, secondPart, thirdPart };
}

export function getFieldData(poe: any, fieldName) {
  const docName = poe?.poeFieldDetails?.filter(
    (d) => d.fieldName === fieldName
  );
  return docName?.[0]?.fieldValue || '';
}

export function validateOptionalInput(
  inputData: string,
  minLength: number,
  maxLength: number
): boolean {
  const val = trimVal(inputData);
  return (
    val?.length === 0 || (val?.length >= minLength && val?.length <= maxLength)
  );
}

export function validateDocumentName(
  val: string,
  minLength: number,
  maxLength: number
): ValidationResponse {
  let error = '';
  const errorOption: any = {};
  if (val?.length < minLength || val?.length > maxLength) {
    error = 'valueShouldBeBetween';
    errorOption.minLength = minLength;
    errorOption.maxLength = maxLength;
  } else if (val?.includes(' ') && val?.lastIndexOf(' ') === 0) {
    error = 'spaceNotAllowedAtStart';
  } else if (/[^a-zA-Z0-9\s]/.test(val)) {
    error = 'onlySpaceAllowed';
  } else if (val?.match(/\s{2,}/g)?.length) {
    error = 'noConsecutiveSpace';
  }
  return { error, errorOption };
}

export function validateNameAsPerPan(val: string) {
  if (val === '') {
    return { state: false, msg: '' };
  } else if (val?.trim().endsWith('.')) {
    return {
      state: true,
      msg: nameErrors.dotNotAllowedInEnd,
    };
  } else if (val?.includes('..')) {
    return {
      state: true,
      msg: nameErrors.noConsecutiveDots,
    };
  } else if (val?.includes('  ')) {
    return {
      state: true,
      msg: nameErrors.noConsecutiveSpaces,
    };
  } else if (
    val?.length < appCharLimit.MIN_PAN_NAME ||
    val?.length > appCharLimit.MAX_PAN_NAME
  ) {
    return {
      state: true,
      msg: nameErrors.inputValueValidation,
    };
  } else {
    return { state: false, msg: '' };
  }
}
