import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AU_RoundBox, Icon, XB_Modal } from '@core-components/atoms';
import { XB_InputOtp } from '@core-components/molecules';
import { useAlertBox } from '@core-hooks';
import {
  MpinAuthenticationApi,
  ReqMpinAuthenticate,
  ResMpinAuthenticate,
} from '@core-services';
import { RootState, useAppSelector } from '@core-stores';
import { getSha256HashedValue } from '@core-utils';
import { checkUser } from '@modules/common/services/user-management.service';

interface CommonMpinModalProps {
  mpinModal: boolean;
  setMpinModal: (checked: boolean) => void;
  onMpinVerified: (isVerified: boolean) => void;
}

export const AU_CommonMpinModal = ({
  mpinModal,
  onMpinVerified,
  setMpinModal,
}: CommonMpinModalProps) => {
  const { t } = useTranslation();

  const auAlert = useAlertBox();

  const mobNumber = useAppSelector(
    (profileState: RootState) => profileState?.profile?.data?.mobileNumber
  );
  const [mpin, setMpin] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  const [infoText, setInfoText] = useState<string | React.ReactNode>('');
  const [mpinStatus, setMpinStatus] = useState<'error' | 'success' | 'info'>(
    'info'
  );
  const checkUserApi = async () => {
    try {
      const response = await checkUser(mobNumber ?? '');
      if (response?.successfulResponse) {
        setDeviceId(response?.data?.deviceResponse?.deviceId ?? '');
      } else {
        auAlert.error(response?.error?.message as string);
      }
    } catch (error: any) {
      auAlert.error(error?.displayMessage as string);
    }
  };

  useEffect(() => {
    checkUserApi();
  }, []);

  const callMpinAuthenticationApi = async () => {
    setInfoText(
      <span className="flex gap-x-2 items-center">
        <span className="loader"></span>
        <span className="m-text-md-regular text-primary-600">
          {t('verifying')}
        </span>
      </span>
    );
    try {
      const payload: ReqMpinAuthenticate = {
        deviceId,
        credentialValue: getSha256HashedValue(mpin),
      };

      const response: ResMpinAuthenticate = await MpinAuthenticationApi(
        payload
      );

      if (response?.successfulResponse) {
        onMpinVerified(true);
        setMpinStatus('success');
        setInfoText(
          <span className="flex gap-x-2 items-center">
            <Icon
              icon={'greenCircle'}
              color="transparent"
              height={16}
              width={16}
            />
            <span className="m-text-md-regular">{t('verified')}</span>
          </span>
        );
      } else {
        setMpinStatus('error');
        setInfoText(t('invalidOtp'));
        onMpinVerified(false);
      }
    } catch (err: any) {
      onMpinVerified(false);
      setMpinStatus('error');
      setInfoText(err?.displayMessage as string);
    }
  };

  useEffect(() => {
    if (mpin.length === 4 && mpin) {
      callMpinAuthenticationApi();
    }
  }, [mpin]);

  return (
    <div>
      <XB_Modal
        headPos="text-left"
        img={
          <div className="flex justify-between">
            <AU_RoundBox height="3rem" width="3rem">
              <Icon
                icon={'outline-password-check'}
                className="text-orange-600"
                color="transparent"
                height={24}
                width={24}
              />
            </AU_RoundBox>
            <span
              className="close--icon text-xs cursor-pointer"
              onClick={() => setMpinModal(false)}
            >
              <Icon
                icon="close"
                width={16}
                height={16}
                className="text-gray-900"
              />
            </span>
          </div>
        }
        open={mpinModal}
        setOpen={(open) => setMpinModal(open)}
        maxWidth="408px"
        title={t('lblEnterMpin')}
        subTitle={t('youNeedToEnterMpin')}
        body={
          <div>
            <XB_InputOtp
              id="MPIN"
              length={4}
              onValueChange={(val) => {
                setMpin(val);
              }}
              value={mpin}
              textType={mpinStatus}
              additionalText={infoText}
            />
          </div>
        }
      />
    </div>
  );
};
