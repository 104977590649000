import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserProfile, RootState, useAppDispatch } from '@core-stores';
import { SessionStorage } from '@core-utils';

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const userId = useSelector<RootState>(
    (state: RootState) => state.auth.userId
  ) as boolean | undefined;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = async () => {
    const userToken = SessionStorage.getItem('accessToken');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      navigate('/auth/login');
    } else if (userToken && location.pathname === '/') {
      navigate('/dashboard');
    } else {
      try {
        if (!userId) {
          const data = await dispatch(
            getUserProfile({ merchantId: '' })
          ).unwrap();

          sessionStorage.setItem(
            'userProfile',
            JSON.stringify(data?.data?.userGroups)
          );
          sessionStorage.setItem('branchName', data?.data?.branchName);
          sessionStorage.setItem('userId', data?.data?.userId);
          sessionStorage.setItem('name', data?.data?.name);
          sessionStorage.setItem('employeeId', data?.data?.employeeId);
          sessionStorage.setItem('branchCode', data?.data?.branchCode);
          sessionStorage.setItem('userType', data?.data?.userType);
        }
        setIsLoggedIn(true);
      } catch (e) {
        SessionStorage.removeItem('accessToken');
        navigate('/auth/login');
      }
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn, location.pathname]);
  return <React.Fragment>{isLoggedIn ? children : null}</React.Fragment>;
};
