export const persona = { persona1: 'Persona1', persona2: 'WebPersona2' };
export const success = 'success';
export const BLANK = '';
export const Debit_Card = 'debitCard';
export const AccountActiveStatus = ['6', '8'];

export const statementNavigation = '/accountstatement';
export const loanStmtNavigation = '/loanstatement';

export const initialCaseData = {
  mobileNumber: '',
  customerType: '',
  businessType: '',
  cifNumber: '',
  countryCode: '+91',
  caseId: '',
  isReadOnly: true,
};

export const navLinkPathArrays = {
  pathArray: [
    '/accountsummary/home',
    '/deposit/dashboard',
    '/debit-card',
    '/payments/gst',
    '/payments/moneytransfer',
    statementNavigation,
    loanStmtNavigation,
    '/service-request/track-service-req',
    '/service-request',
    '/video-banking',
  ],
  ntbPathArray: [
    '/payments/moneytransfer',
    '/payees',
    '/payments/gst',
    '/statement',
    '/service-request/track-service-req',
    '/service-request',
  ],
  storeLabelArray: ['collections', 'Settlements'],
};

export const ServicesConstants = {
  SELF_TRANSFER: 'selfTransfer',
  BILL_PAYMENTS: 'billPayments',
  FASTAG_RECHARGE: 'fasTagRecharge',
};

export const transactionsStatus = {
  FAILED: 'failed',
  TRANSFERRED: 'transferred',
  SUCCESS: 'success',
};

export const Web = 'Web';

export const FE_CONFIG = 'FE_CONFIG';

export const OfferType = {
  slider: 'Slider',
  header: 'Header',
  cards: 'Cards',
};

export const BannerType = {
  CLEAR_TAX: 'CLEAR_TAX',
};

export const CustomerType = {
  INDIVIDUAL: 'Individual',
  SOLEPROP: 'Sole Prop',
};
