import { AU_RoundBox, Icon, XB_Card } from '@core-components/atoms';

import './AU_Linkcard.style.scss';

interface LinkCardIconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  isRoundBox?: boolean;
}

export interface QuickLinksDataProps {
  prefixIcon?: string;
  prefixIconProps?: LinkCardIconProps;
  title: string;
  titleIcon?: string;
  titleClass?: string;
  descriptionClass?: string;
  description?: React.ReactNode;
  suffixBtnIcon: boolean;
  suffixBtnpath?: () => void;
  btnList?: string[];
  btnIdList?: string[];
  btnPathList?: string[];
  actions?: React.ReactNode;
  rightArrowIconColor?: string;
  disabled?: boolean;
}

interface LinkcardProps {
  data: QuickLinksDataProps;
  cardClass?: string;
  isRoundBox?: boolean;
  isHover?: boolean;
}

export const AU_Linkcard = ({
  data,
  cardClass,
  isRoundBox,
  isHover,
}: LinkcardProps) => {
  const getCardClassname = () => {
    if (data?.disabled) {
      return 'opacity-60 cursor-default bg-gray-50';
    } else if (isHover) {
      return 'hover:bg-orange-100';
    } else {
      return '';
    }
  };

  return (
    <XB_Card showShadow={false} isRoundBorder={false}>
      <div
        className={`h-full account-summary-card-container flex p-2 gap-x-3 rounded-lg ${cardClass} ${getCardClassname()}`}
        onClick={() => {
          if (!data?.disabled) {
            data.suffixBtnpath?.();
          }
        }}
      >
        <div className="account-summary-content-container flex align-middle gap-3">
          {isRoundBox ? (
            <AU_RoundBox>
              <Icon
                icon={data.prefixIcon ?? ''}
                className="text-orange-600"
                color="transparent"
              />
            </AU_RoundBox>
          ) : (
            data.prefixIcon && (
              <Icon
                icon={data.prefixIcon}
                color={data.prefixIconProps?.color ?? 'none'}
                width={data.prefixIconProps?.width ?? 24}
                height={data.prefixIconProps?.height ?? 24}
                className={data.prefixIconProps?.className ?? ''}
              />
            )
          )}

          <div className="account-summary-text ">
            <div className="flex gap-2">
              <p className={data.titleClass ?? 'm-text-md-medium'}>
                {data.title}
              </p>
              {data.titleIcon && (
                <Icon
                  icon={data.titleIcon}
                  color={data.prefixIconProps?.color}
                  width={data.prefixIconProps?.width ?? 24}
                />
              )}
            </div>
            <div className="account-summary-text-container ">
              <p className={data.descriptionClass ?? 'm-text-md-regular'}>
                {data.description}
              </p>
            </div>
          </div>
        </div>
        {data.suffixBtnIcon && (
          <div className="rounded-lg flex align-middle self-center p-2">
            <Icon
              icon="right-arrow-outlined"
              width={20}
              height={20}
              className={data.rightArrowIconColor ?? 'text-neutrals-900'}
            />
          </div>
        )}
      </div>
      {data.actions && <div>{data.actions}</div>}
    </XB_Card>
  );
};
