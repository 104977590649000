import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, XB_Button, XB_Modal, XB_Spinner } from '@core-components/atoms';
import { useEventProvider } from '@core-providers';
import { uploadFile } from '@core-services';

import './XB_FileUpload.style.scss';

interface FileUploadProps {
  id?: string;
  label?: string;
  secondaryLabel?: string;
  dataTestId: string;
  accept?: string;
  icon?: string;
  uploadFileText?: string;
  infoMsgText?: string;
  multiple?: boolean;
  disabled?: boolean;
  fileUploadHeader?: object;
  fileUploadName?: string;
  upload?: boolean;
  images?: object[];
  size?: number;
  btnSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  showUploadedFiles?: boolean;
  eventOnUpload?: (fileData?: any) => void;
  eventOnRemove?: (callback?: (error) => void) => void;
  allowDuplicateFile?: boolean;
  enableDeleteFile?: boolean;
  btnType?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'primary-error'
    | 'primary-error-50'
    | 'secondary-gray'
    | 'secondary-error'
    | 'tertiary-gray'
    | 'tertiary-error';
  beforeFileUpload?: (files: FileList | never[]) => Promise<boolean>;
  onFileDelete?: (val: string) => void;
}

export const XB_FileUpload = ({
  id,
  label,
  dataTestId,
  accept = 'image/png, image/jpeg, application/pdf, image/JFIF',
  icon = 'upload',
  uploadFileText = 'Upload File...',
  infoMsgText = 'jpeg, png, jfif (max. 5MB) ',
  multiple,
  disabled,
  btnType = 'secondary-gray',
  fileUploadHeader = {},
  upload = false,
  fileUploadName,
  images = [],
  size = 5,
  btnSize = 'md',
  eventOnUpload,
  eventOnRemove,
  onFileDelete,
  showUploadedFiles = true,
  secondaryLabel,
  enableDeleteFile = true,
  allowDuplicateFile = false,
  beforeFileUpload,
  ...otherProps
}: FileUploadProps) => {
  const { t } = useTranslation();
  const classList = ['file-upload'];
  const { getEventId } = useEventProvider();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [idx, setIdx] = useState(-1);

  const updateInputFiles = (fileName) => {
    const fileListArr = Array.from(inputRef.current?.files ?? []);
    const dataTransfer = new DataTransfer();
    fileListArr.forEach((file) => {
      if (file.name !== fileName) {
        dataTransfer.items.add(file);
      }
    });
    if (inputRef.current?.files) {
      inputRef.current.files = dataTransfer.files;
      setFiles(dataTransfer.files);
      inputRef.current?.dispatchEvent(new Event('change'));
    }
  };

  const removeFile = (fileName) => {
    if (eventOnRemove) {
      eventOnRemove((error) => {
        if (error) {
          const updatedFiles = Object.keys(files).reduce((acc, key) => {
            const file = files[key];
            file.isDeleting = false;
            return { ...acc, [key]: file };
          }, {});
          setFiles(updatedFiles);
        } else {
          updateInputFiles(fileName);
        }
      });
    } else {
      updateInputFiles(fileName);
    }
  };

  const selectFile = () => {
    inputRef.current?.click();
  };

  const isFileEncrypted = useCallback(async (file: File) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const textDecoder = new TextDecoder('utf-8');
      const content = textDecoder.decode(uint8Array);
      if (content.includes('%PDF')) {
        return content.includes('/Encrypt');
      }
      if (uint8Array[0] === 0xff && uint8Array[1] === 0xd8) {
        const len = uint8Array.length;
        return !(uint8Array[len - 2] === 0xff && uint8Array[len - 1] === 0xd9);
      }
      if (
        uint8Array[0] === 0x89 &&
        uint8Array[1] === 0x50 &&
        uint8Array[2] === 0x4e &&
        uint8Array[3] === 0x47
      ) {
        const IHDR = [0x49, 0x48, 0x44, 0x52];
        const IDAT = [0x49, 0x44, 0x41, 0x54];
        const IEND = [0x49, 0x45, 0x4e, 0x44];
        const hasIHDR = uint8Array
          .subarray(12, 16)
          .every((byte, index) => byte === IHDR[index]);
        const indexOfIDAT = Array.from(uint8Array.subarray(33)).findIndex(
          (_, indx, arr) => IDAT.every((b, i) => b === arr[indx + i])
        );
        const indexOfIEND = Array.from(uint8Array).findIndex((_, indx, arr) =>
          IEND.every((b, i) => b === arr[indx + i])
        );
        if (!hasIHDR || indexOfIDAT === -1 || indexOfIEND === -1) {
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }, []);

  const uploadFileData = useCallback(async (file: File) => {
    const config = {
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const fileObj = {};
        fileObj[file.name] = { name: file.name, progress: percentCompleted };
        setFiles({ ...files, ...fileObj });
      },
    };
    try {
      await uploadFile(
        file,
        { fileName: fileUploadName as string },
        fileUploadHeader,
        config
      );
    } catch (err) {
      const fileObj = {};
      fileObj[file.name] = {
        name: file.name,
        error: 'This is a error message.',
      };
      setFiles({ ...files, ...fileObj });
    }
  }, []);

  useEffect(() => {
    if (images?.length === 0) {
      setFiles({});
    }
  }, [images?.length]);

  useEffect(() => {
    if (images.length > 0) {
      const dataTransfer = new DataTransfer();
      images.forEach((file: any) => {
        const fileMake: any = new File([], file.name);
        fileMake.url = file.url;
        fileMake.isDeleting = false;
        dataTransfer.items.add(fileMake);
      });
      if (inputRef.current?.files) {
        inputRef.current.files = dataTransfer.files;
        setFiles(dataTransfer.files);
      }
    }
  }, [images]);

  const handleFileUpload = () => {
    setModalTitle(t(`passwordEncrytedFile`));
    setModalShow(true);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const checkFileExtension = (file, fileListArr) => {
    setFiles({});
    if (file.size < size * 1024 * 1024) {
      // Allowing file type  --- code
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
      if (!allowedExtensions.exec(file.name)) {
        setModalTitle(t(`fileTypeError`));
        setModalShow(true);
      } else {
        const url = URL.createObjectURL(file);
        file.url = url;
        setFiles(fileListArr);
        if (eventOnUpload) eventOnUpload(fileListArr);
      }
    } else {
      setModalTitle(t(`fileSizeError`, { size: `${size}` }));
      setModalShow(true);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const handleAllowDuplicateFile = () => {
    if (allowDuplicateFile && inputRef.current) {
      inputRef.current.value = '';
    }
  };
  useEffect(() => {
    const onFileUploadChange = async (): Promise<void> => {
      const fileListArr: any = Array.from(inputRef.current?.files ?? []);
      if (fileListArr?.length) {
        const selectedFile = fileListArr[0];
        const isEncrypted = await isFileEncrypted(selectedFile);
        if (isEncrypted) {
          handleFileUpload();
        } else {
          fileListArr.forEach((file) => {
            if (upload) {
              uploadFileData(file);
            } else {
              checkFileExtension(file, fileListArr);
            }
          });
        }
        handleAllowDuplicateFile();
      }
    };
    const handleOnChange = () => {
      if (beforeFileUpload) {
        beforeFileUpload(inputRef.current?.files ?? []).then(
          (shouldUpload: boolean) => {
            if (shouldUpload) {
              onFileUploadChange();
            } else if (inputRef.current) {
              inputRef.current.value = '';
            }
          }
        );
      } else {
        onFileUploadChange();
      }
    };
    inputRef.current?.addEventListener('change', handleOnChange);
    return () => {
      inputRef.current?.removeEventListener('change', handleOnChange as any);
    };
  }, [upload, eventOnUpload, beforeFileUpload]);

  return (
    <div className={`${classList.join(' ')}`}>
      {label && (
        <label className="m-text-md-regular" htmlFor={dataTestId}>
          {label}
        </label>
      )}

      {secondaryLabel && (
        <label className="m-text-sm-regular" htmlFor={dataTestId}>
          {secondaryLabel}
        </label>
      )}
      <div className="input__wrapper">
        <XB_Button
          dataTestId={dataTestId}
          iconName={icon}
          btnType={btnType}
          onClick={selectFile}
          disabled={disabled}
          size={btnSize}
          {...otherProps}
        >
          {uploadFileText}
        </XB_Button>
        <input
          id={id}
          ref={inputRef}
          data-testid={`${getEventId(dataTestId)}-INPT`}
          type="file"
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          {...otherProps}
        />
      </div>
      {infoMsgText && (
        <span
          className="input__info-text m-text-md-regular block pt-1.5 text-gray-500"
          id={`${dataTestId}-info`}
        >
          {t(infoMsgText)}
        </span>
      )}
      {Object.keys(files).length > 0 && (
        <div className="file-list">
          {showUploadedFiles &&
            Object.keys(files).map((file) => {
              const filesObject = files[file];
              const errorClass = `${
                filesObject.error ? 'upload-error' : 'test'
              }`;
              return (
                <span className="flex m-text-md-medium" key={filesObject.name}>
                  {filesObject.progress ? (
                    <>
                      {filesObject.progress < 100 && (
                        <span className="pr-2 loader"></span>
                      )}
                      <span className={'pr-4'}>
                        {filesObject.name} - {filesObject.progress}%
                      </span>
                    </>
                  ) : (
                    <span className={`pr-4 ${errorClass}`}>
                      <a
                        href={filesObject.url}
                        target="_blank"
                        className="text-gray-700"
                        rel="noreferrer"
                      >
                        {filesObject.name}
                      </a>
                    </span>
                  )}
                  {enableDeleteFile && (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setIdx(Number(file));
                        setModalTitle(t('areYouSureToDeleteImage'));
                        setModalShow(true);
                      }}
                    >
                      {filesObject.isDeleting ? (
                        <XB_Spinner size="sm" />
                      ) : (
                        <Icon icon="close" width={20} height={20} />
                      )}
                    </span>
                  )}
                </span>
              );
            })}
        </div>
      )}
      <XB_Modal
        img={
          <Icon
            width={48}
            height={48}
            color={'transparent'}
            icon="alert-modal"
          />
        }
        maxWidth="400px"
        open={modalShow}
        backdropClose={false}
        setOpen={setModalShow}
        title={modalTitle}
        subTitle={''}
        onModalClose={() => setModalShow(false)}
        actions={
          idx !== -1 ? (
            <div className="flex gap-4">
              <XB_Button
                btnType="secondary-gray"
                dataTestId="MDL-ONBRD-SO"
                classes="w-1/2"
                onClick={() => {
                  setModalShow(false);
                  setIdx(-1);
                }}
              >
                {t('no')}
              </XB_Button>
              <XB_Button
                dataTestId="MDL-ONBRD-SO"
                classes="w-1/2"
                onClick={() => {
                  const filesObject = files[idx];
                  filesObject.isDeleting = true;
                  setFiles({ ...files });
                  removeFile(filesObject.name);
                  onFileDelete?.(id ?? '');
                  setIdx(-1);
                  setModalShow(false);
                }}
              >
                {t('yes')}
              </XB_Button>
            </div>
          ) : (
            <XB_Button
              btnType="primary"
              dataTestId="SUPT-DOC-CNCL"
              classes="w-full"
              onClick={() => {
                setIdx(-1);
                setModalShow(false);
              }}
            >
              {t('ok')}
            </XB_Button>
          )
        }
      />
    </div>
  );
};
