import { createSlice } from '@reduxjs/toolkit';

import { AUDashboardRdxData } from '../../index';

const initialState: AUDashboardRdxData = {
  moneyTransferTabCount: 0,
  merchantId: '',
};

const auDashboardSlice = createSlice({
  name: 'au-dashboard',
  initialState,
  reducers: {
    setDashboardData(state, { payload }: { payload: AUDashboardRdxData }) {
      state.moneyTransferTabCount = payload?.moneyTransferTabCount ?? 0;
      state.merchantId = payload.merchantId;
    },
  },
});

export const { setDashboardData } = auDashboardSlice.actions;
export default auDashboardSlice.reducer;
