import { AUEndPoints, Service } from '@core-services';
import { handleError, logError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestUpdatePurpose } from '@src/core-stores/models/update-purpose.model';

export const getPurposeDetails = createAsyncThunk(
  'getPurposeDetails',
  async (purposeData: RequestUpdatePurpose, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.depositService.UPDATE_PURPOSE,
        obj: purposeData,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }
      return response.data;
    } catch (error) {
      logError('getNickNameDetails error', JSON.stringify(error));
      return rejectWithValue(handleError(error));
    }
  }
);
