import { AUEndPoints, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IConfigReq } from '../models/config-service.modal';

export const configRequest = createAsyncThunk(
  'configData',
  async (payload: IConfigReq, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'POST',
        baseurl: '',
        url: AUEndPoints.configService.CONFIG_URL,
        obj: payload,
      });
      if (
        response.data === null ||
        response.data.status === 'error' ||
        response.data.data === null
      ) {
        return rejectWithValue(handleError(response.data));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
