import { useEffect, useState } from 'react';

import { XB_Button } from '../button/XB_Button.component';

interface CarouselProps {
  children: React.ReactNode;
  carouselName: string;
  showIndicators?: boolean;
  indicators?: { prev: string; next: string };
  indicatorText?: string;
  showDots?: boolean;
  slideLength: number;
  animation?: boolean;
  autoLoopTime?: number;
}

export const XB_Carousel = ({
  children,
  carouselName,
  showIndicators = true,
  indicators = { prev: 'back', next: 'arrowright' },
  indicatorText = '',
  showDots = true,
  slideLength = 0,
  autoLoopTime = 0,
  animation = true,
}: CarouselProps) => {
  const colorOrange = '#f37435';
  const colorGray = '#D1D2D5';

  const [slideIndex, setSlideIndex] = useState(0);

  const onIndicatorClick = (moveStep: number) => {
    if (slideIndex + moveStep < 0) {
      setSlideIndex(slideLength - 1);
      showSlide(slideLength - 1);
    } else if (slideIndex + moveStep >= slideLength) {
      setSlideIndex(0);
      showSlide(0);
    } else {
      setSlideIndex(slideIndex + moveStep);
      showSlide(slideIndex + moveStep);
    }
  };

  const onDotClick = (n) => {
    setSlideIndex(n);
    showSlide(n);
  };

  const showSlide = (n: number) => {
    let i: number;
    const slides = document.getElementsByClassName('slide') as any;
    const dots = document.getElementsByClassName('dot') as any;

    if (n < 0) setSlideIndex(slideLength - 1);
    if (n > slideLength - 1) setSlideIndex(0);

    // hide all slides and dots class
    if (slides?.length > 0) {
      for (i = 0; i < slideLength; i++) {
        if (animation) {
          slides[i].style.cssText = 'position:absolute;top:0';
          slides[i].style.transform = `translateX(${(i - n) * 100}%)`;
        } else
          slides[i].style.cssText = 'position:absolute;top:0;display:none;';

        if (showDots) {
          dots[i].classList.remove('bg-orange-600');
          dots[i].classList.add('bg-orange-300');
        }
      }
      if (animation) slides[n].style.cssText = 'position:inherit';
      else slides[n].style.cssText = 'position:inherit;display:block';
      // show the active slide and dot class
      if (showDots) {
        dots[n].classList.remove('bg-orange-300');
        dots[n].classList.add('bg-orange-600');
      }
    }
  };

  useEffect(() => {
    showSlide(0);
  }, []);

  useEffect(() => {
    let autoLoopTimer: ReturnType<typeof setTimeout>;
    if (autoLoopTime) {
      autoLoopTimer = setTimeout(() => {
        onIndicatorClick(1);
      }, autoLoopTime);
    }
    return () => {
      clearTimeout(autoLoopTimer);
    };
  }, [slideIndex]);

  return (
    <>
      <div className={`slider ${carouselName}`} id={carouselName}>
        <div className="slider-items">{children}</div>
      </div>
      {showDots && (
        <div
          className="flex justify-center space-x-3 w-full mt-8"
          id={`${carouselName}-dots`}
        >
          {Array.from({ length: slideLength }, (_x, i) => i).map((dot) => {
            return (
              <div
                key={dot}
                className="dot w-2 h-2 rounded-full cursor-pointer"
                onClick={() => onDotClick(dot)}
              ></div>
            );
          })}
        </div>
      )}
      {showIndicators && (
        <div className="flex justify-center items-center mt-4">
          <XB_Button
            iconName={indicators.prev}
            dataTestId="PREV"
            btnType="tertiary-gray"
            iconColor={slideLength > 1 ? colorOrange : colorGray}
            onClick={() => onIndicatorClick(-1)}
            disabled={slideLength <= 1}
          />
          {indicatorText && (
            <p className="m-text-xs-bold mx-2 text-gray-700">
              {indicatorText} {slideIndex + 1}/{slideLength}
            </p>
          )}
          <XB_Button
            iconName={indicators.next}
            dataTestId="NEXT"
            btnType="tertiary-gray"
            iconColor={slideLength > 1 ? colorOrange : colorGray}
            onClick={() => onIndicatorClick(1)}
            disabled={slideLength <= 1}
          />
        </div>
      )}
    </>
  );
};

interface CarouselSlideProps {
  children: React.ReactNode;
}
export const XB_Carousel_Slide = ({ children }: CarouselSlideProps) => {
  return (
    <>
      <div className={`slide`}>{children}</div>
    </>
  );
};
