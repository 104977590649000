import { createSlice } from '@reduxjs/toolkit';

export interface IUserProfileImg {
  img: string | undefined;
}

const initialState: IUserProfileImg = {
  img: '',
};

const userProfileImageSlice = createSlice({
  name: 'userProfileImg',
  initialState,
  reducers: {
    setUserProfileImg(state, { payload }) {
      state.img = payload;
    },
  },
});
export const { setUserProfileImg } = userProfileImageSlice.actions;
export default userProfileImageSlice.reducer;
