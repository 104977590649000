import { createSlice } from '@reduxjs/toolkit';

import { ResPaymentTransfer } from '../models/payment-transfer.model';
import { paymentTransfer } from '../thunks/payment-transfer.thunk';

const initialState: ResPaymentTransfer = {
  status: undefined,
  loading: false,
  successfulResponse: undefined,
  error: undefined,
  data: undefined,
  paymentToPayeeReq: undefined,
  selectedPayeeId: undefined,
  selectedFromAcc: undefined,
  selectedTxn: undefined,
  selectedPaymentForAddPayee: undefined,
};
const PaymentTransferRes = createSlice({
  name: 'paymentTransfer',
  initialState,
  reducers: {
    setPaymentToPayeeReq: (state, { payload }) => {
      state.paymentToPayeeReq = payload;
    },
    setSelectedPayeeId: (state, { payload }) => {
      state.selectedPayeeId = payload;
    },
    setSelectedFromAcc: (state, { payload }) => {
      state.selectedFromAcc = payload;
    },
    setSelectedTxn: (state, { payload }) => {
      state.selectedTxn = payload;
    },
    setSelectedPaymentForAddPayee: (state, { payload }) => {
      state.selectedPaymentForAddPayee = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(paymentTransfer.fulfilled, (state, { payload }) => {
      ({
        status: state.status,
        error: state.error,
        successfulResponse: state.successfulResponse,
        data: state.data,
        loading: state.loading,
      } = payload);
    });
    builder.addCase(paymentTransfer.pending, (state) => {
      state.status = undefined;
      state.successfulResponse = undefined;
      state.data = undefined;
      state.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      paymentTransfer.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = undefined;
        state.error = payload;
        state.successfulResponse = undefined;
        state.data = undefined;
        state.loading = false;
      }
    );
  },
});

export const {
  setPaymentToPayeeReq,
  setSelectedPayeeId,
  setSelectedFromAcc,
  setSelectedTxn,
  setSelectedPaymentForAddPayee,
} = PaymentTransferRes.actions;
export default PaymentTransferRes.reducer;
