import { AUEndPoints, ServerError, Service } from '@core-services';
import { handleError } from '@core-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAppIcons = createAsyncThunk(
  '',
  async (_req: any, { rejectWithValue }) => {
    try {
      const response = await Service.send({
        method: 'GET',
        baseurl: AUEndPoints.strapiService.getAppIcons,
        url: '',
        obj: {},
      });
      if (response.data.status === 'error') {
        const error = handleError(response.data);
        throw new ServerError(error);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.getErrorMessage());
    }
  }
);
