import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { XB_AuthCarousel } from '@core-components/templates';
import { AuthLayout_footer_links } from '@src/core-constants';

import AuLogo from '@src/assets/images/AuLogo.png';

import './XB_AuthLayout.style.scss';

export const XB_AuthLayout = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="auth-screen">
        <div className="auth-screen__container bg-white">
          <div className="auth-screen__carousel relative bg-purple-800 h-screen flex flex-col justify-between">
            <div className="h-[70px] w-[70px] mt-6 ml-6 au-logo hidden md:block">
              <img src={AuLogo} alt="au-logo" style={{ borderRadius: 5 }} />
            </div>
            <XB_AuthCarousel />
            <div className="auth-screen__footer-links justify-center mt-4 hidden md:flex items-center flex-wrap mb-4">
              {AuthLayout_footer_links.map((item, index) => {
                return (
                  <div className="flex " key={index}>
                    <div className="link px-1 flex">
                      {item.url === '' ? (
                        <a
                          className="m-text-xs-regular text-white cursor-pointer opacity-50"
                          rel="noreferrer"
                        >
                          {t(item.title)}
                        </a>
                      ) : (
                        <a
                          href={item.url}
                          target="_blank"
                          className="m-text-xs-regular text-white cursor-pointer opacity-50"
                          rel="noreferrer"
                        >
                          {t(item.title)}
                        </a>
                      )}
                    </div>
                    {index !== AuthLayout_footer_links.length - 1 && (
                      <div className="bg-white w-[1px] h-[12px] mt-auto opacity-50" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="auth-screen__content h-screen overflow-scroll relative">
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
};
